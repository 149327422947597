const OrderNumberChip = ({ id, children }) => {
  return (
    <span
      style={{
        background: "rgba(21, 56, 98, 0.1)",
        borderRadius: "38px",
        fontSize: "10px",
        color: "rgba(21, 56, 98, 0.5)",
        padding: "2px 8.5px",
      }}
      data-test-id={id}
    >
      {children}
    </span>
  );
};

export { OrderNumberChip };
