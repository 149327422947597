import React, { useContext } from "react"

import { DateTime } from "luxon";

import { Box } from "@mui/material"

import { LanguageContext } from "../../hooks/LanguageContext";

const LastPurchaseCell = ({ params }) => {
  const { currentLanguage } = useContext(LanguageContext);

  const formatDateTime = () => {
    if (params.lastFeelGreatOrderDate) {
      const formattedDate = DateTime.fromISO(params.lastFeelGreatOrderDate).setLocale(currentLanguage.split("-")[0]).toLocaleString(DateTime.DATE_MED);
      return formattedDate
    } return null
  }

  if (params.lastFeelGreatOrderDate) return (
    <Box sx={{
      fontSize: "12px",
      opacity: params.count !== 0 ? "" : ".7"
    }}>
      {formatDateTime()}
    </Box>
  )
}

export { LastPurchaseCell }