import React from "react"

import { Stack, Typography } from "@mui/material";

const PartnersGenealogyChip = ({ isSelected, number }) => {
  return (
    <Stack
      data-test-id={`genealogy-number-chip-${number}`}
      alignItems="center"
      sx={{
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: "38px",
        height: "12px",
        width: "16px"
      }}>
      <Typography sx={{
        color: isSelected ? "#009ECF" : "#93ABC9",
        fontWeight: "700",
        fontSize: "8px"
      }}>
        {number}
      </Typography>
    </Stack>
  )
}

export { PartnersGenealogyChip }