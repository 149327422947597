import React from "react";
import { T, useTranslateKey } from "../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useFlags } from 'launchdarkly-react-client-sdk';

const GlobalNoticeComponent = () => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const { showBanner } = useFlags();

  const translatedKey = useTranslateKey(showBanner?.bannerKey);
  const bannerMessage = translatedKey !== `{{${showBanner?.bannerKey}}}` ? translatedKey : showBanner?.bannerMessage;

  const noticeType = {
    error: {
      sx: {
        borderRadius: "8px",
        border: "1px solid rgba(255, 0, 0, 0.25)",
        backgroundColor: "rgba(255, 0, 0, 0.05)",
      },
    },
    warning: {
      sx: {
        borderRadius: "8px",
        border: "1px solid rgba(241, 182, 68, 0.25)",
        backgroundColor: "rgba(241, 182, 68, 0.05)",
      },
    },
    info: {
      sx: {
        borderRadius: "8px",
        border: "1px solid rgba(0, 0, 255, 0.25)",
        backgroundColor: "rgba(0, 0, 255, 0.05)",
      },
    },
    success: {
      sx: {
        borderRadius: "8px",
        border: "1px solid rgba(0, 128, 0, 0.25)",
        backgroundColor: "rgba(0, 128, 0, 0.05)",
      },
    }
  }

  return showBanner?.show &&
    (
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={1}
        p={1.5}
        mr={!viewportIsSmall && 2}
        ml={!viewportIsSmall && 2}
        sx={noticeType[showBanner?.severity]?.sx}
      >
        <img src="icons/notice-icon.svg" alt="notice icon" style={{ height: 20, width: 20 }} />
        <Typography sx={{
          fontSize: "12px",
          fontWeight: "400",
          color: "#153862"
        }}>
          <strong><T>notice</T>:</strong> {bannerMessage}
        </Typography>
      </Stack>
    )
}

export { GlobalNoticeComponent }