import React, { useState } from "react";
import { T, useTranslateKey } from "../../../../hooks/useTranslate";
import { useAuth } from "../../../../hooks/useAuth";
import {
  Stack,
  Typography,
  Button,
  Divider,
  Alert,
  Box
} from "@mui/material";
import { OfficeBox } from "../../../../components/OfficeBox";
import { SettingsTextField } from "../../SettingsTextField";
import styles from "../../settingsScreenStyles";
import { formatPhoneNumber } from "../../../../common/format-functions";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ContactInfoModal } from "./ContactInfoModal";
import { useFormikInstances } from './useFormikInstances';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ContactInfoSection = () => {
  // reauthentication state
  const [isReauthenticated, setIsReauthenticated] = useState(false);
  const [isEmailChange, setIsEmailChange] = useState(false);
  const [isUpdated, setIsUpdated] = useState({ open: false, type: null, value: null });

  // modal state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // modal loading state
  const [isModalLoading, setIsModalLoading] = useState(false);

  const translateCurrentEmailAddress = useTranslateKey("current_email_address");
  const translateCurrentPhoneNumber = useTranslateKey("current_phone_number");
  const translatePhoneUnavailable = useTranslateKey("phone_unavailable");
  const translateSuccessEmail = useTranslateKey("successful_email");
  const translateSuccessPhone = useTranslateKey("successful_phone");

  const { userInfo } = useAuth();
  const { formikReauthentication, formikNewEmail, formikNewPhone } = useFormikInstances(
    setIsModalLoading,
    setIsReauthenticated,
    setIsModalOpen,
    setIsUpdated
  );

  const phoneText =
    formatPhoneNumber(userInfo.homePhone) ||
    formatPhoneNumber(userInfo.mobilePhone) ||
    formatPhoneNumber(userInfo.workPhone) ||
    translatePhoneUnavailable;

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsReauthenticated(false);
    // reset all forms, this worked more consistently than using formik.resetForm()
    formikReauthentication.setValues(formikReauthentication.initialValues);
    formikNewEmail.setValues(formikNewEmail.initialValues);
    formikNewPhone.setValues(formikNewPhone.initialValues);
    // revert to "complete" on close to remove error message for failed reauth
    userInfo.state = "complete";
  };

  return (
    <OfficeBox data-test-id="contact-info-section" sx={styles.mainContent}>
      <Typography
        data-test-id="contact-info-title"
        variant="h2"
      >
        <T>contact_info</T>
      </Typography>
      {
        isUpdated.open &&
        <Box mt={2}>
          <Alert severity="info" icon={<InfoOutlinedIcon sx={{ color: "#153862" }} />} data-test-id="update-alert">
            <Typography sx={styles.alert} data-test-id="update-alert-text">
              {isUpdated.type === "email" ? translateSuccessEmail : translateSuccessPhone}: <strong>{isUpdated.value}</strong>
            </Typography>
          </Alert>
        </Box>
      }
      <Stack
        direction={viewportIsLarge ? "row" : "column"}
        gap={viewportIsLarge ? 2 : 4}
        mt={5}
      >
        <EmailSection
          userInfo={userInfo}
          translateCurrentEmailAddress={translateCurrentEmailAddress}
          setIsEmailChange={setIsEmailChange}
          handleOpenModal={handleOpenModal}
        />
        {!viewportIsLarge && <Divider />}
        <PhoneSection
          phoneText={phoneText}
          translateCurrentPhoneNumber={translateCurrentPhoneNumber}
          setIsEmailChange={setIsEmailChange}
          handleOpenModal={handleOpenModal}
        />
      </Stack>
      <ContactInfoModal
        isReauthenticated={isReauthenticated}
        setIsReauthenticated={setIsReauthenticated}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isModalLoading={isModalLoading}
        setIsModalLoading={setIsModalLoading}
        handleCloseModal={handleCloseModal}
        isEmailChange={isEmailChange}
        setIsUpdated={setIsUpdated}
      />
    </OfficeBox>
  );
};

const EmailSection = ({ userInfo, translateCurrentEmailAddress, setIsEmailChange, handleOpenModal }) => {
  return <Stack flex={1} alignItems="flex-start">
    <Typography
      variant="h4"
      mb={3}
      data-test-id="email-address-title"
    >
      <T>email_address</T>
    </Typography>
    <SettingsTextField
      name="email"
      content={userInfo.email}
      isLocked={true}
      label={translateCurrentEmailAddress}
      mb={2}
    />
    <Button
      data-test-id="change-email-address-button"
      sx={{
        ...styles.contactInfoButton,
        marginTop: "1rem",
      }}
      onClick={() => {
        setIsEmailChange(true);
        handleOpenModal();
      }}
    >
      <Typography
        data-test-id="change-email-address-button-text"
        variant="h4"
        color="#153862"
      >
        <T>change_email_address</T>
      </Typography>
    </Button>
  </Stack>
}

const PhoneSection = ({ phoneText, translateCurrentPhoneNumber, setIsEmailChange, handleOpenModal }) => {
  return <Stack flex={1} alignItems="flex-start">
    <Typography
      data-test-id="email-address-title"
      variant="h4"
      mb={3}
    >
      <T>phone_number</T>
    </Typography>
    <SettingsTextField
      name="phone"
      content={phoneText}
      isLocked={true}
      label={translateCurrentPhoneNumber}
      mb={2}
    />
    <Button
      data-test-id="change-phone-number-button"
      sx={{
        ...styles.contactInfoButton,
        marginTop: "1rem",
      }}
      onClick={() => {
        handleOpenModal();
        setIsEmailChange(false);
      }}
    >
      <Typography
        data-test-id="change-phone-number-button-text"
        variant="h4"
        color="#153862"
      >
        <T>change_phone_number</T>
      </Typography>
    </Button>
  </Stack>
}

export { ContactInfoSection };
