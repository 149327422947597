// function returns the first unfilled rank idx
const getLowestUnfilledRankIdx = (scopedRankOptions) => {
  let idx;

  for (let i = 0; i < scopedRankOptions.length; i++) {
    let metrics = scopedRankOptions[i].metrics;

    const legObject1 = metrics.filter((metric) =>
      metric.name.includes("ov_leg1")
    )[0];
    const legObject2 = metrics.filter((metric) =>
      metric.name.includes("ov_leg2")
    )[0];
    const legObject3 = metrics.filter((metric) =>
      metric.name.includes("ov_leg3")
    )[0];

    // return if any of the three leg objects are undefined
    if (legObject1 === undefined || legObject2 === undefined || legObject3 === undefined) {
      return idx;
    }

    if (legObject1.value < legObject1.requiredValue || legObject2.value < legObject2.requiredValue || legObject3.value < legObject3.requiredValue) {
      idx = i;
      break;
    }
  }

  return idx;
}

const getLowestNotQualifiedRankIdx = (scopedRankOptions) => {
  let idx;

  for (let i = 0; i < scopedRankOptions.length; i++) {
    if (scopedRankOptions[i].qualified === false) {
      idx = i;
      break;
    }
  }

  return idx
}

const translations = {
  active_legs: {
    qualified: "active_legs_qualified",
    unqualified: "active_legs_unqualified"
  },
  pv: {
    qualified: "pv_qualified",
    unqualified: "pv_unqualified"
  },
  tv: {
    qualified: "tv_qualified",
    unqualified: "tv_unqualified",
  }
};

export { getLowestUnfilledRankIdx, getLowestNotQualifiedRankIdx, translations }