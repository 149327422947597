import React, { useState } from 'react';
import { T, useTranslateKey } from "../../hooks/useTranslate";
import {
  Box,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText
} from "@mui/material"
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { columns } from "../../screens/MyMembersScreen/format-members-columns";
import { styles } from '../../themes/datagrid.styles';
import { getFilterButtonStyling } from "../../screens/PartnersScreen/getFilterButtonStyling";
import { useMixPanel } from '../../hooks/useMixPanel';


const ManageColumns = ({ loading, columnVisibility, setColumnVisibility }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { logEvent } = useMixPanel();

  const translateKey = useTranslateKey;

  const handleButtonClick = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManageColumnClick = (e) => {
    const newObj = { ...columnVisibility };
    const currentField = e.currentTarget.getAttribute("value");

    newObj[currentField] = !columnVisibility[currentField];
    setColumnVisibility(newObj);
    sessionStorage.setItem("membersColumnVisibility", JSON.stringify(newObj));
    logEvent("members_manage_columns_edit", {
      location: "members",
      columnVisibilityModel: newObj
    });
  };

  const textStyle = {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "14px"
  }

  return (
    <>
      <Box
        id="manage-column-button"
        aria-controls={open ? "manage-column-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="open manage columns"
        onClick={handleButtonClick}
        style={getFilterButtonStyling(loading, open, false)}
        sx={{
          ...styles.button,
          paddingLeft: ".75rem",
          paddingRight: ".75rem",
          opacity: loading ? ".5" : null,
          "&:hover": {
            cursor: loading ? "not-allowed" : "pointer",
            backgroundColor: "#FAFAFA"
          }
        }}>
        <ViewColumnOutlinedIcon sx={{ marginRight: "5px" }} /><T>manage_columns</T>
      </Box>
      <Menu
        id="manage-column-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "manage-column-button",
        }}
        PaperProps={{
          style: {
            width: "240px",
            marginTop: "8px",
            borderRadius: "8px",
            border: "1px solid rgba(145, 158, 171, 0.1)",
            boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)'
          },
        }}
      >
        <Box
          sx={{
            ...styles.subheaderStyle,
            padding: "16px 24px 0px 24px",
          }}><T isUpperCase={true}>manage_columns</T></Box>
        {columns.map((column) => {
          if (column.field === "name") return null;
          return <MenuItem
            key={column.headerName}
            value={column.field}
            data-test-id={`manage-columns-${column.headerName}`}
            aria-label={column.headerName}
            onClick={handleManageColumnClick}
            disabled={column.field === "name" ? true : loading}
          >
            <Checkbox
              checked={columnVisibility[column.field] === true}
              disabled={
                column.field === "name" ? true : loading
              }
            />
            <ListItemText primary={translateKey(column.headerName)} primaryTypographyProps={textStyle} />
          </MenuItem>
        })}
      </Menu>
    </>
  )
};

export { ManageColumns }