const businessDrawerItems = [
  {
    text: "dashboard",
    value: "dashboard",
    url: "/dashboard",
    eventName: "dashboard_view",
    iconSrc: "icons/dashboard-icon.svg"
  },
  {
    text: "members",
    value: "members",
    url: "/members",
    eventName: "members_view",
    iconSrc: "icons/people-icon.svg"
  },
  {
    text: "partners",
    value: "partners",
    url: "/partners",
    eventName: "partners_view",
    iconSrc: "icons/business-icon.svg"
  },
  {
    text: "action_hub",
    value: "actionhub",
    url: "/actionhub",
    eventName: "action_hub_view",
    iconSrc: "icons/actionhub-icon.svg"
  },
  {
    text: "centurion",
    value: "centurion",
    url: "/centurion",
    eventName: "centurion_view",
    iconSrc: "icons/centurion-icon.svg"
  },
  {
    text: "referral_links",
    value: "referrals",
    url: "/referrals",
    eventName: "referral_links_view",
    iconSrc: "icons/link-icon.svg"
  },
  {
    text: "earnings",
    value: "earnings",
    url: "/earnings",
    eventName: "earnings_view",
    iconSrc: "icons/earnings-icon.svg"
  },
  {
    text: "settings",
    value: "settings",
    url: "/settings/my-profile",
    eventName: "settings_view",
    iconSrc: "icons/settings-icon.svg"
  },
];

const resourceDrawerItems = [
  {
    text: "library",
    value: "library",
    url: process.env.REACT_APP_LIBRARY_URL,
    eventName: "old_library_view",
  },
  {
    text: "help_center",
    value: "external-help",
    url: "",
    eventName: "help_center_view",
  },
  {
    text: "shop_feel_great",
    value: "shop-feel-great",
    url: "",
    eventName: "shop_feel_great_view"
  },
  {
    text: "shop_unicity",
    value: "shop-unicity",
    url: "",
    eventName: "shop_unicity_view",
  },
];

export { businessDrawerItems, resourceDrawerItems };
