import React from "react";
import { T } from "../hooks/useTranslate";
import { LanguageContext } from "../hooks/LanguageContext";

import { Stack, Box } from "@mui/material";

const RowPageCount = ({ rowCount, page, pageSize, highestRowOnPage}) => {
  const { localizeNumber } = React.useContext(LanguageContext);
  return (
    <Stack
      direction="row"
      data-test-id={`row-count`}
      gap={.4}>
      <Stack direction="row">
        <Box>
          {rowCount === 0 ? 0 : localizeNumber(((page + 1) * pageSize - (pageSize - 1)))}
        </Box>
        <Box>
          -
        </Box>
        <Box>
          {localizeNumber(highestRowOnPage)}
        </Box>
      </Stack>
      <T>of</T>
      <Box>
        {localizeNumber(rowCount)}
      </Box>
    </Stack>
  );
}

export { RowPageCount }