import { useState, createContext, useEffect } from "react";

import { useAuth } from "./useAuth";
import { useApi } from "./useApi";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getMonthRange, getQualificationPeriodMonth } from "../common/date-functions";

const PartnersContext = createContext([{}, () => { }]);

const PartnersContextProvider = (props) => {
  const { userInfo } = useAuth();
  const { sendRequest } = useApi();

  // use viewport to determine default pagesize
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const [downlineUid, setDownlineUid] = useState(userInfo.unicityId);
  const [downlineUidInfo, setDownlineUidInfo] = useState({});
  const [contextUserJoinDate, setContextUserJoinDate] = useState("");
  const [contextUserFirstName, setContextUserFirstName] = useState("Frontline");
  const [contextUserId, setContextUserId] = useState("");
  const [contextUserStats, setContextUserStats] = useState(null);
  const [contextUserType, setContextUserType] = useState("");
  const [customerNotes, setCustomerNotes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [includeMembers, setIncludeMembers] = useState(false);
  const [isLevelLimitMax, setIsLevelLimitMax] = useState(false);
  const [gridUpdateTimestamp, setGridUpdateTimestamp] = useState()
  const [hasError, setHasError] = useState(false);
  const [leg1UnicityId, setLeg1UnicityId] = useState(null);
  const [leg2UnicityId, setLeg2UnicityId] = useState(null);

  // DataGrid state
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  // store sort model, column visibility model, and mobile column in local storage to persist state across refereshes and sessions
  const [sortModel, setSortModel] = useState(
    sessionStorage.getItem("partnersSortModel")
      ? JSON.parse(sessionStorage.getItem("partnersSortModel"))
      : [{ field: "totalOv", sort: "desc" }]
  );
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    sessionStorage.getItem("partnersColumnVisibilityModel")
      ? JSON.parse(sessionStorage.getItem("partnersColumnVisibilityModel"))
      : viewportIsSmall ? {
        name: true,
        nextRank: true,
        newPartners: true,
        centurionCount: true,
        pv: true,
        uncompressedTv: true,
        totalOv: true,
        relevance: false,
      } : {
        name: true,
        nextRank: false,
        newPartners: false,
        centurionCount: false,
        pv: false,
        uncompressedTv: false,
        totalOv: true,
        relevance: false,
      }
  );

  // activity filter button state
  const [filterSelection, setFilterSelection] = useState("all-statuses");
  const [filterValue, setFilterValue] = useState("all-statuses");

  // month dropdown
  const [contextMonthRange, setContextMonthRange] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  const resetContext = () => {
    setDownlineUid("");
    setDownlineUidInfo({});
    setContextUserJoinDate("");
    setContextUserFirstName("Frontline");
    setCustomerNotes(false);
    setLoading(false);
    setRows([]);
    setRowCount(0);
    setPage(0);
    setPageSize(50);
    setFilterSelection("all-statuses");
    setFilterValue("all-statuses");
    setSearchQuery("");
  };

  const setUpdateTimestamp = (membersTimestamp, partnersTimestamp) => {
    if (new Date(membersTimestamp) < new Date(partnersTimestamp)) {
      setGridUpdateTimestamp(membersTimestamp)
    } else {
      setGridUpdateTimestamp(partnersTimestamp)
    }
  }

  useEffect(() => {
    if (!userInfo.loggedIn) {
      resetContext();
    }
  }, [userInfo]);

  // on mount get and set default month
  useEffect(() => {
    // get month range and set default value to most recent month
    setContextMonthRange(getMonthRange(3));
    setSelectedMonth(getQualificationPeriodMonth(3));
  }, []);

  // clear DG state when downlineUid changes
  useEffect(() => {
    setRows([]);
    setPage(0);
    setRowCount(0);
  }, [downlineUid]);

  let hasErroredOnce = false;

  // hit API when parameters change or are set
  useEffect(() => {
    const abortController = new AbortController();

    const { loggedIn, unicityId } = userInfo;

    const handleError = (err) => {
      setHasError(true);
      console.log(err);

      //if we hit an error with the current month, maybe the commission engine hasn't run yet;
      //try getting the previous month's data.
      if (!hasErroredOnce) {
        const month = getMonthRange(2)[1];
        setSelectedMonth(month.value);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      hasErroredOnce = true;
    };

    if (loggedIn && downlineUid && unicityId) {
      setLoading(true);
      setRows([]);
      setContextUserFirstName("");
      const offset = page * pageSize;

      const filterQueryParam =
        filterValue !== "all-statuses" ? `&customerStatus=${filterValue}` : "";
      const searchQueryParam = searchQuery ? `&searchTerm=${searchQuery}` : "";
      let promiseArray = [];
      const includeMembersQuery = includeMembers ? "&type=all" : "&type=partners";

      const promise1 = sendRequest({
        method: "get",
        endpoint: `${process.env.REACT_APP_OFFICE_API
          }/${downlineUid}/partnersmembers?sortField=${sortModel[0].field
          }&sortDirection=${sortModel[0].sort
          }&offset=${offset}&limit=${pageSize}${filterQueryParam}${searchQueryParam}${includeMembersQuery}&period=${selectedMonth.replace(
            "-",
            ""
          )}&distributorUnicityId=${unicityId}`,
        addPrefix: false,
        abortController,
      }).catch(handleError);

      promiseArray.push(promise1);

      // eslint-disable-next-line
      if (downlineUid != userInfo.unicityId) {
        const promise2 = sendRequest({
          method: "get",
          endpoint: `${process.env.API_URL}/customers?unicity=${downlineUid}&expand=customer`,
          addPrefix: false,
          abortController,
        }).catch(handleError);

        const promise3 = sendRequest({
          method: "get",
          endpoint: `${process.env.REACT_APP_OFFICE_API}/${downlineUid}/stats`,
          addPrefix: false,
          abortController,
        }).catch((err) => console.log(err));

        promiseArray.push(promise2);
        promiseArray.push(promise3);

        const promise4 = sendRequest({
          method: "get",
          addPrefix: false,
          endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/notes?customerId=${downlineUid}`,
          abortController,
        }).catch((err) => console.log(err));

        promiseArray.push(promise4);
      }

      Promise.all(promiseArray)
        .then((values) => {
          setRowCount(values[0]?.data?.hits || 0);
          setIsLevelLimitMax(values[0]?.data?.isDistributorLevelLimit || false)
          setUpdateTimestamp(values[0]?.data?.members_last_updated, values[0]?.data?.partners_last_updated)
          setLeg1UnicityId(values[0]?.data?.leg1UnicityId)
          setLeg2UnicityId(values[0]?.data?.leg2UnicityId)
          setRows(
            values[0]?.data?.items?.map((item, idx) => {
              item.id = idx;
              return item;
            })
          );

          if (values[1] && values[1].data?.items[0]?.type) {
            setContextUserType(values[1].data?.items[0]?.type === "Associate" ? "partner" : "member");
          }

          if (values[1] && values[1].data?.items[0].humanName.fullName)
            setContextUserFirstName(
              `${values[1].data?.items[0].humanName.firstName ||
              values[1].data?.items[0].humanName.fullName
              }`
            );
          else setContextUserFirstName("");

          if (values[1] && values[1].data?.items[0].unicity)
            setContextUserId(values[1].data?.items[0].unicity);
          else setContextUserId("");

          // set user stats if third promise value exists
          if (values[2]) setContextUserStats(values[2].data);

          setCustomerNotes(values[3] !== undefined ? values[3].data.items : [])

          setLoading(false);

          values[1]?.data &&
            setContextUserJoinDate(values[1]?.data?.items[0]?.joinDate);
        })
        .catch((err) => {
          setLoading(false);
          setHasError(true);
          console.log(err);
        });
    }

    return () => abortController.abort();
    // eslint-disable-next-line
  }, [
    page,
    pageSize,
    sortModel,
    filterValue,
    downlineUid,
    selectedMonth,
    userInfo,
    searchQuery,
    includeMembers
  ]);

  useEffect(() => {
    if (!viewportIsSmall) {
      setPageSize(10);
    }
  }, [viewportIsSmall]);

  return (
    <PartnersContext.Provider
      value={{
        rows,
        filterSelection,
        setFilterSelection,
        filterValue,
        setFilterValue,
        contextMonthRange,
        selectedMonth,
        setSelectedMonth,
        rowCount,
        setRowCount,
        page,
        setPage,
        pageSize,
        setPageSize,
        columnVisibilityModel,
        setColumnVisibilityModel,
        sortModel,
        setSortModel,
        loading,
        setLoading,
        downlineUid,
        setDownlineUid,
        downlineUidInfo,
        setDownlineUidInfo,
        contextUserFirstName,
        contextUserId,
        contextUserJoinDate,
        contextUserStats,
        customerNotes,
        resetContext,
        searchQuery,
        setSearchQuery,
        includeMembers,
        setIncludeMembers,
        contextUserType,
        isLevelLimitMax,
        gridUpdateTimestamp,
        hasError,
        leg1UnicityId,
        leg2UnicityId,
      }}
    >
      {props.children}
    </PartnersContext.Provider>
  );
};

export { PartnersContext, PartnersContextProvider };
