import { useContext } from "react"
import { IconButton, Box } from "@mui/material"
import { LanguageContext } from "../hooks/LanguageContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const BackArrowButton = ({ onBackArrowClick }) => {
  const { languageDir } = useContext(LanguageContext);
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const style = {
    cursor: "pointer",
    transform: languageDir === "rtl" ? "rotate(180deg)" : "none"
  }

  return (
    viewportIsSmall ? (<>
      <Box sx={{ display: "flex", justifyContent: "center" }} onClick={onBackArrowClick}>
        <img src="icons/arrow-left.svg" alt="back arrow" style={style} />
      </Box>
    </>) : (<>
      <IconButton
        id="back-arrow"
        data-test-id="back-arrow"
        aria-label="back arrow"
        onClick={onBackArrowClick}
        sx={{
          height: "40px",
          width: "40px",
          border: "1px solid rgba(145, 158, 171, 0.1)",
          boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
          borderRadius: "8px"
        }}
      >
        <img
          src="icons/arrow-left.svg"
          alt="menu-icon"
          style={{ transform: languageDir === "rtl" ? "rotate(180deg)" : "none" }}
        />
      </IconButton>
    </>)
  )
}

export { BackArrowButton }