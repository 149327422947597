import React from "react";
import { useMixPanel } from "../../../hooks/useMixPanel";

import { Box, Link } from "@mui/material"

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ContactCell = ({ params, screen }) => {
  const { logEvent } = useMixPanel();
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const handleContactClick = (type) => {
    logEvent(type, {
      location: `${screen}_desktop_view`,
      user_type: "member",
      target_id: params.unicityId
    })
  }

  return (
    <Box sx={{ display: "flex", gap: "16px", justfiyContent: "center" }}>
      {
        params.homePhone ?
          <Link
            href={`tel:${params.homePhone}`}
            sx={{ display: "flex", justifyContent: "center" }}
            target="_blank" rel="noreferrer"
            onClick={() => handleContactClick("phone_contact")}
          >
            <img src="icons/phone.svg" alt="phone icon" />
          </Link> :
          <Link
            sx={{ display: "flex", justifyContent: "center", cursor: "not-allowed" }}>
            <img src="icons/phone-disabled.svg" alt="phone icon" />
          </Link>
      }
      {
        params.homePhone ?
          <Link
            href={`sms:${params.homePhone}`}
            sx={{ display: "flex", justifyContent: "center" }}
            target="_blank" rel="noreferrer"
            onClick={() => handleContactClick("sms_contact")}
          >
            <img src="icons/message.svg" alt="message icon" />
          </Link> :
          <Link
            sx={{ display: "flex", justifyContent: "center", cursor: "not-allowed" }}>
            <img src="icons/message-disabled.svg" alt="phone icon" />
          </Link>
      }
      {
        params.email ?
          <Link
            href={viewportIsSmall ? `mailTo:${params.email}` : `sms:${params.email}`}
            sx={{ display: "flex", justifyContent: "center" }}
            target="_blank" rel="noreferrer"
            onClick={() => handleContactClick("email_contact")}
          >
            <img src={viewportIsSmall ? "icons/mail.svg" : "icons/message.svg"} alt="mail icon" />
          </Link> :
          <Link
            sx={{ display: "flex", justifyContent: "center", cursor: "not-allowed" }}>
            <img src={viewportIsSmall ? "icons/mail-disabled.svg" : "icons/mail-disabled.svg"} alt="mail icon" />
          </Link>

      }
    </Box>
  )
}

export { ContactCell }