import { getCookie } from 'common/cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { removeCookie } from 'common/cookie';

const FeedbackContext = createContext({});

export const FeedbackContextProvider = ({ children }) => {
  const { userInfo } = useAuth()
  const [ isFeedbackModalSkipped, setIsFeedbackModalSkipped ] = useState(getCookie(`skippedSurvey_${userInfo?.unicityId}`) === 'true');
  const [ isFeedbackTaken, setIsFeedbackTaken ] = useState(getCookie(`tookSurvey_${userInfo?.unicityId}`) === 'true');
  const flags = useFlags()
  const [ isSurveyActive, setIsSurveyActive ] = useState(null)
  const [ titleKeys, setTitleKeys ] = useState({})

  useEffect(() => {
    if (!flags?.feedback) return
    setIsSurveyActive(flags.feedback.show)
    setTitleKeys({
      cardTitleKey: flags.feedback.titleKey,
      modalTitleKey: flags.feedback.modalTitleKey
    })
    if (!flags.feedback.show) {
      removeCookie(`tookSurvey_${userInfo?.unicityId}`)
      removeCookie(`skippedSurvey_${userInfo?.unicityId}`)
    }
  }, [ flags, userInfo?.unicityId ])

  useEffect(() => {
    if (!userInfo && !userInfo?.unicityId) return
    setIsFeedbackModalSkipped(getCookie(`skippedSurvey_${userInfo?.unicityId}`) === 'true')
    setIsFeedbackTaken(getCookie(`tookSurvey_${userInfo?.unicityId}`) === 'true')
  }, [ userInfo?.unicityId, userInfo ])

  return (
    <FeedbackContext.Provider value={{
      isFeedbackModalSkipped,
      setIsFeedbackModalSkipped,
      isFeedbackTaken,
      setIsFeedbackTaken,
      isSurveyActive,
      titleKeys
    }}>
      {children}
    </FeedbackContext.Provider> 
  )
}

export const useFeedbackContext = () => {
  const ctx = useContext(FeedbackContext);

  if (!ctx) throw new Error('Error in FeedbackContext');

  return ctx;
}