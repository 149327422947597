import React, { useState, useEffect } from "react";
import { Stack, Box } from "@mui/material";

import { useAuth } from "../../../hooks/useAuth";
import { useApi } from "../../../hooks/useApi";
import { useLocation } from 'react-router-dom';

import { AccountInfoSection } from "./AccountInfoSection";
import { ContactInfoSection } from "./ContactInfo/ContactInfoSection";
import { SsnSection } from "./SsnSection/SsnSection";
import { BackdropLoadingOverlay } from "../../../components/BackdropLoadingOverlay";

const SettingsPersonalInfoSection = () => {
  const { hash } = useLocation();
  const { sendRequestAsCallback } = useApi();
  const { userInfo } = useAuth();
  const { countryCode } = userInfo;
  const [ showLoadingOverlay, setShowLoadingOverlay ] = useState(false);
  const [ personalInfo, setPersonalInfo ] = useState();
  const [ error, setError ] = useState(null);

  const handleClose = () => {
    setShowLoadingOverlay(false);
  };

  const fetchCustomerData = async () => {
    setShowLoadingOverlay(true);
    try {
      const personalInfoResponse = await sendRequestAsCallback({
        endpoint: "customers/me?expand=flags"
      }); 
      if ((countryCode === "US" || countryCode === "PR") && !personalInfoResponse.data.flags.hasGovernmentId && hash) {
        const target = document.getElementById(hash.substring(1));
        if (target) {
          target.scrollIntoView({ behavior: 'smooth' });
        }
      }
      setPersonalInfo(personalInfoResponse.data);
    } catch (error) {
      setError(true)
    } finally {
      setShowLoadingOverlay(false);
    }
  };

  useEffect(() => {
    fetchCustomerData();
    // eslint-disable-next-line
  }, []);

  if (error) {
    return (
      <Box data-test-id="error-box">
        Error loading personal information: {error.message}
      </Box>
    );
  }

  return (
    <Stack direction="column" flex={1} gap={3}>
      <AccountInfoSection
        showLoadingOverlay={showLoadingOverlay}
        setShowLoadingOverlay={setShowLoadingOverlay}
        customerInfo={personalInfo}
      />
      <ContactInfoSection
        showLoadingOverlay={showLoadingOverlay}
      />
      {(countryCode === "US" || countryCode === "PR") &&
        <SsnSection
          setShowLoadingOverlay={setShowLoadingOverlay}
          hasGovId={!!personalInfo?.flags.hasGovernmentId}
        />
      }
      <BackdropLoadingOverlay
        showLoadingOverlay={showLoadingOverlay}
        handleClose={handleClose}
      />
    </Stack>
  );
};

export { SettingsPersonalInfoSection };