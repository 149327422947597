import { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { Box, CircularProgress } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useLocation } from "react-router-dom";

import { AvatarChip } from "./AvatarChip";
import { OrderNumberChip } from "./OrderNumberChip";
import { ReferralTable } from "./ReferralTable";
import { MemberContext } from "../../hooks/MemberContext";
import { LanguageContext } from "../../hooks/LanguageContext";

const ReferralSummaryDrawerContent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { customerHrefData, customerReferrals, apiChainInProgress, setIsDrawerOpen } = useContext(MemberContext);
  const { languageDir } = useContext(LanguageContext);

  const handleArrowClick = () => {
    navigate(-1, { replace: true });
  }

  const handleCloseClick = () => {
    setIsDrawerOpen(false);
    navigate(`${location.pathname}`, { replace: true });
  }

  // render loading bar while API chain in in progress
  if (apiChainInProgress)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 500,
        }}
      >
        <CircularProgress data-test-id="progress-bar-shown" />
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "24px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {
          languageDir === "ltr" ? (
            <KeyboardBackspaceIcon
              onClick={handleArrowClick}
              sx={{ cursor: "pointer" }}
            />
          ) : (
            <ArrowForwardIcon
              onClick={handleArrowClick}
              sx={{ cursor: "pointer" }}
            />
          )
        }
        <CloseIcon onClick={handleCloseClick} sx={{ cursor: "pointer" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          padding: "16px 0px",
        }}
      >
        <AvatarChip
          id="referralAvatarChip"
          imageUrl={customerHrefData.profilePicture?.href}
          name={`${customerHrefData.humanName?.firstName} ${customerHrefData.humanName?.lastName}`}
          mixpanelLocation="referrals_summary_drawer"
          customerHrefData={customerHrefData}
        />
      </Box>
      <Box>
        <span
          style={{ fontSize: "16px", fontWeight: "600", marginRight: "10px" }}
        >
          <T>referrals</T>
        </span>
        <OrderNumberChip>{customerReferrals.length}</OrderNumberChip>
      </Box>
      <div style={{ display: "flex", height: "100%", overflowX: 'auto' }}>
        <div id="ReferralsDataGrid" style={{ flexGrow: 1 }}>
          <ReferralTable referrals={customerReferrals} />
        </div>
      </div>
    </Box>
  );
};

export { ReferralSummaryDrawerContent };
