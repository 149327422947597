import { useContext, useEffect } from "react";

import { useParams } from "react-router-dom";

import { OfficeBox } from "../../components/OfficeBox";
import { OfficePage } from "../../components/OfficePage";

import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { Box, Stack } from "@mui/material";
import { styles } from "./partners.styles";

import { PartnersContext } from "../../hooks/PartnersContext";
import { MemberContextProvider } from "../../hooks/MemberContext";
import { PartnersHeadSection } from "./PartnersHeadSection";
import { PartnersLeftColumn } from "./PartnersLeftColumn";
import { CustomPagination } from "../../components/CustomPagination";
import { PartnersNoRowsOverlay } from "./PartnersDataGridComponents/PartnersNoRowsOverlay";
import { PartnersBlankOverlay } from "./PartnersDataGridComponents/PartnersBlankOverlay";
import { PartnersClearFilterOverlay } from "./PartnersDataGridComponents/PartnersClearFilterOverlay";
import { PartnersEmptyFrontlineOverlay } from "./PartnersDataGridComponents/PartnersEmptyFrontlineOverlay";
import { columns } from "./PartnersDataGridComponents/format-partners-columns";
import { ErrorScreen } from "../ErrorScreen/ErrorScreen";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../hooks/useAuth";
import { useMixPanel } from "../../hooks/useMixPanel";
import { PartnersGenealogyCollapsible } from "./PartnersMobileComponents/PartnersGenealogyCollapsible";
import { PartnersSearchSortSection } from "./PartnersSearchSortSection";
import { calculateDynamicRowHeight } from "../../common/helper-functions";

const PartnersScreen = ({ isWebView }) => {
  const {
    rows,
    page,
    pageSize,
    setPage,
    setPageSize,
    columnVisibilityModel,
    sortModel,
    setSortModel,
    filterValue,
    rowCount,
    loading,
    setDownlineUid,
    setSearchQuery,
    hasError
  } = useContext(PartnersContext);
  const { userInfo } = useAuth();
  const { distributorId } = useParams();
  const { logEvent } = useMixPanel();
  const viewingMyFrontline = distributorId === undefined;

  // add distirbutorId to dependency array so when viewing different partners screens, the downlineUid is updated
  useEffect(() => {
    if (!viewingMyFrontline) {
      setDownlineUid(distributorId);
    } else {
      setDownlineUid(userInfo.unicityId);
    }
    // eslint-disable-next-line
  }, [distributorId])

  useEffect(() => {
    // reset search query and sort model when viewing different partners screens
    setSearchQuery("");
    // apply default sort model if none exists in local storage
    if (sessionStorage.getItem("partnersSortModel")) {
      setSortModel(JSON.parse(sessionStorage.getItem("partnersSortModel")));
    } else {
      setSortModel([{ field: "totalOv", sort: "desc" }]);
      sessionStorage.setItem("partnersSortModel", JSON.stringify([{ field: "totalOv", sort: "desc" }]));
    }
    // eslint-disable-next-line
  }, [])

  const apiRef = useGridApiRef();

  const theme = useTheme();
  const viewportIsMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    // if apiRef is errored out, don't attempt to pin columns
    if (apiRef.current.pinColumn) {
      const visibleColumnsCount = Object.values(columnVisibilityModel).filter((v) => v).length;
      !viewportIsLarge && visibleColumnsCount > 2
        ? apiRef.current.pinColumn("name", "left")
        : apiRef.current.pinColumn(null);
    }

    // eslint-disable-next-line
  }, [apiRef, viewportIsLarge, viewportIsSmall, columnVisibilityModel]);

  const getOverlay = () => {
    if (loading) return PartnersBlankOverlay;
    if (!viewingMyFrontline && rowCount === 0) {
      return PartnersEmptyFrontlineOverlay;
    } else
      return filterValue === "all-statuses"
        ? PartnersNoRowsOverlay
        : PartnersClearFilterOverlay;
  };

  // prevent default MUI sorting behavior when clicking mobile help icon
  const handleColumnHeaderClick = (params, event) => {
    if (event.target.getAttribute("data-test-id") === "help-icon-mobile") {
      event.defaultMuiPrevented = true;
    }
  }

  // handle sort model change and store in local storage
  // if sorting by name, do not change mobile column
  const handleSortModelChange = (newSortModel) => {
    logEvent("partners_sort_change", {
      location: "partners",
      sortModel: sortModel[0],
    });
    if (newSortModel[0].field !== "name") {
      sessionStorage.setItem("partnersMobileColumn", newSortModel[0].field);
    }
    setSortModel(newSortModel);
    sessionStorage.setItem("partnersSortModel", JSON.stringify(newSortModel));
  };

  const displayMobileHeaderForDownline =
    !viewportIsSmall && !viewingMyFrontline && !loading && !isWebView;

  // calculate row height based on number of chips for that row
  const getRowHeight = (params) => {
    // return default row height because chips are not stacked beyond this breakpoint
    if (viewportIsLarge) return 50;

    const dynamicRowHeight = calculateDynamicRowHeight(params);
    return dynamicRowHeight;
  };

  if (hasError) return <ErrorScreen />

  return (
    <OfficePage pageTitle={"partners"} isWebView={isWebView}>
      <MemberContextProvider>
        <PartnersSearchSortSection />
        <Stack
          direction={viewportIsSmall ? "row" : "column"}
          gap={2}
          alignItems={viewportIsSmall ? "flex-start" : "stretch"}
        >
          {
            // don't display left column if drawer is opened from /partners
            viewportIsSmall &&
            !viewingMyFrontline && (
              <Stack minWidth="280px" sx={{ flex: "1 1 30%" }}>
                <PartnersLeftColumn />
              </Stack>
            )
          }
          {/* https://github.com/recharts/recharts/issues/1767#issuecomment-586248188 -> link for animation disable reference (width: 0 on flex items) */}
          {(displayMobileHeaderForDownline) && (
            <OfficeBox
              sx={
                viewportIsMobile
                  ? { flex: "1 1 100%" }
                  : { flex: "1 1 100%", width: 0 }
              }
            >
              <PartnersGenealogyCollapsible />
            </OfficeBox>
          )}
          <OfficeBox
            sx={
              viewportIsMobile
                ? { flex: "1 1 100%" }
                : { flex: "1 1 100%", width: 0 }
            }
          >
            <PartnersHeadSection isWebView={isWebView} />
            <Box
              sx={{
                height: `calc(100dvh - ${viewportIsSmall ? "290px" : "332px"
                  })`,
                minHeight: "315px",
              }}
            >
              {
                rows && (
                  <DataGridPro
                    apiRef={apiRef}
                    columns={columns}
                    onColumnHeaderClick={handleColumnHeaderClick}
                    rows={rows}
                    getRowHeight={getRowHeight}
                    pageSize={pageSize}
                    columnHeaderHeight={36}
                    columnVisibilityModel={columnVisibilityModel}
                    sx={styles.dataGrid}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    hideFooterSelectedRowCount
                    loading={loading}
                    rowCount={rowCount}
                    onPageChange={setPage}
                    rowsPerPageOptions={[10, 50, 100]}
                    onPageSizeChange={setPageSize}
                    sortingOrder={["desc", "asc"]}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    pagination
                    paginationMode="server"
                    sortingMode="server"
                    disableVirtualization
                    slots={{
                      pagination: CustomPagination,
                      noRowsOverlay: getOverlay(),
                      noResultsOverlay: PartnersNoRowsOverlay,
                    }}
                    slotProps={{
                      pagination: {
                        page: page,
                        pageSize: pageSize,
                        rowCount: rowCount,
                        handlePageSizeChange: setPageSize,
                        loading: loading,
                        handlePageChange: setPage,
                        isPartners: true,
                      },
                    }}
                  />
                )
              }
            </Box>
          </OfficeBox>
        </Stack>
      </MemberContextProvider>
    </OfficePage>
  );
};

export { PartnersScreen };
