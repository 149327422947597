import React from "react";
import { T, useTranslateKey } from "../../../../hooks/useTranslate";
import { Stack, Typography, Divider } from "@mui/material";
import { FormField } from "./FormField";
import styles from "../../settingsScreenStyles";
import { CountryCodeSelect } from "./CountryCodeSelect";
import { BackdropLoadingOverlay } from "../../../../components/BackdropLoadingOverlay";

const ChangePhoneForm = ({
  formikNewPhone,
  handleKeyPress,
  viewportIsSmall,
  isModalLoading,
  buttonContent
}) => {
  const translatedNewPhoneNumber = useTranslateKey("new_phone_number");

  return (
    <form onSubmit={formikNewPhone.handleSubmit} data-test-id="change-phone-form">
      <Stack gap={3}>
        <Typography variant="h2" data-test-id="change-phone-title" mb={1.4}>
          <T>change_phone_number</T>
        </Typography>
        <Stack direction="row" gap={1}>
          <CountryCodeSelect formik={formikNewPhone} />
          <FormField
            label={translatedNewPhoneNumber}
            name="phone"
            value={formikNewPhone.values.phone}
            onChange={formikNewPhone.handleChange}
            error={formikNewPhone.touched.phone && Boolean(formikNewPhone.errors.phone)}
            helperText={formikNewPhone.touched.phone && formikNewPhone.errors.phone}
            inputProps={styles.inputProps}
            InputLabelProps={{ style: { ...styles.labelStyle } }}
            FormHelperTextProps={styles.formHelperTextProps}
            sx={{ flex: '1 1 75%' }}
            onKeyPress={handleKeyPress}
          />
        </Stack>
        {
          !viewportIsSmall && (
            <>
              <Divider m={0} />
              {buttonContent}
            </>
          )
        }
      </Stack>
      <BackdropLoadingOverlay showLoadingOverlay={isModalLoading} />
    </form>
  )
}

export { ChangePhoneForm }