import React, { useContext } from "react";
import { useTranslateKey } from "../../hooks/useTranslate"

import { useNavigate, useLocation } from "react-router-dom";

import { Box, Typography } from "@mui/material"

import { formatDateShortByLocale } from "../../common/date-functions";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { LanguageContext } from "../../hooks/LanguageContext";
import Chip from "components/Chip";

const SubscriptionCell = ({ params }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const { currentLanguage, languageDir } = useContext(LanguageContext);

  const yesString = useTranslateKey("yes");

  const handleArrowClick = () => {
    navigate(`${location.pathname}?drawer=member&id=${params.unicityId}&drawerContent=profile`, { state: location })
  }

  return (
    params.hasFeelGreatAutoOrder
      ? <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          gap: "5px",
          marginRight: !viewportIsLarge ? "0px" : "20px"
        }}>
          <Box sx={{ fontWeight: "600", fontSize: "10px" }}>
            <Box 
              sx={{
                  backgroundColor: params.count !== 0 ? theme.subscription.subscribed.backgroundColor : "#1538621A",
                  color: params.count !== 0 ? theme.subscription.subscribed.color : "#15386280",
                  padding: "4px 8px",
                  borderRadius: "38px",
              }}
            >
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px"
                  }}>
                    {
                      params.count !== 0 ?
                        <img src="icons/small-auto-renew.svg" alt="auto order icon" /> :
                        <img src="icons/small-subscription-grey.svg" alt="auto order icon" />
                    }
                    <Typography sx={{ fontWeight: "600", fontSize: "10px" }}>
                      {`${params.nextRunDate ? formatDateShortByLocale(currentLanguage, params.nextRunDate) : yesString}`}
                    </Typography>
                  </Box>
              </Box>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleArrowClick}
          >
            <img
              src="icons/chevron-right.svg"
              alt="right arrow"
              style={{ transform: languageDir === "rtl" ? "rotate(180deg)" : "none" }}
            />
          </Box>
        </Box>
      : <Chip
          translationKey="no"
          customChipStyles={{
            padding: "4px 8px",
            borderRadius: "38px",
            color: theme.subscription.default.color,
            backgroundColor: theme.subscription.default.backgroundColor,
            fontSize: "10px",
            fontWeight: 600,
            textAlign: "center",
          }}
       />
  )
}

export { SubscriptionCell }