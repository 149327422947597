import { StyledGridOverlay } from "../../../components/StyledGridOverlay";

const PartnersBlankOverlay = () => {
  return (
    <StyledGridOverlay data-test-id="partners-no-rows-overlay">
    </StyledGridOverlay>

  )

}

export { PartnersBlankOverlay }