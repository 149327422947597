import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";

const LoadingOverlay = ({ showLoadingOverlay, handleClose }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={showLoadingOverlay}
      onClick={handleClose}
      data-test-id={
        !showLoadingOverlay ? "progress-bar-not-shown" : "progress-bar-shown"
      }
    >
      <CircularProgress
        color="inherit"
        data-test-id={
          !showLoadingOverlay
            ? "progress-bar-not-shown"
            : "progress-bar-shown"
        }
      />
    </Backdrop>
  )
}

export { LoadingOverlay }