import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";
import { useTranslateKey } from "../../hooks/useTranslate";

import { TextField, IconButton, InputAdornment, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SearchPopper } from "./SearchPopper";
import { useSearchSuggestions } from "../../hooks/useSearchSuggestions";
import { PartnersContext } from "../../hooks/PartnersContext";
import { useMixPanel } from "../../hooks/useMixPanel";

const DatagridSearchInput = ({ type, setSearchField }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const [currentInput, setCurrentInput] = useState("");
  const [debouncedInput, cancelDebounce] = useDebounce(currentInput, 500);
  const [open, setOpen] = useState(false);
  const id = open ? 'search-results-popper' : undefined;
  const {
    getSearchSuggestions,
    searchSuggestionsResults,
    searchSuggestionsResultsCount,
    loading,
    error } = useSearchSuggestions(type);
  const { downlineUid, loading: contextLoading, setSearchQuery } = useContext(PartnersContext);

  const anchorRef = useRef(null);

  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const placeholderText = useTranslateKey("search_by_id_name_phone_etc");

  // when changes to debouncedInput occur, make API call to get search suggestions
  useEffect(() => {
    if (debouncedInput && debouncedInput.length > 2) {
      getSearchSuggestions(debouncedInput);
      setOpen(true);
    } else {
      setOpen(false);
    }
    // eslint-disable-next-line
  }, [debouncedInput]);

  // reset currentInput when location changes
  useEffect(() => {
    setCurrentInput("");
    // eslint-disable-next-line
  }, [location.pathname])

  const handleClearClick = () => {
    setCurrentInput("");
    // if type members, clean up the field that is the dependency for the API call
    if (type === "members") {
      setSearchField("");
    }
  };

  const handleSearch = () => {
    logEvent(`${type}_search_results`, {
      location: `${type}`,
      click_type: 'press_enter_to_search',
      searchTerm: currentInput
    });

    cancelDebounce(); // cancel debounce when a search is initiated

    if (type === "partners") {
      setSearchQuery(currentInput);
      navigate(`/${type}/search-results?searchTerm=${currentInput}&partnerId=${downlineUid}`);
    } else if (type === "members") {
      setSearchField(currentInput);
    }

    setOpen(false); // ensure the popper is closed
  };

  const handleKeyDown = (evt) => {
    if (evt.key === "Enter") {
      handleSearch();
    }
  };

  const handleIconClick = () => {
    handleSearch();
    setOpen(false);
  }

  const handleOnFocus = () => {
    if (currentInput) {
      setOpen(true);
    }
  }

  if (!error) return (
    <>
      <TextField
        id={`${type}-search-text-input`}
        data-test-id={`${type}-search-text-input`}
        value={currentInput}
        inputRef={anchorRef}
        onChange={(evt) => setCurrentInput(evt.target.value)}
        onFocus={handleOnFocus}
        placeholder={placeholderText}
        onKeyDown={handleKeyDown}
        disabled={type === "partners" && contextLoading}
        fullWidth
        // disabled={loading}
        sx={{
          maxWidth: "450px",
        }}
        inputProps={{
          // the decimal values along with the height determined by the fontSize (17.25px) brings the overall height of the search field to 44px
          style: {
            paddingTop: viewportIsMedium ? "14px" : "13.375px",
            paddingBottom: viewportIsMedium ? "14px" : "13.375px",
            fontSize: viewportIsMedium ? "14px" : "12px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{ cursor: "pointer" }}
              onClick={handleIconClick}
            >
              <img src="icons/search-glass.svg" alt="magnified glass icon" />
            </InputAdornment>
          ),
          endAdornment: currentInput && (
            <IconButton
              size="small"
              onClick={handleClearClick}
              disabled={loading}
            >
              <CloseIcon sx={{ height: "20px" }} />
            </IconButton>
          ),
        }}
      />
      <SearchPopper
        id={id}
        type={`${type}`}
        anchorEl={anchorRef.current}
        open={open}
        setOpen={setOpen}
        loading={loading}
        searchSuggestionsResults={searchSuggestionsResults}
        searchSuggestionsResultsCount={searchSuggestionsResultsCount}
        debouncedInput={debouncedInput}
        currentInput={currentInput}
        setSearchField={setSearchField}
      />
    </>

  )
}

export { DatagridSearchInput }