import React, { useState, useEffect, useContext } from "react"
import { T } from "../../../hooks/useTranslate";

import { Box, Typography, Stack, CircularProgress, Link } from "@mui/material"
import LaunchIcon from '@mui/icons-material/Launch';

import { RankQualDropdowns } from "./RankQualDropdowns"
import { RankQualProgBarContainer } from "./RankQualProgBarContainer"
import { RankQualPvTvBoxes } from "./RankQualPvTvBoxes"

import { useApi } from "../../../hooks/useApi";
import { DashboardDivider } from "../DashboardDivider";

import { getMonthRange } from "../../../common/date-functions";
import { getLowestNotQualifiedRankIdx, getLowestUnfilledRankIdx } from "../../../common/rank-qual-functions";
import { transformRanks } from "../../../common/format-functions";
import { OfficeBox } from "../../../components/OfficeBox";
import { LanguageContext } from "../../../hooks/LanguageContext";
import { countThirdLeg } from "../../../common/helper-functions";
import { getQualificationPeriodMonth } from "../../../common/date-functions";

import { GeneralAlert } from "../../../components/GeneralAlert";
import { UpdatedDate } from "../../../components/UpdatedDate";

const { DateTime } = require("luxon");

const RankQualificationsContent = () => {
  const { sendRequest: promiseSendRequest } = useApi();
  const { languageDir } = useContext(LanguageContext);

  // month dropdown
  const [contextMonthRange, setContextMonthRange] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  // boolean for Promise status
  const [rankQualLoading, setRankQualLoading] = useState(false);

  // boolean to persist rank across month changes and only apply default rank logic on inital load
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [dateCalculated, setDateCalculated] = useState(null);

  // customer data for progress legs
  const [customer1, setCustomer1] = useState({});
  const [customer2, setCustomer2] = useState({});
  const [customer3, setCustomer3] = useState({});
  const [thirdLegCount, setThirdLegCount] = useState({});
  const [totalOvDetails, setTotalOvDetails] = useState(null);

  // rank dropdown
  const [rank, setRank] = useState("Mgr");
  const [rankOptions, setRankOptions] = useState([]);
  const [selectedRankDetails, setSelectedRankDetails] = useState({});

  const [isError, setError] = useState(false)

  useEffect(() => {
    setContextMonthRange(getMonthRange(6));
    setSelectedMonth(getQualificationPeriodMonth(6));
  }, [])

  useEffect(() => {
    const abortController = new AbortController();
    setRankQualLoading(true);
    setDateCalculated(null);
    setError(false);

    const promise1 = promiseSendRequest({
      method: "get",
      endpoint: `customers/me/achievementsHistory?expand=metrics&period=${selectedMonth}`,
      abortController
    })

    const promise2 = promiseSendRequest({
      method: "get",
      endpoint: `customers/me/metricsProfileHistory`,
      abortController
    });

    Promise.all([promise1, promise2]).then((values) => {
      setRankQualLoading(false);
      setDateCalculated(values[0]?.data?.items[0]?.value[0]?.metrics[0]?.dateCalculated);

      const scopedRankOptions = values[0].data?.items[0].value.filter(rank => {
        return !["Asc", "DIA2"].includes(rank.achievement.rankShort) && rank.achievement.rankShort
      })

      setRankOptions(transformRanks(scopedRankOptions));

      const achievementsHistoryRes = values[0]?.data?.items[0].metricsDetails;

      // if user has legs, populate setCustomer state
      setCustomer1(achievementsHistoryRes.ov_leg1?.customers?.[0]?.customer ?? achievementsHistoryRes.ov_leg1?.customers?.[0]?.customer);
      setCustomer2(achievementsHistoryRes.ov_leg2?.customers?.[0]?.customer ?? achievementsHistoryRes.ov_leg2?.customers?.[0]?.customer);
      setCustomer3(achievementsHistoryRes.ov_leg3hc?.customers?.length ?? achievementsHistoryRes.ov_leg3hc?.customers?.length);

      // Count partners and members for third leg
      const thirdLegCount = countThirdLeg(achievementsHistoryRes.ov_leg3hc?.customers);
      setThirdLegCount(thirdLegCount);

      // get default rank selection using these two indexes
      let lastMonthHighestRankIdx = 0;

      // build string for current and last month to filter /metricsHistory response
      const currentMonthSplit = selectedMonth ? DateTime.fromISO(selectedMonth).toISODate().split("-") : null;
      const currentMonthString = `${currentMonthSplit[0]}-${currentMonthSplit[1]}`;
      const lastMonthSplit = selectedMonth ? DateTime.fromISO(selectedMonth).minus({ months: 1 }).toISODate().split("-") : null;
      const twoMonthsAgoSplit = selectedMonth ? DateTime.fromISO(selectedMonth).minus({ months: 2 }).toISODate().split("-") : null;
      const lastMonthString = `${lastMonthSplit[0]}-${lastMonthSplit[1]}`;
      const twoMonthsAgoString = `${twoMonthsAgoSplit[0]}-${twoMonthsAgoSplit[1]}`;

      const totalOvValue = values[1]?.data?.items.filter(item => item.period === currentMonthString)[0].value?.ov;
      setTotalOvDetails({
        value: totalOvValue,
        requiredValue: null,
        qualified: null
      })

      // filter /metricsHistory response to get last month
      let lastMonthHighestRank = values[1]?.data?.items?.filter((item) => {
        return item.period === lastMonthString
      })

      // // if highest rank last month is Dst, may issue with MENA or commissions engine, but use highest rank achieved two months ago for default rank
      if (lastMonthHighestRank[0]?.value.rankShort === "Dst") {
        lastMonthHighestRank = values[1]?.data?.items?.filter((item) => {
          return item.period === twoMonthsAgoString
        }) || [];
      }

      // iterate through ranks and set lastMonthHighestRankIdx based on the last month if possible
      if (lastMonthHighestRank.length) {
        scopedRankOptions.forEach((rankItem, idx) => {
          if (rankItem.achievement.rankShort === lastMonthHighestRank[0]?.value?.rankShort) {
            lastMonthHighestRankIdx = idx;
          }
        });
      }

      // apply default rank logic on initial load
      if (isInitialLoad) {
        const lowestNotQualifiedRankIdx = getLowestNotQualifiedRankIdx(scopedRankOptions);
        const lowestUnfilledRankIdx = getLowestUnfilledRankIdx(scopedRankOptions);

        // using last month highest rank idx, not qualified from API rank idx, and unfilled rank idx, select the default rank
        // outer if compares qualified from the API vs. last month highest indexes
        // inner if then checks for filled ranks
        if (lowestNotQualifiedRankIdx >= lastMonthHighestRankIdx) {
          if (lowestUnfilledRankIdx > lowestNotQualifiedRankIdx) {
            setRank(scopedRankOptions[lowestUnfilledRankIdx].achievement.rankShort);
          } else {
            setRank(scopedRankOptions[lowestNotQualifiedRankIdx].achievement.rankShort);
          }
        } else {
          if (lowestUnfilledRankIdx > lastMonthHighestRankIdx) {
            setRank(scopedRankOptions[lowestUnfilledRankIdx].achievement.rankShort);
          } else {
            setRank(scopedRankOptions[lastMonthHighestRankIdx].achievement.rankShort);
          }
        }
      }

      setIsInitialLoad(false);

    }).catch((err) => {
      console.log(err);
      setError(true);
      setRankQualLoading(false);
      setIsInitialLoad(false);
      setCustomer1({});
      setCustomer2({});
      setCustomer3({});
      setThirdLegCount({});
      setSelectedRankDetails({});
    })
    return () => abortController.abort()
    // eslint-disable-next-line
  }, [selectedMonth])

  useEffect(() => {
    // rank changes
    setSelectedRankDetails(rankOptions.filter(item => item.rank === rank)[0]);

    // eslint-disable-next-line
  }, [rankOptions, rank])

  return (
    <OfficeBox>
      {isError && <GeneralAlert style={{ marginTop: "24px" }} alertKey="missing_rank_info_alert" />}
      <Stack direction="row" gap={.75} alignItems="center" mb={.5}>
        <Stack
          direction="column"
          gap={.5}
          p={3}
          justifyContent="center">
          <Typography variant="h3" data-test-id="rank-qual-header1">
            <T>rank_qualifications</T>
          </Typography>
          <UpdatedDate date={dateCalculated} />
        </Stack>
      </Stack>
      <Stack>
        <RankQualDropdowns
          rankOptions={rankOptions}
          monthOptions={contextMonthRange}
          rank={rank} month={selectedMonth}
          setRank={setRank}
          setMonth={setSelectedMonth}
          loading={rankQualLoading}
          isError={isError}
        />
        {
          rankQualLoading ?
            <Stack sx={{ height: 300 }} justifyContent="center" alignItems="center">
              <CircularProgress data-test-id="progress-bar-shown" />
            </Stack> :
            <Box>
              <RankQualProgBarContainer
                selectedRankDetails={selectedRankDetails}
                name1={customer1}
                name2={customer2}
                name3={customer3}
                thirdLegCount={thirdLegCount}
              />
              <RankQualPvTvBoxes
                selectedRankDetails={selectedRankDetails}
                totalOv={totalOvDetails}
                isError={isError}
              />
            </Box>
        }
      </Stack>
      <DashboardDivider />
      <Link
        href={"https://unicitystatic.s3.amazonaws.com/materials/Unicity_Comp_Plan_2022_js_v6.pdf"}
        target="_blank"
        rel="noreferrer"
        underline="hover"
      >
        <Stack flexDirection="row" p={3} alignItems="center" justifyContent="space-between">
          <Typography variant="h4"><T>learn_more_about_rank_advancement</T></Typography>
          <LaunchIcon sx={{
            height: "20px",
            width: "20px",
            transform: languageDir === "rtl" ? "scaleX(-1)" : null
          }} />
        </Stack>
      </Link>
    </OfficeBox>
  )
}

export { RankQualificationsContent }