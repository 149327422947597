import React from "react";
import { T } from "../../hooks/useTranslate";

import { Box, Typography, Divider } from "@mui/material";
import { CopyToClipboard } from "../../components/CopyToClipboard";

const AdditionalSection = () => {
  return (
    <Box sx={{ marginTop: "32px", padding: "0px 32px" }}>
      <Typography variant="h3" sx={{ marginBottom: "24px" }}>
        <T>additional</T>
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "4px",
        }}
      >
        <img src="icons/upgrade.svg" alt="upgrade icon" />
        <Typography sx={{ fontSize: "14px" }}>upgrade.unicity.com</Typography>
        <CopyToClipboard
          stringToCopy="https://upgrade.unicity.com"
          iconId="copyUpgradeIcon"
          sx={{
            height: "20px",
            width: "20px",
            color: "#7388A1",
            cursor: "pointer",
          }}
        />
      </Box>
      <Typography
        sx={{ color: "#7388A1", fontSize: "11px", marginBottom: "24px" }}
      >
        <T>upgrade_link_subtext</T>
      </Typography>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginTop: "24px",
        }}
      >
        <img src="icons/library.svg" alt="library icon" />
        <Typography sx={{ fontSize: "14px" }}>ufeelgreat.com/learn</Typography>
        <CopyToClipboard
          stringToCopy="https://ufeelgreat.com/learn"
          iconId="copyUpgradeIcon"
          sx={{
            height: "20px",
            width: "20px",
            color: "#7388A1",
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
};

export { AdditionalSection };
