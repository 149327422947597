import React from 'react';
import { Stack, Typography } from '@mui/material';

const EmptyStateMessage = ({ message }) => (
  <Stack mt={8} mb={8} alignItems="center">
    <Typography variant="h2">{message}</Typography>
  </Stack>
);

export { EmptyStateMessage };
