import React from "react";
import { T } from "../../../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { styles } from "../partners.styles";

const InactiveChip = () => {
  return (
    <Stack
      justifyContent="center"
      data-test-id="inactive-chip">
      <Typography sx={styles.inactiveChip}>
        <T>inactive</T>
      </Typography>
    </Stack>
  )
}

export { InactiveChip }