import React from "react";

import { Grid, Skeleton } from "@mui/material";

import { styles } from "../DashboardScreen/News/news.styles";

const AllNewsLoadingSkeleton = () => {
  return (
    <Grid
      data-test-id="loading-skeleton-grid-container"
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {Array.from(Array(6)).map((_, index) => (
        <Grid
          data-test-id="loading-skeleton-grid-item"
          item
          xs={12}
          sm={12}
          md={6}
          key={index}
          sx={styles.skeletonGridItem}
        >
          <Skeleton
            data-test-id="loading-skeleton"
            variant="rectangular"
            width="100%"
            height={230}
            sx={{ borderRadius: ".75rem" }} />
        </Grid>
      ))}
    </Grid>
  );
};

export { AllNewsLoadingSkeleton }
