import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MemberContext } from "../../hooks/MemberContext";

const DrawerButtonWrapper = styled.button`
  color: #153862;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight:600;
  padding: 5px 12px 5px 12px;
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  border-radius: 4px;
  &:hover { cursor: pointer;}
  ${props => props.isDisabled && "color: #919EAB; background: #F1F4F7; &:hover { cursor: not-allowed;}"}
`;

const DrawerButton = ({ id, text, isDisabled, showFrontlineIcon, type }) => {
  const { memberId } = useContext(MemberContext);
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const drawer = searchParams.get("drawer");

  const handleButtonClick = () => {
    if (isDisabled) return;
    showFrontlineIcon ? navigate(`/partners/${memberId}`) :
      navigate(`${location.pathname}?drawer=${drawer}&id=${memberId}&drawerContent=${type}`)
  }

  return (
    <DrawerButtonWrapper
      data-test-id={id}
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={handleButtonClick}
    >
      <div>{text}</div>
      {
        showFrontlineIcon ?
          <img src="icons/downline.svg" alt="downline icon" style={{ paddingLeft: "8px", opacity: isDisabled && ".5" }} /> :
          <KeyboardArrowRightIcon sx={{ transform: "scale(.8)" }} />
      }
    </DrawerButtonWrapper>
  );
};

export { DrawerButton };
