import React, { useEffect } from "react";
import { T, useTranslateKey } from "../../../hooks/useTranslate";
import { Stack, Typography, Link } from "@mui/material";
import { DisplayNameTextField } from "./DisplayNameTextField";

const SettingsDisplayNameSection = ({
  displayNameInputValue,
  setDisplayNameInputValue,
  displayNameFromApi,
  formik,
  handleSaveChanges,
  enableSaveChanges,
  showLoadingOverlay,
  viewportIsMedium
}) => {
  const firstNameTranslated = useTranslateKey("first_name");
  const lastNameTranslated = useTranslateKey("last_name");

  useEffect(() => {
    if (displayNameFromApi) {
      formik.setValues({
        firstName: displayNameFromApi["firstName"],
        lastName: displayNameFromApi["lastName"],
      });
    }
    // eslint-disable-next-line
  }, [displayNameFromApi]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setDisplayNameInputValue({ ...displayNameInputValue, [name]: value });
    formik.handleChange(e);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && enableSaveChanges) {
      handleSaveChanges();
    }
  };

  const linkProps = {
    href: useTranslateKey("finding_fg_link_url"),
    target: "_blank",
    rel: "noreferrer",
    sx: { cursor: "pointer" },
  };


  return (
    <form>
      <Stack
        data-test-id="display-name-sub-header"
        pt={3}
        pb={3}
        alignItems="flex-start"
      >
        <Typography variant="h4" sx={{ lineHeight: "22px" }} data-test-id="display-name-subheader">
          <T>display_name</T>
        </Typography>
        <Typography
          sx={{
            fontSize: "11px",
            fontWeight: "400",
            color: "rgba(21, 56, 98, 0.8)",
          }}
        >
          <span data-test-id="display-name-subtext"><T>display_name_subheader</T></span>.&nbsp;&nbsp;
          <Link {...linkProps} data-test-id="learn-more-link">
            <T>learn_more</T>
          </Link>
        </Typography>
        <Stack direction={viewportIsMedium ? "row" : "column"} width="100%" gap={1}>
          <Stack sx={{ flex: 1 }}>
            <DisplayNameTextField
              label={firstNameTranslated}
              name="firstName"
              formik={formik}
              changeHandler={changeHandler}
              handleKeyPress={handleKeyPress}
              showLoadingOverlay={showLoadingOverlay}
            />
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <DisplayNameTextField
              label={lastNameTranslated}
              name="lastName"
              formik={formik}
              changeHandler={changeHandler}
              handleKeyPress={handleKeyPress}
              showLoadingOverlay={showLoadingOverlay}
            />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export { SettingsDisplayNameSection };

