import { getCountryCallingCode } from 'libphonenumber-js';
import countriesData from 'libphonenumber-js/metadata.min.json';
import { getCodeList } from 'country-list';

// using external libraries, build array of countries with calling codes, and ISO codes
export function getCountriesWithCallingCodes() {
  const countries = [];

  for (const isoCode of Object.keys(countriesData.countries)) {
    const countryData = countriesData.countries[isoCode];
    const countryList = getCodeList();

    if (countryData) {
      if (countryList[isoCode.toLowerCase()] === undefined) {
        continue;
      }
      const callingCode = getCountryCallingCode(isoCode);
      countries.push({ isoCode, name: countryList[isoCode.toLowerCase()], callingCode });
    }
  }

  return countries;
}
