import React from "react";
import { T } from "../../hooks/useTranslate";

import { Box } from "@mui/material";

import { formatTextSizeWeight } from "./format-functions";

const CenturionLevelBoxMobile = ({ sales, number, salesNeeded }) => {
  const isComplete = sales >= salesNeeded;

  // mobile complete box
  if (isComplete) return (
    <Box
      data-test-id={`level-box-${number}`}
      sx={{
        padding: "20px",
        backgroundColor: "#FFECE5",
        borderRadius: "8px"
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box data-test-id={`level-value-${number}`}>
          <Box sx={{
            ...formatTextSizeWeight("11px", 600),
            textWrap: "nowrap"
          }}>
            <T>level</T> {number}
          </Box>
          <Box sx={{
            fontSize: "14px",
            fontWeight: "700", 
            marginTop: "2px",
            textWrap: "nowrap"
          }}>
            100 %
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Box sx={{ color: "#E07854", fontWeight: "600", fontSize: "11px" }} data-test-id={`level-text-${number}`}>
            <T>completed</T> {salesNeeded} <T>sales</T>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src="icons/centurion-check.svg" alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  )

  // mobile incomplete box
  return (
    <Box
      data-test-id={`level-box-${number}`}
      sx={{
        padding: "20px",
        backgroundColor: "#F3F5F7",
        borderRadius: "8px"
      }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box data-test-id={`level-value-${number}`}>
          <Box sx={formatTextSizeWeight("11px", 600)}>
            <T>level</T> {number}
          </Box>
          <Box sx={{ fontSize: "14px", fontWeight: "700", marginTop: "2px" }}>
            {`${Math.trunc(sales / salesNeeded * 100)} %`}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <Box sx={{ color: "#153862", fontWeight: "600", fontSize: "11px", opacity: ".5" }} data-test-id={`level-text-${number}`}>
            <T>reach</T> {salesNeeded} <T>sales</T>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {getSvg(number)}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { CenturionLevelBoxMobile }

const getSvg = (num) => {
  switch (num) {
    case 1:
      return <img src="icons/centurion-one.svg" alt="centurion level one" />
    case 2:
      return <img src="icons/centurion-two.svg" alt="centurion level two" />
    case 3:
      return <img src="icons/centurion-three.svg" alt="centurion level three" />
    default:
      return "Icon not found"
  }
}