import { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { Popper, Box, Stack, Typography, CircularProgress, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { SearchSuggestionItem } from "./SearchSuggestionItem";
import { styles } from "../../screens/PartnersScreen/partners.styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PartnersContext } from "../../hooks/PartnersContext";
import { useMixPanel } from "../../hooks/useMixPanel";


const NoResults = () => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "center", margin: "40px 0px" }}>
        <img src="icons/no-search-results.svg" alt="" style={{ width: "80px" }} />
        <Typography variant="h3"><T>no_search_results</T></Typography>
        <Typography variant="h5" sx={{ width: "221px", textAlign: "center" }}>
          <T>no_search_results1</T>
        </Typography>
      </Box>
    </Box >
  )
}

const SearchPopper = ({
  id,
  type,
  open,
  setOpen,
  anchorEl,
  searchSuggestionsResults,
  searchSuggestionsResultsCount,
  loading,
  debouncedInput,
  currentInput,
  setSearchField
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const displayedSearchResultsCount = searchSuggestionsResultsCount > 99 ? "99+" : searchSuggestionsResultsCount;
  const { downlineUid, setLoading, leg1UnicityId, leg2UnicityId } = useContext(PartnersContext);
  const { logEvent } = useMixPanel();

  const handleClickAway = (e) => {
    if (e.target.id === `${type}-search-text-input`) return;
    setOpen(false);
  }

  const handleClickToSearchResults = () => {
    setLoading(true);
    logEvent(`${type}_search_results`, {
      location: `${type}`,
      click_type: 'click_show_results_for',
      searchTerm: currentInput
    })
    if (type === "partners") {
      navigate(`/${type}/search-results?searchTerm=${currentInput}&partnerId=${downlineUid}`);
    } else if (type === "members") {
      setSearchField(currentInput);
      setOpen(false);
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        sx={{ zIndex: 1 }}
      >
        <Box
          mt={1.5}
          mb={1.5}
          sx={{
            ...styles.searchSuggestionsPopper,
            width: viewportIsSmall ? 400 : 325,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={.5}
            mb={3}
            sx={{ padding: "24px 24px 0px 24px" }}
          >
            <img src="icons/search-glass.svg" alt="magnified glass icon" style={{ height: 20, width: 20 }} />
            <Typography variant="h4"><T>search_results</T>&nbsp;{searchSuggestionsResultsCount ? `(${displayedSearchResultsCount})` : ""}</Typography>
          </Stack>
          {
            loading ? (
              <Stack alignItems="center" p={12}>
                <CircularProgress />
              </Stack>
            ) : searchSuggestionsResults.length ? (
              <Stack gap={2} pr={3} pl={3} pb={3}>
                {searchSuggestionsResults.map((item, index) => (
                  <SearchSuggestionItem
                    key={index}
                    type={type}
                    item={item}
                    searchTerm={currentInput}
                    setOpen={setOpen}
                    leg1UnicityId={leg1UnicityId}
                    leg2UnicityId={leg2UnicityId}
                  />
                ))}
              </Stack>
            ) : (
              <NoResults />
            )
          }
          {searchSuggestionsResultsCount !== 0 &&
            <>
              <Divider />
              <Stack
                direction="row"
                alignItems="flex-end"
                gap={1}
                onClick={handleClickToSearchResults}
                sx={{
                  padding: "18.5px 24px",
                  "&:hover": {
                    cursor: "pointer",
                    bgcolor: "#f5f5f5"
                  }
                }}
              >
                <Typography variant="h4">
                  {searchSuggestionsResultsCount > 8 ? (
                    <T>show_all_results_for</T>
                  ) : (
                    <T>show_results_for</T>
                  )}
                  {` "${debouncedInput}"`}
                </Typography>
                <img src="icons/arrow-right.svg" alt="right arrow" />
              </Stack>
            </>
          }
        </Box>
      </Popper >
    </ClickAwayListener>
  )
}

export { SearchPopper }