import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { OfficePage } from "../../components/OfficePage";
import { OfficeBox } from "../../components/OfficeBox";
import { styles } from "../PartnersScreen/partners.styles";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { CustomPagination } from "../../components/CustomPagination";
import { PartnersNoSearchResultsOverlay } from "./PartnersNoSearchResultsOverlay";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PartnersHeadSection } from "../PartnersScreen/PartnersHeadSection";
import { PartnersSearchSortSection } from "../PartnersScreen/PartnersSearchSortSection";
import { PartnersContext } from "../../hooks/PartnersContext";
import { columns } from "../PartnersScreen/PartnersDataGridComponents/format-partners-columns";
import { calculateDynamicRowHeight } from "../../common/helper-functions";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const PartnersSearchResultsScreen = ({ isWebView }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const searchQuery = params.get("searchTerm");
  const id = params.get("partnerId");
  const { rows,
    rowCount,
    loading,
    pageSize,
    page,
    setPage,
    setPageSize,
    columnVisibilityModel,
    sortModel,
    setSortModel,
    setDownlineUid,
    setSearchQuery } = useContext(PartnersContext);

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();

  const { downlineUid } = useContext(PartnersContext);
  const { userInfo } = useAuth();
  // eslint-disable-next-line
  const backToSignedInUser = downlineUid == userInfo.unicityId;

  useEffect(() => {
    // if apiRef is errored out, don't attempt to pin columns
    if (apiRef.current.pinColumn) {
      const visibleColumnsCount = Object.values(columnVisibilityModel).filter((v) => v).length;
      !viewportIsLarge && visibleColumnsCount > 2
        ? apiRef.current.pinColumn("name", "left")
        : apiRef.current.pinColumn(null);
    }

    // eslint-disable-next-line
  }, [apiRef, viewportIsLarge, viewportIsSmall, columnVisibilityModel]);

  // set downlineUid to current user if viewing search screen
  useEffect(() => {
    if (id) {
      setDownlineUid(id);
    }
    // eslint-disable-next-line
  }, [id])

  useEffect(() => {
    if (searchQuery) {
      setSearchQuery(searchQuery);
    }
    // default to relevance sort on load for search results
    setSortModel([{ field: "relevance", sort: "desc" }]);
    // eslint-disable-next-line
  }, [])

  // prevent default MUI sorting behavior when clicking mobile help icon
  const handleColumnHeaderClick = (params, event) => {
    if (event.target.getAttribute("data-test-id") === "help-icon-mobile") {
      event.defaultMuiPrevented = true;
    }
  }

  // calculate row height based on number of chips for that row
  const getRowHeight = (params) => {
    // return default row height because chips are not stacked beyond this breakpoint
    if (viewportIsLarge) return 50;

    const dynamicRowHeight = calculateDynamicRowHeight(params);
    return dynamicRowHeight;
  };

  const handleBackArrowClick = () => {
    if (backToSignedInUser) {
      navigate("/partners", { replace: true })
    } else {
      navigate(`/partners/${downlineUid}`, { replace: true })
    }
  }

  return (
    <OfficePage pageTitle="search_results" showBackArrow={true} onBackArrowClick={handleBackArrowClick}>
      <PartnersSearchSortSection />
      <OfficeBox>
        <PartnersHeadSection isWebView={isWebView} />
        <Box
          sx={{
            height: `calc(100dvh - ${viewportIsSmall ? "200px" : isWebView ? "140px" : "272px"
              })`,
            minHeight: "315px",
          }}
        >
          <DataGridPro
            apiRef={apiRef}
            columns={columns}
            onColumnHeaderClick={handleColumnHeaderClick}
            rows={rows}
            getRowHeight={getRowHeight}
            pageSize={pageSize}
            columnHeaderHeight={36}
            columnVisibilityModel={columnVisibilityModel}
            sx={styles.dataGrid}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            loading={loading}
            rowCount={rowCount}
            onPageChange={setPage}
            rowsPerPageOptions={[10, 50, 100]}
            onPageSizeChange={setPageSize}
            sortingOrder={["desc", "asc"]}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            pagination
            paginationMode="server"
            sortingMode="server"
            disableVirtualization
            slots={{
              pagination: CustomPagination,
              noRowsOverlay: PartnersNoSearchResultsOverlay,
              noResultsOverlay: PartnersNoSearchResultsOverlay,
            }}
            slotProps={{
              pagination: {
                page: page,
                pageSize: pageSize,
                rowCount: rowCount,
                handlePageSizeChange: setPageSize,
                loading: loading,
                handlePageChange: setPage,
                isPartners: true,
              },
            }}
          />
        </Box>
      </OfficeBox>
    </OfficePage>
  )
}

export { PartnersSearchResultsScreen }