import {
  RouterProvider,
  Outlet,
  createHashRouter,
} from "react-router-dom";

import { RequireAuth } from "../components/RequireAuth.jsx";
import { LoginScreen } from "../screens/LoginScreen/LoginScreen.jsx";
import { DashboardScreen } from "../screens/DashboardScreen/DashboardScreen.jsx";
import { MyMembersScreen } from "../screens/MyMembersScreen/MyMembersScreen.jsx";
import { PartnersScreen } from "../screens/PartnersScreen/PartnersScreen.jsx";
import { ActionHubScreen } from "../screens/ActionHubScreen/ActionHubScreen.jsx";
import { CenturionScreen } from "../screens/CenturionScreen/CenturionScreen.jsx";
import { ReferralLinksScreen } from "../screens/ReferralLinksScreen/ReferralLinksScreen.jsx";
import { AllStatementsScreen } from "../screens/EarningsScreen/AllStatementsScreen.jsx";
import { SettingsScreen } from "../screens/SettingsScreen/SettingsScreen.jsx";
import { SettingsMyProfileSection } from "../screens/SettingsScreen/MyProfile/SettingsMyProfileSection.jsx";
import { SettingsPersonalInfoSection } from "../screens/SettingsScreen/PersonalInfo/SettingsPersonalInfoSection.jsx";
import { SettingsSponsorEnrollerSection } from "../screens/SettingsScreen/SponsorEnroller/SettingsSponsorEnrollerSection.jsx";
import { SettingsPlacementProgramSection } from "../screens/SettingsScreen/PlacementProgram/SettingsPlacementProgramSection.jsx";
import { ElectronicIDSection } from "../screens/SettingsScreen/ElectronicID/ElectronicIDSection.jsx";
import { NewFeelGreatMembersScreen } from "../screens/ActionHubScreen/NewFeelGreatMembersScreen.jsx";
import { OrderRemindersScreen } from "../screens/ActionHubScreen/OrderRemindersScreen.jsx";
import { PartialsScreen } from "../screens/ActionHubScreen/PartialsScreen.jsx";
import { ErrorScreen } from "../screens/ErrorScreen/ErrorScreen.jsx";
import { AllNewsScreen } from "../screens/AllNewsScreen/AllNewsScreen.jsx";
import { EarningsScreen } from "../screens/EarningsScreen/EarningsScreen.jsx";
import { PartnersSearchResultsScreen } from "../screens/PartnersSearchResultsScreen/PartnersSearchResultsScreen.jsx";


//given the name of a feature flag,
// get its value from the query string,
// and assume it is boolean with default false
const getFeatureFlagFromQueryString = (flagName) =>
  window?.location?.hash
    ?.split("?")[1]
    ?.split("&")
    ?.find((p) => p.indexOf(flagName) !== -1)
    ?.split("=")
    ?.slice(-1)[0] || false;

//check for feature flags when the application first loads
//this value will only be set once, since routes are only built once - so this should persist in webviews
const isWebView = getFeatureFlagFromQueryString("isWebView");
const isPwa = getFeatureFlagFromQueryString("isPwa");

const OfficeRouter = () => {
  const routes = [
    {
      path: "/",
      element: <RequireAuth>
        <DashboardScreen />
      </RequireAuth>,
    },
    {
      path: "/login",
      element: <LoginScreen isWebView={isWebView} isPwa={isPwa} />,
      children: [
        {
          path: "/login/sl/:token",
          element: <LoginScreen isWebView={isWebView} isPwa={isPwa} />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: <RequireAuth>
        <DashboardScreen />
      </RequireAuth>,
    },
    {
      path: "/members",
      element: <RequireAuth>
        <MyMembersScreen isWebView={isWebView} />
      </RequireAuth>,
    },
    {
      path: "/partners",
      element: <RequireAuth>
        <PartnersScreen isWebView={isWebView} />
      </RequireAuth>,
      children: [
        {
          path: "/partners/:distributorId",
          element: <PartnersScreen isWebView={isWebView} />,
        }
      ],
      errorElement: <ErrorScreen isWebView={isWebView} />,
    },
    {
      path: "/partners/search-results",
      element: <RequireAuth>
        <PartnersSearchResultsScreen isWebView={isWebView} />
      </RequireAuth>,
    },
    {
      path: "/actionhub",
      element: <RequireAuth>
        <ActionHubScreen />
      </RequireAuth>
    },
    {
      path: "/actionhub/newfeelgreat",
      element: <RequireAuth>
        <NewFeelGreatMembersScreen />
      </RequireAuth>
    },
    {
      path: "/actionhub/reminders",
      element: <RequireAuth>
        <OrderRemindersScreen />
      </RequireAuth>
    },
    {
      path: "/actionhub/partials",
      element: <RequireAuth>
        <PartialsScreen />
      </RequireAuth>
    },
    {
      path: "/centurion",
      element: <RequireAuth>
        <CenturionScreen />
      </RequireAuth>,
    },
    {
      path: "/referrals",
      element: <RequireAuth>
        <ReferralLinksScreen />
      </RequireAuth>,
    },
    {
      path: "/earnings/statements",
      element: <RequireAuth>
        <AllStatementsScreen isWebView={isWebView} renderAsChild={true} />
      </RequireAuth>,
    },
    {
      path: "/earnings",
      element: <RequireAuth>
        <EarningsScreen isWebView={isWebView} />
      </RequireAuth>,
    },
    {
      path: "/settings",
      element: <RequireAuth>
        <SettingsScreen>
          <Outlet />
        </SettingsScreen>
      </RequireAuth>,
      children: [
        {
          path: 'my-profile',
          element: <SettingsMyProfileSection />,
        },
        {
          path: 'personal-information',
          element: <SettingsPersonalInfoSection />,
        },
        {
          path: 'sponsor-and-enroller',
          element: <SettingsSponsorEnrollerSection />,
        },
        {
          path: 'placement-program',
          element: <SettingsPlacementProgramSection />,
        },
        {
          path: 'electronic-id',
          element: <ElectronicIDSection />,
        },
      ]
    },
    {
      path: "/news",
      element: (
        <RequireAuth>
          <AllNewsScreen />
        </RequireAuth>
      )
    },
    {
      path: "*",
      element: <RequireAuth>
        <DashboardScreen />
      </RequireAuth>
    }
  ];

  const router = createHashRouter(routes);

  return <RouterProvider router={router} />;
};

export { OfficeRouter };