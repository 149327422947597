import React from 'react';
import { Box } from '@mui/material';
import { useMixPanel } from "../../hooks/useMixPanel";
import { ComButton } from "./ComButton";
import {
  formatPhoneNumber
} from "../../common/format-functions";

const ComButtons = ({ customerHrefData, drawerType, eventLocation }) => {
  const { logEvent } = useMixPanel();
  let formattedPhoneNumber = "";
  if (customerHrefData?.homePhone) {
    formattedPhoneNumber = formatPhoneNumber(customerHrefData.homePhone);
  } else if (customerHrefData?.mobilePhone) {
    formattedPhoneNumber = formatPhoneNumber(customerHrefData.mobilePhone);
  }

  const handleClick = (eventType) => {
    logEvent(eventType, {
      location: eventLocation,
      user_type: drawerType,
      target_id: customerHrefData.unicity
    })
  }

  return (
    <Box
      sx={{
        margin: "16px 0",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "8px"
      }}
    >
      <ComButton
        href={`mailTo:${customerHrefData?.email}`}
        onClick={() => handleClick("email_contact")}
        aria-disabled={!customerHrefData?.email}
        disabled={!customerHrefData?.email}
        data-testid={`${drawerType}-profile-mail`}
      >
        <img src="icons/mail-fill.svg" alt="downline icon" style={{ opacity: !customerHrefData?.email && ".5" }} />
      </ComButton>
      <ComButton
        href={`sms:${formattedPhoneNumber}`}
        onClick={() => handleClick("sms_contact")}
        disabled={!formattedPhoneNumber}
        aria-disabled={!formattedPhoneNumber}
        data-testid={`${drawerType}-profile-sms`}
      >
        <img src="icons/comment-fill.svg" alt="downline icon" style={{ opacity: !formattedPhoneNumber && ".5" }} />
      </ComButton>
      <ComButton
        href={`tel:${formattedPhoneNumber}`}
        onClick={() => handleClick("phone_contact")}
        disabled={!formattedPhoneNumber}
        aria-disabled={!formattedPhoneNumber}
        data-testid={`${drawerType}-profile-phone`}
      >
        <img src="icons/call-fill.svg" alt="downline icon" style={{ opacity: !formattedPhoneNumber && ".5" }} />
      </ComButton>
    </Box>
  );
};

export { ComButtons };
