import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { OfficeBox } from "../../components/OfficeBox";

import { OfficePage } from "../../components/OfficePage";
import { MembersDataGrid } from "./MembersDataGrid";
import { MemberContextProvider } from "../../hooks/MemberContext";
import { usePrevious } from "../../hooks/usePrevious";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TooManyHitsOverlay } from "../../overlays/TooManyHitsOverlay";
import { MembersSearchFilterSection } from "./MembersSearchFilterSection";
import { useMixPanel } from "../../hooks/useMixPanel";

//My Members screen
const MyMembersScreen = ({ isWebView }) => {
  const { userInfo } = useAuth();
  const { logEvent } = useMixPanel();

  const OFFICE_API_URL = `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/members`;

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));

  //this is passed to the DataGrid to tell it where to put the up/down arrow
  const [sortModel, setSortModel] = useState([
    { field: "lastOrderDate", sort: "desc" },
  ]);

  const handleSortModelChange = (newSortModel) => {
    logEvent("members_sort_change", {
      location: "members",
      sortModel: sortModel[0],
    })
    setSortModel(newSortModel);
  }

  // passed to DataGrid to control column visibility
  // check local storage for a persisted state, otherwise apply default
  const [columnVisibility, setColumnVisibility] = useState(
    sessionStorage.getItem("membersColumnVisibility")
      ? JSON.parse(sessionStorage.getItem("membersColumnVisibility"))
      : viewportIsSmall ? {
        name: true,
        joinDate: true,
        orders: true,
        referrals: true,
        lastOrderDate: true,
        lastOrderPv: true,
        subscriptionRenewalDate: true,
        country: true,
      } : {
        name: true,
        joinDate: false,
        orders: false,
        referrals: false,
        lastOrderDate: true,
        lastOrderPv: false,
        subscriptionRenewalDate: false,
        country: false,
      }
  );

  // define filters for API
  const [filterModel, setFilterModel] = useState([]);

  //page size: how many rows do we want in each request?
  const [pageSize, setPageSize] = useState(!viewportIsMedium ? 10 : 50);

  //page number: what page are we on right now?
  const [page, setPage] = useState(0);

  // member search field contents
  const [searchField, setSearchField] = useState("");
  const prevSearchField = usePrevious(searchField);

  const { response, status, sendRequest } = useApi();

  //map data returned by the api to make it more digestible to the data grid
  const members = response?.data?.items || [];
  const hits = response?.data?.hits;

  //offset tells us which item should be the first one returned by the api
  // i.e., if we are on page 10 and the pageSize is 20,
  // we want to ask the api for items 200 - 220,
  // so the offset will be 200.
  const offset = page * pageSize;

  const [gridUpdateTimestamp, setGridUpdateTimestamp] = useState()

  //hotfix for https://app.clickup.com/t/866amerzc
  // "User not navigated to page 1 of search results when a search is initiated from any page besides page 1 of Members list."
  // solution: if we wind up in a state where the offset is greater than the total number of hits, let's just go back to page 0.
  useEffect(() => {
    if (offset > response?.data?.hits && response?.data?.hits > 0 && page > 0)
      setPage(0);
  }, [response, offset, page, setPage, searchField]);

  //as a side effect of changes to sortField, page, and pageSize (as well as on page load),
  // make a new API request to refresh the data
  useEffect(() => {
    if (userInfo?.loggedIn && sendRequest) {
      //if the offset is greater than the total number of items we're paging through,
      // it means mui hasn't given us the updated page yet,
      // so we should do nothing and wait.
      const waitForPageChange = offset > response?.data?.hits || 0;

      let filterQuery = filterModel.reduce((prev, curr) => {
        return prev + `&${Object.keys(curr)[0]}=${curr[Object.keys(curr)[0]]}`;
      }, "");

      //if searchField is populated, add searchTerm to the API call
      if (searchField) {
        filterQuery += `&searchTerm=${searchField}`;
        //if searchField is not populated, yet we are sorting by relevance, restore to default sort
      } else if (sortModel[0].field === "relevance") {
        setSortModel([{ field: "lastOrderDate", sort: "desc" }]);
      }

      //if we're going from a blank search term to a populated search term, sort by relevance descending
      if (!prevSearchField && searchField)
        setSortModel([{ field: "relevance", sort: "desc" }]);

      const abortController = new AbortController();

      if (!waitForPageChange)
        sendRequest({
          method: "get",
          endpoint: `${OFFICE_API_URL}?offset=${offset}&limit=${pageSize}&sortField=${sortModel[0].field}&sortDirection=${sortModel[0].sort}${filterQuery}`,
          addPrefix: false,
          abortController,
        });

      return () => abortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortModel, page, pageSize, filterModel, searchField]);

  useEffect(() => {
    if (response && response.status === 200) {
      const {members_last_updated} = response.data
      setGridUpdateTimestamp(members_last_updated)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response])

  const clearFilters = () => {
    setFilterModel([]);
  }

  // calculate row height based on number of chips for that row
  const getRowHeight = (params) => {
    // params.model.customerType
    const showCustomerTypeChip = ["Customer", "WholesaleCustomer", "PreferredCustomer"].includes(params.model.customerType);

    return showCustomerTypeChip ? 65 : 52;
  };

  return (
    <>
      <OfficePage pageTitle="members">
        <MemberContextProvider>
          <MembersSearchFilterSection
            setSearchField={setSearchField}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            filterModel={filterModel}
            setFilterModel={setFilterModel}
            handlePageChange={setPage}
            clearFilters={clearFilters}
            apiInProgress={status === "wait" || status === "uninitialized"}
            gridUpdateTimestamp={gridUpdateTimestamp}
          />
          <OfficeBox>
            {hits > 9999 && status === "complete" ? (
              <TooManyHitsOverlay />
            ) : (
              <MembersDataGrid
                members={members}
                getRowHeight={getRowHeight}
                handleSortModelChange={handleSortModelChange}
                loading={status === "wait" || status === "uninitialized"}
                sortModel={sortModel}
                filterModel={filterModel}
                setFilterModel={setFilterModel}
                searchField={searchField}
                setSearchField={setSearchField}
                rowCount={response?.data?.hits || 0}
                hits={response?.data?.hits}
                handlePageChange={setPage}
                pageSize={pageSize}
                handlePageSizeChange={setPageSize}
                page={page}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                isWebView={isWebView}
              />
            )}
            {status === "error" && (
              <Alert id="membersError" severity="error">
                Error retrieving member data. Please try again later.
              </Alert>
            )}
          </OfficeBox>
        </MemberContextProvider>
      </OfficePage>
    </>
  );
};

export { MyMembersScreen };
