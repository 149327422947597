import React, { useState, useEffect } from "react";
import { T } from "../../hooks/useTranslate";
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Divider,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../hooks/useAuth";
import { BusinessNavItem } from "./BusinessNavItem";
import { ResourceNavItem } from "./ResourceNavItem";
import { businessDrawerItems, resourceDrawerItems } from "./nav-drawer-items";
import { CopyrightSection } from "../CopyrightSection";

const MainNavDrawer = ({ shopLink, viewportIsLarge }) => {
  const drawerOpenKey = "drawerOpen";
  const [drawerWidth, setDrawerWidth] = useState(240);
  const defaultOpen =
    localStorage.getItem(drawerOpenKey) === "true" ||
    localStorage.getItem(drawerOpenKey) === null
  const [open, setOpen] = useState(defaultOpen);
  const { userInfo } = useAuth();

  const toggleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    localStorage.setItem(drawerOpenKey, open);
  }, [open]);

  useEffect(() => {
    if (!viewportIsLarge) {
      setOpen(false);
      return
    }
    setOpen(true);
  }, [ viewportIsLarge])

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    paddingTop: 8,
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    paddingTop: 8,
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const subheaderStyle = {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
    color: "#8A9BB0",
    padding: "0px 0px 12px 24px",
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <Box
          id="nav-drawer"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box id="row1">
            <List>
              <ListItem
                aria-label="toggle navigation"
                key="hamburger"
                disablePadding
                sx={{ display: "block", marginBottom: "24px" }}
              >
                <ListItemButton
                  id="nav-icon"
                  data-test-id="nav-icon"
                  onClick={toggleDrawerOpen}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 2 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <MenuIcon sx={{ color: "#153862" }} />
                  </ListItemIcon>

                  {open && (
                    <Box sx={{ display: "flex", gap: "6.5px" }}>
                      <img
                        alt="Unicity Logo"
                        src="icons/logo-unicity.svg"
                        style={{ width: "87px" }}
                      />
                      <img alt="Office Logo" src="icons/logo-office.svg" />
                    </Box>
                  )}
                </ListItemButton>
              </ListItem>
              <Box sx={{ visibility: open ? "visible" : "hidden" }}>
                <Typography sx={subheaderStyle}>
                  <T isUpperCase={true}>business</T>
                </Typography>
              </Box>
              {businessDrawerItems.map((item, index) => {
                return (
                  <BusinessNavItem
                    key={index}
                    item={item}
                    open={open}
                    isMobile={false}
                  />
                );
              })}
              {open ? (
                <Typography sx={subheaderStyle}>
                  <T isUpperCase={true}>resources</T>
                </Typography>
              ) : (
                <Divider variant="middle" sx={{ marginBottom: "18px" }} />
              )}
              {resourceDrawerItems
                .filter(
                  (item) =>
                    !(
                      !["CA", "US"].includes(userInfo.countryCode) &&
                      item.value === "new-library"
                    )
                )
                .map((item, index) => (
                  <ResourceNavItem
                    key={index}
                    item={item}
                    open={open}
                    isMobile={false}
                    setDrawerWidth={setDrawerWidth}
                    shopLink={shopLink}
                  />
                ))}
            </List>
          </Box>
          <Box id="row2">
            <CopyrightSection hidden={!open} padding={2.5} />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export { MainNavDrawer };
