const ranks = [
  {
    label: "Distributor/Franchise Partner(EU)/Business Associate(KR)",
    value: ["Dst", "As", "FP", "PC", "BA"],
    checked: true,
  },
  {
    label: "Phase 1/Associate(KR)/Member(JP) - (Asia Plan)",
    value: ["Ph1", "P1", "Asc", "Mbr"],
    checked: true,
  },
  {
    label: "Jr. Manager - (Global Plan)",
    value: ["JrM"],
    checked: true,
  },
  { label: "manager", value: ["Mgr", "Emp"], checked: true },
  { label: "senior_manager", value: ["SrM", "SrE"], checked: true },
  {
    label: "executive_manager",
    value: ["ExM"],
    checked: true,
  },
  { label: "director", value: ["Dir"], checked: true },
  { label: "senior_director", value: ["SrD"], checked: true },
  { label: "executive_director", value: ["ExD"], checked: true },
  { label: "presidential_director", value: ["PrD"], checked: true },
  { label: "presidential_sapphire", value: ["PrS"], checked: true },
  { label: "presidential_ruby", value: ["PrR"], checked: true },
  { label: "presidential_diamond", value: ["DIA"], checked: true },
];

export { ranks };
