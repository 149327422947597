import React, { useEffect } from "react";
import {
  Stack,
  Modal,
  Box,
  Divider,
} from "@mui/material";
import styles from "../../settingsScreenStyles";
import { BackdropLoadingOverlay } from "../../../../components/BackdropLoadingOverlay";
import { useFormikInstances } from './useFormikInstances';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChangeEmailForm } from "./ChangeEmailForm";
import { ChangePhoneForm } from "./ChangePhoneForm";
import { ReauthenticateForm } from "./ReauthenticateForm";
import { ModalButtons } from "../../ModalButtons";

const ContactInfoModal = ({
  isReauthenticated,
  setIsReauthenticated,
  isModalOpen,
  setIsModalOpen,
  isModalLoading,
  setIsModalLoading,
  isEmailChange,
  handleCloseModal,
  setIsUpdated
}) => {
  const { formikReauthentication, formikNewEmail, formikNewPhone } = useFormikInstances(setIsModalLoading, setIsReauthenticated, setIsModalOpen, handleCloseModal, setIsUpdated);
  const theme = useTheme();
  const viewportIsSmall = !useMediaQuery(theme.breakpoints.up("sm"));

  // enter key submits the reauthentication form
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        if (!isReauthenticated) {
          formikReauthentication.handleSubmit();
        }
      }
    };
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    isReauthenticated,
    formikReauthentication,
  ]);

  const handleKeyPress = (event) => {
    const pattern = /^[0-9-]*$/; // Regular expression for numbers and hyphens
    if (!pattern.test(event.key)) {
      event.preventDefault(); // Prevent input of other characters
    }
  };


  // get button content based on reauthentication flow
  const buttonContent = isReauthenticated ? (
    isEmailChange ? (
      <>
        <Divider sx={{ marginBottom: "24px" }} />
        <ModalButtons
          handleCloseModal={handleCloseModal}
          formik={formikNewEmail}
          type="email"
          setIsReauthenticated={setIsReauthenticated}
        />
      </>
    ) : (
      <>
        <Divider sx={{ marginBottom: "24px" }} />
        <ModalButtons
          handleCloseModal={handleCloseModal}
          formik={formikNewPhone}
          type="phone"
          setIsReauthenticated={setIsReauthenticated}
        />
      </>
    )
  ) : (
    <>
      <Divider sx={{ marginBottom: "24px" }} />
      <ModalButtons
        handleCloseModal={handleCloseModal}
        formik={formikReauthentication}
        setIsReauthenticated={setIsReauthenticated}
        viewportIsSmall={viewportIsSmall}
      />
    </>
  );

  // get modal content based on reauthentication flow
  const modalContent = isReauthenticated ? (
    isEmailChange ? (
      <ChangeEmailForm
        formikNewEmail={formikNewEmail}
        setIsReauthenticated={setIsReauthenticated}
        handleCloseModal={handleCloseModal}
        viewportIsSmall={viewportIsSmall}
        isModalLoading={isModalLoading}
        buttonContent={buttonContent}
      />
    ) : (
      <ChangePhoneForm
        formikNewPhone={formikNewPhone}
        setIsReauthenticated={setIsReauthenticated}
        handleCloseModal={handleCloseModal}
        handleKeyPress={handleKeyPress}
        viewportIsSmall={viewportIsSmall}
        isModalLoading={isModalLoading}
        buttonContent={buttonContent}
      />
    )
  ) : (
    <ReauthenticateForm
      formikReauthentication={formikReauthentication}
      setIsReauthenticated={setIsReauthenticated}
      handleCloseModal={handleCloseModal}
      viewportIsSmall={viewportIsSmall}
      isModalLoading={isModalLoading}
      buttonContent={buttonContent}
    />
  );

  // modal takes up entire screen on mobile
  const modalStyles = {
    ...styles.modalBox,
    [theme.breakpoints.down('sm')]: {
      position: "fixed",
      width: "100vw",
      minHeight: "100vh",
      // eslint-disable-next-line
      minHeight: "100dvh",
      backgroundColor: "white",
      border: "1px solid rgba(145, 158, 171, 0.10)",
      boxShadow:
        "0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)",
      borderRadius: "0"
    }
  };

  // need this mobile layout to float buttons to the bottom of the screen
  if (viewportIsSmall) return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="reauthentication-modal-title"
      aria-describedby="reauthentication-modal-description"
    >
      <Box sx={modalStyles}>
        <Stack p={3} sx={{
          minHeight: "100vh",
          // eslint-disable-next-line
          minHeight: "100dvh"
        }} justifyContent="space-between">
          <Box mt={3}>
            <Box
              onClick={handleCloseModal}
              sx={{ cursor: "pointer" }}
              mb={2}
            >
              <img src="icons/close-icon.svg" alt="close icon" />
            </Box>
            {modalContent}
          </Box>
          <Box mb={3}>
            {buttonContent}
          </Box>
        </Stack>
      </Box>
    </Modal>
  )

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="reauthentication-modal-title"
      aria-describedby="reauthentication-modal-description"
    >
      <>
        <Box
          sx={{
            ...modalStyles,
            minHeight: viewportIsSmall ? "100vh" : "",
            // eslint-disable-next-line
            minHeight: viewportIsSmall ? "100dvh" : ""
          }}>
          <Box p={3}>
            {modalContent}
          </Box>
        </Box>
        <BackdropLoadingOverlay showLoadingOverlay={isModalLoading} />
      </>
    </Modal>
  )
}

export { ContactInfoModal }