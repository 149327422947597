import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { useLocation, useNavigate } from "react-router-dom";

import { Stack, Typography, Divider, Box } from "@mui/material";

import { OfficeAvatar } from "../OfficeAvatar";
import { LanguageContext } from "../../hooks/LanguageContext";

import { formatDateByLocale } from "../../common/date-functions"
import { DateTime } from "luxon";
import { useMixPanel } from "hooks/useMixPanel";


const ActivityFeedItem = ({ props, isLastItem, handleClose, isDashboard }) => {
  const { currentLanguage, localizeNumber } = useContext(LanguageContext);

  const isSeen = props.isSeen === "true";

  const location = useLocation();
  const navigate = useNavigate();
  const { logEvent } = useMixPanel();

  const handleItemClick = () => {
    logEvent('recent_activity_feed_item_click')
    const orderHref = props.orderDetails.href.split("/").slice(-1)[0]
    navigate(`${location.pathname}?drawer=member&id=${props.memberId}&drawerContent=orderDetails&orderHref=${orderHref}`, { state: location });
  }

  return (
    <Box sx={{ cursor: "pointer" }} onClick={handleItemClick} data-test-id="activity-feed-item">
      <Stack direction="row" pl={1} pr={2.5}>
        {
          isDashboard || isSeen ?
            <Box width={"13px"}></Box> :
            <Stack justifyContent="center" mr={1}>
              <img src="icons/new-activity.svg" alt="new activity indicator" style={{ height: "5px", width: "5px" }} />
            </Stack>

        }
        <Stack justifyContent="center" mr={1.5}>
          <OfficeAvatar
            id="activity-feed-item-avatar"
            height={24}
            width={24}
            firstInitial={props.firstName[0]}
          />
        </Stack>
        <Stack>
          <Stack>
            <Typography sx={{
              fontWeight: "500",
              fontSize: "12px",
              lineHeight: "12px"
            }}>
              <Typography
                data-test-id="activity-feed-item-name"
                variant="span"
                sx={{ fontWeight: "bold", cursor: "pointer" }}>
                {props.firstName}{props.lastName ? ` ${props.lastName}` : ""}
              </Typography>
              &nbsp;<T>made_a</T>&nbsp;
              <Typography
                data-test-id={`activity-feed-item-pv-value`}
                variant="span"
                sx={{ fontWeight: "bold", cursor: "pointer" }}>
                {localizeNumber(props.orderDetails.pv)}&nbsp;<T>pv</T>
              </Typography>
              &nbsp;<T>order</T>.
            </Typography>
          </Stack>
          <Stack>
            <Typography
              data-test-id={`activity-feed-item-date`}
              sx={{
                fontWeight: "500",
                fontSize: "10px",
                color: "rgba(21, 56, 98, 1)",
                opacity: ".6",
                lineHeight: "12px"
              }}>
              {formatDateByLocale(currentLanguage, props.dateCreated, DateTime.DATETIME_MED)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {!isLastItem && <Divider sx={{ marginTop: "16px" }} />}
    </Box>

  )

}

export { ActivityFeedItem }