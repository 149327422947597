export const dataGridStyling = {
  border: "none",
  fontSize: "12px",
  // needed to extend column headers background over scrollbar
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F9FAFB",
  },
  "& .MuiDataGrid-footerContainer": {
    minHeight: "auto",
  }
};

export const statementsDataGridStyling = {
  "& .MuiDataGrid-withBorder": {
    border: "none"
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-row .MuiDataGrid-cell:first-of-type': {
    paddingLeft: '1.5rem',
  },
  '& .MuiDataGrid-row .MuiDataGrid-cell:last-of-type': {
    paddingRight: '1.5rem',
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:first-of-type': {
    paddingLeft: '1.5rem',
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader:last-of-type': {
    paddingRight: '1.5rem',
  },
}