import React from "react";
import { T } from "../../../../hooks/useTranslate";
import { useNavigate, useLocation } from "react-router-dom"

import { Box, Typography, Stack, Link } from "@mui/material"

import { HelpIconMobile } from "../../../../components/HelpIconMobile";
import { useMixPanel } from "../../../../hooks/useMixPanel";

const CollapsibleReferredBy = ({ row }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const handleSponsorClick = () => {
    logEvent("open_profile", {
      location: "action_hub_new_members",
      user_type: "member"
    })
    navigate(`${location.pathname}?drawer=member&id=${row.sponsorUnicityId}&drawerContent=profile`, { state: { location: location, } });
  }

  return (
    <Box sx={{ flex: 1, backgroundColor: "rgba(21, 56, 98, 0.05);", borderRadius: "8px", padding: "12px" }}>
      <Stack direction="row" spacing={.3}>
        <Typography sx={{ color: "#153862", opacity: ".6", fontSize: "11px", display: "inline" }}><T>referred_by</T></Typography>
        <Stack justifyContent="center">
          <HelpIconMobile
            text1={"referred_by_line1"}
            text2={"referred_by_line2"} />
        </Stack>
      </Stack>
      <Link onClick={handleSponsorClick} sx={{ cursor: "pointer" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>{row.sponsorName}</Typography>
      </Link>
    </Box>
  )
}

export { CollapsibleReferredBy }