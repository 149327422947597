import React, { useState, useRef } from "react";
import { Stack, Typography, Divider, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OfficeBox } from "../../../components/OfficeBox";
import { T } from "../../../hooks/useTranslate";
import { ElectronicIDCard } from "./ElectronicIDCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "../settingsScreenStyles"
import { useReactToPrint } from 'react-to-print';

const ElectronicIDSection = () => {
  const [enablePrintButton, setEnablePrintButton] = useState(true);
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setEnablePrintButton(false),
    onAfterPrint: () => setEnablePrintButton(true)
  });

  return (
    <>
      <Stack direction="column" flex={1} gap={3}>
        <OfficeBox data-test-id="settings-electronic-id-section" sx={styles.mainContent}>
          <Typography variant="h2" mb={5} data-test-id="settings-electronic-id-title">
            <T>electronic_id</T>
          </Typography>

          <Stack mt={3} gap={3}>
            <ElectronicIDCard ref={componentRef} />

            <Divider /> 

            <Stack
              direction={viewportIsSmall ? "row" : "column-reverse"}
              justifyContent="flex-end"
              alignItems={viewportIsSmall ? "center" : "normal"}
              gap={2}
              data-test-id="electronic-id-print-button-section"
            >
              <Button
                data-test-id="electronic-id-print-button"
                onClick={handlePrint}
                disableRipple={!enablePrintButton}
                disabled={!enablePrintButton}
                sx={{
                  ...styles.button,
                  ...(!enablePrintButton && styles.disabledButton),
                  "&.Mui-disabled": {
                    color: "#fff"
                  }
                }}
              >
                <T>{'print_link'}</T>
              </Button>              
            </Stack>
          </Stack>
        </OfficeBox>
      </Stack>
    </>
  );
};

export { ElectronicIDSection };
