import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PartnersContext } from "../../hooks/PartnersContext";
import { T } from "../../hooks/useTranslate";

import { Stack, Link, Typography } from "@mui/material";

const PartnersSearchResultsTitle = () => {
  const navigate = useNavigate();
  const { searchQuery, rowCount, contextUserFirstName, contextUserId } = useContext(PartnersContext);

  const handleClick = () => {
    if (contextUserId) {
      navigate(`/partners/${contextUserId}`);
    } else {
      navigate("/partners");
    }
  }

  return (
    <Stack direction="column" gap={.5}>
      <Stack direction="row">
        <Link
          onClick={handleClick}
          sx={{
            "&:hover": {
              textDecoration: "none",
              cursor: "pointer"
            }
          }}
        >
          <Typography variant="h3">
            {
              contextUserFirstName && <>{`${contextUserFirstName}'s `}</>
            }
            <T>partners</T>
          </Typography>

        </Link>
        <img src="icons/chevron-right.svg" alt="right arrow" />
        <Typography variant="h3" sx={{ color: "rgba(21, 56, 98, 0.7)" }}>
          <T>search_results_for</T>{` “${searchQuery}”  (${rowCount})`}
        </Typography>
      </Stack>
    </Stack >
  )
}

export { PartnersSearchResultsTitle }