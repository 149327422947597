import React, { useContext, useState } from 'react';
import { toTitleCase } from "../../common/format-functions";

import {
    ArrowDropDownOutlined,
} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { LanguageContext } from "../../hooks/LanguageContext";
import Button from '@mui/material/Button';


import { LANGUAGE_DETAILS } from "../../common/languageDetails";
import { LanguageModalMobile } from "../../components/settings/LanguageModalMobile";
import { LanguageModal } from './LanguageModal';
import { Stack } from '@mui/material';

export const LanguageButton = () => {
    const { currentLanguage } = useContext(LanguageContext);

    // desktop modal state
    const [open, setOpen] = useState(false);
    const handleModalOpen = () => setOpen(!open);
    const handleModalClose = () => setOpen(false);

    // mobile modal state
    const [openMobile, setOpenMobile] = useState(false);
    const handleMobileModalOpen = () => setOpenMobile(!open);
    const handleMobileModalClose = () => setOpenMobile(false);

    const theme = useTheme();
    const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
    return (
        <Stack p={4} alignItems="flex-end">
            <Button
                aria-label="open language switcher"
                className="flex-row language-switcher"
                onClick={viewportIsSmall ? handleModalOpen : handleMobileModalOpen}
                sx={{
                    width: 148,
                    backgroundColor: "white",
                    justifyContent: "space-between",
                    color: "rgba(21, 56, 98, 1)",
                    border: "1px solid rgba(145, 158, 171, 0.1);",
                    boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
                    borderRadius: viewportIsSmall ? "12px" : ""
                }}
                data-test-id="language-switcher-button"
            >
                <Stack direction="row" alignItems="center">
                    <img src={`icons/globe.svg`} alt={`globe icon`} style={{ padding: "10px" }} />
                    <div style={{ fontSize: 12, fontWeight: "500" }}>
                        {toTitleCase(LANGUAGE_DETAILS.filter(language => language.countryCode === currentLanguage)[0].label)}
                    </div>
                </Stack>
                <ArrowDropDownOutlined sx={{ color: "rgba(21, 56, 98, 0.5)", transform: open ? "rotate(180deg)" : null }} />
            </Button>
            <LanguageModal
                open={open}
                handleModalOpen={handleModalOpen}
                handleModalClose={handleModalClose}
            />
            <LanguageModalMobile
                open={openMobile}
                handleModalOpen={handleMobileModalOpen}
                handleModalClose={handleMobileModalClose}
            />
        </Stack>
    )
};
