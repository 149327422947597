import React, { useContext } from "react";
import { styled } from '@mui/system';
import { T } from "../../hooks/useTranslate";

import { Typography, Stack, Box } from "@mui/material";

import { PartnersContext } from "../../hooks/PartnersContext";
import { LanguageContext } from "../../hooks/LanguageContext";
import { PartnersViewProfileButton } from "./PartnersViewProfileButton";


const PartnersStatsContent = ({ isMobile }) => {
  const { contextUserFirstName, contextUserStats, contextUserType } = useContext(PartnersContext);
  const { localizeNumber } = useContext(LanguageContext);

  const StackRow = styled("div")({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "12px",
    marginBottom: "12px"
  })

  const LeftText = styled("div")({
    color: "#153862",
    fontSize: isMobile ? "13px" : "11px",
    fontWeight: "600"
  })

  const RightText = styled("div")({
    color: "#153862",
    fontSize: isMobile ? "13px" : "11px",
    fontWeight: "400"
  })

  return (
    <Box
      data-test-id={`stats-section`}
      sx={{ marginTop: isMobile && "16px" }}
    >
      {
        contextUserFirstName && <Typography variant="h2" sx={{ mb: "1rem" }} data-test-id={`stats-box-header`}>{`${contextUserFirstName}'s `}<T>stats</T></Typography>
      }
      {
        contextUserType === "partner" && <PartnersViewProfileButton style={{marginBottom: "1rem"}}/>
      }
      <Stack>
        <StackRow>
          <LeftText data-test-id={`active-partners-label`}><T>active_partners</T>:</LeftText>
          <RightText data-test-id={`active-partners-value`}>{contextUserStats && localizeNumber(contextUserStats.partnersCount)}</RightText>
        </StackRow>
        <StackRow>
          <LeftText data-test-id={`total-ov-label`}><T>total_ov</T>:</LeftText>
          <RightText data-test-id={`total-ov-value`}>{contextUserStats && localizeNumber(contextUserStats.totalOv)}</RightText>
        </StackRow>
        <Typography
          data-test-id={`stats-sections-members-subheader`}
          variant="h6"
          mt={2}
          mb={1.5}>
          <T isUpperCase={true}>members</T>
        </Typography>
        <StackRow>
          <LeftText data-test-id={`total-members-label`}><T>total_members</T>:</LeftText>
          <RightText data-test-id={`total-members-value`}>{contextUserStats && localizeNumber(contextUserStats.membersCount)}</RightText>
        </StackRow>
        <StackRow>
          <LeftText data-test-id={`purchased-last-60-label`}><T>purchased_in_last_60_days</T>: </LeftText>
          <RightText data-test-id={`purchased-last-60-value`}>{contextUserStats && localizeNumber(contextUserStats.lastSixtyDayPurchaserCount)}</RightText>
        </StackRow>
        <StackRow>
          <LeftText data-test-id={`active-subscribers-label`}><T>active_subscribers</T>:</LeftText>
          <RightText data-test-id={`active-subscribers-value`}>{contextUserStats && localizeNumber(contextUserStats.activeSubscribers)}</RightText>
        </StackRow>
        <StackRow>
          <LeftText data-test-id={`members-with-referrals-label`}><T>members_with_referrals</T>:</LeftText>
          <RightText data-test-id={`members-with-referrals-value`}>{contextUserStats && localizeNumber(contextUserStats.referralCount)}</RightText>
        </StackRow>
      </Stack>
    </Box>
  )
}

export { PartnersStatsContent }