const countThirdLeg = (customers) => {
  // Count the number of partners and members in the third leg that have OV
  return customers.reduce((counts, customer) => {
    const customerType = customer.customer?.type;
    if (customerType === "Associate" && customer.customer?.ov > 0) {
      counts.partners++;
    } else if (customerType === "Member" && customer.customer?.ov > 0) {
      counts.members++;
    }
    return counts;
  }, { partners: 0, members: 0 });
};

// NOTE: the 'profilePicture' function parameter could be a javascript string, "" or new String(), or a javascript object {}
// When it is a javascript string then it can be an JSON object string with the sizes array or a direct s3://unicitystatic URL
const parseProfilePictureUrl = (profilePicture) => {
  if (profilePicture) {
    if(profilePicture.hasOwnProperty("sizes") && profilePicture.sizes && profilePicture.sizes.length > 0 && profilePicture.sizes[0].media) {
      return profilePicture.sizes[0].media.replace("s3://unicitystatic", process.env.REACT_APP_UNICITY_STATIC_URL);
    } else if(profilePicture instanceof String || typeof profilePicture === "string") {
      if(profilePicture.startsWith("s3://unicitystatic")) {
        return profilePicture.replace("s3://unicitystatic", process.env.REACT_APP_UNICITY_STATIC_URL);
      } else {
        try {
          const profPic = JSON.parse(profilePicture);
          if(profPic.hasOwnProperty("sizes") && profPic.sizes && profPic.sizes.length > 0 && profPic.sizes[0].media) {
            return profPic.sizes[0].media.replace("s3://unicitystatic", process.env.REACT_APP_UNICITY_STATIC_URL);
          } else {
            return "";
          }
        } catch(err) {
          console.log(err);
          return "";
        }
      }
    } else {
      return "";
    }
  } else {
    return "";
  }
}

const isDate60DaysInactive = (date) => {
  if (date === null) return true;
  const today = new Date();
  const orderDate = new Date(date);
  const differenceInTime = orderDate.getTime() - today.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays > 60;
}

function getTruncatedName(firstName, lastName, truncateName, truncatePosition = 15) {
  // Concatenate the first name and last name to get length
  const name = `${firstName} ${lastName}`;

  // Calculate the length
  const nameLength = name.length;

  // Conditionally truncate the name based on the name length and truncate position value
  const nameTruncated = nameLength > truncatePosition && truncateName
    ? `${name.slice(0, truncatePosition)}...` // Truncate and add ellipsis if length exceeds position
    : name; // Use full name otherwise

  // Split the truncated name
  const nameTruncatedSplit = nameTruncated.split(" ");

  // Extract the first and last name from the truncated name
  const firstNameTruncated = nameTruncatedSplit[0];
  const lastNameTruncated = nameTruncatedSplit[1] || '';

  return { firstNameTruncated, lastNameTruncated };
}

const calculateDynamicRowHeight = (params) => {
  let dynamicRowHeight = 50;
  let chipCount = 0;

  const isSuspended = params.model.customerStatus === "Suspended";
  const isInactivePartner = params.model.type === "partner" && !params.model.is100PvActive;
  const isInactiveMember = params.model.type === "member" && isDate60DaysInactive(params.model.lastOrderDate);
  const isMember = params.model.type === "member";

  // UI renders either a suspended chip or inactive chip, see PartnersNameCell.jsx: 102
  if (isSuspended || isInactivePartner || isInactiveMember) chipCount += 1;
  if (isMember) chipCount += 1;

  if (chipCount > 0) dynamicRowHeight += chipCount * 15.5;

  return dynamicRowHeight;
}

const formatNumber = (num, locale = 'en-US') => num.toLocaleString(locale)

export { countThirdLeg, parseProfilePictureUrl, isDate60DaysInactive, getTruncatedName, calculateDynamicRowHeight, formatNumber }