import React, { useState } from 'react';
import { T } from "../../../hooks/useTranslate";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import { Typography, Stack, Menu, Link } from '@mui/material';

function OvToNextRankHelpIcon({ text1, text2, text3, externalLink }) {
  // State to control the menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Function to handle opening the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle closing the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack
        id="help-icon"
        aria-controls={open ? 'help-icon' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onMouseOver={handleClick}
      >
        <img src="icons/help.svg" alt="help icon" id="help-icon-img" />
      </Stack>

      {/* Tooltip Menu */}
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'help-icon',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(145, 158, 171, 0.1)",
            border: "1px solid rgba(145, 158, 171, 0.1)",
            borderRadius: "12px",
            marginTop: "5px"
          }
        }}
      >
        <Stack padding="18.5px" direction="row" spacing="18.5px">
          {/* Info Icon */}
          <Stack>
            <InfoOutlinedIcon />
          </Stack>

          {/* Text and Link */}
          <Stack direction="column" maxWidth={text3 ? "200px" : "280px"} spacing={2}>
            {text1 && (
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                <T>{text1}</T>
              </Typography>
            )}
            {text2 && (
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                <T>{text2}</T>
              </Typography>
            )}
            {text3 && externalLink && (
              <Link href={externalLink} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h4">
                    <T>{text3}</T>
                  </Typography>
                  <LaunchIcon sx={{ height: "20px", cursor: "pointer" }} />
                </Stack>
              </Link>
            )}
          </Stack>
        </Stack>
      </Menu>
    </div>
  );
}

export { OvToNextRankHelpIcon };