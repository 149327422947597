import React from 'react';
import styled from "styled-components";
import { Link } from '@mui/material';

const StyledLink = styled(Link)(({ theme, disabled }) => ({
  color: disabled ? '#919EAB' : '#153862',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: '600',
  padding: '8px',
  background: disabled ? '#F1F4F7' : '#ffffff',
  border: '1px solid rgba(145, 158, 171, 0.20)',
  boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10)',
  borderRadius: '8px',
  userSelect: 'none',
  '&:hover' : { cursor: disabled ? 'not-allowed' : 'pointer'}
}));

const ComButton = ({ href, dataTestid, children, onClick, disabled = false }) => {

  const handleClick = event => {
    !disabled ? onClick(): event.preventDefault();
  }

  return (
    <StyledLink
      href={!disabled ? href: undefined}
      onClick={(event) => handleClick(event)}
      aria-disabled={disabled}
      disabled={disabled}
      data-testid={dataTestid}
    >
      {children}
  </StyledLink>
  );
};

export { ComButton };
