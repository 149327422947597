import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import { Typography, Stack, Box, Divider, FormControl, RadioGroup, Radio, FormControlLabel, MenuItem, Select, InputLabel } from "@mui/material";
import { DateTime } from "luxon";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { T, useTranslateKey } from "../../../hooks/useTranslate";

import { SettingsTextField } from "../SettingsTextField"
import { SaveChangesButton } from "../MyProfile/SaveChangesButton";
import { ResetFormButton } from "../MyProfile/ResetFormButton";

import settingsStyles from "../settingsScreenStyles";

const INITIAL_VALUES = {
  selection: "distributor",
  distributorName: "",
  distributorUid: "",
  sponsorName: "",
  sponsorUid: "",
  month: ""
}

const PlacementForm = ({
  enrollerName,
  enrollerID,
  handleSaveChanges,
  success
}) => {

  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const stackDirection = viewportIsMedium ? "row" : "column";
  const stackGap = viewportIsMedium ? 1 : 3;

  const enrollerNameLabel = useTranslateKey("enroller_name");
  const enrollerIDLabel = useTranslateKey("enroller_id_number");

  const distributorNameLabel = useTranslateKey("distributor_name");
  const distributorUIDLabel = useTranslateKey("distributor_uid");
  const customerNameLabel = useTranslateKey("customer_name");
  const customerUIDLabel = useTranslateKey("customer_uid");

  const sponserNameLabel = useTranslateKey("sponsor_name");
  const sponserUIDLabel = useTranslateKey("sponsor_uid");

  const invalidUidErrorLabel = useTranslateKey("invalid_uid")

  const [monthOptions, setMonthOptions] = useState([])
  const [selectionType, setSelectionType] = useState()

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validate: (values) => {
      const errors = {};
      if (isNaN(Number(values.distributorUid))) {
        errors.distributorUid = invalidUidErrorLabel;
      }
      if (isNaN(Number(values.sponsorUid))) {
        errors.sponsorUid = invalidUidErrorLabel;
      }
      return errors;
    }
  });

  const getPeriodMonths = () => {
    const getMonth = (date) => {
      const paddedMonth = date.toFormat('MM')
      return {
        "label": date.monthLong.toLowerCase(),
        "value": `${date.year}-${paddedMonth}`
      }
    }
    const now = new Date();
    let periodMonths = [
      getMonth(DateTime.fromJSDate(now)),
      getMonth(DateTime.fromJSDate(now).plus({ months: 1 }))
    ];
    if (now.getDate() <= 3) {
      periodMonths = [
        getMonth(DateTime.fromJSDate(now).minus({ months: 1 })),
        ...periodMonths
      ];
    }
    return periodMonths;
  }

  const handleFormReset = () => {
    formik.setValues({ ...INITIAL_VALUES, month: monthOptions[0].value })
  };

  const isSaveEnabled = () => {
    const { selection, distributorName, distributorUid, sponsorName, sponsorUid, month } = formik.values
    return !!(selection && distributorName && distributorUid && sponsorName && sponsorUid && month)
  }

  useEffect(() => {
    if (monthOptions.length === 0) {
      const months = getPeriodMonths()
      setMonthOptions(months)
      formik.setValues({ ...INITIAL_VALUES, month: months[0].value })
    }
    // eslint-disable-next-line
  }, [monthOptions])

  useEffect(() => {
    if (success) {
      handleFormReset()
    }
    // eslint-disable-next-line
  }, [success])

  return (
    <Stack mt={3} gap={3}>

      <FormControl>
        <RadioGroup
          row
          defaultValue="distributor"
          name="placement-form-selection"
          onChange={(e) => {
            formik.setFieldValue('selection', e.target.value)
            setSelectionType(e.target.value)
          }}
        >
          <FormControlLabel value="distributor" control={<Radio />} label={<Typography style={styles.radioLabel}><T>distributor</T></Typography>} />
          <FormControlLabel value="customer" control={<Radio />} label={<Typography style={styles.radioLabel}><T>customer</T></Typography>} />
        </RadioGroup>
      </FormControl>

      <Stack direction={"column"} gap={3}>
        <Typography variant="h3" sx={{ lineHeight: '1.375rem' }} data-test-id="placement-form-enroller-title">
          <T>enroller_requester</T>
        </Typography>
        <Stack direction={stackDirection} gap={stackGap} data-test-id="placement-form-enroller-row">
          <SettingsTextField
            name="enroller-name"
            label={enrollerNameLabel}
            content={enrollerName}
            isLocked={true}
          />
          <SettingsTextField
            name="enroller-id"
            label={enrollerIDLabel}
            content={enrollerID}
            isLocked={true}
          />
        </Stack>
      </Stack>

      <Stack direction={"column"} gap={3}>
        <Typography variant="h3" sx={{ lineHeight: '1.375rem' }} data-test-id="placement-form-user-title">
          <T>user_to_be_placed</T>*
        </Typography>
        <Stack direction={stackDirection} gap={stackGap} data-test-id="placement-form-user-row">
          <SettingsTextField
            name="distributorName"
            label={selectionType === "customer" ? customerNameLabel : distributorNameLabel}
            content={""}
            formik={formik}
          />
          <SettingsTextField
            name="distributorUid"
            label={selectionType === "customer" ? customerUIDLabel : distributorUIDLabel}
            content={""}
            formik={formik}
          />
        </Stack>
      </Stack>

      <Stack direction={"column"} gap={3}>
        <Typography variant="h3" sx={{ lineHeight: '1.375rem' }} data-test-id="placement-form-sponsor-title">
          <T>new_sponsor</T>*
        </Typography>
        <Stack direction={stackDirection} gap={stackGap} data-test-id="placement-form-sponsor-row">
          <SettingsTextField
            name="sponsorName"
            label={sponserNameLabel}
            content={""}
            formik={formik}
          />
          <SettingsTextField
            name="sponsorUid"
            label={sponserUIDLabel}
            content={""}
            formik={formik}
          />
        </Stack>
      </Stack>

      <Stack gap={3}>
        <Typography variant="h3" sx={{ lineHeight: '1.375rem' }} data-test-id="placement-form-sponsor-title">
          <T>month_of_change</T>*
        </Typography>
        <FormControl
          id="placement-form-month-dropdown"
          sx={{ width: "240px", backgroundColor: "#ffffff" }}
        >
          <InputLabel>
            {<T>month</T>}
          </InputLabel>
          <Select
            labelId="placement-form-month-dropdown-label"
            label={<T>month</T>}
            value={formik.values.month}
            onChange={(e) => {
              formik.setFieldValue('month', e.target.value)
            }}
            renderValue={(i) => {
              const selected = monthOptions.filter((month) => month.value === i)[0];
              return <T>{selected?.label}</T>
            }}
            sx={styles.select}
            MenuProps={styles.selectMenuProps}
            inputProps={styles.selectInputProps}
          >
            <Box sx={styles.selectMenuItemHeader}><T isUpperCase>select_month</T></Box>
            {monthOptions.map(item => {
              return (
                <MenuItem
                  value={item.value}
                  key={item.value}
                  data-test-id={`placement-form-month-dropdown-listitem`}
                  sx={styles.selectMenuItem}
                >
                  <T>{item.label}</T>
                  {formik.values.month === item.value && <img src="icons/check.png" alt="checkmark" />}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>

      <Divider />

      <Stack
        direction={viewportIsSmall ? "row" : "column-reverse"}
        justifyContent="flex-end"
        alignItems={viewportIsSmall ? "center" : "normal"}
        gap={2}
        data-test-id="placement-form-save-button-section"
      >
        <ResetFormButton handleFormReset={handleFormReset} styles={settingsStyles} />
        <SaveChangesButton
          styles={settingsStyles}
          handleSaveChanges={() => handleSaveChanges(formik.values)}
          enableSaveChanges={isSaveEnabled()}
          buttonText={'submit'}
        />
      </Stack>

    </Stack>
  )
}

const styles = {
  select: {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(145, 158, 171, 0.2)",
      borderRadius: "8px",
      minHeight: '54px'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DDDDDD'
    }
  },
  selectMenuProps: {
    PaperProps: {
      sx: {
        boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
        border: "1px solid rgba(145, 158, 171, 0.1)",
        borderRadius: "8px",
        marginTop: "0.375rem"
      }
    },
    MenuListProps: {
      sx: {
        ".MuiButtonBase-root": {
          fontWeight: "500",
          fontSize: "0.875rem",
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem"
        },
        ".Mui-selected": {
          color: "rgba(0, 158, 207, 1)",
          backgroundColor: "rgba(0, 158, 207, 0.05)",
          fontWeight: "600"
        }
      }
    }
  },
  selectInputProps: {
    sx: {
      color: "rgba(21, 56, 98, 1)",
      fontSize: "0.875rem",
      fontWeight: "400",
      padding: "1rem",
      lineHeight: "1.375rem",
      minHeight: "54px"
    }
  },
  selectMenuItemHeader: {
    fontFamily: "Inter",
    fontWeight: '600',
    fontSize: '0.625rem',
    color: "#8A9BB0",
    marginBottom: "0.75rem",
    marginTop: "0.75rem",
    marginLeft: "1.25rem"
  },
  selectMenuItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "230px",
    fontSize: "0.875rem"
  },
  radioLabel: {
    color: "#153862",
    fontSize: '0.875rem',
    fontWeight: '400',
    lineHeight: '1.375rem'
  }
};

export { PlacementForm }