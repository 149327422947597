import React from "react"

import { Box } from "@mui/material"

const OrderDetailItem = ({ item, CurrencyLocalizer }) => {
  const title = item.catalogSlide.content.description;
  const skuNumber = item.item.id.unicity;
  const quantity = item.quantity;
  const priceEach = item.terms.priceEach;
  const pvEach = item.terms.pvEach;
  const totalPrice = priceEach * quantity;
  const totalPV = pvEach * quantity;

  return (
    <Box sx={{ marginBottom: "33px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Box sx={{ fontSize: "14px", fontWeight: "600", maxWidth: "270px" }} data-test-id="order-detail-item-title">
            {title}
          </Box>
          <Box sx={{ fontWeight: "400", fontSize: "12px", opacity: ".6" }} data-test-id="order-detail-item-subtitle">
            {`SKU: ${skuNumber} • QTY: ${quantity}`}
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ textAlign: "right", fontWeight: "600", fontSize: "12px" }} data-test-id="order-detail-item-total-price">
            {`${CurrencyLocalizer.format(totalPrice)}`}
          </Box>
          <Box sx={{ textAlign: "right", fontWeight: "400", fontSize: "12px", opacity: ".6" }} data-test-id="order-detail-item-pv">
            {`${totalPV} PV`}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export { OrderDetailItem }