import React, { useState, useEffect } from "react";
import { T } from "../../hooks/useTranslate";

import { Box, Typography } from "@mui/material";

import { useAuth } from "../../hooks/useAuth";

import { ReferralsCopyBox } from "../../components/ReferralsCopyBox"

const DistributorEnrollmentSection = ({ referralCode }) => {
  const [link, setLink] = useState("");

  const { userInfo } = useAuth();

  useEffect(() => {
    if (referralCode) {
      setLink(`${process.env.REACT_APP_ENROLLMENT_URL}/${referralCode}`)
    } else {
      setLink(`${process.env.REACT_APP_ENROLLMENT_URL}/${userInfo.unicityId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode])

  return (
    <Box sx={{ marginTop: "32px", marginBottom: "32px", padding: "0px 32px" }}>
      <Typography variant="h3" sx={{ marginBottom: "8px" }}>
        <T>distributor_enrollment</T>
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: "24px" }}>
        <T>distributor_enrollment_subtext</T>
      </Typography>
      <Box>
        <ReferralsCopyBox text={link} />
      </Box>
    </Box>
  )
}

export { DistributorEnrollmentSection }