import React from "react";
import { T } from "../../../hooks/useTranslate";
import { getAvailabilityChipColors } from "../../../common/get-colors"

import { Box } from "@mui/material";
const { DateTime } = require("luxon");

const AvailabilityCell = ({ params }) => {
  const now = DateTime.now();
  const joinDate = DateTime.fromISO(params.joinDate);

  // calculate days left until 14 days joined
  const diff = joinDate.diff(now, ["days"]);
  const daysLeft = Math.ceil(diff.values.days) + 14

  const chipColor = getAvailabilityChipColors(daysLeft);


  return (
    <Box sx={{
      fontWeight: "600",
      fontSize: "12px",
      backgroundColor: chipColor.backgroundColor,
      color: chipColor.color,
      padding: "4px 12px 4px 12px",
      borderRadius: "12px"
    }}>
      {`${daysLeft} `}{daysLeft === 1 ? <T>day_left</T> : <T>days_left</T>}
    </Box>
  )
}

export { AvailabilityCell }