import React from "react";
import { T } from "../../../hooks/useTranslate";

import { useNavigate } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material"

import { StyledGridOverlay } from "../../../components/StyledGridOverlay";

const PartnersNoRowsOverlay = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/referrals");
  }
  return (
    <StyledGridOverlay data-test-id="partners-no-rows-overlay">
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "center", margin: "60px 0px" }}>
        <img src="icons/no-search-results.svg" alt="" style={{ width: "80px" }} />
        <Typography variant="h3"><T>no_partners_yet</T></Typography>
        <Typography variant="h5" sx={{ width: "221px", textAlign: "center" }}><T>partner_no_rows_overlay1</T></Typography>
        <Button
          sx={{
            height: "31px",
            paddingRight: "8px",
            paddingLeft: "8px",
            fontSize: "12px",
            backgroundColor: "#009ECF",
            borderRadius: "4px",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#009ECF90"
            }
          }}
          onClick={handleButtonClick}
        >
          <T>go_to_referral_links</T>
        </Button>
      </Box>
    </StyledGridOverlay>

  )

}

export { PartnersNoRowsOverlay }