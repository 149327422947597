import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

import { Box, useMediaQuery } from "@mui/material";

import { CustomPagination } from "../../components/CustomPagination";
import { CustomNoRowsOverlay } from "../../overlays/CustomNoRowsOverlay";
import { columns } from "./format-members-columns";

import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import { SearchNoRowsOverlay } from "../../overlays/SearchNoRowsOverlay";
import { FilterNoRowsOverlay } from "../../overlays/FilterNoRowsOverlay";
import { dataGridStyling } from "../../common/data-grid-styling";

const MembersDataGrid = ({
  members,
  handleSortModelChange,
  loading,
  sortModel,
  filterModel,
  setFilterModel,
  searchField,
  setSearchField,
  rowCount,
  handlePageChange,
  handlePageSizeChange,
  pageSize,
  page,
  columnVisibility,
  isWebView,
  getRowHeight
}) => {
  // initialze api reference for DataGrid and Pagination component
  const apiRef = useGridApiRef();

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  // logic to determine when to pin name column
  useEffect(() => {
    const visibleColumnsCount = Object.values(columnVisibility).filter((v) => v).length;
    !viewportIsLarge && visibleColumnsCount > 2
      ? apiRef.current.pinColumn("name", "left")
      : apiRef.current.pinColumn(null);
    // eslint-disable-next-line
  }, [apiRef, viewportIsLarge, columnVisibility]);

  const getOverlay = () => {
    return searchField !== ""
      ? SearchNoRowsOverlay
      : filterModel.length > 0
        ? FilterNoRowsOverlay
        : CustomNoRowsOverlay;
  };

  return (
    <Box
      id="MembersDataGrid"
      sx={{
        height: `calc(100dvh - ${
          viewportIsSmall ? "275px" : isWebView ? "140px" : "205px"
        })`,
        minHeight: "315px",
      }}
    >
      <DataGridPro
        apiRef={apiRef}
        rows={members}
        getRowHeight={getRowHeight}
        columns={columns.filter(c => !c.hideFromGrid)}
        pageSize={pageSize}
        getRowId={(row) => row.unicityId}
        sx={dataGridStyling}
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooterSelectedRowCount
        sortingMode="server"
        paginationMode="server"
        onSortModelChange={handleSortModelChange}
        sortModel={sortModel[0].field === 'relevance' ? [] : sortModel}
        loading={loading}
        rowCount={rowCount}
        onPageChange={handlePageChange}
        rowsPerPageOptions={[10, 50, 100]}
        onPageSizeChange={handlePageSizeChange}
        sortingOrder={["desc", "asc"]}
        columnVisibilityModel={columnVisibility}
        pagination
        disableVirtualization
        slots={{
          noRowsOverlay: getOverlay(),
          noResultsOverlay: CustomNoRowsOverlay,
          pagination: CustomPagination,
        }}
        slotProps={{
          pagination: {
            page: page,
            pageSize: pageSize,
            rowCount: rowCount,
            handlePageSizeChange: handlePageSizeChange,
            loading: loading,
            handlePageChange: handlePageChange,
          },
          noRowsOverlay: {
            setSearchField: setSearchField,
            setFilterModel: setFilterModel,
          },
          loadingOverlay: {
            "data-test-id": "progress-bar-shown"
          },
        }}
      />
    </Box>
  );
};

export { MembersDataGrid };
