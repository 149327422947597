import React from "react";
import { T } from "../hooks/useTranslate";

import { Box } from "@mui/material";

const CustomerTypeChip = ({ customerType, customStyles }) => {
  const defaultStyles = {
    fontSize: "9px",
    fontWeight: 600,
    backgroundColor: "rgba(90, 143, 195, 0.10)",
    color: "#5A8FC3",
    padding: "1px 8px",
    marginTop: "4px",
    borderRadius: "38px",
  };

  // map customerType value from API to appropriate rosetta translation key
  // https://app.clickup.com/t/863hbdtmt?comment=90100022842726&threadedComment=90020046154772
  const mapToKey = customerType === "Member" ? "member" :
    customerType === "Customer" ? "retail" :
      customerType === "WholesaleCustomer" ? "wholesale" :
        customerType === "PreferredCustomer" ? "preferred"
          : "Customer Type Not Found";

  const mergedStyles = { ...defaultStyles, ...customStyles };

  return (
    <Box
      data-test-id={`customer-type-chip-${mapToKey}`}
      sx={mergedStyles}>
      <T>{mapToKey}</T>
    </Box>
  );
}

export { CustomerTypeChip };
