import { DateTime, Interval } from "luxon";

const formatDateString = (dateString) => {
  let split = dateString.split("-");
  const year = split[0];
  const month = split[1];
  const day = split[2];

  return `${month}/${day}/${year}`;
};

const createDate = (year, month, day) => {
  return DateTime.local(year, month, day);
}

const datesOnSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

// return date object minus X number of days
const getDateXDaysAgo = (days, date = new Date()) => {
  const daysAgo = new Date(date.getTime());

  daysAgo.setDate(date.getDate() - days);

  return daysAgo.toISOString().split("T")[0];
};

const dateToString = (date) => {
  return date ? date.toISOString().split("T")[0] : "";
};

const today = DateTime.now();

const yesterday = today.plus({ days: -1 });

const oneYearAgoArray = () => {
  const todayAsString = dateToString(today);
  const oneYearAgoAsString = getDateXDaysAgo(365, today);

  return `[${oneYearAgoAsString};${todayAsString}]`;
};

function getMonthShortName(monthNo) {
  const date = new Date();
  date.setMonth(monthNo - 1);

  return date.toLocaleString("en-US", { month: "short" });
}

const formatDateForOrderDetail = (dateAsString) => {
  const date = new Date(dateAsString);

  const day = date.getDate();
  const month = getMonthShortName(date.getMonth() + 1);
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
};

let monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// get last size months for centurion dropdown
// take from feelGreat.js
let getMonthRange = (numOfMonths) => {
  let date = new Date();
  let month = date.getMonth();
  let year = date.getFullYear();
  let months = [];
  for (var i = 0; i < numOfMonths; i++) {
    months.push({
      label: `${monthNames[month]} ${year}`,
      value: `${year}-${month < 9 ? "0" : ""}${month + 1}`,
    });
    if (month === 0) {
      month = 11;
      year--;
    } else {
      month--;
    }
  }
  return months;
};

//The API returns way too much stuff, so we need to filter to only include this month and last month's orders.
//selectedMonthString: format '2023-02', gives the selected month in Centurion context
//dateString: a string representing an order date
const orderDateIsInCenturionDateRange = (
  selectedMonthString,
  centurionOrderDateString
) => {
  const givenDateTime = DateTime.fromISO(centurionOrderDateString);

  const intervalStartDateTime = DateTime.fromISO(selectedMonthString)
    .endOf("month")
    .plus({ month: -2 });
  const intervalEndDateTime =
    DateTime.fromISO(selectedMonthString).endOf("month");

  const interval = Interval.fromDateTimes(
    intervalStartDateTime,
    intervalEndDateTime
  );

  return interval.contains(givenDateTime);
};

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

const formatDate = (str) => {
  return str.value ? dateFormatter.format(new Date(str.value + "T00:00:00.000")) : dateFormatter.format(new Date(str + "T00:00:00.000"))
};

const isDateInFuture = (str) => {
  const date = DateTime.fromISO(str);

  return date.startOf("day").diff(DateTime.now().startOf("day"), 'days').days >= 0;
};

// format a number as either an integer or a decimal (1 or two places), whichever is appropriate
const formatDecimalPlaces = (value) => {
  return Number.isInteger(value) === true ? value :
    value.toString().split('.')[1].length === 1 ? value.toFixed(1) :
      value.toFixed(2);
}

// format and return date by locale and apply code if given
// accepts format codes from Luxon otherwise defaults to DATE_MED
// https://moment.github.io/luxon/#/formatting
const formatDateByLocale = (currentLanguage, dateString, formatCode) => {
  return formatCode ? DateTime.fromISO(dateString).setLocale(currentLanguage.split("-")[0]).toLocaleString(formatCode) :
    DateTime.fromISO(dateString).setLocale(currentLanguage.split("-")[0]).toLocaleString(DateTime.DATE_MED)
}

// custom format function to accomadte MMM YY date format for non-US locales
const formatDateShortByLocale = (currentLanguage, dateString) => {
  const language = currentLanguage.split("-")[0];
  let split;


  switch (language) {
    case 'en':
      return formatDateByLocale(currentLanguage, dateString).split(",")[0];
    case 'es':
      split = formatDateByLocale(currentLanguage, dateString).split(" ");
      split.pop();
      return split.join(" ");
    case 'fr':
      split = formatDateByLocale(currentLanguage, dateString).split(".");
      return split[0];
    case 'de':
      split = formatDateByLocale(currentLanguage, dateString).split(" ");
      split.pop();
      return split.join(" ");

    default:
      return formatDateByLocale(currentLanguage, dateString).split(",")[0];
  }
};

// get 7 day range from "2023-11-06 00:00:00.000" as Nov 6 - Nov 12
function formatEarningsDateRange(dateString, languageCode = 'en-US') {
  // Step 1: Parse the date string to create a Date object
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(5, 7), 10) - 1; // months are 0-indexed in JS
  const day = parseInt(dateString.substring(8, 10), 10);
  const startDate = new Date(year, month, day);

  // Calculate the end date by adding 6 days to the start date
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  // Format the dates
  const formattedStart = startDate.toLocaleString(languageCode, { month: 'short', day: 'numeric' });
  const formattedEnd = endDate.toLocaleString(languageCode, { month: 'short', day: 'numeric' });

  return `${formattedStart} - ${formattedEnd}`;
}

// boolean to check if end date is past and show upcoming message
function isPayDateUpcoming(dateString) {
  // step 1: Parse the date string to create a Date object
  const year = parseInt(dateString.substring(0, 4), 10);
  const month = parseInt(dateString.substring(5, 7), 10) - 1; // months are 0-indexed in JS
  const day = parseInt(dateString.substring(8, 10), 10);
  const startDate = new Date(year, month, day);

  // step 2: add 12 days to start date to get end date
  // this is the Friday following the end of the pay period
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 12);

  // step 3: compare end date to today and return boolean
  const today = new Date();
  return today < endDate;
}

function formatSelectedMonth(selectedMonth) {
  const [year, month] = selectedMonth.split("-").map(Number);

  return { year, month };
}

function formatToMidNextMonth(dateString, locale = 'en-US') {
  const [year, month] = dateString.split("-").map(Number);

  let date = new Date(year, month - 1);
  date.setMonth(date.getMonth() + 1);
  date.setDate(15);

  const options = { month: 'short', day: 'numeric' };
  return date.toLocaleDateString(locale, options);
}

// ex. formatUpcomingDate("Dec 3", undefined, translateUpcoming) => "Upcoming Dec 8"  
function formatUpcomingDate(dateString, year = new Date().getFullYear(), leadingText = "Upcoming") {
  // Adding the year information to the date string
  const fullDateString = `${dateString}, ${year}`;

  // Parse the input string to a Date object
  const date = new Date(fullDateString);

  // Check if the date is valid
  if (isNaN(date)) {
    return 'Invalid date';
  }

  // Add 5 days to the date
  date.setDate(date.getDate() + 5);

  // Define options for toLocaleDateString
  const options = { month: 'short', day: 'numeric' };

  // Format the date to a string
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Return the formatted string with "Upcoming" prefixed
  return `${leadingText} ${formattedDate}`;
}

function getMonthsAgo(dateString) {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  let months = (currentDate.getFullYear() - inputDate.getFullYear()) * 12;
  months -= inputDate.getMonth();
  months += currentDate.getMonth();

  return months <= 0 ? 0 : months;
}

const getPastYears = (num, addAllTime) => {
  const currentYear = new Date().getFullYear();
  const pastYears = [];
  for (let i = 0; i < num; i++) {
    pastYears.push(currentYear - i);
  }
  if (addAllTime) {
    pastYears.unshift("All time");
  }
  return pastYears;
};

function getPurchasePeriodFromFilterModel(filters) {
  let startDate;
  let endDate;

  for (const item of filters) {
    if (item.purchasePeriodStartDate) {
      startDate = new Date(item.purchasePeriodStartDate);
      startDate.setDate(startDate.getDate() + 1);
    }
    if (item.purchasePeriodEndDate) {
      endDate = new Date(item.purchasePeriodEndDate);
      endDate.setDate(endDate.getDate() + 1);
    }
  }

  if (startDate && endDate) {
    const today = new Date();
    const differenceInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 7 && endDate.getDate() === today.getDate()) {
      return "last-seven-days";
    } else if (differenceInDays === 30 && endDate.getDate() === today.getDate()) {
      return "last-thirty-days";
    } else if (differenceInDays === 90 && endDate.getDate() === today.getDate()) {
      return "last-ninety-days";
    } else if (differenceInDays === 365 && endDate.getDate() === today.getDate()) {
      return "last-365-days";
    } else {
      return "custom-range";
    }
  } else {
    return "all-time";
  }
}

// if on or before the 4th of the month, return the previous month, else return the current month
const getQualificationPeriodMonth = (num) => {
  const currentDayNumber = DateTime.now().day;
  return getMonthRange(num)[currentDayNumber <= 4 ? 1 : 0].value;
};

const getCurrentCommissionMonth = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() returns 0-11, so we add 1
  const day = date.getDate();
  let formattedYear = year;
  let formattedMonth = month;
  if (day <= 4) {
    // If the current day is less than or equal to 4, go to the previous month
    if (month === 1) {
      // If it's January, the previous month is December of the previous year
      formattedYear = year - 1;
      formattedMonth = 12;
    } else {
      formattedMonth = month - 1;
    }
  }
  const formattedMonthString = formattedMonth.toString().padStart(2, '0');
  return `${formattedYear}${formattedMonthString}`;
}

const getDaysBeforeEndOfTheMonth = () => {
  const today = DateTime.now();
  const endOfMonth = today.endOf("month");
  return (endOfMonth.diff(today, 'days').days + 1).toFixed(0);
}

const formatMidOfNextMonthAsFullString = (dateString, languageCode = 'en-US') => {
  const date = new Date(dateString);
  date.setMonth(date.getMonth() + 1);
  date.setDate(15);
  return date.toLocaleString(languageCode, { month: "long", day: "numeric" });
}

export {
  datesOnSameDay,
  dateToString,
  formatDateString,
  getDateXDaysAgo,
  today,
  yesterday,
  oneYearAgoArray,
  formatDateForOrderDetail,
  getMonthRange,
  orderDateIsInCenturionDateRange,
  formatDate,
  dateFormatter,
  isDateInFuture,
  createDate,
  formatDecimalPlaces,
  formatDateByLocale,
  formatDateShortByLocale,
  formatEarningsDateRange,
  formatToMidNextMonth,
  formatUpcomingDate,
  getMonthsAgo,
  getPastYears,
  isPayDateUpcoming,
  getPurchasePeriodFromFilterModel,
  getQualificationPeriodMonth,
  getCurrentCommissionMonth,
  getDaysBeforeEndOfTheMonth,
  formatSelectedMonth,
  formatMidOfNextMonthAsFullString
};
