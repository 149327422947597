import React from "react";
import { T } from "../../../hooks/useTranslate";
import { Stack, Typography } from "@mui/material";
import { HelpIcon } from "../../../components/HelpIcon";
import { HelpIconMobile } from "../../../components/HelpIconMobile";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const NewPartnersHeadCell = () => {
  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Stack
      direction="row"
      spacing="4px"
      data-test-id={`new-partners-column-header`}
    >
      <Stack justifyContent="center">
        <Typography variant="h4" data-test-id={`new-partners-column-header-text`}><T>new_partners</T></Typography>
      </Stack>
      <Stack>
        {
          viewportIsMedium ? (
            <HelpIcon text1="new_partners_help_text" menuWidth="308px" />
          ) : (
            <HelpIconMobile text1="new_partners_help_text" />
          )
        }

      </Stack>
    </Stack>
  )
}

export { NewPartnersHeadCell }