import { useMemo } from 'react';

function useCurrencyFormatter(locale = 'en-US', currency = 'USD') {
  const formatter = useMemo(() => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [locale, currency]);

  return formatter.format;
}

export { useCurrencyFormatter };
