import React from "react";

import { Stack, Typography } from "@mui/material";
import { styles } from "../../../screens/DashboardScreen/News/news.styles";
import { useArticle } from "../../../hooks/useArticles";

const ArticleDetailView = ({ article }) => {
  const { name, displayDate, imgUrl, formattedHTML } = useArticle(article);

  return (
    <Stack mt={2}>
      <Stack alignItems="center">
        {
          imgUrl && (
            <img
              src={imgUrl}
              alt="main"
              height="327px"
              width="100%"
              style={{ borderRadius: '12px', maxWidth: "327px" }}
            />
          )
        }
      </Stack>
      <Typography mt={3} sx={styles.articleViewItemName}>
        {name}
      </Typography>
      <Typography sx={styles.articleViewItemDate}>
        {displayDate && displayDate}
      </Typography>
      <Typography sx={styles.articleViewItemDescription} dangerouslySetInnerHTML={{ __html: formattedHTML }}>
      </Typography>
    </Stack>
  )
}

export { ArticleDetailView }