import React from "react";
import { useAuth } from "../../hooks/useAuth";
import { handleDownloadClick } from "./EarningsScreen.helper";

const AllStatementsDownloadIcon = ({ params, setLoading }) => {
  const { userInfo } = useAuth();

  const handleDownload = handleDownloadClick(params.value, userInfo.token, setLoading);

  return (
    <img
      src="icons/download.svg"
      alt="download icon"
      height="20px"
      width="20px"
      onClick={handleDownload}
      style={{ cursor: "pointer" }}
    />
  )
}

export { AllStatementsDownloadIcon }