import React, { useContext } from "react";

import { Stack, CircularProgress } from "@mui/material";

import { OfficeBox } from "../../components/OfficeBox"

import { PartnersContext } from "../../hooks/PartnersContext";
import { PartnersStatsContent } from "./PartnersStatsContent";


const PartnersStatsDesktopContainer = () => {
  const { loading } = useContext(PartnersContext);

  if (loading)
    return (
      <Stack justifyContent="center" alignItems="center" height={"100px"} mb={3}>
        <CircularProgress data-test-id="progress-bar-shown" />
      </Stack>
    );

  return (
    <OfficeBox
      sx={{
        padding: "24px 20px 12px 20px"
      }}>
      <PartnersStatsContent isMobile={false} />
    </OfficeBox>
  )
}

export { PartnersStatsDesktopContainer }