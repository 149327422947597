import { OfficeBox } from './OfficeBox'
import { Rating, Stack, Typography, Box, Button } from '@mui/material'
import { T } from 'hooks/useTranslate'
import { StarRateRounded } from '@mui/icons-material'
import useFeedback from 'hooks/useFeedback'
import { useFeedbackContext } from 'hooks/FeedbackContext'
import { useTranslateKey } from 'hooks/useTranslate'
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react'

const ratingLabels = {
  1: 'Unacceptable',
  2: 'Weak',
  3: 'Acceptable',
  4: 'Good',
  5: 'Excellent',
};

const FeedbackCard = () => {
  const {
    hover,
    setHover,
    rating,
    setRating,
    isFeedbackSubmitted,
    handleFeedbackSubmit
  } = useFeedback(null, ratingLabels)
  const { setIsFeedbackModalSkipped, titleKeys, setIsFeedbackTaken } = useFeedbackContext()
  const ratingLabel = useTranslateKey(ratingLabels[hover !== -1 ? hover : rating]?.toLowerCase())
  const cardTitle = useTranslateKey(titleKeys?.cardTitleKey || "")
  const { ref, inView, entry } = useInView({
    threshold: 1,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView === false && entry === undefined && isFeedbackSubmitted) {
      setIsFeedbackModalSkipped(false)
      setIsFeedbackTaken(true)
    }
    // eslint-disable-next-line
  }, [ inView, entry, isFeedbackSubmitted ])

  return (
    <OfficeBox
      sx={{
        padding: !isFeedbackSubmitted ? "25px" : "10px 15px"
      }}
    >
      {
        !isFeedbackSubmitted
          ? (
            <Box ref={ref}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
                gutterBottom
              >
                {cardTitle}
              </Typography>
              <Stack
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={2}
              >
                <Rating
                  name="hover-feedback"
                  value={rating}
                  precision={1}
                  onChange={(_, newValue) => {
                    setRating(newValue);
                    handleFeedbackSubmit(newValue)
                  }}
                  onChangeActive={(_, newHover) => {
                    setHover(newHover);
                  }}
                  icon={<StarRateRounded fontSize="inherit" sx={{ borderRadius: '5px' }} />}
                  emptyIcon={<StarRateRounded fontSize="inherit" style={{ color: '#cfcfcf' }} />}
                  sx={{
                    '& .MuiRating-iconFilled': {
                      color: '#ff5566',
                    },
                    '& .MuiRating-iconHover': {
                      color: '#ff5566',
                    },
                    fontSize: '2.5rem',
                  }}
                  size="large"
                />
                <Typography variant="body2" color="text.secondary" align="center" mt={1}>
                  {ratingLabel === "{{undefined}}" ? '' : ratingLabel}
                </Typography>
              </Stack>
            </Box>
          )
          : (
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography
                id="ty-message"
                sx={{
                  fontSize: "0.875rem",
                  fontWeight: 600
                }}
              >
                <T>ty_for_rating</T>
              </Typography>
              <Button
                id="close-feedback-card-button"
                data-test-id="close-feedback-card-button"
                aria-label="close feedback card"
                sx={{
                  color: "#153862",
                  backgroundColor: "#eaecee",
                  height: "34px",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "12px",
                  padding: "8px 12px 8px 12px",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                  },
                  marginTop: "5px"
                }}
                variant="contained"
                onClick={() => {
                  setIsFeedbackModalSkipped(false)
                  setIsFeedbackTaken(true)
                }}
              >
                <T>close</T>
              </Button>
            </Stack>
          )
      }
    </OfficeBox>
  )
}

export default FeedbackCard