import React from "react";
import { T } from "../../../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { styles } from "../partners.styles";

const SuspendedChip = () => {
  return (
    <Stack
      justifyContent="center"
      data-test-id="suspended-chip">
      <Typography sx={styles.suspendedChip}>
        <T>suspended</T>
      </Typography>
    </Stack>
  )
}

export { SuspendedChip }