import React, { useContext, useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import { OfficePage } from "../../components/OfficePage";
import { OfficeBox } from "../../components/OfficeBox";
import { SettingsOption } from "./SettingsOption";
import { settingsOptions } from "./settings-options";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SettingsCollapsibleMenu } from "./SettingsCollapsibleMenu";
import { LanguageContext } from "../../hooks/LanguageContext";
import { useAuth } from "../../hooks/useAuth";
import { useConfig } from "../../hooks/useConfig";

const SettingsScreen = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const location = useLocation();
  const { userInfo } = useAuth();
  const { data: configData } = useConfig("countryLists/showCommunications.json");
  const { data: showPlacementPageMarketsData } = useConfig("markets/showPlacementPageMarkets.json");

  // State for the selected setting
  const [selectedSetting, setSelectedSetting] = useState("my_profile");
  const showPlacementPageArray = showPlacementPageMarketsData?.data?.showPlacementPage?.value || [];

  // Theme and media query setup
  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));

  // Link child route to selectedSetting
  useEffect(() => {
    if (location.pathname.split("/").length > 2) {
      setSelectedSetting(location.pathname.split("/")[2].replaceAll("-", "_"));
      setExpanded(false);
    }
  }, [location.pathname]);

  // State for controlling mobile menu expansion
  const [expanded, setExpanded] = useState(false);

  // Function to handle mobile menu expansion/collapse
  const handleClick = () => {
    setExpanded(!expanded);
  }

  // Mapping settings options to elements
  const settingsMenuItems = [];
  const settingsOptionElements = settingsOptions.map((option, idx) => {
    // Hide KYC upload option for non-Indian users
    // Hide communications option for non-American countries
    if (
      (option.key === "upload_kyc" && userInfo.countryCode !== "IN") ||
      (option.key === "electronic_id" && userInfo.countryCode !== "IN") ||
      (option.key === "communications" && !configData?.data?.showCommunications.includes(userInfo.countryCode)) ||
      (option.key === "placement_program" && !showPlacementPageArray.includes(userInfo.countryCode))
    ) {
      return null;
    }

    settingsMenuItems.push(option.key);
    return <SettingsOption
      key={idx}
      option={option}
      selectedSetting={selectedSetting}
      setSelectedSetting={setSelectedSetting}
      testId={`settings-option-${option.key.replaceAll("_", "-")}`} // Unique test ID for each option
    />
  });

  return (
    <OfficePage pageTitle="settings">
      <Stack
        data-test-id="settings-screen"
        direction={viewportIsMedium ? "row" : "column"}
        gap={2}
        alignItems={viewportIsMedium ? "flex-start" : "stretch"}
      >
        {viewportIsMedium ? (
          // Desktop view with a list of settings options
          <OfficeBox
            sx={styles.desktopContainer}
            data-test-id="settings-options-container-desktop"
          >
            {settingsOptionElements}
          </OfficeBox>
        ) : (
          // Mobile view with a collapsible menu
          <SettingsCollapsibleMenu
            settingsMenuItems={settingsMenuItems}
            selectedSetting={selectedSetting}
            settingsOptionElements={settingsOptionElements}
            viewportIsMedium={viewportIsMedium}
            expanded={expanded}
            handleClick={handleClick}
          />
        )}

        {/* Main content */}
        <Outlet key={currentLanguage} />
      </Stack>
    </OfficePage>
  );
};

export { SettingsScreen };

const styles = {
  desktopContainer: {
    display: "flex",
    gap: "4px",
    flexDirection: "column",
    padding: "12px 0px",
    width: "268px",
  },
  mobileContainer: {
    display: "flex",
    gap: "4px",
    flexDirection: "column",
    padding: "12px 0px",
  }
};
