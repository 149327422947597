import { useContext } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { IconButton, Badge } from "@mui/material"

import { ActivityFeedContext } from "../../hooks/ActivityFeedContext";
import { useMixPanel } from "hooks/useMixPanel";

const buttonStyling = {
  border: "1px solid rgba(145, 158, 171, 0.1)",
  boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
  borderRadius: "8px",
  padding: "8px 10.5px 8px 10.5px"
}

const ActivityFeedButton = () => {
  const { activities } = useContext(ActivityFeedContext);
  const location = useLocation();
  const navigate = useNavigate();

  const { logEvent } = useMixPanel();

  const handleClick = () => {
    logEvent('recent_activity_feed_button_click');
    navigate(`${location.pathname}?drawer=activityFeed`);
  }

  const activitiesMarkedUnseen = activities.filter(activity => activity.isSeen === "false");

  return (
    <Badge
      variant="dot"
      invisible={activitiesMarkedUnseen.length === 0}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: "#009ECF",
          position: "absolute",
          top: "10px",
          right: "10px",
          minWidth: "6px",
          width: "6px",
          height: "6px"
        }
      }}
      slotProps={{
        badge:
          { "data-test-id": "activity-badge-span" }
      }}
    >
      <IconButton aria-label="activity" sx={buttonStyling} onClick={handleClick}>
        <img src="icons/activity.svg" alt="activity icon" />
      </IconButton>
    </Badge>

  )
}

export { ActivityFeedButton }