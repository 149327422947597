import React from "react";
import { T } from "../../hooks/useTranslate";

import { Box, Stack } from "@mui/material";
import { DataGridFilterButtons } from "./DataGridFilterButtons";
import { styles } from '../../themes/datagrid.styles';
import { useMixPanel } from "../../hooks/useMixPanel";

const MembersFilterButtons = ({
  columnVisibility,
  setColumnVisibility,
  filterModel,
  setFilterModel,
  handlePageChange,
  clearFilters,
  apiInProgress,
}) => {
  const { logEvent } = useMixPanel();

  const handleClearFilters = () => {
    clearFilters();
    logEvent("members_clear_filters", {
      location: "members",
    })
  }
  return (
    <Box sx={{
      display: "flex",
      gap: "8px",
      overflowX: "auto",
    }}>
      <Stack
        direction="row"
        gap={1}
        pb={1}
      >
        <DataGridFilterButtons
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          handlePageChange={handlePageChange}
          apiInProgress={apiInProgress}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
        />
        {
          filterModel.length > 0 &&
          <Stack
            justifyContent="center"
            onClick={handleClearFilters}
            sx={styles.clearFiltersLink}
          >
            <T>clear_filters</T>
          </Stack>
        }
      </Stack>
    </Box>
  )
}

export { MembersFilterButtons }