import React from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";

import { TextField } from "@mui/material";

const EmailUsernameInput = ({ formik, InputLabelProps, inputProps, FormHelperTextProps, hidePlaceholderText }) => {
  const translateHelperText = useTranslateKey(formik.errors.username);
  return (
    <TextField
      id="username"
      data-test-id="username-text-field"
      variant="outlined"
      value={formik.values.username}
      onChange={formik.handleChange}
      label={<T>email_address_or_unicity_id</T>}
      inputProps={{ ...inputProps, placeholder: "" }}
      InputLabelProps={{ ...InputLabelProps, shrink: hidePlaceholderText && true }}
      FormHelperTextProps={{ ...FormHelperTextProps }}
      error={
        formik.touched.username && Boolean(formik.errors.username)
      }
      helperText={formik.touched.username && Boolean(formik.errors.username) && translateHelperText}
      fullWidth
    />
  )
}

export { EmailUsernameInput }