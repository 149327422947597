import React from 'react';
import { T } from "../../hooks/useTranslate";
import { Stack, Typography } from '@mui/material';
import { ClientSidePagination } from "../../components/ClientSidePagination";

const AllStatementsFooter = ({ pageSizeOptions, perPageKey, totals }) => {
  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        pt={1}
        pb={1}
        borderBottom="1px solid rgba(224, 224, 224, 1)"
        sx={{ backgroundColor: "rgba(237, 239, 241, 0.32)" }}
      >
        <Typography variant="h3" sx={{ flex: 1.5, paddingLeft: '1.5rem' }}>
          <T>total</T>:
        </Typography>
        <Typography variant="h3" sx={{ flex: 2, paddingLeft: "1rem" }} textAlign="right" pr={1.5}>
          {totals}
        </Typography>
        {/* placeholder to mirror spacing on datagrid columns */}
        <Typography variant="body2" sx={{ flex: 2 }}>
          &nbsp;
        </Typography>
      </Stack>

      <ClientSidePagination perPageKey={perPageKey} pageSizeOptions={pageSizeOptions} />
    </Stack>
  );
};

export { AllStatementsFooter };
