const useEvent = () => {
  const subscribe = (eventName, listener) =>  document.addEventListener(eventName, listener)

  const unsubscribe = (eventName, listener) => document.removeEventListener(eventName, listener)

  const publish = (eventName, data) => {
    const event = new CustomEvent(eventName, data);
    document.dispatchEvent(event);
  };

  return { subscribe, unsubscribe, publish };
};

export { useEvent };
