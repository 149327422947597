import React from "react";
import { T } from "../../../../hooks/useTranslate";

import { Box, Typography } from "@mui/material"

import { formatDate } from "../../../../common/date-functions";

const CollapsibleDateJoined = ({ row }) => {
  return (
    <Box sx={{ flex: 1, backgroundColor: "rgba(21, 56, 98, 0.05);", borderRadius: "8px", padding: "12px" }}>
      <Typography sx={{ color: "#153862", opacity: ".6", fontSize: "11px" }}><T>date_joined</T></Typography>
      <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>{formatDate(row.joinDate)}</Typography>
    </Box>
  )
}

export { CollapsibleDateJoined }