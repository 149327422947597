import React from "react";
import { T } from "../../hooks/useTranslate";
import styles from "./settingsScreenStyles";

import { Stack, Button } from "@mui/material";

const ModalButtons = ({
  formik,
  type,
  handleCloseModal,
  setIsReauthenticated,
  viewportIsSmall
}) => {
  const loading = formik.isSubmitting;

  const handleCancelClick = () => {
    handleCloseModal();
    formik.resetForm();
    if (setIsReauthenticated) {
      setIsReauthenticated(false);
    }
  }

  return (
    <Stack
      gap={1}
      direction="row"
      justifyContent="flex-end"
      alignItems="center">
      <Button
        data-test-id="cancel-button"
        variant="contained"
        color="primary"
        sx={{
          ...styles.modalButton,
          flexGrow: viewportIsSmall ? "1" : "",
          backgroundColor: "#FFF",
          color: "#153862",
          border: "1px solid rgba(145, 158, 171, 0.20)",
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
        onClick={handleCancelClick}
      >
        <T>cancel</T>
      </Button>
      <Button
        data-test-id="continue-confirm-button"
        variant="contained"
        color="primary"
        disabled={Object.keys(formik.errors).length !== 0 || loading}
        sx={{
          ...styles.modalButton,
          flexGrow: viewportIsSmall ? "1" : "",
          '&:hover': {
            opacity: "0.7",
            backgroundColor: "#009ECF"
          },
        }}
        onClick={formik.submitForm}
      >
        {
          // different button text for referral code, email and phone. else, use "continue" for reauth modal
          type === "referral-code" ? <T>confirm</T> :
            ["email", "phone"].includes(type) ? <T>confirm_changes</T> : <T>continue</T>
        }
      </Button>
    </Stack>
  )
}

export { ModalButtons }