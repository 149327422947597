import React, { useContext } from "react";
import { T } from "../../../../hooks/useTranslate";

import { Stack, Typography, Skeleton } from "@mui/material";

import { DistributorContext } from "../../../../hooks/DistributorContext";
import { MemberContext } from "../../../../hooks/MemberContext";
import { SuccessTrackerSelectDropdown } from "./SuccessTrackerSelectDropdown";
import { RankQualSection } from "./RankQualSection";
import { CenturionSection } from "./CenturionSection";
import { GeneralAlert } from "../../../../components/GeneralAlert"

const SuccessTracker = () => {
  const { centurionHistory, historyApiInProgress, hasError, isCenturionHistoryApiInProgress, centurionHistoryError } = useContext(DistributorContext);
  const { isDistributorLevelLimit } = useContext(MemberContext)
  const centurionDateCalculated = centurionHistory?.centurionHistory?.items[0].value[0].metrics[0].dateCalculated;
  const currentCentCount = parseInt(centurionHistory?.centurionHistory?.items[0].value[0].metrics[0].value);

  return (
    <>
      {isDistributorLevelLimit &&
        <Stack pt={3}>
          <GeneralAlert alertKey="level_Limit_alert" tooltipKey="level_Limit_tooltip" />
        </Stack>
      }
      <Stack p={3} gap={2}>

        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
          <Stack direction="row" gap={.75}>
            <Typography variant="h2"><T>success_tracker</T></Typography>
          </Stack>

          <SuccessTrackerSelectDropdown disabled={historyApiInProgress} />
        </Stack>
        <Stack gap={2}>
          {hasError && <GeneralAlert hasMargin={false} alertKey="success_tracker_error" tooltipKey="success_tracker_error" />}
          {historyApiInProgress ? <Skeleton variant="rounded" width={350} height={300} /> : <RankQualSection />}
          {centurionHistoryError && <GeneralAlert hasMargin={false} alertKey="success_tracker_error" tooltipKey="success_tracker_error" />}
          {isCenturionHistoryApiInProgress ? <Skeleton variant="rounded" width={350} height={125} /> : <CenturionSection currentCentCount={isNaN(currentCentCount) ? 0 : currentCentCount} centurionDateCalculated={centurionDateCalculated} />}
        </Stack>
      </Stack></>
  )
}

export { SuccessTracker }