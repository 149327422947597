import React, { useContext } from "react"

import { Box, CircularProgress } from "@mui/material"

import { CenturionLevelBox } from "./CenturionLevelBox"
import { CenturionLevelBoxMobile } from "./CenturionLevelBoxMobile"

import { CenturionContext } from "../../hooks/CenturionContext";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const CenturionLevelBoxWrapper = () => {
  const { sales, isLoading } = useContext(CenturionContext);

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  // loading progress
  if (isLoading) return (
    <Box sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
      <CircularProgress data-test-id="progress-bar-shown" />
    </Box>
  )

  // desktop boxes
  if (viewportIsLarge) return (
    <Box data-test-id="bonus-box-wrapper" sx={{ display: "flex", gap: "20px" }}>
      <CenturionLevelBox sales={sales} number={1} salesNeeded={30} />
      <CenturionLevelBox sales={sales} number={2} salesNeeded={60} />
      <CenturionLevelBox sales={sales} number={3} salesNeeded={100} />
    </Box>
  )
  // mobile boxes
  return (
    <Box id="bonus-box-wrapper" sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <CenturionLevelBoxMobile sales={sales} number={1} salesNeeded={30} />
      <CenturionLevelBoxMobile sales={sales} number={2} salesNeeded={60} />
      <CenturionLevelBoxMobile sales={sales} number={3} salesNeeded={100} />
    </Box>
  )
}

export { CenturionLevelBoxWrapper }