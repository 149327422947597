import React, { useContext } from "react";
import { T, TM } from "../../hooks/useTranslate";

import { MenuItem, FormControl, Select, InputLabel, Box } from "@mui/material"

import { CenturionContext } from "../../hooks/CenturionContext";
import { useMixPanel } from "../../hooks/useMixPanel";

const CenturionMonthSelect = ({ isDashboard }) => {
  const { status, selectedMonth, contextMonthRange, setSelectedMonth, isError } = useContext(CenturionContext);
  const { logEvent } = useMixPanel();

  const onSelectMonth = (e) => {
    logEvent("centurion_dashboard_month_select", { month: e.target.value });
    setSelectedMonth(e.target.value);
  }

  const onOpen = () => {
    logEvent("centurion_dashboard_month_dropdown_open");
  }

  return (
    <FormControl
      id="month-dropdown-form"
      fullWidth
      sx={{ minWidth: "150px", backgroundColor: "#FFFFFF", maxWidth: isDashboard ? "150px" : null }}
    >
      {isDashboard && <InputLabel id="month-label" sx={{ fontSize: "14px" }}><T>month</T></InputLabel>}
      <Select
        labelId="month-dropdown-label"
        id="month-dropdown-select"
        label={isDashboard ? "Month" : null}
        value={selectedMonth}
        onChange={onSelectMonth}
        onOpen={onOpen}
        fullWidth
        renderValue={(i) => {
          if (isError) {
            return "-"
          }
          const selected = contextMonthRange.filter((month) => month.value === i)[0];
          return <TM>{selected.label}</TM>
        }}
        disabled={status !== "complete" || isError}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "white",
            boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
            borderRadius: "8px"
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#DDDDDD',
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
              border: "1px solid rgba(145, 158, 171, 0.1)",
              borderRadius: "8px",
              marginTop: "5px"
            }
          },
          MenuListProps: {
            sx: {
              ".MuiButtonBase-root": {
                fontWeight: "500",
                fontSize: "14px",
                paddingLeft: "24px",
                paddingRight: "24px"
              },
              ".Mui-selected": {
                color: "rgba(0, 158, 207, 1)",
                backgroundColor: "rgba(0, 158, 207, 0.05)",
                fontWeight: "600"
              }
            }
          }
        }}
        inputProps={{
          sx: {
            color: "rgba(21, 56, 98, 1)",
            fontSize: isDashboard ? "12px" : "14px",
            fontWeight: isDashboard ? "500" : "600",
            padding: isDashboard ? "8px 16px 8px 16px" : "9px 32px 9px 12px"
          }
        }}
      >
        <Box sx={subheaderStyle}>SELECT PERIOD</Box>
        {contextMonthRange.map(item => {
          return (
            <MenuItem
              value={item.value}
              key={item.value}
              data-test-id={`centurion-month-dropdown-listitem`}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                minWidth: "230px"
              }}>
              <TM>{item.label}</TM>
              {selectedMonth === item.value && <img src="icons/check.png" alt="checkmark" />}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  )
}

const subheaderStyle = {
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: 10,
  color: "#8A9BB0",
  marginTop: "8px",
  marginBottom: "8px",
  marginLeft: "24px"
};

export { CenturionMonthSelect }