import React, { useEffect, useState, createContext } from "react";

import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { getMonthRange, getQualificationPeriodMonth } from "../common/date-functions";
import { decodeString, formatName } from "../common/format-functions";

const CenturionContext = createContext([{}, () => { }]);

const CenturionContextProvider = (props) => {
  const [contextMonthRange, setContextMonthRange] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [sales, setSales] = useState(0);
  const [currentMembers, setCurrentMembers] = useState([]);
  const [isError, setError] = useState(false);
  const [dateCalculated, setDateCalculated] = useState("");
  const [isLoading, setIsLoading] = useState(true);  // Track initial loading state
  const [firstLoad, setFirstLoad] = useState(true);  // Track if it's the initial load

  const { response, status, sendRequest } = useApi();
  const { userInfo } = useAuth();

  const resetContext = () => {
    setSales(0);
    setCurrentMembers([]);
    setDateCalculated("");
  };

  useEffect(() => {
    if (status === "wait" && firstLoad) {
      setDateCalculated("");
      setIsLoading(true);
    }
  }, [status, firstLoad]);

  useEffect(() => {
    if (!userInfo.loggedIn) {
      resetContext();
    }
  }, [userInfo]);

  useEffect(() => {
    setContextMonthRange(getMonthRange(6));
    setSelectedMonth(getQualificationPeriodMonth(6));
  }, []);

  useEffect(() => {
    if (userInfo.token) {
      const updateContext = () => {
        sendRequest({
          method: "get",
          endpoint: `customers/me/centurionHistory?period=${selectedMonth}`,
        });
      };

      if (selectedMonth) updateContext();

      const updateTimer = setInterval(updateContext, 120000);

      return () => {
        clearInterval(updateTimer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, userInfo.token]);

  useEffect(() => {
    if (status === "error") {
      setError(true);
    }

    if (status === "complete") {
      setIsLoading(false); // Turn off loading after the first data fetch
      setFirstLoad(false); // Set first load to false so future loads don’t trigger UI loading

      if (response.data?.centurionHistory?.items[0].value[0].metrics[0].dateCalculated) {
        setDateCalculated(response.data?.centurionHistory?.items[0].value[0].metrics[0].dateCalculated);
      }
      if (!response.data?.centurionHistory?.items[0].value[0].metrics[0].value) {
        setSales(0);
      }
      if (response.data?.centurionHistory?.items[0].value[0].metrics[0].value) {
        setError(false);
        const centurionHistory = response.data?.centurionHistory?.items[0];
        const currentCentCount = centurionHistory.value[0].metrics[0].value;

        const filteredMembers = response.data.centurionHistory?.items[0].metricsDetails?.count?.customers
          .filter(c => c.customer.centurionCount.value > 0)
          .sort((member1, member2) => {
            return (
              member2.customer.centurionCount.value - member1.customer.centurionCount.value
            );
          })
          .map((member, idx) => ({
            id: idx,
            name: formatName(decodeString(member.customer.humanName.fullName)),
            count: member.customer.centurionCount.value,
            unicityId: member.customer.id.unicity,
            hasAutoOrder: member.customer.hasAutoOrder,
            lastFeelGreatOrderDate: member.customer.lastFeelGreatOrderDate,
            hasFeelGreatAutoOrder: member.customer.hasFeelGreatAutoOrder,
            nextRunDate: member.customer.nextRunDate || undefined,
          }));

        setCurrentMembers(filteredMembers || []);
        setSales(currentCentCount);
      }
    }
  }, [status, response, selectedMonth]);

  useEffect(() => {
    setFirstLoad(true);
  }, [selectedMonth])

  return (
    <CenturionContext.Provider
      value={{
        sales,
        setSales,
        selectedMonth,
        contextMonthRange,
        setSelectedMonth,
        status,
        currentMembers,
        setCurrentMembers,
        isError,
        dateCalculated,
        isLoading  // Provide the loading state to the UI
      }}
    >
      {props.children}
    </CenturionContext.Provider>
  );
};

export { CenturionContext, CenturionContextProvider };
