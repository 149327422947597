import { useState } from "react";
import { getCookie, removeCookie, setCookie } from "../common/cookie";
import { useFeedbackContext } from "./FeedbackContext";
import { useMixPanel } from "./useMixPanel";
import { useAuth } from "./useAuth";


/**
 * Common logic for the feedback components, both for modal and card
 * @param {*} props 
 */
const useFeedback = (
  onClose = null,
  ratingLabels = []
) => {
  const { userInfo } = useAuth();
  const [ hover, setHover ] = useState(-1);
  const [ rating, setRating ] = useState(0);
  const [ isFeedbackSubmitted, setIsFeedbackSubmitted ] = useState(getCookie(`tookSurvey_${userInfo?.unicityId}`) === 'true' || false)
  const { setIsFeedbackModalSkipped } = useFeedbackContext()
  const { logEvent } = useMixPanel()
  const isInProd = process.env.REACT_APP_IS_PRODUCTION === "true"
  const domain = isInProd ? "unicity.com" : "unicityqa.com";

  const handleSkip = () => {
    if (!isFeedbackSubmitted) {
      setCookie(`skippedSurvey_${userInfo?.unicityId}`, domain, 'true')
      setIsFeedbackModalSkipped(true)
      logEvent('skipped_feedback', {
        userId: userInfo?.unicityId
      })
    }
    onClose && onClose()
  }

  const handleFeedbackSubmit = (propRating = null) => {
    const selectedRating = ratingLabels[propRating ? propRating : rating];

    logEvent('feedback', {
      rating: selectedRating,
      userId: userInfo?.unicityId
    })
    setIsFeedbackSubmitted(true)
    setCookie(`tookSurvey_${userInfo?.unicityId}`, domain, 'true')
    removeCookie(`skippedSurvey_${userInfo?.unicityId}`)
  }

  return {
    hover,
    setHover,
    rating,
    setRating,
    handleSkip,
    handleFeedbackSubmit,
    isFeedbackSubmitted,
    setIsFeedbackSubmitted
  }
}

export default useFeedback;
