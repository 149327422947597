import styled from "styled-components";
import AddIcon from '@mui/icons-material/Add';
import { Stack } from "@mui/material";

const ButtonTemplate = styled.button`
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-size: 12px;
  font-weight:600;
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
  &:hover { cursor: pointer;}
`;

const BorderedButtonWrapper = styled(ButtonTemplate)`
  color: #153862;
  background: #ffffff;
  border: 1px solid rgba(145, 158, 171, 0.2);
  ${props => props.disabled && "color: #919EAB; background: #F1F4F7; &:hover { cursor: not-allowed;}"}
`

const SaveButtonWrapper = styled(ButtonTemplate)`
  color: #ffffff;
  background: #009ECF;
  border: 1px solid #009ECF;
  ${props => props.disabled && "color: #919EAB; background: #F1F4F7; border: 1px solid #fff; &:hover { cursor: not-allowed;}"}
`

const CancelButtonWrapper = styled(ButtonTemplate)`
  color: #153862;
  background: #ffffff;
  border: none;
`

const AddNoteButton = ({ id, text, onClick }) => {
  return (
    <BorderedButtonWrapper data-test-id={id} onClick={onClick} >
      <Stack direction="row" justifyContent="center" alignItems="center">
        <div>{text}</div>
        <AddIcon sx={{ marginLeft: "5px", width: "16px", height: "16px" }} />
      </Stack>
    </BorderedButtonWrapper>
  );
};

const LoadMoreButton = ({ id, text, onClick, disabled }) => {
  return (
    <BorderedButtonWrapper data-test-id={id} onClick={onClick} disabled={disabled}>
        <div>{text}</div>
    </BorderedButtonWrapper>
  );
};

const SaveNoteButton = ({ id, text, onClick, disabled}) => {
  return (
    <SaveButtonWrapper data-test-id={id} onClick={onClick} disabled={disabled}>
      <div>{text}</div>
    </SaveButtonWrapper>
  );
};

const CancelNoteButton = ({ id, text, onClick }) => {
  return (
    <CancelButtonWrapper data-test-id={id} onClick={onClick} >
      <div>{text}</div>
    </CancelButtonWrapper>
  );
};

export { AddNoteButton, SaveNoteButton, CancelNoteButton, LoadMoreButton };
