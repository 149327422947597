import React, { useContext } from "react"

import { Box, Typography, CircularProgress, Stack, useMediaQuery } from "@mui/material";
// Radial separators
import RadialSeparators from "./RadialSeparators";

import { CenturionContext } from "../hooks/CenturionContext";
import { formatTextSizeWeight } from "../screens/CenturionScreen/format-functions"

// https://www.npmjs.com/package/react-circular-progressbar
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LanguageContext } from "../hooks/LanguageContext";
import { T } from "hooks/useTranslate";
import { useTheme } from "@mui/material/styles";


const CenturionProgressCircle = ({ isDashboard }) => {
  const { sales, isError, isLoading } = useContext(CenturionContext);
  const { localizeNumber } = useContext(LanguageContext)
  const theme = useTheme()
  const viewportIsSmall = useMediaQuery(theme.breakpoints.down("sm"));

  if (!isLoading || isError) return (
    <>
      {
        sales < 100
          ? (
            <Box
              sx={{
                width: isDashboard
                  ? "148px"
                  : !viewportIsSmall
                    ? "240px"
                    : "148px",
                height: isDashboard
                  ? "148px"
                  : !viewportIsSmall
                    ? "240px"
                    : "148px",
              }}
            >
              <CircularProgressbarWithChildren
                data-test-id="centurion-progress-circle"
                value={sales}
                strokeWidth={5}
                styles={buildStyles({
                  pathColor: "rgba(255, 173, 145, 1)",
                  trailColor: "rgba(255, 236, 229, 1)",
                  strokeLinecap: "round"
                })}
              >
                <Typography sx={formatTextSizeWeight(isDashboard ? "40px" : "60px", 700)}>{localizeNumber(sales)}</Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "17.5px", opacity: ".6" }}>{`${isError ? "/0" : "/100"}`}</Typography>
                <RadialSeparators
                  data-test-id="centurion-progress-circle-separators"
                  count={3}
                  style={{
                    background: "#fff",
                    width: "2px",
                    // This needs to be equal to props.strokeWidth
                    height: `${5}%`
                  }}
                />
              </CircularProgressbarWithChildren>
            </Box>
          )
          : (
            <Box>
              <Typography
                sx={{
                  fontSize: isDashboard
                    ? "0.68rem"
                    : !viewportIsSmall
                      ? "1rem"
                      : "0.813rem",
                  textAlign: "center",
                  fontWeight: isDashboard ? 600 : 500,
                  lineHeight: isDashboard
                    ? "16.5px"
                    : !viewportIsSmall
                      ? "24px"
                      : "13.5px"
                }}
              >
                <T>reached_max_centurion_points</T>
              </Typography>
              <Stack
                alignItems="center"
                justifyContent="center"
                mt={4}
              >
                <img
                  src="icons/centurion-badge.svg"
                  alt="max-centurion-badge"
                  style={{
                    width: isDashboard
                      ? "125px"
                      : !viewportIsSmall
                        ? "222px"
                        : "152px",
                  }}
                />
              </Stack>
            </Box>
          )
      }
    </>
  )

  return (
    <Box sx={{
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      marginTop: isDashboard ? "35px" : ""
    }}>
      <CircularProgress data-test-id="progress-bar-shown" />
    </Box>
  )
}

export { CenturionProgressCircle }