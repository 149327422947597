import React, { useState, useContext } from "react";
import { T, useTranslateKey } from "../../../hooks/useTranslate";

import { Stack, Typography, Box } from "@mui/material";
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { PartnersStatsMobileContainer } from "./PartnersStatsMobileContainer";
import { PartnersContext } from "../../../hooks/PartnersContext";


const PartnersGenealogyMobileStats = () => {
  const [expanded, setExpanded] = useState(false);
  const { contextUserFirstName } = useContext(PartnersContext);

  const translateShow = useTranslateKey("show");
  const translateHide = useTranslateKey("hide");

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      mt={3}
      sx={{ borderTop: "1px solid #EDEFF1" }}>
      {
        expanded && <PartnersStatsMobileContainer />
      }
      <Stack
        alignItems="center"
        onClick={handleChange}
        pt={2}
        pb={2}
        sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}>
        <Stack
          direction="row"
          gap={.5}
          alignItems="center"
          data-test-id={`stats-show-hide-box`}>
          <EqualizerIcon />
          <Typography variant="h3" data-test-id={`stats-show-hide-box-text`}>{expanded ? translateHide : translateShow} {`${contextUserFirstName}'s `}<T>stats</T></Typography>
        </Stack>
      </Stack>
    </Box>

  )
}

export { PartnersGenealogyMobileStats }