import React from "react";
import { T, useTranslateKey } from "../../../../hooks/useTranslate";
import { Stack, Typography, Divider } from "@mui/material";
import { FormField } from "./FormField";
import styles from "../../settingsScreenStyles";
import { BackdropLoadingOverlay } from "../../../../components/BackdropLoadingOverlay";

const ChangeEmailForm = ({
  formikNewEmail,
  viewportIsSmall,
  isModalLoading,
  buttonContent
}) => {
  const translatedNewEmailAddress = useTranslateKey("new_email_address");
  const translatedConfirmNewEmailAddress = useTranslateKey("confirm_new_email_address");

  return (
    <form onSubmit={formikNewEmail.handleSubmit} data-test-id="change-email-form">
      <Stack gap={3}>
        <Typography variant="h2" data-test-id="change-email-modal-title" mb={1.4}>
          <T>change_email_address</T>
        </Typography>
        <FormField
          label={translatedNewEmailAddress}
          name="email"
          value={formikNewEmail.values.email}
          onChange={formikNewEmail.handleChange}
          error={formikNewEmail.touched.email && Boolean(formikNewEmail.errors.email)}
          helperText={formikNewEmail.touched.email && formikNewEmail.errors.email}
          inputProps={styles.inputProps}
          InputLabelProps={{ style: { ...styles.labelStyle } }}
          FormHelperTextProps={styles.formHelperTextProps}
        />
        <FormField
          label={translatedConfirmNewEmailAddress}
          name="emailConfirm"
          value={formikNewEmail.values.emailConfirm}
          onChange={formikNewEmail.handleChange}
          error={formikNewEmail.touched.emailConfirm && Boolean(formikNewEmail.errors.emailConfirm)}
          helperText={formikNewEmail.touched.emailConfirm && formikNewEmail.errors.emailConfirm}
          inputProps={styles.inputProps}
          InputLabelProps={{ style: { ...styles.labelStyle } }}
          FormHelperTextProps={styles.formHelperTextProps}
        />
        {
          !viewportIsSmall && (
            <>
              <Divider m={0} />
              {buttonContent}
            </>
          )
        }

      </Stack>
      <BackdropLoadingOverlay showLoadingOverlay={isModalLoading} />
    </form>
  )
}

export { ChangeEmailForm }