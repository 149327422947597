import React, { useState, useEffect } from "react"

import { useApi } from "../../hooks/useApi";

import { Divider, Stack } from "@mui/material"

import { OfficeBox } from "../../components/OfficeBox";
import { OfficePage } from "../../components/OfficePage";
import { FeelGreatSection } from "./FeelGreatSection";
import { DistributorEnrollmentSection } from "./DistributorEnrollmentSection";
import { AdditionalSection } from "./AdditionalSection";
import { ExplainedButton } from "../../components/ExplainedButton"

import { MemberContextProvider } from "../../hooks/MemberContext";
import { CustomLinkSection } from "./CustomLinkSection";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../hooks/useAuth";
import marketsWithNoCustomReferralLinks from "common/noCustomReferralLinkMarket.js";


const ReferralLinksScreen = () => {
  const [referralCode, setReferralCode] = useState("");
  const { userInfo } = useAuth();
  const userMarket = userInfo?.countryCode;
  const { response, status, sendRequest } = useApi();

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    sendRequest({
      method: "get",
      endpoint: `customers/me/referralcodes?__resetCaches=1`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === "complete") {
      setReferralCode(response.data.items[0].referralCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <OfficePage pageTitle={"referral_links"}>
      <MemberContextProvider>
        <Stack
          direction={viewportIsLarge ? "row" : "column"}
          gap={3}
          alignItems={viewportIsLarge ? "flex-start" : "stretch"}>
          <OfficeBox sx={{ flex: 3, padding: "32px 0px" }}>
            <FeelGreatSection referralCode={referralCode} />
            <Divider />
            <DistributorEnrollmentSection referralCode={referralCode} />
            <Divider />
            <AdditionalSection />
          </OfficeBox>
          {
            !marketsWithNoCustomReferralLinks.includes(userMarket) &&
            <OfficeBox sx={{ flex: 1, padding: "32px 24px 24px 24px", minWidth: viewportIsLarge ? "300px" : 0 }}>
              <CustomLinkSection />
            </OfficeBox>
          }
        </Stack>
        <Stack justifyContent="center" alignItems="center" sx={{ margin: "40px" }}>
          <ExplainedButton
            type="referralLinks"
            testId="referrals-explained-button"
            imgUrl="icons/centurion-info.svg"
            altText="referral explained button"
            buttonText="referral_links_explained"
            urlKey="referral_link_explained_url" />
        </Stack>
      </MemberContextProvider>
    </OfficePage>
  )
}

export { ReferralLinksScreen }