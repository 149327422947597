import * as yup from "yup";
import { referralLinkIncludesBlacklist } from "./blacklists";

export function useValidationSchema(useTranslateKey) {
  const validationSchema = yup.object({
    username: yup
      .string("Email address or Unicity ID")
      .required("field_cannot_be_empty"),
    password: yup
      .string("Password")
      .min(6, useTranslateKey("password_min_length", "6"))
      .required(useTranslateKey("field_cannot_be_empty")),
  });

  return validationSchema;
}

export function useEmailValidationSchema(useTranslateKey) {
  const emailValidationSchema = yup.object().shape({
    email: yup
      .string("email")
      .email(useTranslateKey("invalid_email_address"))
      .required(useTranslateKey("field_cannot_be_empty")),
    emailConfirm: yup
      .string()
      .email(useTranslateKey("invalid_email_address"))
      .oneOf([yup.ref('email'), null], useTranslateKey("email_addresses_do_not_match"))
      .required(useTranslateKey("field_cannot_be_empty")),
  });

  return emailValidationSchema;
}

export function usePhoneValidationSchema(useTranslateKey) {
  const phoneValidationSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(
        /^[\d-]+$/,
        useTranslateKey("name_invalid_characters")
      )
      .required(useTranslateKey("field_cannot_be_empty")),
    countryCode: yup
      .string()
      .matches(
        /^\d+$/,
        useTranslateKey("name_invalid_characters")
      )
      .required(useTranslateKey("field_cannot_be_empty")),
  });

  return phoneValidationSchema;
}

export function useReferralCodeValidationSchema() {
  const referralCodeValidationSchema = yup.object({
    referralCode: yup
      .string("referral_code")
      .lowercase()
      .min(3, "insufficient_code_length_error")
      .max(20, "referral_code_max_length_error")
      .test(
        "referral_code_includes_disallow_phrase",
        "referral_code_includes_disallow_phrase",
        (value) => {
          return !referralLinkIncludesBlacklist.some((phrase) => value.includes(phrase));
        }
      )
      .required("field_cannot_be_empty"),
  });

  return referralCodeValidationSchema;
}
