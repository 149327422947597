import React from "react";
import { T } from "../../hooks/useTranslate";
import { useNavigate, useLocation } from "react-router-dom";
import { Stack, Typography, Button } from "@mui/material";
import { styles } from "../DashboardScreen/News/news.styles";
import { useArticle } from "../../hooks/useArticles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMixPanel } from "../../hooks/useMixPanel";

const AllNewsArticleCard = ({ article }) => {
  const { name, displayDate, imgUrl, formattedHTML } = useArticle(article);
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const handleReadMoreClick = () => {
    logEvent("news_article_opened", {
      location: "news",
      article_name: article.name,
      article_date: article.displayDate
    })
    navigate(`${location.pathname}?drawer=article&articleId=${article.id}`, { state: location });
  }

  return (
    <Stack
      data-test-id={`article-card-${article.id}`}
      direction={viewportIsSmall ? 'row' : 'column'}
      gap={3}
      p={3}
      sx={{
        ...styles.articleCard,
        ...(viewportIsSmall && { borderRadius: '.75rem' })
      }}
    >
      {
        imgUrl && (
          <img
            data-test-id={`article-image-${article.id}`}
            src={imgUrl}
            alt="main"
            height={viewportIsSmall ? '180px' : '120px'}
            width={viewportIsSmall ? '180px' : '120px'}
            style={{ borderRadius: '12px' }}
          />
        )
      }
      <Stack>
        <Stack>
          <Typography
            variant="h2"
            data-test-id={`article-title-${article.id}`}
          >
            {name}
          </Typography>
          <Typography
            data-test-id={`article-date-${article.id}`}
            sx={styles.articleViewItemDate}
          >
            {displayDate && displayDate}
          </Typography>
          <Typography
            data-test-id={`article-description-${article.id}`}
            mb={3}
            sx={{
              ...styles.articleViewItemDescription,
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            dangerouslySetInnerHTML={{ __html: formattedHTML }}>
          </Typography>
        </Stack>
        <Stack alignItems="flex-start">
          <Button
            data-test-id={`read-more-button-${article.id}`}
            variant="contained"
            sx={styles.readMoreButton}
            onClick={handleReadMoreClick}
          >
            <Stack direction="row" alignItems="center">
              <Typography
                data-test-id={`read-more-text-${article.id}`}
                variant="h4"
                sx={{ marginRight: '4px' }}
              >
                <T>read_more</T>
              </Typography>
              <img src="icons/chevron-right-small.svg" alt="right arrow" data-test-id="right-arrow" />
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export { AllNewsArticleCard }