import React, { useContext } from "react";

import { Box } from "@mui/material";

import { LanguageContext } from "../hooks/LanguageContext";
import { formatDateByLocale } from "../common/date-functions";

const FormatDate = ({ dateString, fontSizeValue, formatCode }) => {
  const { currentLanguage } = useContext(LanguageContext);

  return (
    <Box sx={{
      fontSize: fontSizeValue && fontSizeValue
    }}>
      {dateString && formatDateByLocale(currentLanguage, dateString, formatCode)}
    </Box>
  )
}

export { FormatDate }