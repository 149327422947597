import React from "react";
import { T } from "../hooks/useTranslate";

import { Box, Typography, Alert } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ReferralLinksAlert = ({ setShowUpdateAlert }) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const alertProps = {
    "data-test-id": "referrals-alert",
    icon: <InfoOutlinedIcon sx={{ color: "#153862" }} />,
    severity: "info",
    sx: {
      display: "flex",
      alignItems: "center",
      padding: "0px 14px",
      marginBottom: "24px",
      border: "1px solid rgba(0, 158, 207, 0.15)",
      borderRadius: "8px",
      backgroundColor: "rgba(0, 158, 207, 0.05)",
      color: "#153862",
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "12px",
      marginTop: "10px",
      '& .MuiAlert-message': {
        width: "100%",
        justifyContent: "space-between",
      },
    },
    onClose: () => setShowUpdateAlert(false),
  };

  return (
    <Alert {...alertProps}>
      {viewportIsSmall ? (
        <Box>
          <T>link_has_been_updated</T>
        </Box>
      ) : (
        <Box sx={{ display: "flex", padding: "8px 0px 8px 12px" }}>
          <Typography sx={{ fontSize: "12px", marginRight: "20px" }}>
            <T>link_has_been_updated</T>
          </Typography>
        </Box>
      )}
    </Alert>
  );
};

export { ReferralLinksAlert };
