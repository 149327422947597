import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMixPanel } from "../../../hooks/useMixPanel";
import { LanguageContext } from "../../../hooks/LanguageContext";

import { Box, IconButton } from "@mui/material";
import { styles } from "../partners.styles";

const GenealogyButtonCell = ({ params, customerType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const { languageDir } = useContext(LanguageContext);

  const handleClick = () => {
    logEvent("partners_view", {
      location: "partners_page",
      use_genealogy_button: true,
      user_type: customerType
    })
    navigate(`/partners/${params.unicityId}`, {
      state: {
        location: location,
        userFirstName: params.firstName,
        userFullName: params.name,
        totalPartners: params.totalPartners,
      },
    });
  };

  // if user does not have frontline, do not show genealogy button
  if (params.hasFrontline !== 1) return null;

  const disabled = !params.showGenealogy

  return (
    <Box>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        sx={{
          ...styles.genealogyIcon,
          opacity: disabled ? 0.5 : 1,
          "&:hover": {
            cursor: disabled ? "not-allowed" : "pointer"
          }
        }}>
        <img
          src="icons/downline.svg"
          alt="genealogy"
          style={{
            transform: languageDir === "rtl" ? "rotate(180deg)" : "none"
          }}
        />
      </IconButton>
    </Box>
  )
}

export { GenealogyButtonCell }