export const styles = {
  button: {
    display: "flex",
    alignItems: "center",
    padding: ".6875rem .5rem .6875rem .75rem", // 12px 8px 12px 12px
    border: "1px solid rgba(145, 158, 171, 0.20)",
    borderRadius: "0.5rem", // 4px
    fontFamily: "Inter",
    fontSize: "0.75rem", // 12px
    fontWeight: "600",
    color: "#153862",
    whiteSpace: "nowrap",
    flexShrink: 0,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(4, 158, 207, 0.04)",
    }
  },
  filterButtonIconButton: {
    borderRadius: "0.25rem", // 4px
    padding: "0.25rem", // 4px
    marginLeft: "0.3125rem", // 5px
    backgroundColor: "rgba(0, 158, 207, 0.1)"
  },
  filterButtonCloseIcon: {
    width: "0.875rem", // 14px
    height: "0.875rem", // 14px
    color: "rgba(0, 158, 207, 1)",
  },
  keyboardArrowIcon: {
    transform: "scale(.8)",
  },
  filterMenuPaperProps: {
    mt: 1,
    pt: 1,
    p: 0,
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.1)",
    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)'
  },
  filterApplyButton: {
    height: "31px",
    color: "#FFFFFF",
    backgroundColor: "#009ECF",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "12px",
    pl: "12px",
    pr: "12px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "rgba(0, 158, 198, 0.48)"
    }
  },
  filterCancelButton: {
    height: "31px",
    color: "#153862",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  },
  filterMenuButtonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "16px",
    margin: "16px 16px 8px 16px"
  },
  filterMenuHeaderContainer: {
    font: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    marginTop: 2,
    color: "#153862",
  },
  filterMenuHeader: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
    color: "#8A9BB0"
  },
  clearFiltersLink: {
    fontSize: ".75rem",
    textDecoration: "underline",
    fontWeight: 600,
    padding: ".75rem",
    cursor: "pointer",
    "&:hover": {
      color: "#009ECF"
    },
    flexShrink: 0
  },
  unfilteredButtonStyle: {
    color: "#153862",
  },
  filteredButtonStyle: {
    color: "#009ECF",
  },
  openButtonStyle: {
    border: "1px solid #009ECF",
    color: "#009ECF"
  },
  disabledStyle: {
    backgroundColor: "7e7e7e",
    color: "rgba(0, 0, 0, 0.26)"
  },
  subheaderStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
    color: "#8A9BB0",
    marginBottom: "16px"
  },
};
