import React, { useContext } from "react";

import { LanguageContext } from "./LanguageContext";

import { localTranslationTable } from "./localTranslationTable";
import { toTitleCase, toUpperCase } from "../common/format-functions";

// component that returns a fragment with the translated value
// isUpperCase prop is used to return an ALL CAPS translated value
// if the value is not found in the translation table, that value is displayed inside of curly brackets
const T = ({ children, isUpperCase, isTitleCase, ...params }) => {
  const { translationTable, languageLoading } = useContext(LanguageContext);

  // If translation is empty in rosetta, use English translation
  if (translationTable[children] === "empty_translation_value") return localTranslationTable[children];

  if (languageLoading) return <></>;

  let translatedString = translationTable[children] ? translationTable[children] : localTranslationTable[children] ? localTranslationTable[children] : null;

  if (isUpperCase && translatedString) translatedString = toUpperCase(translatedString);
  if (isTitleCase && translatedString) translatedString = toTitleCase(translatedString);

  if (translatedString) {
    // Handle any number of parameters
    Object.values(params).forEach((param, index) => {
      const placeholder = `{${index}}`;
      if (translatedString.includes(placeholder)) {
        translatedString = translatedString.replace(placeholder, param);
      }
    });
  }

  return translatedString || `{{${children}}}`;
}

// TM - Translate Month - this component takes MMMM YY and returns a fragment with the month portion translated
const TM = ({ children }) => {
  const { translationTable, languageLoading } = useContext(LanguageContext);

  let dateSplit = children.split(" ");

  return languageLoading ? <></> :
    <>
      {
        // check both translation table THEN local translation table before returning {{ not found }} 
        translationTable[dateSplit[0].toLowerCase()] ?
          `${translationTable[dateSplit[0].toLowerCase()]} ${dateSplit[1]}` : (
            localTranslationTable[dateSplit[0].toLowerCase()] ? `${localTranslationTable[dateSplit[0].toLowerCase()]} ${dateSplit[1]}` : `{{${children}}}`
          )
      }
    </>

}

// function that takes a key and returns translated value
// this function can be used on it's own or stored in a variable to be used conditionally. See SortByDropdown.jsx:62
const useTranslateKey = (key, ...params) => {
  const { translationTable, languageLoading } = useContext(LanguageContext);

  // If this function gets an empty translation value, use local translation table (English)
  if (translationTable[key] === "empty_translation_value") return localTranslationTable[key];

  let translatedString = translationTable[key] ? translationTable[key] : localTranslationTable[key] ? localTranslationTable[key] : null;

  if (languageLoading) return "";

  if (translatedString) {
    // Handle any number of parameters
    params.forEach((param, index) => {
      const placeholder = `{${index}}`;
      if (translatedString.includes(placeholder)) {
        translatedString = translatedString.replace(placeholder, param);
      }
    });
  }

  return translatedString || `{{${key}}}`;
}


export { T, TM, useTranslateKey }