import { useContext } from 'react';
import DOMPurify from 'dompurify';
import { LanguageContext } from "../hooks/LanguageContext";
import { formatDate } from "../common/newsDateUtils"
import { setAttributesForHtml } from "../common/newsHtmlUtils";
import { useTranslateKey } from "../hooks/useTranslate"

export const useArticle = (article) => {
  const { currentLanguage } = useContext(LanguageContext);

  const todayTranslated = useTranslateKey("today");
  const yesterdayTranslated = useTranslateKey("yesterday");
  const dateString = article.updated ? article.updated : article.created;
  const displayDate = formatDate(dateString, currentLanguage, todayTranslated, yesterdayTranslated);

  const imgUrl = article.banner?.styles?.original;

  const htmlContent = article.description;
  const cleanHTML = DOMPurify.sanitize(htmlContent);

  // set font size on all p tags and open links in new tab
  const formattedHTML = setAttributesForHtml(cleanHTML, 12);

  const name = article.name;

  return { name, displayDate, imgUrl, formattedHTML };
};