import React from "react"
import { useNavigate, useLocation } from 'react-router-dom';
import { useMixPanel } from "../../../hooks/useMixPanel";

import {
  Box,
  Typography,
  Collapse,
  IconButton,
  Button, Link,
  TableCell,
  TableRow,
  Stack
} from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { OfficeAvatar } from "../../../components/OfficeAvatar";
import { CopyToClipboard } from "../../../components/CopyToClipboard";

import { formatPhoneNumber, formatInitial } from "../../../common/format-functions";
import { decodeString } from "../../../common/format-functions";

import "./CollapsibleTable.css"
import { CollapsibleOrders } from "./CollapsibleComponents/CollapsibleOrders";
import { CollapsibleLastOrderDate } from "./CollapsibleComponents/CollapsibleLastOrderDate";
import { CollapsibleFirstOrderDate } from "./CollapsibleComponents/CollapsibleFirstOrderDate";
import { CollapsibleReferredBy } from "./CollapsibleComponents/CollapsibleReferredBy";
import { CollapsibleDateJoined } from "./CollapsibleComponents/CollapsibleDateJoined"
import { CollapsibleAvailability } from "./CollapsibleComponents/CollapsibleAvailability";

function CollapsibleTableRow(props) {
  const { row, tableType, screen } = props;
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const handleNameClick = () => {
    logEvent("open_profile", {
      location: `${screen}_mobile_view`,
      user_type: "member",
      target_id: row.unicityId
    });
    navigate(`${location.pathname}?drawer=member&id=${row.unicityId}&drawerContent=profile`, { state: { location: location, } });
  }

  const handleContactClick = (contactType) => {
    logEvent(`${contactType}_contact`, {
      location: `${screen}_mobile_view`,
      user_type: "member",
      target_id: row.unicityId
    });
  };

  const handlePhoneNumberClick = () => {
    handleContactClick("phone");
  };

  const handleSmsNumberClick = () => {
    handleContactClick("sms");
  };

  const handleEmailClick = () => {
    handleContactClick("email");
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component="th" scope="row">
          <Stack spacing={1} direction="row">
            <Stack alignItems="center">
              <OfficeAvatar firstInitial={formatInitial(row.name)} addBadge={false} badgeOn={false} id="user-avatar" />
            </Stack>
            <Stack justifyContent="center" spacing={.3}>
              <Stack flex={row.homePhone?.length > 0 && 1}>
                <Typography variant="h4" onClick={handleNameClick} sx={{ "&:hover": { textDecoration: "underline" }, cursor: "pointer", flex: 1 }}>{decodeString(row.name)}</Typography>
              </Stack>
              {row.homePhone?.length > 0 &&
                <Stack direction="row" alignItems="center" flex={1}>
                  <Stack justifyContent="center">
                    <Typography variant="h5" color="rgba(21, 56, 98, 1)" sx={{ fontWeight: "400", fontSize: "10px", opacity: ".6" }}>
                      {formatPhoneNumber(row.homePhone)}
                    </Typography>
                  </Stack>
                  <Stack justifyContent="center">
                    <CopyToClipboard
                      stringToCopy={formatPhoneNumber(row.homePhone)}
                      sx={{
                        verticalAlign: "middle",
                        marginLeft: "2px",
                        cursor: "pointer",
                        opacity: ".6",
                        color: "#153862",
                        height: "12px"
                      }}
                      eventLocation={`${screen}_mobile_view`}
                      sendEvent={true}
                      eventName="phone_copy"
                      userId={row.unicityId}
                      userType={row.customerType === "Associate" ? "distributor" : "member"}
                    />
                  </Stack>
                </Stack>
              }
            </Stack >
          </Stack >
        </TableCell>
        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: "1px solid #EDEFF1" }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: "8px 8px 24px 8px" }}>
              <Stack spacing={3}>
                <Stack spacing={1} direction="row">
                  {tableType === "reminders" && <CollapsibleOrders row={row} />}
                  {tableType === "reminders" && <CollapsibleLastOrderDate row={row} />}
                  {tableType === "newfeelgreat" && <CollapsibleReferredBy row={row} />}
                  {tableType === "newfeelgreat" && <CollapsibleFirstOrderDate row={row} />}
                  {tableType === "partials" && <CollapsibleDateJoined row={row} />}
                  {tableType === "partials" && <CollapsibleAvailability row={row} />}
                </Stack>
                <Stack spacing={1} direction="row">
                  <Box sx={{ flex: 1 }}>
                    {
                      row.homePhone ?
                        <Link href={`tel:${formatPhoneNumber(row.homePhone)}`} target="_blank" rel="noreferrer" onClick={handlePhoneNumberClick}>
                          <Button sx={{ border: "1px solid rgba(145, 158, 171, 0.2)", width: "100%" }}>
                            <img src="icons/phone.svg" alt="phone icon" />
                          </Button>
                        </Link> :
                        <Button sx={{ border: "1px solid rgba(145, 158, 171, 0.2)", width: "100%", cursor: "not-allowed", backgroundColor: "rgb(247, 247, 247)" }} disabled>
                          <img src="icons/phone-disabled.svg" alt="phone icon" />
                        </Button>
                    }
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {
                      row.homePhone ?
                        <Link href={`sms:${formatPhoneNumber(row.homePhone)}`} target="_blank" rel="noreferrer" onClick={handleSmsNumberClick}>
                          <Button sx={{ border: "1px solid rgba(145, 158, 171, 0.2)", width: "100%" }}>
                            <img src="icons/message.svg" alt="message icon" />
                          </Button>
                        </Link> :
                        <Button sx={{ border: "1px solid rgba(145, 158, 171, 0.2)", width: "100%", cursor: "not-allowed", backgroundColor: "rgb(247, 247, 247)" }} disabled>
                          <img src="icons/message-disabled.svg" alt="message icon" />
                        </Button>
                    }
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    {
                      row.email ?
                        <Link href={`mailTo:${formatPhoneNumber(row.email)}`} target="_blank" rel="noreferrer" onClick={handleEmailClick}>
                          <Button sx={{ border: "1px solid rgba(145, 158, 171, 0.2)", width: "100%" }}>
                            <img src="icons/mail.svg" alt="email icon" />
                          </Button>
                        </Link> :
                        <Button sx={{ border: "1px solid rgba(145, 158, 171, 0.2)", width: "100%", cursor: "not-allowed", backgroundColor: "rgb(247, 247, 247)" }} disabled>
                          <img src="icons/mail-disabled.svg" alt="email icon" />
                        </Button>
                    }
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export { CollapsibleTableRow }