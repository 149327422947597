import React, { useEffect } from "react";
import { T } from "../../../hooks/useTranslate";
import {
  Box,
  Stack,
  Typography,
  Button
} from "@mui/material";
import { OfficeAvatar } from "../../../components/OfficeAvatar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SettingsProfilePhotoSection = ({
  profilePictureUrl,
  setProfilePictureUrl,
  setSelectedFile,
}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const fileInput = document.querySelector('input[data-test-id="file-input-element"]');

    const handleFileChange = (event) => {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        setSelectedFile(file);
        setProfilePictureUrl(URL.createObjectURL(file));
      }
    };

    fileInput.addEventListener('change', handleFileChange);

    return () => {
      fileInput.removeEventListener('change', handleFileChange);
    };
    // eslint-disable-next-line
  }, []);

  const handleAddPhoto = () => {
    const fileInput = document.querySelector('input[data-test-id="file-input-element"]');
    fileInput.click(); // Programmatically click the hidden file input
  };

  // click handler for Remove button
  const handleRemoveClick = () => {
    setProfilePictureUrl("");
    setSelectedFile(null);
  };

  return (
    <Stack
      data-test-id="profile-picture-sub-header"
      pt={3}
      pb={3}
      alignItems="flex-start"
    >
      <Typography variant="h4" sx={{ lineHeight: "22px" }}>
        <T>profile_photo</T>
      </Typography>
      <Typography
        sx={styles.subheader}>
        <T>your_profile_picture_may_appear</T>
      </Typography>
      <Stack mt={3} direction="row" gap={1} alignItems="center">
        {profilePictureUrl ? (
          <Box mr={2}>
            <OfficeAvatar imageUrl={profilePictureUrl} height={80} width={80} />
          </Box>
        ) : (
          <Box sx={styles.emptyProfilePicture}>
            <img src="icons/subtract.svg" alt="empty" style={styles.icon} />
          </Box>
        )}
        {profilePictureUrl ? (
          <>
            <Button sx={styles.button} onClick={handleAddPhoto}>
              <T>{viewportIsSmall ? "change_photo" : "change"}</T>
            </Button>
            <Button
              sx={{ ...styles.button, color: "#153862" }}
              onClick={handleRemoveClick}
            >
              <T>{viewportIsSmall ? "remove_photo" : "remove"}</T>
            </Button>
          </>
        ) : (
          <Button sx={styles.button} onClick={handleAddPhoto}>
            <T>add_photo</T>
          </Button>
        )}
      </Stack>
      <input
        data-test-id="file-input-element"
        type="file"
        style={{ display: "none" }}
        accept=".gif, .jpeg, .jpg, .png"
      />
    </Stack>
  );
};

export { SettingsProfilePhotoSection };

const styles = {
  button: {
    lineHeight: "1",
    fontSize: "12px",
    color: "#009ECF",
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.20)",
    padding: "12px 26px",
  },
  emptyProfilePicture: {
    display: "inline-block",
    padding: "28px",
    backgroundColor: "rgba(145, 158, 171, 0.06)",
    borderRadius: "50%",
    border: "1px solid rgba(145, 158, 171, 0.2)",
    marginRight: "16px",
  },
  icon: {
    width: "24px",
    height: "24px",
  },
  buttonContainer: {
    display: "flex",
    gap: "4px",
    flexDirection: "row",
    alignItems: "center",
  },
  dialog: {
    "& .MuiPaper-root": {
      border: "1px solid rgba(145, 158, 171, 0.10)",
      filter: "drop-shadow(0px 1px 3px rgba(145, 158, 171, 0.10)",
    },
  },
  subheader: {
    fontSize: "11px",
    fontWeight: "400",
    color: "rgba(21, 56, 98, 0.8)",
  }
};
