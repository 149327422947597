import React from "react";
import { T } from "../hooks/useTranslate";

import { Box } from "@mui/material";
import { RANK_DETAILS } from "../common/rankDetails";

const RankChip = ({ rankId, styles }) => {
  const appliedRank = !rankId ? 0 : rankId
  const rankDetails = RANK_DETAILS.find(
    (rank) => rank.rankId === appliedRank
  );
  return (
    <Box
      sx={{
        backgroundColor: rankDetails?.backgroundColor,
        color: rankDetails?.color,
        borderRadius: "38px",
        ...styles
      }}
    >
      <T>{rankDetails?.rankTranslateFullKey}</T>
    </Box>
  )
}

export { RankChip } 