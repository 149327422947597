import React from "react";
import { TextField, Box, InputAdornment } from "@mui/material";
import styles from "./../../settingsScreenStyles"

const SsnInputField = ({
  name,
  label,
  content,
  isLocked,
  formik
}) => {

  const handleChange = (e) => {
    formik.handleChange(e);
  }

  return (
    <TextField
      data-test-id={`${name}-text-field`}
      inputProps={{
        "data-test-id": `${name}-input-tag`,
        maxLength: 20,
        style: {
          fontSize: ".875rem",
        }
      }}
      InputLabelProps={{
        "data-test-id": `${name}-input-label`,
        style: {
          fontSize: ".875rem",
        }
      }}
      fullWidth
      disabled={isLocked}
      label={label}
      variant="outlined"
      sx={{
        ...styles.textField,
        ...(isLocked ? styles.lockedTextField : {}),
      }}
      name={name}
      value={content || (formik && formik.values[name]) || ""}
      onChange={handleChange}
      error={Boolean(formik.errors[name])}
      helperText={Boolean(formik.errors[name]) && <Box data-test-id={`${name}-error-text`}>{formik.errors[name]}</Box>}
      FormHelperTextProps={{
        "data-test-id": `helperText`
      }}
      InputProps={{
        endAdornment: isLocked && (
          <InputAdornment position="end">
            <img src="icons/lock-icon.svg" alt="lock icon" height="16px" width="16px" />
          </InputAdornment>
        )
      }}
    />
  );
};

export { SsnInputField }