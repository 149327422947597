import { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { useConfig } from "./useConfig";

const useUshopRedirectMarkets = () => {
  const { userInfo } = useAuth();
  const { data: configData } = useConfig("markets/ushopRedirectMarkets.json");

  const [shopLink, setShopLink] = useState("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // if user is in a market that should be redirected to uShop, fetch the redirect url
    if (configData?.data?.ushop_redirect?.value.includes(userInfo.countryCode)) {
      let autoLoginId = "";

      const fetchUshopUrl = async () => {
        try {
          const data = JSON.stringify({ payload: { token: `Bearer=${userInfo.token}`, baId: userInfo.id, country: userInfo.countryCode }, expire: "2024-12-21T07:23:27.482Z" });

          fetch("https://member-calls2.unicity.com/remoteStorage/broker", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${process.env.REACT_APP_AUTO_LOGIN_AUTH_CODE}`,
              "x-application": `office.web`
            },
            body: data,
          }).then(response => response.json())
            .then(data => {
              autoLoginId = data?.id;
              setShopLink(`https://ushop.unicity.com/${userInfo.countryCode.toLowerCase()}?autoLogin=${autoLoginId}`)
            });
        } catch (err) {
          setError(err);
          console.log("An error has occurred while fetching uShop url, defaulting to shop.");
        } finally {
          setLoading(false);
        }
      };

      fetchUshopUrl();
    } else {
      setShopLink(`${process.env.REACT_APP_SHOP_URL}/${btoa(userInfo.token)}`)
    }
    // eslint-disable-next-line
  }, [configData])

  return { shopLink, loading, error };
};

export { useUshopRedirectMarkets };