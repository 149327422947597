import React from "react";
import { T } from "../../../hooks/useTranslate";

import { OfficeBox } from "../../../components/OfficeBox";
const NewsErrorComponent = () => {
  return (
    <OfficeBox p={3} data-test-id="news-error">
      <T>generic_error</T>
    </OfficeBox>
  )
}

export { NewsErrorComponent }