import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { useFormik } from "formik";

import {
  Modal,
  Typography,
  Stack,
  FormControl,
  RadioGroup,
  Grid,
  Switch
} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

import { LanguageModalOption } from "./LanguageModalOption";
import { LanguageContext } from "../../hooks/LanguageContext";
import { LANGUAGE_DETAILS } from "../../common/languageDetails";

import { desktopContainerStyles } from "./settings-styles";
import { LanguageModalButtons } from "./LanguageModalButtons";


const LanguageModal = ({ open, handleModalClose, handleClose }) => {
  const { currentLanguage, setCurrentLanguage, languageDir, setLanguageDir } = useContext(LanguageContext);

  const handleRadioChange = () => {
    formik.setErrors({});

    return formik.handleChange;
  };

  const handleApplyClick = (option) => {
    setCurrentLanguage(option.selection);
    handleModalClose();
    handleClose();
  }

  // configure Formik to handle form state
  const formik = useFormik({
    initialValues: {
      selection: currentLanguage,
    },
    onSubmit: handleApplyClick,
  });

  // reset selection when modal closes
  const handleModalCancel = () => {
    handleModalClose();
    formik.setFieldValue("selection", currentLanguage)
  }

  const handleRtlChange = (e) => {
    setLanguageDir(languageDir === "rtl" ? "ltr" : "rtl")
  }

  return (
    <Modal
      open={open}
      onClose={handleModalCancel}
      aria-labelledby="modal-language-picker"
      aria-describedby="modal-language-picker-menu"
    >
      <form data-test-id={`language-form`} onSubmit={formik.handleSubmit}>
        <Stack sx={desktopContainerStyles}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mb={1}>
            <Typography variant="h10"><T isUpperCase={true}>change_language</T></Typography>
            <CloseIcon onClick={handleModalClose} sx={{ cursor: "pointer", height: "16px", width: "16px" }} />
          </Stack>
          <Stack sx={{ fontWeight: "500", fontSize: "14px", color: "#153862" }} alignItems={"flex-start"} mb={3}>
            <FormControl>
              <RadioGroup
                aria-labelledby="language-radio-buttons-group-label"
                defaultValue="spanish"
                name="language-radio-group"
                onChange={handleRadioChange}
                value={formik.values.selection}
              >
                <Grid container spacing={0} rowSpacing={0} maxWidth={"367px"}>
                  {LANGUAGE_DETAILS.map((language, idx) => {
                    return <Grid item xs={6} key={idx}>
                      <LanguageModalOption
                        formik={formik}
                        value={language.countryCode}
                        label={language.label}
                        data-test-id={`language-option-${language.countryCode}`}
                      />
                    </Grid>
                  })}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack direction={"row"} justifyContent={"flex-end"} gap={2}>
            <LanguageModalButtons handleModalCancel={handleModalCancel} />
          </Stack>
          {
            process.env.REACT_APP_IS_PRODUCTION === "false" && (
              <Stack direction={"row"} alignItems={"center"}>
                <Typography variant="h10">SET RIGHT-TO-LEFT</Typography>
                <Switch checked={languageDir === "rtl"} onClick={handleRtlChange} />
              </Stack>
            )
          }
        </Stack>
      </form>
    </Modal>
  )
}

export { LanguageModal }
