import React, { useContext, useState } from "react";
import { T } from "../../hooks/useTranslate";

import { useNavigate, useLocation } from "react-router-dom";

import { Stack, Button, Typography } from "@mui/material";

import { PartnersContext } from "../../hooks/PartnersContext";
import { DistributorContext } from "../../hooks/DistributorContext";

const PartnersViewProfileButton = ({ style }) => {
  const { resetContext } = useContext(DistributorContext);
  const { downlineUid } = useContext(PartnersContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleProfileClick = (e) => {
    e.stopPropagation();
    resetContext();
    navigate(`${location.pathname}?drawer=partner&id=${downlineUid}&drawerContent=profile`, {
      state: location,
    });
  };

  return (
    <Button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-test-id="partner-view-profile-button"
      variant="contained"
      color="primary"
      sx={{
        ...style,
        backgroundColor: "#FFF",
        color: "#153862",
        border: "1px solid rgba(145, 158, 171, 0.20)",
        padding: "0.5rem 0.75rem 0.5rem 0.75rem",
        height: "2rem",
        '&:hover': {
          borderColor: '#009ECF',
          color: "#009ECF",
          backgroundColor: "#FFF"
        },
      }}
      onClick={handleProfileClick}
    >
      <Stack gap={"0.25rem"} alignItems={"center"} direction={"row"}>
        {isHovered ?
          <img
            src="icons/profile-icon-hover.png"
            alt="view profile icon"
          /> :
          <img
            src="icons/profile-icon.png"
            alt="view profile icon"
          />
        }
        <Typography style={{
          fontSize: "0.625rem",
          fontWeight: "600",
          lineHeight: "1rem"
        }}>
          <T>view_profile</T>
        </Typography>
      </Stack>
    </Button>
  )
}

export { PartnersViewProfileButton }