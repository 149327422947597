import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { useParams, useLocation } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { PartnersContext } from "../../hooks/PartnersContext";
import { useAuth } from "../../hooks/useAuth";
import { PartnersSearchResultsTitle } from "../PartnersSearchResultsScreen/PartnersSearchResultsTitle";
import { PartnersIncludeMembersToggle } from "./PartnersIncludeMembersToggle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GeneralAlert } from "../../components/GeneralAlert";

const PartnersHeadSection = ({ isWebView }) => {
  const { contextUserFirstName, loading, isLevelLimitMax } = useContext(PartnersContext);
  const { distributorId } = useParams();
  const { userInfo } = useAuth();
  const location = useLocation();

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const title = location.pathname === "/partners" ? <T>my_frontline</T>
    : location.pathname.includes("/partners/search-results") ? <PartnersSearchResultsTitle />
      : distributorId === undefined ? ""
        : loading ? ""
          : distributorId === userInfo.unicityId.toString() ? <T>my_frontline</T>
            : <>{`${contextUserFirstName}'s `}<T>frontline</T></>

  return (
    <Stack id="partners-data-grid-head-section">
      <Stack
        justifyContent="space-between"
        alignItems="baseline"
        data-test-id={`partners-datagrid-title`}
        direction={viewportIsSmall ? "row" : "column"}
        p={3}
        gap={viewportIsSmall ? 0 : 3}
      >
        <Stack
          direction="row"
          alignItems="center"
        >
          {
            loading ? (
              <div style={{ height: "16px" }}></div>
            ) : (
              <>
                <Typography variant="h3">
                  {title}
                </Typography>
                {isLevelLimitMax && <GeneralAlert alertKey="level_Limit_alert" tooltipKey="level_Limit_tooltip" />}
              </>
            )
          }
        </Stack>
        <PartnersIncludeMembersToggle viewportIsSmall={viewportIsSmall} />
      </Stack>
    </Stack>
  );
};

export { PartnersHeadSection };
