import { useEffect } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";

import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  Button,
  LinearProgress,
  Stack,
} from "@mui/material";
import { useFormik } from "formik";

import { LoginErrorAlert } from "../../components/LoginErrorAlert";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { AbsoluteCenteredBox } from "../../components/AbsoluteCenteredBox";
import { UnicityOfficeLogo } from "../../components/UnicityOfficeLogo";
import { useAuth } from "../../hooks/useAuth";
import styled from "styled-components";
import { ForgotPasswordLink } from "../../components/LoginComponents/ForgotPasswordLink";
import { EmailUsernameInput } from "../../components/LoginComponents/EmailUsernameInput";
import { PasswordInput } from "../../components/LoginComponents/PasswordInput";
import { useValidationSchema } from "../../components/LoginComponents/ValidationSchemas";
import { LanguageButton } from "../../components/settings/LanguageButton";
import { CopyrightSection } from "../../components/CopyrightSection";
import { useMixPanel } from "hooks/useMixPanel";

const ResponsiveButtonWrapper = styled.div`
  width: ${(props) => (props.viewportIsMedium ? "50%" : "100%")};
`;

let redirectRoute = "dashboard";

//Screen displayed when user is unauthenticated and needs to log in
const LoginScreen = ({ isWebView, isPwa }) => {
  const { silentLogin, login, logout, userInfo } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const { logEvent } = useMixPanel();

  const { token } = useParams();
  const [searchParams] = useSearchParams();

  if (searchParams.get("redirectRoute"))
    redirectRoute = searchParams.get("redirectRoute");

  if (searchParams.get("language"))
    localStorage.setItem("language", searchParams.get("language"));

  // Clear user info when the component mounts
  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //support silent login via /sl/:token route
  useEffect(() => {
    if (token) silentLogin(token, isPwa);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Once the user logs in, redirect to desired route, or default to dashboard
  useEffect(() => {
    if (userInfo.loggedIn && userInfo.type === "Associate") {
      logEvent("login", {
        status: userInfo.status,
        id: userInfo.unicityId
      })
      if (userInfo.status === "Suspended" || userInfo.status === "Terminated") {
        localStorage.setItem('showSuspendedTerminatedError', true)
        navigate(`/login`, { state: location });
        return
      }
      navigate(`/${redirectRoute}`, { state: location });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, redirectRoute]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("lang")) {
      localStorage.setItem("language", params.get("lang"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //Configure Formik to handle form state
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: useValidationSchema(useTranslateKey),
    onSubmit: ({ username, password }) => {
      login(username, password, isPwa);
    },
  });

  return (
    <Stack>
      <LanguageButton />
      <AbsoluteCenteredBox>
        <form id="loginForm" onSubmit={formik.handleSubmit}>
          <Stack
            spacing={{ xs: 3, sm: 4, md: 5 }}
            pt="3em"
            pl={`${viewportIsMedium ? "5" : "2"}em`}
            pr={`${viewportIsMedium ? "5" : "2"}em`}
            pb="3em"
            width={viewportIsSmall ? "360px" : "90vw"}
          >
            <UnicityOfficeLogo />
            {!isWebView && (
              <EmailUsernameInput formik={formik} hidePlaceholderText={true} />
            )}
            {!isWebView && (
              <PasswordInput formik={formik} hidePlaceholderText={true} />
            )}
            {!isWebView && (
              <Stack
                direction={{ xs: "column", sm: "column", md: "row" }}
                spacing={{ xs: 2, sm: 2, md: 4 }}
                justifyContent="space-between"
                alignItems="center"
              >
                <ResponsiveButtonWrapper viewportIsMedium={viewportIsMedium}>
                  <ForgotPasswordLink />
                </ResponsiveButtonWrapper>
                <ResponsiveButtonWrapper viewportIsMedium={viewportIsMedium}>
                  <Button
                    id="login"
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={userInfo.state === "wait"}
                  >
                    <T>login</T>
                  </Button>
                </ResponsiveButtonWrapper>
              </Stack>
            )}
            {userInfo.state === "wait" && (
              <LinearProgress id="loginProgress" data-test-id="progress-bar-shown" />
            )}
            <LoginErrorAlert />
          </Stack>
        </form>
      </AbsoluteCenteredBox>
      <CopyrightSection padding={5} />
    </Stack>

  );
};

export { LoginScreen };