import React, { useState, useEffect } from "react";
import { T, useTranslateKey } from "../../../hooks/useTranslate";
import { Stack, Typography, Button } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

import { OfficeBox } from "../../../components/OfficeBox";
import { SettingsTextField } from "../SettingsTextField";
import { SettingsShareFeelGreatModal } from "./SettingsShareFeelGreatModal";
import { useApi } from "../../../hooks/useApi";
import styles from "../settingsScreenStyles";
import { useFormikInstances } from './useFormikInstances';

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SettingsShareFeelGreat = ({ setShowLoadingOverlay }) => {
  const { sendRequestAsCallback, status, response } = useApi();
  const [referralCode, setReferralCode] = useState("");
  const [referralLink, setReferralLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [maxChangesReached, setMaxChangesReached] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formikNewReferralCode.resetForm();
  }

  const { formikNewReferralCode } = useFormikInstances(setIsModalLoading, setIsModalOpen, handleCloseModal, setIsUpdated, setReferralCode, setReferralLink);

  const translateReferralLink = useTranslateKey("referral_link");
  const translateReferralCode = useTranslateKey("referral_code");

  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const viewportIsSmall = !useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const fetchReferralCodes = async () => {
      setShowLoadingOverlay(true);

      try {
        await sendRequestAsCallback({
          method: "get",
          endpoint: `customers/me/referralcodes?__resetCaches=1`,
        });
      } catch (err) {
        formikNewReferralCode.setErrors({ referralCode: "generic_error" });
      } finally {
        setShowLoadingOverlay(false);
      }
    };

    fetchReferralCodes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status === "complete") {
      setReferralCode(response.data.items[0]?.referralCode ?? "");
      setReferralLink(`${process.env.REACT_APP_FEEL_GREAT_REFERRAL_URL}/${response.data.items[0]?.referralCode ?? ""}`);
      setMaxChangesReached(response.data.items[0]?.maxChangesReached ?? false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  return (
    <OfficeBox sx={styles.mainContent}>
      <Stack data-test-id="distributor-profile-header">
        <Typography variant="h2" data-test-id="share-feel-great-header">
          <T>share_feel_great</T>
        </Typography>
        <Typography sx={styles.subheader} data-test-id="share-feel-great-subheader">
          <T>share_feel_great_subheader</T>
        </Typography>
        <Stack direction={viewportIsMedium ? "row" : "column"} gap={2} mt={4.4}>
          <SettingsTextField
            name="referral-link"
            label={translateReferralLink}
            content={referralLink}
            isLocked={true}
          />
          <SettingsTextField
            name="referral-code"
            label={translateReferralCode}
            content={referralCode}
            isLocked={true}
          />
        </Stack>
        {
          isUpdated && (
            <Stack direction="row" mt={1} alignItems="center" gap={.5}>
              <img src="icons/green-check.svg" alt="green check" height="14px" width="14px" />
              <Typography sx={styles.updateAlert}>
                <T>referral_link_updated_alert</T>
              </Typography>
            </Stack>
          )
        }
        {
          formikNewReferralCode.errors.referralCode && (
            <Stack direction="row" mt={1} alignItems="center" gap={.5}>
              <ErrorIcon sx={{ color: "#ff4d4d" }} />
              <Typography sx={{ ...styles.updateAlert, color: "#ff4d4d" }}>
                <T>{formikNewReferralCode.errors.referralCode}</T>
              </Typography>
            </Stack>
          )
        }
        {
          maxChangesReached && (
            <Stack direction="row" mt={1} alignItems="center" gap={.5}>
              <Typography sx={{ ...styles.updateAlert, color: "rgba(21, 56, 98, 0.8)" }}>
                <T>share_feel_great_max_changes_reached</T>
              </Typography>
            </Stack>
          )
        }
        {
          !formikNewReferralCode.errors.referralCode && !maxChangesReached && (
            <Stack alignItems="flex-start" mt={3}>
              <Button
                data-test-id="edit-button"
                sx={styles.resetButton}
                onClick={handleOpenModal}
              >
                <T>edit</T>
              </Button>
            </Stack>
          )
        }
      </Stack>
      <SettingsShareFeelGreatModal
        formikNewReferralCode={formikNewReferralCode}
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        isModalLoading={isModalLoading}
        viewportIsSmall={viewportIsSmall}
      />
    </OfficeBox>
  )
}

export { SettingsShareFeelGreat }