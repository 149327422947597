import React from "react";
import { TextField } from "@mui/material";
import { useTranslateKey } from "../../../../hooks/useTranslate";

const FormField = ({
  label,
  name,
  value,
  onChange,
  error,
  helperText,
  inputProps = {},
  InputLabelProps = {},
  FormHelperTextProps = {},
}) => {
  const translateHelperText = useTranslateKey(helperText);

  return (
    <TextField
      name={name}
      label={label}
      inputProps={{ "data-test-id": `${name}-input-tag`, ...inputProps }}
      InputLabelProps={{ "data-test-id": `${name}-input-label`, ...InputLabelProps }}
      FormHelperTextProps={FormHelperTextProps}
      fullWidth
      value={value}
      onChange={onChange}
      error={error}
      helperText={error && translateHelperText}
    />
  );
};

export { FormField }