import React from "react";
import { T } from "../hooks/useTranslate";

import { Box, Typography, Button } from "@mui/material";

import { useEvent } from "../hooks/useEvent";

import { StyledGridOverlay } from "../components/StyledGridOverlay";

const SearchNoRowsOverlay = ({ setFilterModel, setSearchField }) => {
  const { publish } = useEvent();

  return (
    <StyledGridOverlay data-test-id="no-rows-overlay">
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "center", margin: "60px 0px" }}>
        <img src="icons/no-search-results.svg" alt="" style={{ width: "80px" }} />
        <Typography variant="h3"><T>no_results_to_display</T></Typography>
        <Typography variant="h5" sx={{ width: "221px", textAlign: "center" }}><T>no_search_results_overlay1</T></Typography>
        <Button
          sx={{
            height: "31px",
            paddingRight: "8px",
            paddingLeft: "8px",
            fontSize: "12px",
            backgroundColor: "#009ECF",
            borderRadius: "4px",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#009ECF90"
            }
          }}
          onClick={() => {
            publish("clearFilters");
            setSearchField("");
            setFilterModel([]);
          }}
        >
          <T>clear_search</T>
        </Button>
      </Box>
    </StyledGridOverlay>

  )

}

export { SearchNoRowsOverlay }