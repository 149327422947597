import React from "react";

import { Stack } from "@mui/material";
import { PartnersMonthSelect } from "./PartnersMonthSelect";
import { PartnersFilterButton } from "./PartnersFilterButton";
import { PartnersManageColumnsButton } from "./PartnersManageColumnsButton";
import { useMixPanel } from "../../hooks/useMixPanel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PartnersSearchSortButtons = () => {
  const { logEvent } = useMixPanel();
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      sx={{
        overflowX: "auto",
      }}
    >
      <Stack
        direction="row"
        gap={1}
        pb={1}
        flexWrap={viewportIsSmall ? "wrap" : "nowrap"}
      >
        <PartnersMonthSelect
          isDashboard={false}
          logEvent={logEvent}
        />
        <PartnersFilterButton logEvent={logEvent} />
        <PartnersManageColumnsButton />
      </Stack>
    </Stack>
  )
}

export { PartnersSearchSortButtons }