import React, { useContext } from "react";
import { T } from "../../../hooks/useTranslate";
import { Stack, Typography, Link, Box, Divider, Alert } from "@mui/material";
import { OfficeBox } from "../../../components/OfficeBox";
import { useNews } from "../../../hooks/useNews";
import { ArticleListItem } from "./ArticleListItem";
import { DashboardDivider } from "../DashboardDivider";
import { useNavigate, useLocation } from "react-router-dom";
import { useMixPanel } from "../../../hooks/useMixPanel";
import { LoadingSkeleton } from "./LoadingSkeleton";
import { NewsErrorComponent } from "./NewsErrorComponent";
import { LanguageContext } from "../../../hooks/LanguageContext";
import { Arrow } from "../../../components/Arrow";

const NewsContent = () => {
  const { news, loading, error } = useNews();
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const { languageDir } = useContext(LanguageContext);

  const renderNews = () => {
    if (error) {
      return <NewsErrorComponent />;
    }
    if (loading) {
      return (
        <Box p={3}>
          <LoadingSkeleton heights={[60, 60, 60]} data-test-id="loading-skeleton" />
        </Box>
      )
    }

    const trimmedNewsItems = news.slice(0, 3);

    if (trimmedNewsItems.length > 0) {
      return trimmedNewsItems.map((article, index) => (
        <React.Fragment key={article.id}>
          <ArticleListItem article={article} logEvent={logEvent} />
          {index < trimmedNewsItems.length - 1 && <Divider />}
        </React.Fragment>
      ));
    }

    return <Alert severity="info"><T>no_news_to_display</T></Alert>;
  };

  const handleClick = () => {
    logEvent("news_view", { location: 'dashboard' });
    navigate("/news", { state: location });
  }

  return (
    <OfficeBox>
      <Box>
        <Typography
          data-test-id="news-title"
          variant="h3"
          padding="24px 24px 10px 24px"
        >
          <T>unicity_news</T>
        </Typography>
        {renderNews()}
      </Box>
      <DashboardDivider />
      <Link
        data-test-id="see-more-news-link"
        onClick={handleClick}
        underline="hover"
        sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}
      >
        {
          news.length > 0 && (
            <Stack
              flexDirection="row"
              p={3}
              alignItems="center"
              justifyContent="space-between"

            >
              <Typography variant="h4" data-test-id="see-more-news-text"><T>see_more</T></Typography>
              <Arrow dir={languageDir} testId="see-more-news-icon" />
            </Stack>
          )
        }
      </Link>
    </OfficeBox>
  );
};

export { NewsContent };
