export const styles = {
  button: {
    height: "2.5rem",
    paddingLeft: ".75rem",
    paddingRight: ".3125rem",
    border: "1px solid rgba(145, 158, 171, 0.20)",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "600",
    color: "#153862",
    whiteSpace: "nowrap",
  },
  subheaderStyle: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 10,
    color: "#8A9BB0",
    marginBottom: "16px"
  },
  applyButton: {
    height: "31px",
    color: "#FFFFFF",
    backgroundColor: "#009ECF",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "12px",
    pl: "12px",
    pr: "12px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "rgba(0, 158, 198, 0.48)"
    }
  },
  paperPropsStyle: {
    marginTop: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.1)",
    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)',
  },
  inactiveText: {
    fontSize: "12px",
    color: "#153862",
    opacity: ".8",
    maxWidth: "230px",
    marginTop: "12px"
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    gap: "16px",
    margin: "16px 16px 16px 16px"
  },
  cancelButton: {
    height: "31px",
    color: "#153862",
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#FFFFFF"
    }
  },
  checkboxTextStyle: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "14px"
  },
  menuContainer: {
    padding: "16px 24px"
  },
  selectedIcon: {
    color: "#009ECF",
    border: "1px solid #009ECF",
  },
  genealogyIcon: {
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.20)",
  },
  dataGrid: {
    border: "none",
    fontSize: "12px",
    // needed to extend column headers background color over scrollbar
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#F9FAFB",
    },
    "& .MuiDataGrid-footerContainer": {
      minHeight: "auto"
    }
  },
  searchSuggestionsPopper: {
    bgcolor: "#fff",
    border: "1px solid rgba(145, 158, 171, 0.10)",
    filter: "drop-shadow(0px 1px 3px rgba(145, 158, 171, 0.10)) drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.08))",
    borderRadius: "8px"
  },
  rankChip: {
    padding: "1px 4px",
    fontWeight: 700,
    fontSize: "8px"
  },
  searchBar: {
    highlightWeight: "600",
    text: { fontSize: "10px", fontWeight: "400", color: "#153862" },
  },
  inactiveChip: {
    fontWeight: "600",
    fontSize: "9px",
    color: "#153862",
    backgroundColor: "#EDEFF1",
    borderRadius: "38px",
    padding: "1px 8px",
  },
  suspendedChip: {
    fontWeight: "600",
    fontSize: "9px",
    backgroundColor: "#FFEEEE",
    color: "#DF4747",
    borderRadius: "38px",
    padding: "1px 8px",
  }
};