import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { T } from "../../hooks/useTranslate";

import { CircularProgress, Stack, Typography, Box } from "@mui/material";

import { OfficeBox } from "../../components/OfficeBox";
import { PartnersGenealogyRow } from "./PartnersGenealogyRow";
import { PartnersGenealogyMobileStats } from "./PartnersMobileComponents/PartnersGenealogyMobileStats";

import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

import { getCustomerHref } from "../../common/format-functions";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PartnersGenealogyRowSet = ({ rowArr, section, totalLength, viewportIsSmall, setMiddleExpanded }) => {
  // if the section is middle, show grey middle box with overflow number, else map arrRow
  // gray box has an onClick event that sets middleExpanded to true
  // middleExpanded = true hides the divided genealogy arrays and displays all rows in the original genealogyArray
  return section === "middle" ?
    <Stack
      onClick={() => setMiddleExpanded(true)}
      alignItems="center"
      pt={1.3} pb={1.3}
      bgcolor={"rgba(236, 241, 247, .44)"}
      mb={1}
      mt={1}
      sx={{
        cursor: "pointer"
      }}>
      <Typography sx={{
        fontWeight: "600",
        fontSize: "10px",
        color: "#93ABC9"
      }}>{`+${totalLength - 8} Partners`}</Typography>
    </Stack> :
    <Box pr={"20px"} pl={"20px"}>
      {
        rowArr.map((user, idx) => {
          const indexOffset = section === "bottom" ? totalLength - 4 : 0;
          const chipNumber = idx + indexOffset

          const firstName = user.humanName.fullName.split(", ")[1];

          // if row is first OR (row is first in bottomArray AND not displaying all records)
          const rowIsTop = idx === 0 || (idx === indexOffset && section !== "full");

          // if row is the last row displayed
          const rowIsSelected = chipNumber === totalLength - 1;

          return (
            <PartnersGenealogyRow
              key={idx}
              number={chipNumber}
              user={user}
              isTop={rowIsTop}
              name={user?.humanName?.fullName}
              isSelected={rowIsSelected}
              firstInitial={(firstName || "")[0]}
              viewportIsSmall={viewportIsSmall}
            />
          );
        })
      }
    </Box>
}

const PartnersGenealogySection = () => {
  const { distributorId } = useParams();

  const { sendRequest, clear } = useApi();

  const [genealogyArray, setGenealogyArray] = useState([]);

  const [topSectionArray, setTopSectionArray] = useState([]);
  const [middleSectionArray, setMiddleSectionArray] = useState([]);
  const [bottomSectionArray, setBottomSectionArray] = useState([]);

  const [middleExpanded, setMiddleExpanded] = useState(false);

  const { userInfo } = useAuth();

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const abortController = new AbortController();

    if (distributorId) {
      sendRequest({
        method: "get",
        endpoint: `${process.env.API_URL}/customers?unicity=${distributorId}`,
        addPrefix: false,
        abortController,
      }).then((hrefResponse) => {
        sendRequest({
          method: "get",
          endpoint: `${process.env.API_URL}/customers/${getCustomerHref(
            hrefResponse.data.items[0].href
          )}/upline?tree=sponsor`,
          addPrefix: false,
          abortController,
        }).then((uplineResponse) => {
          const customers = uplineResponse.data?.customers.map(
            (cust) => cust.customer
          );

          if (customers?.length > 0) {
            const reducedCustomers = customers.reduce((prev, curr) => {
              if (
                prev.length > 0 ||
                curr.id.unicity === userInfo.unicityId.toString()
              )
                prev.push(curr);

              return prev;
            }, []);

            setGenealogyArray(reducedCustomers);
          }
        });
      });
    }

    return () => {
      clear();
      setGenealogyArray([]);
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorId]);

  useEffect(() => {
    if (genealogyArray.length > 8) {
      setTopSectionArray(genealogyArray.slice(0, 4));
      setMiddleSectionArray(genealogyArray.slice(4, -4))
      setBottomSectionArray(genealogyArray.slice(-4));
    }
  }, [genealogyArray])

  if (genealogyArray.length === 0)
    return (
      <Stack justifyContent="center" alignItems="center" height={"100px"} mb={3}>
        <CircularProgress data-test-id="progress-bar-shown" />
      </Stack>
    );

  return (
    <OfficeBox
      data-test-id={`genealogy-section`}
      sx={{
        padding: viewportIsSmall ? "24px 0px" : "0px",
        borderTop: !viewportIsSmall && "0",
      }}
    >
      {viewportIsSmall && (
        <Typography
          data-test-id={`genealogy-header`}
          variant="h2"
          pr={"20px"}
          pl={"20px"}
          sx={{ mb: "20px" }}>
          <T>genealogy</T>
        </Typography>
      )}
      {
        genealogyArray.length < 9 || middleExpanded ?
          <PartnersGenealogyRowSet rowArr={genealogyArray} section={"full"} totalLength={genealogyArray.length} viewportIsSmall={viewportIsSmall} /> :
          <Box>
            <PartnersGenealogyRowSet rowArr={topSectionArray} section={"top"} totalLength={genealogyArray.length} viewportIsSmall={viewportIsSmall} />
            <PartnersGenealogyRowSet rowArr={middleSectionArray} section={"middle"} totalLength={genealogyArray.length} viewportIsSmall={viewportIsSmall} setMiddleExpanded={setMiddleExpanded} />
            <PartnersGenealogyRowSet rowArr={bottomSectionArray} section={"bottom"} totalLength={genealogyArray.length} viewportIsSmall={viewportIsSmall} />
          </Box>
      }
      {
        !viewportIsSmall && <PartnersGenealogyMobileStats />
      }
    </OfficeBox>
  );
};

export { PartnersGenealogySection };
