import { Box } from "@mui/material";
import { styled } from '@mui/system';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const SeverityBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'severity',
})(({ theme, severity }) => ({
  border: `1px solid ${theme.palette.severity[severity ?? 'default']}`,  
  // backgroundColor: `${severity === 'error' ? "rgb(255, 173, 145)" : "white"}`,
}));

const OfficeBox = ({ children, innerRef, ...props }) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <SeverityBox
      {...props}
      ref={innerRef}      
      backgroundColor={theme.palette.primary.stackBackground}
      borderRadius={viewportIsSmall ? "12px" : ""}
      boxShadow={"0px 1px 3px rgba(145, 158, 171, 0.1)"}
      color="rgba(21, 56, 98, 1)"
    >
      {children}
    </SeverityBox>
  );
};

export { OfficeBox };
