import { T } from "../../hooks/useTranslate";

import { Box, Typography } from "@mui/material";
import Flag from "react-world-flags";
import FlagIcon from "@mui/icons-material/Flag";

import {
  iconSizingStyle,
  gridTextStyle,
  gridChildLeftWrapper,
} from "./drawer-styles";

import { formatLocation } from "../../common/format-functions";


const ProfileLocationLine = ({ customerHrefData, drawerType }) => {
  return (
    <>
      <Box sx={gridChildLeftWrapper}>
        {customerHrefData.mainAddress?.country ? (
          <Flag
            code={`${customerHrefData.mainAddress?.country}`}
            style={{ height: 16, width: 16 }}
          />
        ) : (
          <FlagIcon sx={iconSizingStyle} />
        )}
      </Box>
      {
        true ?
          <Typography sx={gridTextStyle} data-testid={`${drawerType}-profile-location`}>
            {formatLocation({ city: customerHrefData.mainAddress.city, state: customerHrefData.mainAddress.state, country: customerHrefData.mainAddress.country })}
          </Typography> :
          <Typography sx={gridTextStyle} data-testid={`${drawerType}-profile-location`}>
            <T>not_available</T>
          </Typography>
      }
    </>
  )
}

export { ProfileLocationLine }