import React from "react";
import { Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import styles from "./ActionDashboardItemStyles.js";

const ActionDashboardItemContent = ({severity, children, sx}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      data-test-id={`backto-oldoffice-module`}
      style={styles.actionDashboardItemStack} 
      severity={severity}
      p={1}
      gap="1.5px"        
      flexDirection="row"      
      mr={!viewportIsSmall && 2}
      ml={!viewportIsSmall && 2}
      sx={{
        ...sx,
        boxShadow: "0px 1px 3px rgba(145, 158, 171, 0.1)",
        backgroundColor: theme.palette.severity[severity],
      }}
    >
      {children}        
    </Stack>      
  );
};

export { ActionDashboardItemContent };
