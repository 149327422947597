import React, { useState, useEffect } from "react";

import { Stack } from "@mui/material";
import { getTruncatedName } from "../../../common/helper-functions";

const PartnerNameContent = ({ member, cellWidth }) => {
  const { firstName, lastName } = member;
  const [truncateName, setTruncateName] = useState(false);

  const truncatedName = getTruncatedName(firstName, lastName, truncateName);

  // conditionally truncate name based on cell width
  useEffect(() => {
    cellWidth < 230
      ? setTruncateName(true)
      : setTruncateName(false);
    // eslint-disable-next-line
  }, [cellWidth]);

  return (
    <Stack
      sx={{ fontSize: "12px", lineHeight: "11px" }}
      direction="row"
      data-field="full-name-container"
      data-test-id="full-name-container"
    >
      <span
        style={{ fontWeight: 400 }}
        data-field="first-name"
        data-test-id="first-name"
      >
        {truncatedName.firstNameTruncated}
      </span>&nbsp;
      <span
        style={{ fontWeight: 600 }}
        data-field="last-name"
        data-test-id="last-name"
      >
        {truncatedName.lastNameTruncated}
      </span>
    </Stack>
  )
}

export { PartnerNameContent }