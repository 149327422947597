import React from "react";
import { Modal, Typography, Stack, Button, Divider } from "@mui/material";
import { T } from "../../../../hooks/useTranslate";

const SsnSaveModal = ({ open, handleModalClose, handleSaveAction, viewportIsSmall }) => {

  const handleModalCancel = () => {
    handleModalClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleModalCancel}
      aria-labelledby="save-ssn-modal"
    >
      <Stack sx={styles.modal}>
        <Typography
          variant="h2"
          mb={2}
          data-test-id="save-ssn-modal-header"
        >
          <T>are_you_sure</T>
        </Typography>
        <Typography
          mb={2}
          data-test-id="save-ssn-modal-header"
          sx={styles.text}
        >
          <T>ssn_tin_cannot_be_changed</T>
        </Typography>
        <Divider sx={{ marginTop: "1rem" }} />
        <Stack
          direction={viewportIsSmall ? "row" : "column-reverse"}
          justifyContent="flex-end"
          alignItems={viewportIsSmall ? "flex-end" : "normal"}
          gap={2}
          mt={3}
          data-test-id="save-button-section"
        >
          <Button
            data-test-id="cancel-button-ssn-modal"
            variant="contained"
            color="primary"
            sx={{
              ...styles.modalButton,
              flexGrow: !viewportIsSmall ? "1" : "",
              backgroundColor: "#FFF",
              color: "#153862",
              border: "1px solid rgba(145, 158, 171, 0.20)",
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
            onClick={handleModalClose}
          >
            <T>cancel</T>
          </Button>
          <Button
            data-test-id="save-button-ssn-modal"
            variant="contained"
            color="primary"
            sx={{
              ...styles.modalButton,
              flexGrow: !viewportIsSmall ? "1" : "",
              '&:hover': {
                opacity: "0.7",
                backgroundColor: "#009ECF"
              },
            }}
            onClick={handleSaveAction}
          >
            <T>submit</T>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: "250px",
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid rgba(145, 158, 171, 0.10)',
    boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: "8px",
    padding: "1.5rem",
    paddingBottom: "1.5rem"
  },
  modalButton: {
    padding: ".75rem 1rem",
    borderRadius: "8px",
    fontSize: ".75rem",
  },
  text: {
    color: "#153862",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.375rem"
  }
}

export { SsnSaveModal }