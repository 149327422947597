const getAvailabilityChipColors = (daysLeft) => {
  const colorObj = {
    green: {
      backgroundColor: "rgba(67, 192, 111, 0.1)",
      color: "rgba(67, 192, 111, 1)"
    },
    yellow: {
      backgroundColor: "rgba(237, 184, 51, 0.1)",
      color: "rgba(237, 184, 51, 1)"
    },
    orange: {
      backgroundColor: "rgba(255, 142, 81, 0.1)",
      color: "rgba(255, 142, 81, 1)"
    },
    red: {
      backgroundColor: "rgba(242, 99, 99, 0.11)",
      color: "rgba(242, 99, 99, 1)"
    },
    grey: {
      backgroundColor: "rgba(21, 56, 98, 0.06)",
      color: "rgba(21, 56, 98, 0.6)"
    }
  }

  let color;

  switch (true) {
    case daysLeft > 8:
      color = colorObj["green"]
      break;
    case daysLeft > 6:
      color = colorObj["yellow"]
      break;
    case daysLeft > 4:
      color = colorObj["orange"]
      break;
    case daysLeft > 0:
      color = colorObj["red"]
      break;
    default:
      color = colorObj["grey"]
  }

  return color
}

const FIRST_INITIAL_COLORS = {
  a: "#CFEEFC",
  b: "#CFE1FC",
  c: "#E1DEFF",
  d: "#F2D7F1",
  e: "#FBCBEB",
  f: "#FFC8C1",
  g: "#FFDEDE",
  h: "#FCE0CF",
  i: "#FFF2C5",
  j: "#F3F8BE",
  k: "#E4F2C6",
  l: "#99D8EC",
  m: "#99C4EC",
  n: "#D9F3DC",
  o: "#C3BDFF",
  p: "#C3EBE6",
  q: "#E9B9E7",
  r: "#EAEFF1",
  s: "#DD9BC7",
  t: "#F1EEEA",
  u: "#F49D97",
  v: "#F1F1E2",
  w: "#EFB7B7",
  x: "#E2EDE7",
  y: "#F7E4A1",
  z: "#EAE2ED",
};

const DEFAULT_AVATAR_COLOR = "#99D8EC";

export { getAvailabilityChipColors, FIRST_INITIAL_COLORS, DEFAULT_AVATAR_COLOR }