import React from "react";

import { Box, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CopyToClipboard } from "./CopyToClipboard";

const ReferralsCopyBox = ({ text }) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "rgba(145, 158, 171, 0.05)",
        border: "1px dashed rgba(145, 158, 171, 0.3)",
        borderRadius: "8px",
        padding: "13px 16px",
        minWidth: viewportIsSmall && 329,
        maxWidth: 329,
      }}
    >
      <Box sx={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
        <Typography sx={{ fontSize: "14px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{text}</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CopyToClipboard
          stringToCopy={text}
          sx={{
            height: "20px",
            width: "20px",
            marginRight: "5px",
            cursor: "pointer",
          }}
          iconId={`copy-${text}`}
        />
      </Box>
    </Box>
  );
};

export { ReferralsCopyBox };
