import React, { useContext } from "react";

import { Box, Stack, CircularProgress } from "@mui/material";

import { PartnersContext } from "../../../hooks/PartnersContext";
import { PartnersStatsContent } from "../PartnersStatsContent";


const PartnersStatsMobileContainer = () => {
  const { loading } = useContext(PartnersContext);

  if (loading)
    return (
      <Stack justifyContent="center" alignItems="center" height={"100px"} mb={3}>
        <CircularProgress data-test-id="progress-bar-shown" />
      </Stack>
    );

  return (
    <Box pl={2} pr={2}>
      <PartnersStatsContent isMobile={true} />
    </Box>
  )
}

export { PartnersStatsMobileContainer }