import { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { Typography } from "@mui/material";
import { formatDateByLocale } from "../../common/date-functions";
import { AllStatementsDownloadIcon } from "./AllStatementsDownloadIcon";

const { LanguageContext } = require("../../hooks/LanguageContext");

export const useColumns = ({ setLoading, currency }) => {
  const { currentLanguage } = useContext(LanguageContext);

  return [
    {
      field: 'period',
      headerName: 'month',
      flex: 1.5,
      minWidth: 120,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Typography variant="h4">
          {formatDateByLocale(currentLanguage, params.value, { month: 'long', year: 'numeric' })}
        </Typography>
      ),
      renderHeader: (params) => (
        <Typography variant="h4">
          <T>{params.colDef.headerName}</T>
        </Typography>
      )
    },
    {
      field: 'earnings',
      headerName: 'earnings',
      flex: 2,
      editable: false,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <Typography variant="h5">
          {new Intl.NumberFormat(currentLanguage, { style: 'currency', currency: currency ? currency : "USD" }).format(params.value)}
        </Typography>
      ),
      renderHeader: (params) => (
        <Typography variant="h4">
          <T>{params.colDef.headerName}</T>
        </Typography>
      )
    },
    {
      field: 'href',
      headerName: 'statement',
      flex: 2,
      editable: false,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <AllStatementsDownloadIcon params={params} setLoading={setLoading} />
      ),
      renderHeader: () => (
        <></>
      )
    },
  ];
};