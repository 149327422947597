import React, { useContext, useEffect } from "react"
import { T } from "../../hooks/useTranslate";

import { ActionHubContext } from "../../hooks/ActionHubContext"
import "./DataGridComponents/ActionHubDataGrid.css"

import { Box } from "@mui/material"
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { CollapsibleTable } from "./DataGridComponents/CollapsibleTable"

import { OfficePage } from "../../components/OfficePage"
import { OfficeBox } from "../../components/OfficeBox";
import { ActionHubAlert } from "../../components/ActionHubAlert";
import { ActionHubPagination } from "./DataGridComponents/ActionHubPagination";
import { MemberCell } from "./DataGridComponents/MemberCell";
import { ReferralHeaderCell } from "./DataGridComponents/ReferralHeaderCell";
import { ActionHubNoRowsOverlay } from "./DataGridComponents/ActionHubNoRowsOverlay";

import { ContactCell } from "./DataGridComponents/ContactCell";
import { MemberContextProvider } from "../../hooks/MemberContext";
import { ReferredByCell } from "./DataGridComponents/ReferredByCell";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormatDate } from "../../components/FormatDate";
import { useNavigate } from "react-router-dom";

const headerTextStyle = { fontSize: "12px", fontWeight: "600" };

const columns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 0.75,
    minWidth: 200,
    renderCell: (params) => (
      <MemberCell params={params.row} eventLocation="action_hub_new_members" />
    ),
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`name-column-header`}><T>name</T></Box>
  },
  {
    field: 'sponsorName',
    headerName: 'Referred By',
    align: "right",
    headerAlign: "right",
    width: 180,
    renderCell: (params) => (
      <ReferredByCell params={params.row} />
    ),
    renderHeader: () => (
      <ReferralHeaderCell />
    ),
  },
  {
    field: "firstFeelGreatOrderDate",
    headerName: "First Order Date",
    align: "right",
    headerAlign: "right",
    width: 200,
    renderCell: (params) => <FormatDate dateString={params.row.firstFeelGreatOrderDate} fontSizeValue={"12px"} />,
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`first-order-date-column-header`}><T>first_order_date</T></Box>
  },
  {
    field: 'contact',
    headerName: 'Contact',
    headerAlign: "right",
    align: "right",
    width: 130,
    sortable: false,
    renderCell: (params) => (
      <ContactCell params={params.row} screen="new_feel_great" />
    ),
    renderHeader: () => <Box sx={headerTextStyle} data-test-id={`contact-column-header`}><T>contact</T></Box>
  },
];

const NewFeelGreatMembersScreen = () => {
  const { feelGreatMembers,
    feelGreatCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    loading,
    feelGreatSortModel,
    setFeelGreatSortModel,
    resetContext
  } = useContext(ActionHubContext);

  const apiRef = useGridApiRef();

  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  const handleSortModelChange = (sortModel) => {
    setFeelGreatSortModel(sortModel);
  }

  // When action hub section mounts, clear page state from previous action hub section
  useEffect(() => {
    resetContext();
    // eslint-disable-next-line
  }, []);

  const handleBackArrowClick = () => {
    return navigate("/actionhub", { replace: true })
  }

  return (
    <OfficePage pageTitle="new_feel_great_members" showBackArrow={true} onBackArrowClick={handleBackArrowClick}>
      <MemberContextProvider>
        <OfficeBox>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Box sx={{ padding: "24px" }}>
              <ActionHubAlert
                color="rgba(21, 56, 98, 1)"
                backgroundColor="rgba(0, 158, 207, 0.05)"
                border="1px solid rgba(0, 158, 207, 0.15)"
                boldText="new_feel_great_alert_bold"
                text="new_feel_great_alert_not_bold"
              />
            </Box>
            <Box id="ActionHubDataGridWrapper" sx={{
              flexGrow: 1,
              height: "70vh",
              minHeight: "315px",
              width: "100%"
            }}>
              {
                viewportIsMedium ?
                  <DataGridPro
                    disableColumnMenu
                    apiRef={apiRef}
                    sx={{ border: 0 }}
                    rows={feelGreatMembers}
                    rowCount={feelGreatCount}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={setPage}
                    columns={columns}
                    disableRowSelectionOnClick
                    hideFooterSelectedRowCount
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[10, 50, 100]}
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={feelGreatSortModel}
                    onSortModelChange={handleSortModelChange}
                    sortingOrder={['desc', 'asc']}
                    pagination
                    loading={loading}
                    slots={{
                      pagination: ActionHubPagination,
                      noRowsOverlay: ActionHubNoRowsOverlay
                    }}
                    slotProps={{
                      pagination: {
                        page: page,
                        pageSize: pageSize,
                        rowCount: feelGreatCount,
                        handlePageSizeChange: setPageSize,
                        loading: loading,
                        handlePageChange: setPage,
                        apiRef: apiRef,
                      },
                    }}
                  /> :
                  <CollapsibleTable
                    rows={feelGreatMembers}
                    totalRowCount={feelGreatCount}
                    loading={loading}
                    tableType={"newfeelgreat"}
                    screen="new_feel_great"
                  />
              }
            </Box>
          </Box>
        </OfficeBox>
      </MemberContextProvider>
    </OfficePage>
  )
}

export { NewFeelGreatMembersScreen }

