import { formatState } from "./format-state";
import { staticCountries } from "./countries";

//format a phone number to US standard
//see: https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";

    return [intlCode, match[2], " ", match[3], "-", match[4]].join("");
  }

  return phoneNumberString;
};

const formatSubscriptionDate = (dateString) => {
  const dateObj = new Date(dateString);

  const month = dateObj.toLocaleDateString("default", { month: "short" });
  const day = dateObj.getDate() + 1;

  return `${month} ${day}`
}

// https://www.w3docs.com/snippets/javascript/how-to-convert-string-to-title-case-with-javascript.html
function toTitleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

function toUpperCase(str) {
  return str.split(" ").map(word => word.toUpperCase()).join(" ");
}

function decodeString(str) {
  var txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
}

function formatInitial(name) {
  if (name.includes(", ")) {
    return name.split(", ")[1][0]
  } return name[0]
}

function formatName(name) {
  if (name.includes(", ")) {
    const splitName = name.split(", ");
    return `${splitName[1]} ${splitName[0]}`
  } return name
}

function getCustomerHref(fullHref) {
  const fullHrefSplit = fullHref.split("/");
  return fullHrefSplit[fullHrefSplit.length - 1]
}

const formatLocation = (params) => {
  const { city, state, country } = params;

  let formattedLocation = [];

  const currCountryObj = staticCountries.filter((item) => {
    return item.code === country
  })

  // format city to title case
  city && formattedLocation.push(toTitleCase(city));

  // if state can be formatted using formatState, use that, if not use unformatted. if no state, skip to country
  const stateCanBeFormatted = state && formatState(state, "name");

  state && formattedLocation.push(stateCanBeFormatted ? formatState(state, "name") : state);

  // if country obj is in static countries, push the formatted country name, otherwise push the country value
  const countryFormatted = currCountryObj.length > 0 ? currCountryObj[0].country : country;

  formattedLocation.push(toTitleCase(countryFormatted));


  return formattedLocation.join(", ")
}

// function to split languages into left and right columns
function splitArray(arr) {
  let rightColumn = [],
    leftColumn = [];
  for (let i = 0; i < arr.length; i++)
    (i % 2 === 0 ? leftColumn : rightColumn).push(arr[i]);
  return [leftColumn, rightColumn];
}

function transformRanks(data) {
  return data.map(item => {
    const metrics = item.metrics.map(metric => {
      return {
        name: metric.name,
        value: metric.value,
        requiredValue: metric.requiredValue,
        qualified: metric.qualified
      };
    });

    return {
      rank: item.achievement.rankShort,
      metrics: metrics
    };
  });
}

function deepEqual(objA, objB) {
  if (objA === objB) return true;

  if (typeof objA !== 'object' || typeof objB !== 'object') return false;

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(objA[key], objB[key])) {
      return false;
    }
  }

  return true;
}



export { formatPhoneNumber, formatSubscriptionDate, toTitleCase, decodeString, formatInitial, formatName, getCustomerHref, formatLocation, toUpperCase, splitArray, transformRanks, deepEqual };
