/**
 * Util helper to set cookies
 * @param {*} name 
 * @param {*} value 
 * @param {*} days 
 */
const setCookie = (name, domain, value, expiration = null) => {
  document.cookie = !expiration
      ? `${name}=${value}; path=/; domain=${domain}`
      : `${name}=${value}; expires=${expiration}; path=/; domain=${domain}`
}

/**
 * Function to get cookie by name
 * @param {name} name - cookie name
 * @returns string | null
 */
const getCookie = (name) => {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split('; ');
  
  for (let cookie of cookieArray) {
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}

/**
 * Function to delete cookie by name
 * @param {name} name - cookie name
 * @returns void
 */
const removeCookie = name => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export {
  setCookie,
  getCookie,
  removeCookie
}