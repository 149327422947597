import React, { useContext } from 'react'
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { LanguageContext } from "../../hooks/LanguageContext";

const MobileHeader = ({ children, ...rest }) => {
  const { languageDir } = useContext(LanguageContext);
  const theme = useTheme();
  const viewPortIsSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box 
      pl={((viewPortIsSmall || (!viewPortIsSmall && !viewportIsLarge && !viewportIsMedium)) && languageDir === 'rtl') && 3}
      { ...rest }
    >
      { children }
    </Box>
  )
}

export default MobileHeader
