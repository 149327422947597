import React from "react";
import { T } from "../../../../hooks/useTranslate";
import { getAvailabilityChipColors } from "../../../../common/get-colors";

import { Box, Typography } from "@mui/material";
const { DateTime } = require("luxon");

const CollapsibleAvailability = ({ row }) => {
  const now = DateTime.now();
  const joinDate = DateTime.fromISO(row.joinDate);

  // calculate days left until 14 days joined
  const diff = joinDate.diff(now, ["days"]);
  const daysLeft = Math.ceil(diff.values.days) + 14

  const chipColor = getAvailabilityChipColors(daysLeft);

  return (
    <Box sx={{ flex: 1, backgroundColor: "rgba(21, 56, 98, 0.05);", borderRadius: "8px", padding: "12px" }}>
      <Typography sx={{ color: "#153862", opacity: ".6", fontSize: "11px" }}><T>availability</T></Typography>
      <Box sx={{
        display: "inline",
        fontWeight: "600",
        fontSize: "12px",
        backgroundColor: chipColor.backgroundColor,
        color: chipColor.color,
        padding: "2px 12px 2px 12px",
        borderRadius: "12px"
      }}>
        {`${daysLeft} `}<T>days_left</T>
      </Box>
    </Box>
  )
}

export { CollapsibleAvailability }