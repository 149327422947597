import React from "react";
import { useMixPanel } from "../../hooks/useMixPanel";
import { T } from "../../hooks/useTranslate";

import { Link } from "@mui/material";

const ForgotPasswordLink = ({ styles }) => {
  const { logEvent } = useMixPanel();

  const handleClick = () => {
    logEvent("forgot_password_link_clicked");
  }

  return (
    <Link
      id="forgotPasswordLink"
      data-test-id="forgot-password-link"
      rel="noreferrer"
      target="_blank"
      href={process.env.REACT_APP_PWRESET_URL}
      sx={styles}
      onClick={handleClick}
    >
      <T>forgot_password</T>
    </Link>
  )
}

export { ForgotPasswordLink }