import { T } from "../../hooks/useTranslate";
import React from "react";
import Flag from "react-world-flags";
import FlagIcon from "@mui/icons-material/Flag";
import { TextField, InputAdornment } from "@mui/material";
import styles from "./settingsScreenStyles";

const SettingsTextField = ({
  name,
  label,
  isLocked,
  isCountry,
  countryCode,
  content,
  formik,
  maxLength,
  translateErrors
}) => {
  const handleChange = (e) => {
    formik.handleChange(e);
  }

  return (
    <TextField
      data-test-id={`${name}-text-field`}
      inputProps={{
        "data-test-id": `${name}-input-tag`,
        maxLength: maxLength || null,
      }}
      InputLabelProps={{
        "data-test-id": `${name}-input-label`,
        style: { ...styles.labelStyle },
      }}
      fullWidth
      disabled={isLocked}
      label={label}
      name={name}
      value={content || (formik && formik.values[name]) || ""}
      onChange={handleChange}
      FormHelperTextProps={{
        "data-test-id": `${name}-helper-text`,
      }}
      helperText={
        translateErrors && formik && formik.errors.hasOwnProperty(name) ? <T>{formik.errors[name]}</T> :
          formik && formik.errors[name]
      }
      error={formik && formik.errors.hasOwnProperty(name)}
      sx={{
        ...styles.textField,
        ...(isLocked ? styles.lockedTextField : {}),
      }}
      InputProps={{
        endAdornment: isLocked && (
          <InputAdornment position="end">
            <img src="icons/lock-icon.svg" alt="lock icon" height="16px" width="16px" />
          </InputAdornment>
        ),
        startAdornment: isCountry && (
          countryCode ? (
            <Flag
              code={countryCode}
              style={styles.reactFlagIcon}
            />
          ) : (
            <FlagIcon style={styles.defaultFlagIcon} />
          )
        ),
      }}
    />
  );
};

export { SettingsTextField };
