import { useState, useEffect, useContext } from "react";
import { useAuth } from "../hooks/useAuth";
import { useApi } from "../hooks/useApi";
import { LanguageContext } from "../hooks/LanguageContext";

const useNews = () => {
  const { API_URL } = process.env;
  const { userInfo } = useAuth();
  const { currentLanguage } = useContext(LanguageContext);
  const { sendRequestAsCallback } = useApi();

  const selectedLanguage = currentLanguage.split("-")[0];
  const userMarket = userInfo.countryCode;
  const currentLanguageLocale = `${selectedLanguage}-${userMarket}`;

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    sendRequestAsCallback({
      method: 'GET',
      endpoint: `drawers/${userInfo.countryCode}%20Dashboard`,
      acceptLanguage: currentLanguageLocale
    })
      .then((response) => {
        const news = response.data;
        news.cards = news.cards.map((card, idx) => {
          card.id = idx;
          return card;
        });

        // sort by updated date
        news.cards.sort((a, b) => new Date(b.updated) - new Date(a.updated));

        setNews(news?.cards);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [API_URL, userInfo, sendRequestAsCallback, currentLanguageLocale]);

  return { news, loading, error };
};

export { useNews };