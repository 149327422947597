import React from "react";
import { T } from "../../../../hooks/useTranslate";
import { useLocation, useNavigate } from "react-router-dom"

import { Box, Stack, Link, Typography } from "@mui/material"

const CollapsibleOrders = ({ row }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleOrdersClick = () => {
    navigate(`${location.pathname}?drawer=member&id=${row.unicityId}&drawerContent=orders`, { state: { location: location, } });
  }

  return (
    <Box sx={{ flex: 1, backgroundColor: "rgba(21, 56, 98, 0.05);", borderRadius: "8px", padding: "12px" }}>
      <Stack direction="row" spacing={.3}>
        <Typography sx={{ color: "#153862", opacity: ".6", fontSize: "11px", display: "inline" }}><T>orders</T></Typography>
      </Stack>
      <Link onClick={handleOrdersClick} sx={{ cursor: "pointer" }}>
        <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>{row.orders}</Typography>
      </Link>
    </Box>
  )
}

export { CollapsibleOrders }