import React from "react";
import { T } from "../../../hooks/useTranslate";
import { Box } from "@mui/material";

const PartnersHeaderCell = ({ headerTextStyle, text, testId, sortValue }) => {

  return (
    <Box
      data-test-id={testId}
      sx={headerTextStyle}
    >
      <T>{text}</T>
    </Box>
  )
}

export { PartnersHeaderCell }