import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMixPanel } from "../../hooks/useMixPanel";

import { Stack, Typography } from "@mui/material";
import { OfficeAvatar } from "../OfficeAvatar";
import { RankChip } from "../RankChip";
import { styles } from "../../screens/PartnersScreen/partners.styles";
import Chip from "components/Chip";

const FIELDS = [
  { key: "unicityId", label: "UID", isMatched: false },
  { key: "email", label: "Email", isMatched: false },
  { key: "homePhone", label: "Phone", isMatched: false }
]

const HighlightedField = ({ value, highlight }) => {
  const parts = value.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span>
      {parts.map((part, i) => (
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={i} style={{ fontWeight: styles.searchBar.highlightWeight }}>
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        )
      ))}
    </span>
  )
}

const SearchSuggestionItem = ({
  item,
  type,
  searchTerm,
  setOpen,
  leg1UnicityId,
  leg2UnicityId
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const isNameMatched = item?.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
  const matchedFields = FIELDS.map(field => ({ ...field, isMatched: item[field.key] && item[field.key].toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 }))

  const firstInitial = item.name?.charAt(0);

  const handleItemClick = () => {
    setOpen(false);
    logEvent("open_profile", {
      user_type: `${type}`,
      location: `${type}_search_suggested`,
    })

    localStorage.setItem("selectedMemberContext", JSON.stringify(item));
    // open partner or member drawer
    navigate(`${location.pathname}?drawer=${type === "partners" ? "partner" : "member"}&id=${item.unicityId}&drawerContent=profile`)
  }

  // if rank is 2 (not-defined for partner) or undefined, set it to 1 (Distributor)
  const getCorrectRankId = (item) => {
    const rankId = item.previousPaidRank
    if (item.type === "member") {
      return 0
    } else {
      return (!rankId || rankId === 2) ? 1 : rankId
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      onClick={handleItemClick}
      sx={{
        "&:hover": {
          cursor: "pointer",
          backgroundColor: "#f5f5f5"
        },
        borderRadius: 2,
        padding: 1
      }}
    >
      <OfficeAvatar
        firstInitial={firstInitial}
      />
      <Stack direction="column">
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
        >
          {isNameMatched ? (
            <Typography variant="h5"><HighlightedField value={item.name} highlight={searchTerm} /></Typography>
          ) : (
            <Typography variant="h5">{item.name}</Typography>
          )}
          <RankChip
            rankId={getCorrectRankId(item)}
            styles={styles.rankChip}
          />
          {
            item?.unicityId?.trim() === leg1UnicityId?.trim() && (
              <Chip
                translationKey="leg"
                customTextStyles={{
                  color: "#43C06F"
                }}
                customChipStyles={{
                  padding: "1px 6px",
                  background: "rgba(67, 192, 111, 0.16)"
                }}
              >
                &nbsp;1
              </Chip>
            )
          }
          {
            item?.unicityId?.trim() === leg2UnicityId?.trim() && (
              <Chip
                translationKey="leg"
                customTextStyles={{
                  color: "#009ECF"
                }}
                customChipStyles={{
                  padding: "1px 6px",
                  background: "rgba(0, 158, 207, 0.16)"
                }}
              >
                &nbsp;2
              </Chip>
            )
          }
        </Stack>
        {matchedFields.map(field => {
          const { key, label, isMatched } = field
          return (isMatched ? (
            <Typography key={key} variant="h5" sx={styles.searchBar.text}>{label}:&nbsp;<HighlightedField value={item[key]} highlight={searchTerm} /></Typography>
          ) : null)
        })}
      </Stack>
    </Stack >
  )
}

export { SearchSuggestionItem }