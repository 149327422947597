import { useEffect, useState } from "react";
import dayjs from 'dayjs';

import {
  Box,
  Divider,
  Menu,
  Button,
  FormControl,
  RadioGroup,
} from "@mui/material";

import { T } from "../../hooks/useTranslate";

import { styles } from "../../themes/datagrid.styles";
import { useMixPanel } from "../../hooks/useMixPanel";

import { FilterRadioButton } from "./FilterRadioButton";
import { getPurchasePeriodFromFilterModel } from "../../common/date-functions";
import { DateRangePickerComponent } from "./DateRangePickerComponent";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PurchasePeriodMenu = ({
  filterModel,
  setFilterModel,
  open,
  anchorEl,
  handleClose
}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const [selected, setSelected] = useState('all-time');
  const { logEvent } = useMixPanel();

  const [dateRangePickerValue, setDateRangePickerValue] = useState([
    dayjs().subtract(1, 'day'),
    dayjs(),
  ]);

  useEffect(() => {
    setSelected(checkFilterStatus(filterModel));
  }, [filterModel])

  const handleMenuClose = () => {
    // when the menu closes, reset the current filter based on the filterModel
    setSelected(checkFilterStatus(filterModel));
    handleClose();
  }

  const handleApplyFilterClick = () => {
    // remove current subscription filter from filterModel before reapplying
    const newFilterModel = filterModel.filter(obj => !obj.hasOwnProperty('purchasePeriodStartDate') && !obj.hasOwnProperty('purchasePeriodEndDate'))

    function formatDate(date) {
      return date.toISOString().split('T')[0];
    }

    const today = new Date();
    const formattedToday = formatDate(today);

    const dateRanges = {
      'last-seven-days': 7,
      'last-thirty-days': 30,
      'last-ninety-days': 90,
      'last-365-days': 365
    };

    const currentRange = dateRanges[selected];
    const startDate = new Date(today);

    if (currentRange) {
      startDate.setDate(today.getDate() - currentRange);
      newFilterModel.push({ purchasePeriodStartDate: formatDate(startDate) });
      newFilterModel.push({ purchasePeriodEndDate: formattedToday });
    } else if (selected === 'custom-range') {
      newFilterModel.push({ purchasePeriodStartDate: formatDate(dateRangePickerValue[0]) });
      newFilterModel.push({ purchasePeriodEndDate: formatDate(dateRangePickerValue[1]) });
    }

    setFilterModel(newFilterModel);
    logEvent("members_filter_change", {
      location: 'members',
      filterModel: newFilterModel
    })
    handleClose();
  }

  const changeFilterSelection = (event) => {
    setSelected(event.target.value);
  }

  // check the filterModel to see if any subscription filters are present
  const checkFilterStatus = (model) => {
    return getPurchasePeriodFromFilterModel(model);
  }

  return (
    <Menu
      id="purchase-period-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      MenuListProps={{
        "aria-labelledby": "purchase-period-button",
      }}
      PaperProps={{
        sx: {
          ...styles.filterMenuPaperProps,
          width: viewportIsSmall ? 280 : "100%",
        },
      }}
    >
      <form id="purchase-period-filter-form">
        <Box sx={{ padding: "16px 24px 0px 24px" }}>
          <Box sx={styles.filterMenuHeader}>
            <T isUpperCase={true}>filter</T>
          </Box>
          <Box mb={2}>
            <FormControl>
              <Box sx={styles.filterMenuHeaderContainer}>
                <T>purchase_period</T>
              </Box>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                defaultValue="all-time"
                id="radioGroupPurchasePeriodDateRange"
                data-test-id={`purchase-period-menu`}
                value={selected}
                onChange={changeFilterSelection}
              >
                <FilterRadioButton
                  id="all-time"
                  value="all-time"
                  text="all_time"
                />
                <FilterRadioButton
                  id="last-seven-days"
                  value="last-seven-days"
                  text="last_seven_days"
                />
                <FilterRadioButton
                  id="last-thirty-days"
                  value="last-thirty-days"
                  text="last_thirty_days"
                />
                <FilterRadioButton
                  id="last-ninety-days"
                  value="last-ninety-days"
                  text="last_ninety_days"
                />
                <FilterRadioButton
                  id="last-365-days"
                  value="last-365-days"
                  text="last_year"
                />
                <FilterRadioButton
                  id="custom-range"
                  value="custom-range"
                  text="custom_range"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        {selected === 'custom-range' && (
          <DateRangePickerComponent dateRangePickerValue={dateRangePickerValue} setDateRangePickerValue={setDateRangePickerValue} />
        )}
        <Divider sx={{ marginTop: "14px" }} />
        <Box sx={styles.filterMenuButtonContainer}>
          <Button
            id="apply-filter-button"
            aria-label="apply filter"
            sx={styles.filterApplyButton}
            onClick={handleApplyFilterClick}
          >
            <T>apply_filter</T>
          </Button>
          <Button
            onClick={handleMenuClose}
            id="cancel-button"
            aria-label="cancel filter"
            sx={styles.filterCancelButton}
          >
            <T>cancel</T>
          </Button>
        </Box>
      </form>
    </Menu>
  );
};

export { PurchasePeriodMenu };
