import React from 'react'

import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Typography, Link } from "@mui/material"

import { useAuth } from '../../../hooks/useAuth';
import { useMixPanel } from '../../../hooks/useMixPanel';

const ReferredByCell = ({ params }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useAuth();
  const { logEvent } = useMixPanel();

  const handleClick = () => {
    logEvent("open_profile", {
      location: "action_hub_new_members_referred_by_cell",
      user_type: "member"
    })
    navigate(`${location.pathname}?drawer=member&id=${params.sponsorUnicityId}&drawerContent=profile`, { state: { location: location, } });
  }

  const showReferrer = () => {
    return userInfo.unicityId !== parseInt(params.sponsorUnicityId)
  }

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "12px",
          cursor: showReferrer() && "pointer",
          "&:hover": {
            textDecoration: showReferrer() && "underline"
          }
        }}>
        {
          showReferrer() ?
            <Link onClick={handleClick}>{params.sponsorName}</Link> : "-"

        }
      </Typography>
    </Box>
  )
}

export { ReferredByCell }