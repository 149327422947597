import { T } from "../../../hooks/useTranslate";

import { Typography, Stack } from "@mui/material";
import { OvToNextRankHelpIcon } from "./OvToNextRankHelpIcon";

const OvToNextRankHeaderCell = () => {
  return (
    <Stack
      direction="row"
      spacing="4px"
      data-test-id={`ov-to-next-rank-column-header`}
    >
      <Stack justifyContent="center">
        <Typography variant="h4"><T>ov_to_next_rank</T></Typography>
      </Stack>
      <Stack>
        <OvToNextRankHelpIcon
          text1={"what_is_next_rank"}
          text2={"next_rank_is"}
          text3={"learn_more"}
          externalLink={"https://unicitystatic.s3.amazonaws.com/materials/Unicity_Comp_Plan_2022_js_v6.pdf"}
        />
      </Stack>
    </Stack>
  )
}

export { OvToNextRankHeaderCell }
