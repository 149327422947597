import React, { useContext } from "react";
import { List, Stack, CircularProgress, Typography } from "@mui/material"

import { ActivityFeedItem } from "../../../components/activityFeed/ActivityFeedItem";
import { ActivityFeedContext } from "../../../hooks/ActivityFeedContext";
import { useMixPanel } from "hooks/useMixPanel";

function RecentActivityList() {
  const { activities, loading } = useContext(ActivityFeedContext);
  const [wasScrolled, setWasScrolled] = React.useState(false);

  const { logEvent } = useMixPanel();

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
        '& ul': { padding: 0 },
      }}
      onScroll={(e) => {
        if (!wasScrolled) {
          logEvent('dashboard_recent_activity_scroll');
          setWasScrolled(true);
        }
      }}
    >
      <ul>
        {
          !loading ?
            <Stack spacing={2}>
              {
                activities.length > 0 &&
                activities.map((item, idx) => {
                  return <ActivityFeedItem
                    props={item}
                    key={idx}
                    isLastItem={idx + 1 === activities.length}
                    isDashboard={true} />
                })
              }
            </Stack> :
            <Stack mt={10} mb={10} alignItems="center" justifyContent="center"><CircularProgress data-test-id="progress-bar-shown" /></Stack>
        }
        {
          !loading && activities.length === 0 &&
          <Typography sx={{ padding: '0em 2em 1em 2em' }}>There are no activities to display.</Typography>
        }
      </ul>
    </List>
  );
}

export { RecentActivityList }