import React, { useContext } from "react";

import { Box } from "@mui/material";
import { LanguageContext } from "../../hooks/LanguageContext";


const TotalOvCell = ({ params }) => {
  const { localizeNumber } = useContext(LanguageContext);
  return (
    <Box data-test-id={`total-ov-value-row-${params.id}`}>
      {localizeNumber(params.totalOv)}
    </Box>
  )
}

export { TotalOvCell }