import React, { useContext } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "@mui/material";
import { LanguageContext } from "../hooks/LanguageContext"; 

const ReferralsCell = ({ params:member }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { localizeNumber } = useContext(LanguageContext);

  const handleClick = () => {
    navigate(`${location.pathname}?drawer=member&id=${member.unicityId}&drawerContent=referrals`, { state: location });
  };

  if (member.referrals === 0) return <div>{member.referrals}</div>;

  const localizedReferrals = localizeNumber(member.referrals);

  return (
    <Link
      onClick={handleClick}
      sx={{
        cursor: "pointer"
      }}
    >
      {localizedReferrals}
    </Link>
  )
};

export { ReferralsCell };
