import React from "react";
import { T } from "../../../hooks/useTranslate";

import { Button } from "@mui/material";

const ResetFormButton = ({
  styles,
  handleFormReset
}) => {
  return (
    <Button
      data-test-id="discard-changes-button"
      onClick={handleFormReset}
      sx={{
        ...styles.button,
        ...(styles.resetButton),
        "&.Mui-disabled": {
          color: "#fff"
        }
      }}
    >
      <T>reset</T>
    </Button>
  )
}

export { ResetFormButton }