import React, { useContext, useEffect, useState } from "react";
import { Stack, Box, Typography, Divider } from "@mui/material";

import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import { useConfig } from "../../hooks/useConfig";
import { useCurrencyFormatter } from "../../hooks/useCurrencyFormatter";
import { LanguageContext } from "../../hooks/LanguageContext";

import { OfficePage } from "../../components/OfficePage";
import { OfficeBox } from "../../components/OfficeBox";
import { LoadingOverlay } from "./LoadingOverlay";

import { getMonthRange, getMonthsAgo } from "../../common/date-functions";

import { EarningsMonthSelect } from "./EarningsMonthSelect";
import { EarningsRows } from "./EarningsRows";
import { EmptyStateMessage } from "../../components/EmptyState";
import { EarningsStatementButton } from "./EarningsStatementButton";
import { useTranslateKey } from "../../hooks/useTranslate";
import { EarningsScreenBottomLink } from "./EarningsScreenBottomLink";
import { formatMidOfNextMonthAsFullString } from "../../common/date-functions";

const EarningsScreen = () => {
  const { userInfo } = useAuth();
  const { sendRequestAsCallback: fetchSummaryData, status: fetchSummaryStatus, response: fetchSummaryRes } = useApi();
  const { sendRequestAsCallback: fetchStatementsData, status: fetchStatementsStatus, response: fetchStatementsRes } = useApi();
  const { currentLanguage } = useContext(LanguageContext);
  const { data: configData } = useConfig("countryLists/showWeeklyEarnings.json");

  const [displayedCurrency, setDisplayedCurrency] = useState("USD");
  const [selectedMonth, setSelectedMonth] = useState({ label: "", value: "" });
  const formatCurrency = useCurrencyFormatter(currentLanguage, displayedCurrency);

  // translatations for props
  const monthName = selectedMonth ? selectedMonth.label.split(" ")[0].toLowerCase() : "";
  const monthNameTranslated = useTranslateKey(monthName);
  const earningsTranslated = useTranslateKey("earnings");
  const totalTranslated = useTranslateKey("total");
  const paidTranslated = useTranslateKey("paid");
  const processingFeesTranslated = useTranslateKey("processing_fees");
  const weekTranslated = useTranslateKey("week");
  const monthEndTranslated = useTranslateKey("month_end");
  const statementTranslated = useTranslateKey("statement");

  const [monthOptions, setMonthOptions] = useState([]);
  const [earningsSummary, setEarningsSummary] = useState([]);
  const [, setIsTotalPaidLabelShown] = useState(false);
  const [, setIsDownloadStatementLabelShown] = useState(false);
  // eslint-disable-next-line
  const [currentMonthStatement, setCurrentMonthStatement] = useState({});
  const [monthEndReport, setMonthEndReport] = useState({});

  // show/hide weekly reports by country
  const showWeeklyCountriesList = configData?.data?.showWeeklyEarnings;
  const showWeekly = showWeeklyCountriesList ? showWeeklyCountriesList.includes(userInfo.countryCode) : null;

  // loading backdrop state
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  const handleClose = () => {
    setShowLoadingOverlay(false);
  };
  const handleOpen = () => {
    setShowLoadingOverlay(true);
  };

  useEffect(() => {
    // if user joined less than 6 months ago, get month range using join date, otherwise, show 6 months
    const monthsSinceJoined = getMonthsAgo(userInfo.joinDate) + 1;
    const months = (monthsSinceJoined > 0 && monthsSinceJoined < 6) ? getMonthRange(monthsSinceJoined) : getMonthRange(6);

    setSelectedMonth(months[0]);
    setMonthOptions(months);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    if (selectedMonth) {
      fetchSummaryData({
        method: "get",
        endpoint: `customers/me/officeCommissionSummaryByPeriod?date=${selectedMonth.value}`,
        abortController
      });
    }

    return () => {
      abortController.abort();
    };
  }, [selectedMonth, fetchSummaryData]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchStatementsData({
      method: "get",
      endpoint: `customers/me/commissionstatements`,
      abortController
    });

    return () => {
      abortController.abort();
    };
  }, [fetchStatementsData]);

  useEffect(() => {
    if (fetchSummaryStatus === "wait") {
      handleOpen();
    } else {
      handleClose();
    }

    if (fetchSummaryRes.status === 200) {
      const listOfCurrencies = fetchSummaryRes.data.map(item => item.CurrencyCode).filter(item => item !== null);

      // if all the currencies that come back are the same once the nulls are stripped, then we set the currency code for the report
      if (areAllElementsSame(listOfCurrencies) && listOfCurrencies.length > 0) {
        setDisplayedCurrency(listOfCurrencies[0]);
      }

      setEarningsSummary(fetchSummaryRes.data);
    }
  }, [fetchSummaryStatus, fetchSummaryRes, selectedMonth.value])

  useEffect(() => {
    if (fetchStatementsStatus === "wait") {
      handleOpen();
    } else {
      handleClose();
    }

    if (fetchStatementsRes.status === 200) {
      try {
        const currentStatement = fetchStatementsRes.data?.items.filter(item => item.period === selectedMonth.value && item.type === "Monthly")[0];

        setCurrentMonthStatement(currentStatement)
      } catch (err) {
        console.error(err);
      }
    }
  }, [fetchStatementsStatus, fetchStatementsRes, selectedMonth])

  useEffect(() => {
    const monthlyReport = earningsSummary?.filter(item => item["Commission Type"] === "Monthly")[0];
    setMonthEndReport(monthlyReport)
    let date = new Date(monthlyReport?.StartDate);
    let today = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(15);
    // for total paid label
    if (today < date && monthlyReport?.earning === ".0000") {
      setIsTotalPaidLabelShown(true);
    } else {
      setIsTotalPaidLabelShown(false);
    }
    // for download statement label
    if (monthlyReport?.earning !== ".0000") {
      if (currentMonthStatement?.href) {
        setIsDownloadStatementLabelShown(true);
      } else {
        setIsDownloadStatementLabelShown(false);
      }
    } else if (monthlyReport?.earning === ".0000") {
      if (today >= date) {
        setIsDownloadStatementLabelShown(false)
      } else {
        setIsDownloadStatementLabelShown(true)
      }
    }
  }, [currentMonthStatement, earningsSummary, selectedMonth.value])

  const handleChange = (event) => {
    const month = monthOptions.find(m => m.value === event.target.value);
    setSelectedMonth(month);
  };

  function addFees(earningsArray) {
    return earningsArray.reduce((acc, curr) => {
      return acc + parseFloat(curr.fee);
    }, 0);
  }

  function addPayments(earningsArray) {
    return earningsArray.reduce((acc, current) => {
      const payment = parseFloat(current.payment);
      if (!isNaN(payment)) {
        return acc + payment;
      }
      return acc;
    }, 0)
  }

  return (
    <>
      <OfficePage pageTitle="earnings">
        <OfficeBox>
          <Stack
            direction="row"
            justifyContent="space-between"
            mb={1}
            pt={3}
            pr={3}
            pl={3}
          >
            <Box>
              <Typography variant="h3">
                {
                  selectedMonth &&
                  `${monthNameTranslated} ${earningsTranslated.toLowerCase()}`
                }
              </Typography>
            </Box>
            <Box>
              {
                selectedMonth &&
                <EarningsMonthSelect
                  selectedMonth={selectedMonth}
                  handleChange={handleChange}
                  monthOptions={monthOptions}
                />
              }
            </Box>
          </Stack>
          {
            selectedMonth ? (
              earningsSummary.length === 0 ?
                <Stack mt={8} mb={8} alignItems="center">
                  <Typography variant="h2">
                    <EmptyStateMessage
                      message={`No earnings data available for the month of ${selectedMonth.label.split(" ")[0]}.`}
                    />
                  </Typography>
                </Stack> :
                <EarningsRows
                  earningsSummary={earningsSummary}
                  currentLanguage={currentLanguage}
                  selectedMonth={selectedMonth}
                  addFees={addFees}
                  formatCurrency={formatCurrency}
                  addPayments={addPayments}
                  monthNameTranslated={monthNameTranslated}
                  totalTranslated={totalTranslated}
                  paidTranslated={paidTranslated}
                  processingFeesTranslated={processingFeesTranslated}
                  weekTranslated={weekTranslated}
                  monthEndTranslated={monthEndTranslated}
                  showWeekly={showWeekly}
                  currentMonthStatement={currentMonthStatement}
                />
            ) : (
              <Typography variant="h2">
                <EmptyStateMessage
                  message={`No earnings data available.`}
                />
              </Typography>
            )
          }
          <EarningsStatementButton
            currentMonthStatement={currentMonthStatement}
            monthNameTranslated={monthNameTranslated}
            statementTranslated={statementTranslated}
            setShowLoadingOverlay={setShowLoadingOverlay}
            statementMonth={formatMidOfNextMonthAsFullString(monthEndReport?.StartDate, currentLanguage)}
          />
          <Divider />
          <EarningsScreenBottomLink />
        </OfficeBox>
      </OfficePage>
      <LoadingOverlay
        showLoadingOverlay={showLoadingOverlay}
        handleClose={handleClose}
      />
    </>
  )
}

export { EarningsScreen }


function areAllElementsSame(arr) {
  if (arr.length === 0) {
    return true;
  }

  const firstElement = arr[0];
  for (let i = 1; i < arr.length; i++) {
    if (arr[i] !== firstElement) {
      return false;
    }
  }
  return true;
}