import React from "react";
import { T } from "../../../../hooks/useTranslate";
import {
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import styles from "../../settingsScreenStyles";
import { EmailUsernameInput } from "../../../../components/LoginComponents/EmailUsernameInput";
import { PasswordInput } from "../../../../components/LoginComponents/PasswordInput";
import { ForgotPasswordLink } from "../../../../components/LoginComponents/ForgotPasswordLink";
import { LoginErrorAlert } from "../../../../components/LoginErrorAlert";
import { BackdropLoadingOverlay } from "../../../../components/BackdropLoadingOverlay";

const ReauthenticateForm = ({
  formikReauthentication,
  setIsReauthenticated,
  handleCloseModal,
  viewportIsSmall,
  isModalLoading,
  buttonContent
}) => {
  return (
    <form onSubmit={formikReauthentication.handleSubmit} data-test-id="reauthentication-form">
      <Stack gap={3}>
        <Typography variant="h2" data-test-id="reauthentication-modal-title" mb={1.4}>
          <T>reauthenticate_to_continue</T>
        </Typography>
        <EmailUsernameInput
          formik={formikReauthentication}
          inputProps={styles.inputProps}
          InputLabelProps={{ style: { ...styles.labelStyle } }}
          FormHelperTextProps={{ style: { ...styles.formHelperTextProps } }}
        />
        <PasswordInput
          formik={formikReauthentication}
          inputProps={styles.inputProps}
          InputLabelProps={{ style: { ...styles.labelStyle } }}
          FormHelperTextProps={{ style: { ...styles.formHelperTextProps } }}
        />
        {
          viewportIsSmall && <ForgotPasswordLink styles={{ fontSize: ".875rem" }} />
        }
        {
          !viewportIsSmall && (
            <>
              <Divider m={0} />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                {
                  !viewportIsSmall && <ForgotPasswordLink styles={{ fontSize: ".875rem" }} />
                }
                {buttonContent}
              </Stack>
              <LoginErrorAlert />
            </>
          )
        }
      </Stack>
      <BackdropLoadingOverlay showLoadingOverlay={isModalLoading} />
    </form>
  )
}

export { ReauthenticateForm }