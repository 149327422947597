import React, { useState, useEffect } from 'react';
import ReactCountryFlag from "react-country-flag";


import { Select, FormControl, MenuItem } from '@mui/material';
import { useAuth } from '../../../../hooks/useAuth';
import styles from '../../settingsScreenStyles';
import { getCountriesWithCallingCodes } from './countryCodeHelper';

function CountryCodeSelect({ formik }) {
  const { userInfo } = useAuth();
  const countries = getCountriesWithCallingCodes();
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (userInfo.countryCode) {
      setSelectedCountry(userInfo.countryCode);
    }
  }, [userInfo.countryCode])

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    const callingCode = countries.find(c => c.isoCode === event.target.value).callingCode;
    formik.setFieldValue("countryCode", callingCode);
  };

  return (
    <div>
      <FormControl data-test-id="country-code-select-form-control">
        <Select
          data-test-id="country-code-select"
          labelId="country-code-select"
          id="country-code-select"
          value={selectedCountry}
          onChange={handleCountryChange}
          sx={styles.selectTag}
          inputProps={{ "data-test-id": "country-code-select-input" }}
          MenuProps={styles.menuPaperProps}
          renderValue={(selected) => {
            const country = countries.find(c => c.isoCode === selected);
            return (
              <div>
                <ReactCountryFlag
                  countryCode={country?.isoCode}
                  svg
                  style={{
                    width: '1em',
                    height: '1em',
                    marginRight: '0.5em'
                  }}
                />
                +{country?.callingCode}
              </div>
            );
          }}
        >
          {countries.map((country) => (
            <MenuItem
              key={country.isoCode}
              value={country.isoCode}
              data-test-id={`country-code-select-option-${country.isoCode}`}
            >
              <ReactCountryFlag
                countryCode={country.isoCode}
                svg
                style={{
                  width: '1em',
                  height: '1em',
                  marginRight: '0.5em'
                }}
              />
              {country.name}(+{country.callingCode})
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div >
  );
}

export { CountryCodeSelect };
