import React, { useContext } from "react"

import { Box } from "@mui/material"
import { LanguageContext } from "../hooks/LanguageContext";


const LastOrderPvCell = ({ params: member }) => {
  const { localizeNumber } = useContext(LanguageContext)

  const localizedLastOrderPv = member.lastOrderPv ? localizeNumber(member.lastOrderPv) : 0;

  return (
    <Box>
      {localizedLastOrderPv}
    </Box>
  )
}

export { LastOrderPvCell }