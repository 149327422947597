import React, { useState, useEffect } from "react";
import { useFormik } from "formik";

import { Stack, Typography, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { T, useTranslateKey } from "../../../../hooks/useTranslate";
import { useApi } from "../../../../hooks/useApi";
import { useAuth } from "../../../../hooks/useAuth";
import { useActionableItemsContext } from "../../../../hooks/ActionableItemsContext";

import { OfficeBox } from "../../../../components/OfficeBox";
import { ActionableIndicator } from "../../../../components/ActionableIndicator";
import { SsnInputField } from "./SsnInputField";
import { SaveChangesButton } from "../../MyProfile/SaveChangesButton";
import { SsnSaveModal } from "./SsnSaveModal";

import styles from "../../settingsScreenStyles";

const INITIAL_VALUES = {
  ssnTin: ""
}

const SsnSection = ({ setShowLoadingOverlay, hasGovId }) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const ssnFieldLabel = useTranslateKey("ssn_tin");
  const ssnFieldErrorLabel = useTranslateKey("invalid_ssn_tin")
  const ssnNotSavedErrorLabel = useTranslateKey("ssn_not_saved")

  const { sendRequest } = useApi(false);
  const { userInfo, refreshUserInfo } = useAuth();
  const { isActionable } = useActionableItemsContext();

  const { countryCode, href } = userInfo

  const [modalOpen, setModalOpen] = useState(false);
  const [isSsnSet, setSsnIsSet] = useState(hasGovId)

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validate: (values) => {
      const errors = {};
      const ssnValidationRegex = /^(?!.*--)(?!.*-$)(?!^-)[\d-]+$/
      if (!(ssnValidationRegex.test(values.ssnTin) && values.ssnTin.match(/\d+/g).join("").length === 9)) {
        errors.ssnTin = ssnFieldErrorLabel;
      }
      return errors;
    }
  });

  useEffect(() => {
    setSsnIsSet(hasGovId);
  }, [hasGovId]);

  const isSaveEnabled = () => {
    const { ssnTin } = formik.values
    return !isSsnSet && ssnTin && !formik.errors.ssnTin
  }

  const handleSaveAction = async () => {
    closeSaveModal();
    setShowLoadingOverlay(true);
    const { ssnTin } = formik.values
    try {
      const updateSsnResponse = await sendRequest({
        method: "post",
        endpoint: `customers/${href.split("/").splice(-1)[0]}`,
        addContentType: true,
        data: JSON.stringify({
          taxTerms: { taxId: ssnTin },
          mainAddress: { country: countryCode }
        })
      });
      if (updateSsnResponse?.data?.taxTerms?.taxId) {
        setSsnIsSet(true);
        refreshUserInfo(userInfo.token);
      }
    } catch (error) {
      formik.errors['ssnTin'] = ssnNotSavedErrorLabel
    } finally {
      setShowLoadingOverlay(false);
    }
  }

  const openSaveModal = () => {
    setModalOpen(true);
  };

  const closeSaveModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <OfficeBox id="ssn-tin" data-test-id="ssn-section" sx={styles.mainContent}>
        <Typography
          data-test-id="contact-info-title"
          variant="h2"
        >
          <ActionableIndicator invisible={!isActionable('ssn_tin')}>
            <T>ssn_tin</T>
          </ActionableIndicator>
        </Typography>
        <Stack
          direction={"column"}
          gap={viewportIsSmall ? 3 : 4}
          mt={5}
        >
          {!isSsnSet && <Typography variant="h3" data-test-id="ssn-tin-needed">
            <T>ssn_tin_needed</T>            
          </Typography>}
          <SsnInputField
            name="ssnTin"
            label={ssnFieldLabel}
            content={isSsnSet ? "*********" : ""}
            formik={formik}
            isLocked={isSsnSet}
          />
          <Divider sx={{}} />
          <Stack
            direction={viewportIsSmall ? "row" : "column-reverse"}
            justifyContent="flex-end"
            alignItems={viewportIsSmall ? "center" : "normal"}
            data-test-id="save-button-section"
          >
            <SaveChangesButton
              styles={styles}
              handleSaveChanges={openSaveModal}
              enableSaveChanges={isSaveEnabled()}
            />
          </Stack>
        </Stack>
      </OfficeBox>
      <SsnSaveModal
        open={modalOpen}
        viewportIsSmall={viewportIsSmall}
        handleModalClose={closeSaveModal}
        handleSaveAction={handleSaveAction}
      />
    </>
  );
};

export { SsnSection };
