import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";

import { T, useTranslateKey } from "../../hooks/useTranslate";

import { inputPropsStyles, selectStyles, menuPropsStyles } from "./EarningsScreen.styles";

const EarningsMonthSelect = ({ selectedMonth, monthOptions, handleChange }) => {
  const translateMonth = useTranslateKey("month");
  return (
    <FormControl fullWidth>
      <InputLabel id="month-select-label"><T>month</T></InputLabel>
      <Select
        data-test-id="month-select"
        id="month-select"
        value={selectedMonth.value}
        label={translateMonth}
        onChange={handleChange}
        sx={selectStyles}
        MenuProps={menuPropsStyles}
        inputProps={inputPropsStyles}
        renderValue={(i) => {
          const selected = monthOptions.filter(month => month.value === i)
          return <T>{selected[0].label.split(" ")[0].toLowerCase()}</T>
        }}
      >
        {monthOptions.map((month) => (
          <MenuItem
            key={month.value}
            value={month.value}
            data-test-id="month-select-menu-item"
            sx={{ justifyContent: "space-between" }}
          >
            <T>{month.label.split(" ")[0].toLowerCase()}</T>
            {selectedMonth.value === month.value && <img src="icons/check.png" alt="checkmark" />}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export { EarningsMonthSelect }