import React, { useState } from "react";
import { Typography, Stack } from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { T } from "../../../hooks/useTranslate";

const PlacementDescription = () => {

  const [showMore, setExpandDescription] = useState(false);

  const FullDescription =
    <Typography data-test-id="placement-description-long" style={styles.regularText} >
      <Typography paragraph style={styles.regularText} >
        <T>placement_desc_long1</T>
      </Typography>
      <Typography style={styles.regularText} >
        • <T>placement_desc_long2</T>
      </Typography>
      <Typography style={styles.regularText} >
        • <T>placement_desc_long3</T>
      </Typography>
      <Typography paragraph style={styles.regularText} >
        • <T>placement_desc_long4</T>
      </Typography>
      <Typography style={styles.regularText} >
        <T>placement_desc_long5</T>
      </Typography>
    </Typography>

  const ShortDescription =
    <Typography data-test-id="placement-description-short" style={styles.regularText} >
      <T>placement_desc_short</T>
    </Typography>

  return (
    <Stack direction="row" alignItems="center" gap={1} style={styles.container}>
      <InfoOutlinedIcon />
      <Stack direction="column" gap={3}>
        {showMore ? FullDescription : ShortDescription}
        <Typography
          sx={{ cursor: "pointer" }}
          onClick={() => setExpandDescription(!showMore)}
          data-test-id="placement-description-see-more"
          style={{ fontWeight: "600", fontSize: 12 }}
        >
          {showMore ? <T>see_less</T> : <T>see_more</T>}
        </Typography>
      </Stack>
    </Stack>
  );
};

const styles = {
  container: {
    backgroundColor: "rgba(0, 158, 207, 0.05)",
    border: "1px solid rgba(0, 158, 207, 0.15)",
    padding: "0.75rem",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  regularText: { fontWeight: "400", fontSize: "0.75rem" },
  boldText: { fontWeight: "600", fontSize: "0.75rem" }
}

export { PlacementDescription };