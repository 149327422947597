import React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import styles from "./ElectronicIDCardStyle";
import { Typography } from "@mui/material";
import { T } from "../../../hooks/useTranslate";
import { useAuth } from "../../../hooks/useAuth";
import { DateTime } from "luxon";

const ElectronicIDCard = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { userInfo } = useAuth();
  const joinDate = DateTime.fromISO(userInfo.joinDate);

  return (
    <Box
      className="non-selectable"
      ref={ref}
      {...props}        
      sx={styles.cardPrintWrapper}
      data-test-id="electronic-id-print-area"
    >
      <Box
        sx={styles.cardHolder}
      >
        <img
          width={viewportIsSmall ? 327 : 455}
          src={`https://s3.amazonaws.com/unicitystatic/media/image/uo/IdCard-optimized.png`}
          alt={`id_card`}
          loading="lazy"
        />
        <Box
          sx={styles.contexTopHolder}          
        >
          <Box
            sx={styles.contextHolder}
          >
            <Typography
              variant="caption"
              data-test-id="electronic-id-name"
              fontWeight="bold"
              fontSize={viewportIsSmall ? "12px" : "14px"}
            >
              {userInfo.name}
            </Typography>
            <Typography
              variant="caption"
              data-test-id="electronic-id-unicity-id"
              fontWeight="bold"
              fontSize={viewportIsSmall ? "10px" : "12px"}
            >
              {userInfo.unicityId}
            </Typography>
          </Box>
          <Box
            sx={styles.contextHolder}
          >
            <Typography 
              variant="caption" 
              textTransform="uppercase"
              fontWeight="normal"
              fontSize={viewportIsSmall ? "12px" : "14px"}
            >
              <T>valid_from</T>
            </Typography>
            <Typography 
              variant="caption"
              data-test-id="electronic-id-join-date"
              fontWeight="normal"
              fontSize={viewportIsSmall ? "10px" : "12px"}
            >
              {joinDate.toFormat('MM/yyyy')}
            </Typography>
          </Box>          
        </Box>
      </Box>
    </Box>
  );
});

export { ElectronicIDCard };