import { useState, useContext } from 'react';
import { T } from "../../../hooks/useTranslate";

import { Box, Typography, Stack, IconButton } from "@mui/material"

import { PartnersContext } from '../../../hooks/PartnersContext';
import { PartnersGenealogySection } from '../PartnersGenealogySection';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const PartnersGenealogyCollapsible = () => {
  const [expanded, setExpanded] = useState(true);
  const { loading } = useContext(PartnersContext);

  const handleClick = () => {
    setExpanded(!expanded);
  }

  if (!loading) return (
    <Box>
      <Stack
        padding={expanded ? "24px 16px 16px 16px" : "24px 16px"}
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h2"><T>genealogy</T></Typography>
        <Box border="1px solid rgba(145, 158, 171, 0.2)" borderRadius="4px">
          <IconButton onClick={handleClick}>
            {
              expanded ? <ExpandLessIcon sx={{ fontSize: "16px" }} /> : <ExpandMoreIcon sx={{ fontSize: "16px" }} />
            }
          </IconButton>
        </Box>
      </Stack>
      <Box display={expanded ? "block" : "none"}>
        <PartnersGenealogySection />
      </Box>
    </Box>

  );
}

export { PartnersGenealogyCollapsible } 