import React from "react";

import { Stack } from "@mui/material";

import { RankQualBox } from "./RankQualBox";

const RankQualProgBarContainer = ({
  selectedRankDetails, name1, name2, name3, inDrawer, thirdLegCount
}) => {
  const leg1 = selectedRankDetails?.metrics?.filter(item => item.name.includes("ov_leg1"))[0];
  const leg2 = selectedRankDetails?.metrics?.filter(item => item.name.includes("ov_leg2"))[0];
  const leg3 = selectedRankDetails?.metrics?.filter(item => item.name.includes("ov_leg3"))[0];

  return (
    <Stack
      gap="8px"
      sx={{
        padding: inDrawer ?
          "0px 0px 24px 0px" : "24px 20px 24px 20px"
      }}
    >
      {
        <RankQualBox
          color1="#43C06F"
          color2="#D5F0DF"
          legNumber="1"
          name={name1}
          leg={leg1}
          inDrawer={inDrawer} />
      }
      {
        <RankQualBox
          color1="#009ECF"
          color2="#D9F0F8"
          legNumber="2"
          name={name2}
          leg={leg2}
          inDrawer={inDrawer} />
      }
      {
        <RankQualBox
          color1="#9173D1"
          color2="#EEE8FC"
          legNumber="3"
          name={name3}
          leg={leg3}
          inDrawer={inDrawer}
          thirdLegCount={thirdLegCount}
        />
      }
    </Stack>
  )
}

export { RankQualProgBarContainer }