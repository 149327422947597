import { T } from "../../hooks/useTranslate";
import {
  Box,
  Typography,
  Stack,
  IconButton
} from "@mui/material"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OfficeBox } from '../../components/OfficeBox';
import { ActionableIndicator } from "../../components/ActionableIndicator";
import { useActionableItemsContext } from "../../hooks/ActionableItemsContext";

const iconStyling = { fontSize: "24px", color: "rgba(0, 158, 207, 1)" }

const SettingsCollapsibleMenu = ({
  settingsMenuItems,
  selectedSetting,
  settingsOptionElements,
  expanded,
  handleClick }) => {
    const { isActionable, isActionableArrayOfStrings } = useActionableItemsContext();

  return (
    <OfficeBox data-test-id="settings-collapsible-menu">
      <Stack
        padding={expanded ? "24px 16px 16px 16px" : "24px 16px"}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Display selected setting */}
        <ActionableIndicator invisible={!isActionable(selectedSetting)}>
          <Typography variant="h3" data-test-id="selected-setting" sx={{display: "inline"}}>
            <T>{selectedSetting}</T>
          </Typography>  
        </ActionableIndicator>
        <Box>
          {/* Toggle expansion/collapse */}          
          <IconButton onClick={handleClick} data-test-id="toggle-button">
            <ActionableIndicator
              invisible={!(isActionableArrayOfStrings(settingsMenuItems) && !isActionable(selectedSetting))}
              padRight={-6}
            >
              {expanded ? <ExpandLessIcon sx={iconStyling} data-test-id="expand-less-icon" /> : <ExpandMoreIcon sx={iconStyling} data-test-id="expand-more-icon" />}
            </ActionableIndicator>
          </IconButton>          
        </Box>
      </Stack>
      {/* Render settings options when expanded */}
      <Box
        display={expanded ? "block" : "none"}
        data-test-id={expanded ? "settings-options-expanded" : "settings-options-collapsed"}>
        <Box
          sx={styles.options}
        >
          {settingsOptionElements}
        </Box>
      </Box>
    </OfficeBox>
  );
}

export { SettingsCollapsibleMenu }

const styles = {
  options: {
    display: "flex",
    gap: "4px",
    flexDirection: "column",
    padding: "12px 0px",
    borderTop: "1px solid #EDEFF1"
  }
}
