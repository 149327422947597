export const localTranslationTable = {
  "rank_qualifications": "Rank qualifications",
  "rank": "Rank",
  "month": "Month",
  "leg": "Leg",
  "in_this_leg": "in this leg",
  "learn_more_about_rank_advancement": "Learn more about rank advancement",
  "centurion_qualifications": "Centurion qualifications",
  "see_more": "See more",
  "recent_activity": "Recent activity",
  "see_all": "See all",
  "dashboard_explained": "Dashboard explained",
  "pv": "PV",
  "tv": "TV",
  "provide_feedback": "Provide feedback",
  "log_out": "Log Out",
  "manager": "Manager",
  "senior_manager": "Sr. Manager",
  "executive_manager": "Exec. Manager",
  "director": "Director",
  "senior_director": "Sr. Director",
  "executive_director": "Exec. Director",
  "presidential_director": "Pres. Director",
  "presidential_sapphire": "Pres. Sapphire",
  "presidential_ruby": "Pres. Ruby",
  "presidential_diamond": "Diamond",
  "dashboard": "Dashboard",
  "members": "Members",
  "partners": "Partners",
  "action_hub": "Action Hub",
  "centurion": "Centurion",
  "referral_links": "Referral Links",
  "library": "Library",
  "help_center": "Help Center",
  "search_by_id_name_phone_etc": "Search by ID, name, phone, email or location",
  "name_uid": "Name/UID",
  "date_joined": "Date joined",
  "orders": "Orders",
  "referrals": "Referrals",
  "last_order_date": "Last order date",
  "last_order_pv": "Last order PV",
  "subscription": "Subscription",
  "country_state": "Country/state",
  "purchase_period": "Purchase period",
  "products": "Products",
  "subscriptions": "Subscriptions",
  "manage_columns": "Manage columns",
  "members_per_page": "Members per page",
  "partners_per_page": "Partners per page",
  "my_frontline": "My Frontline",
  "frontline": "Frontline",
  "sort_by": "Sort by",
  "show": "Show",
  "activity": "Activity",
  "auto_generated_follow_ups": "Auto-generated follow ups",
  "new_feel_great_members": "New Feel Great Members",
  "order_reminders": "Order reminders",
  "action_hub_explained": "Action Hub explained",
  "centurion_sales": "Centurion sales",
  "centurion_explained": "Centurion explained",
  "award_progress": "Award progress",
  "bonuses": "Bonuses",
  "level": "Level",
  "completed": "Completed",
  "sales": "Sales",
  "sales_per_page": "Sales per page",
  "referral_alert_text": "These are universal links that will adjust based off your prospect's location and language.",
  "referral_alert_link_text": "Learn more",
  "feel_great": "Feel Great",
  "feel_great_subtext": "The shopping site for Feel Great (ufeelgreat.com).",
  "show_link": "Show my referral link",
  "show_code": "Show my referral code",
  "distributor_enrollment_subtext": "Use this link to directly enroll prospects as new Distributors on your team.",
  "distributor_enrollment": "Distributor Enrollment",
  "website_subtext": "Use this page to redirect users to the Unicity store or product page.",
  "country": "Country",
  "website": "Website",
  "page": "Page",
  "page_language": "Page language",
  "countries": "Countries",
  "websites": "Websites",
  "pages": "Pages",
  "page_languages": "Page languages",
  "your_referral_link": "Your referral link",
  "link_has_been_updated": "The link was successfully updated.",
  "referral_links_explained": "Referral Links explained",
  "additional": "Additional",
  "custom_link": "Custom links",
  "no_activities_to_display": "There are no activities to display.",
  "see_full_profile": "See full profile",
  "phone_unavailable": "Phone number currently unavailable",
  "email_unavailable": "Email currently unavailable",
  "timezone_unavailable": "Timezone currently unavailable",
  "address_unavailable": "Address currently unavailable",
  "country_unavailable": "Country currently unavailable",
  "no_orders_text": "There are currently no orders to display for this person.",
  "order_details": "Order details",
  "tracking_number": "Tracking number",
  "shipping": "Shipping",
  "billing_address": "Billing address",
  "summary": "Summary",
  "prepared_to_ship": "Prepared to ship",
  "shipped": "Shipped",
  "one_time_purchase": "One-time purchase",
  "success_tracker": "Success tracker",
  "updated": "Updated",
  "see_frontline": "See Frontline",
  "distributor": "Distributor",
  "distributor_full": "Distributor",
  "phase_1_full": "Phase 1",
  "associate_full": "Associate",
  "non_manager_full": "Non-Manager",
  "manager_full": "Manager",
  "senior_manager_full": "Senior Manager",
  "director_full": "Director",
  "senior_director_full": "Senior Director",
  "executive_manager_full": "Executive Manager",
  "executive_director_full": "Executive Director",
  "presidential_director_full": "Presidential Director",
  "presidential_sapphire_full": "Presidential Sapphire",
  "presidential_ruby_full": "Presidential Ruby",
  "presidential_diamond_full": "Diamond",
  "upgrade_link_subtext": "Use this link to enable your Members to join as Distributors on your team.",
  "january": "January",
  "february": "February",
  "march": "March",
  "april": "April",
  "may": "May",
  "june": "June",
  "july": "July",
  "august": "August",
  "september": "September",
  "october": "October",
  "november": "November",
  "december": "December",
  "new_feel_great_alert_bold": "Here are the people who made their first Feel Great purchase in the last 2 weeks.",
  "new_feel_great_alert_not_bold": "Contact them so they know they can reach out to you.",
  "order_reminders_alert_bold": "Five weeks have passed since these Members made an order.",
  "order_reminders_alert_not_bold": "You may want to check in with them.",
  "of": "of",
  "new": "new",
  "order": "order",
  "made_a": "made a",
  "descending": "Descending",
  "ascending": "Ascending",
  "name": "Name",
  "total_ov": "Total OV",
  "new_partners": "New Partners",
  "ov_to_next_rank": "OV to Next Rank",
  "contact": "Contact",
  "referred_by": "Referred by",
  "first_order_date": "First Order Date",
  "count": "Count",
  "last_purchase": "Last purchase",
  "all_statuses": "All statuses",
  "subscribed": "Subscribed",
  "not_subscribed": "Not subscribed",
  "apply_filter": "Apply Filter",
  "cancel": "Cancel",
  "filter": "Filter",
  "made_referrals": "Made referrals",
  "no_referrals": "No referrals",
  "all_products": "All products",
  "other_products": "Others products",
  "all_time": "All time",
  "last_seven_days": "Last 7 days",
  "last_thirty_days": "Last 30 days",
  "last_ninety_days": "Last 90 days",
  "last_year": "Last Year",
  "custom_range": "Custom range",
  "business": "Business",
  "resources": "Resources",
  "member": "Member",
  "go_to_referral_links": "Go to Referral Links",
  "empty_list": "Empty list",
  "custom_no_rows_overlay_text": "There are no Members associated with your account. Share your referral link to start building your business.",
  "clear_filters": "Clear Filters",
  "filter_no_rows_overlay_text": "Try adjusting your search or filters to see more.",
  "no_results_to_display": "No results to display",
  "no_search_results_overlay1": "There are no search results matching your criteria. Please try again or clear the current search.",
  "clear_search": "Clear Search",
  "too_many_hits_overlay1": "Please add a filter",
  "too_many_hits_overlay2": "We can only display 10,000 records at one time. Please apply at least one filter to limit your records.",
  "too_many_hits_overlay3": "For example, you can filter out users who have not purchased this year.",
  "go_back": "Go back",
  "action_hub_overlay1": "You're all done",
  "action_hub_overlay2": "There are no more tasks on this list.",
  "no_sales": "No Sales",
  "no_sales_overlay1": "There are no sales for selected period. Please change the period or refresh the page.",
  "clear_filter": "Clear Filter",
  "partners_clear_filter_overlay1": "No results to display",
  "partners_clear_filter_overlay2": "Try adjusting or clearing the filter to see more.",
  "empty_frontline": "Empty frontline",
  "partners_empty_frontline1": "This user's frontline is empty.",
  "return_to_partners": "Return to Partners",
  "no_partners_yet": "No partners yet",
  "partner_no_rows_overlay1": "Use the Upgrade or Enroll links to add Distributors to your frontline.",
  "please_add_a_filter": "Please add a filter",
  "too_many_hits1": "We can only display 10,000 records at one time. Please apply at least one filter to limit your records.",
  "too_many_hits2": "For example, you can filter out users who have not purchased this year.",
  "asc": "Asc",
  "desc": "Desc",
  "active": "Active",
  "inactive": "Inactive",
  "suspended": "Suspended",
  "sort": "Sort",
  "inactive_explained": "* Inactive - have not made qualifying purchase (100pv) for 2 months or more",
  "select_period": "Select period",
  "partials": "Partials",
  "partials_alert_bold": "Members who created an account but did not complete their purchase.",
  "partials_alert_not_bold": "Partials are only available for follow up for 2 weeks.",
  "availability": "Availability",
  "days_left": "days left",
  "not_available": "Not available",
  "error": "Error",
  "there_was_an_error": "There was an error",
  "all": "All",
  "genealogy": "Genealogy",
  "stats": "Stats",
  "active_partners": "Active Partners",
  "total_members": "Total Members",
  "purchased_in_last_60_days": "Purchased in last 60 days",
  "active_subscribers": "Active subscribers",
  "members_with_referrals": "Members with referrals",
  "hide": "Hide",
  "note": "Note",
  "generic_error": "An error has occurred. Please reload the page and try again. If the error persists, please contact support.",
  "full_profile_information_error": "Full profile information for non U.S. Members may not be visible for all requests.",
  "build_your_network": "Build your network of Members and we will help you to organize follow ups.",
  "no_further_activities": "No further activities to display at the moment.",
  "day_left": "day left",
  "language": "Language",
  "change": "Change",
  "apply": "Apply",
  "change_language": "Change Language",
  "english": "English",
  "espanol": "Español",
  "login": "Login",
  "forgot_password": "Forgot password",
  "password": "Password",
  "email_address_or_unicity_id": "Email address or Unicity ID",
  "login_or_password_is_incorrect": "Login or password is incorrect. Please try again.",
  "reach": "Reach",
  "date": "Date",
  "commission_month": "Commission Mo.",
  "subtotal": "Subtotal",
  "taxes": "Taxes",
  "total": "Total",
  "copied_to_clipboard": "Copied \"{0}\" to clipboard",
  "what_is_next_rank": "What is \"Next rank\"?",
  "next_rank_is": "Next rank is the rank next in order based on this person's progression.",
  "learn_more": "Learn more",
  "complete_this_goal_to_qualify_for_next_rank": "Complete this goal to qualify for a rank",
  "referred_by_line1": "If this Member was referred by another Member, their name will appear here.",
  "referred_by_line2": "You may want to contact the referrer before contacting your new Member.",
  "today": "Today",
  "yesterday": "Yesterday",
  "start_date": "Start date",
  "end_date": "End date",
  "sign_out": "Sign out",
  "from": "From",
  "to": "To",
  "next": "Next",
  "no_subscription": "No subscription",
  "no": "No",
  "yes": "Yes",
  "retail": "Retail",
  "wholesale": "Wholesale",
  "preferred": "Preferred",
  "new_library": "New Library",
  "beta": "Beta",
  "settings": "Settings",
  "my_profile": "My Profile",
  "personal_information": "Personal Information",
  "sponsor_and_enroller": "Sponsor & Enroller",
  "communications": "Communications",
  "security": "Security",
  "distributor_profile": "Distributor profile",
  "profile_photo": "Profile photo",
  "your_profile_picture_may_appear": "Your profile photo may appear with your referral link or in Unicity Office for other Distributors to see",
  "change_photo": "Change photo",
  "remove_photo": "Remove photo",
  "display_name": "Display name",
  "display_name_subheader": "This will display on ufeelgreat.com when customers use your referral link",
  "save_changes": "Save Changes",
  "add_photo": "Add photo",
  "remove": "Remove",
  "upload": "Upload",
  "choose_file": "Choose file",
  "add": "Add",
  "unauthorized_login": "As a Member you cannot access Unicity Office. Learn more about becoming a Distributor in our",
  "member_help_center": "Member Help Center",
  "member_help_center_url": "https://help.unicity.com/hc/en-us",
  "dashboard_explained_url": "https://distributorhelp.unicity.com/hc/en-us/articles/18199578130068",
  "action_hub_explained_url": "https://distributorhelp.unicity.com/hc/en-us/articles/18199118563604",
  "centurion_explained_url": "https://distributorhelp.unicity.com/hc/en-us/articles/18199891502868",
  "referral_links_alert_explained_url": "https://distributorhelp.unicity.com/hc/en-us/articles/18201027215764",
  "referral_link_explained_url": "https://distributorhelp.unicity.com/hc/en-us/articles/18202068806036",
  "distributor_help_center_url": "https://distributorhelp.unicity.com/hc/en-us/",
  "provide_feedback_url": "https://distributorhelp.unicity.com/hc/en-us/requests/new",
  "first_name": "First name",
  "last_name": "Last name",
  "reset": "Reset",
  "finding_fg_link_url": "https://distributorhelp.unicity.com/hc/en-us/articles/20148474619156",
  "name_required": "Name is required",
  "name_at_least": "Must be at least {0} characters",
  "name_at_most": "Must be at most {0} characters",
  "name_invalid_characters": "Contains invalid characters or spaces",
  "name_not_allowed": "Name is not allowed",
  "name_not_trailing_spaces": "Name must not contain leading or trailing spaces",
  "error_5006": "Unable to upload file. Please select a valid image file with extension .png, .jpg, .jpeg or .gif.",
  "relevance": "Relevance",
  "earnings": "Earnings",
  "download": "Download",
  "processing_fees": "Processing fees",
  "week": "Week",
  "month_end": "Month end",
  "statement": "statement",
  "account_info": "Account info",
  "name_and_address": "Name and address",
  "well_use_this_information": "We'll use this information for your tax documents",
  "full_name": "Full name",
  "address": "Address",
  "city": "City",
  "zip_code": "Zip code",
  "tax_id_ssn": "Tax ID/SSN",
  "optional": "Optional",
  "state": "State",
  "sponsor": "Sponsor",
  "enroller": "Enroller",
  "reload": "Reload",
  "address1_not_blank": "Address Line 1 cannot be left blank.",
  "error_validate_update_address": "An error has occurred while attempting to validate and update your name and address. Please review your changes. If this error persists, please contact support.",
  "placement_program": "Placement program",
  "contact_info": "Contact info",
  "email_address": "Email address",
  "current_email_address": "Current email address",
  "change_email_address": "Change email address",
  "phone_number": "Phone number",
  "current_phone_number": "Current phone number",
  "change_phone_number": "Change phone number",
  "reauthenticate_to_continue": "Re-authenticate to continue",
  "continue": "Continue",
  "email_id_is_not_correct": "Email address or Unicity ID is not correct",
  "new_email_address": "New email address",
  "new_phone_number": "New phone number",
  "confirm_changes": "Confirm changes",
  "confirm_new_email_address": "Confirm new email address",
  "field_cannot_be_empty": "This field cannot be empty.",
  "password_min_length": "Password must be at least {0} characters.",
  "invalid_email_address": "Invalid email address.",
  "email_addresses_do_not_match": "Email must match.",
  "successful_email": "Your email address was successfully changed to",
  "successful_phone": "Your phone number was successfully changed to",
  "statements": "Statements",
  "year": "Year",
  "statements_per_page": "Statements per page",
  "upcoming": "Upcoming",
  "pending": "Pending",
  "share_feel_great": "Share Feel Great",
  "share_feel_great_subheader": "Setup your Feel Great referral link and code. You can change this 2 times per calendar year",
  "referral_link": "Referral link",
  "referral_code": "Referral code",
  "referral_link_updated_alert": "Your referral link and code were successfully updated",
  "edit": "Edit",
  "add_custom_phrase": "Add custom phrase",
  "referral_link_modal_header": "Edit referral link and code",
  "confirm": "Confirm",
  "insufficient_code_length_error": "Phrase must be at least 3 characters.",
  "allowed_code_change_count_exceeded_error": "Phrase change limit reached.",
  "restricted_code_error": "Phrase is restricted.",
  "referralcode_already_assigned_error": "Phrase is already taken. Try another one.",
  "referral_code_max_length_error": "Phrase exceeds character limit. ",
  "shop_feel_great": "Shop Feel Great",
  "shop_unicity": "Shop Unicity",
  "unicity_news": "Unicity News",
  "latest_news": "Latest News",
  "read_more": "Read more",
  "failed_to_reauthenticate": "Failed to reauthenticate.",
  "share_feel_great_max_changes_reached": "You’ve already changed your referral link and code twice this year. You can do it again next year.",
  "referral_code_disallowed_phrase": "Your custom phrase is restricted.",
  "referral_code_includes_disallow_phrase": "Phrase is not allowed.",
  "name_restricted": "This name is restricted.",
  "full_name_restricted": "This first and last name is restricted.",
  "notes": "Notes",
  "no_notes_to_display": "No notes to display",
  "add_new_note": "Add new note",
  "add_note": "Add note",
  "created_on": "Created on",
  "edit_note": "Edit note",
  "remove_note": "Remove note",
  "load_more": "Load more",
  "edited_on": "Edited on",
  "add_note_placeholder": "Add note",
  "save_note": "Save note",
  "new_partners_help_text": "This column shows how many New Partners this Distributor has enrolled in their frontline this month.",
  "enroller_requester": "Enroller (requester)",
  "enroller_name": "Enroller name",
  "enroller_id_number": "Enroller ID number",
  "user_to_be_placed": "Distributor / Customer to be placed",
  "distributor_name": "Distributor name",
  "distributor_uid": "Distributor UID",
  "new_sponsor": "New sponsor",
  "sponsor_name": "Sponsor name",
  "sponsor_uid": "Sponsor UID",
  "select_month": "Select Month",
  "submit": "Submit",
  "field_is_required": "Field is required",
  "month_of_change": "Effective month of change",
  "customer": "Customer",
  "thank_you": "Thank you!",
  "uh_oh": "Uh oh!",
  "placement_success": "You will receive an email once your request has been reviewed.",
  "placement_failure": "We were unable to process your request. If you believe this is an error, please contact the Distributor Compliance team to process your request:",
  "placement_desc_short": "Unicity's Placement Program allows you to Place a new Distributor within their first sixty (60) days, by completing and submitting this form. For a Distributor to be Placed through this program, the following conditions must be met...",
  "placement_desc_long1": "Unicity's Placement Program allows you to Place a new Distributor within their first sixty (60) days, by completing and submitting this form. For a Distributor to be Placed through this program, the following conditions must be met:",
  "placement_desc_long2": "New Distributor’s Enroller and Sponsor are the same person;",
  "placement_desc_long3": "Placement request must be submitted by the original Enroller/Sponsor;",
  "placement_desc_long4": "Requested Sponsor must be in the Downline of the current Enroller/Sponsor.",
  "placement_desc_long5": "Sponsor change requests that fall outside of the first sixty (60) days of the new Distributor can only be changed by following the Sponsor Change Process as outlined in Section 4 of the Unicity Policies and Procedures. Please be advised, that any Placement Requests submitted between the 3rd and 15th of the month will be processed after the 15th, due to the processing of Commissions. You will receive confirmation once the request has been completed.",
  "see_less": "See less",
  "customer_name": "Customer name",
  "customer_uid": "Customer UID",
  "invalid_uid": "Not a valid UID",
  "uncompressedTv": "TV",
  "notice": "Notice",
  "global_notice_message": "Due to a global internet outage, volume and centurion values may not appear accurately. ",
  "upload_kyc": "Upload KYC",
  "referralcode_reserved_error": "Referral code is reserved.",
  "go_now_text": "Go now",
  "head_backto_oldoffice": "Need to head back to Old Office?",
  "view_profile": "View profile",
  "no_news_error": "There are currently no announcements for your country.",
  "invalid_ssn_tin": "Not a valid SSN / TIN",
  "ssn_tin": "SSN / TIN",
  "are_you_sure": "Are you sure?",
  "ssn_tin_cannot_be_changed": "SSN / TIN cannot be changed once submitted.",
  "ssn_not_saved": "Unable to save Tax ID. Please contact Distributor Compliance.",
  "no_news_to_display": "No news to display",
  "not_active_legs_tooltip_text": "Active legs requirement not met",
  "view_all_statements": "View all statements",
  "search_results": "Search results",
  "no_search_results": "No search results",
  "go_back_to_partners": "Go back to Partners",
  "search_results_for": "Search results for",
  "no_search_results1": "There are no search results matching your criteria. Please try again. Use name, UID or email address.",
  "show_all_results_for": "Show all results for",
  "show_results_for": "Show results for",
  "discount": "Discount",
  "partners_toggle_text": "Include Members",
  "go_to_oo_header": "Legacy Office - Discontinuation",
  "go_to_oo_body1": "In order to focus our efforts on providing the best experience in the new Unicity Office, we will be discontinuing support for the old Unicity Office on July 11th. After this date, Distributors will no longer be able to access the old Office website.",
  "go_to_oo_body2": "Do you have any concerns with Old Office going away? Please provide any concerns or feedback here.",
  "electronic_id": "Electronic ID",
  "valid_from": "Valid from",
  "print_link": "Print ID",
  "level_Limit_alert": "You have reached your access limit",
  "level_Limit_tooltip": "In an effort to protect the data of Unicity customers, Distributors can only view as many levels on which they are paid.",
  "active_legs": "Active Legs",
  "active_legs_qualified": "You've hit your Active Legs requirement for this month!",
  "active_legs_unqualified": "You must have {0} active legs to qualify for rank",
  "pv_qualified": "You've hit your PV requirement for this month!",
  "pv_unqualified": "Complete this goal to qualify for a rank",
  "tv_qualified": "You've hit your TV requirement for this month!",
  "tv_unqualified": "Complete this goal to qualify for a rank",
  "al": "AL",
  "too_many_requests": "You have exceeded the maximum number of allowed login attempts. Please wait one minute before trying again.",
  "missing_rank_info_alert": "We are unable to display your rank information. Please check back soon!",
  "paid": "Paid",
  "go_to_ssn_tin": "To process your commission, please enter your SSN/TIN.",
  "ssn_tin_needed": "This is used to claim your well-earned commissions. Simply provide your SSN or TIN.",
  "go_now_exclamation": "Go now!",
  "auto_refills": "Auto-Refills",
  "order_history": "Order History",
  "dashboard_referral_link": "Referral Link",
  "fg_referral_link": "This universal Feel Great referral link will adjust according to your prospect's location.",
  "tv_pv_requirements_not_met": "There are only {0} days left to complete your {1} goals!",
  "leg1": "Leg 1",
  "leg2": "Leg 2",
  "copy_link": "Copy link",
  "switch_to_short_link": "Switch to short link",
  "switch_to_long_link": "Switch to long link",
  "projected_earnings": "Projected Earnings",
  "statement_not_available": "Statement will be available on or before {0}",
  "statement_available": "Statement is now available for download",
  "cancelled": "Cancelled",
  "suspended_terminated_error": "Your account has been suspended or terminated. If you believe this is an error, please contact Unicity Distributor Compliance at",
  "dc_link": "DC@Unicity.com",
  "processing": "Processing",
  "skip": "Skip",
  "value_feedback": "We value your feedback",
  "how_was_experience": "How's your experience with Office?",
  "submit_feedback": "Your rating is invaluable in helping us improve the experience in Office.",
  "ty_for_rating": "Thank you for the rating.",
  "submit_feedback_card": "Submit Feedback",
  "close": "Close",
  "unacceptable": "Unacceptable",
  "weak": "Weak",
  "acceptable": "Acceptable",
  "good": "Good",
  "excellent": "Excellent",
  "survey_modal_text": "How would you rate your experience with Office?",
  "new_version_banner": "A new version of Office is available. The system will refresh automatically in {0} seconds.",
  "success_tracker_error": "We are unable to display the information. Check back later!",
  "reached_max_centurion_points": "Congratulations! Reaching 100 Centurion Count is a remarkable achievement that reflects your dedication and leadership. We look forward to seeing your continued success!",
  "total_centurion_count": "Total Centurion Count"
}