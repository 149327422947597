export const formatDate = (dateString, currentLanguage, todayTranslated, yesterdayTranslated) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  const formattedDate = date.toLocaleDateString(currentLanguage, options);

  if (date.toDateString() === today.toDateString()) {
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = date.toLocaleTimeString(currentLanguage, timeOptions);
    return `${todayTranslated}, ${formattedTime}`;
  } else if (date.toDateString() === yesterday.toDateString()) {
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    const formattedTime = date.toLocaleTimeString(currentLanguage, timeOptions);
    return `${yesterdayTranslated}, ${formattedTime}`;
  } else {
    return formattedDate;
  }
};
