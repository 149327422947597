import { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { T } from "../hooks/useTranslate";
import { Container, Box, Typography, Stack } from "@mui/material";
import { useTranslateKey } from "../hooks/useTranslate";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BackArrowButton } from "./BackArrowButton";
import { MainNavDrawer } from "../components/nav/MainNavDrawer";
import { MobileNavIcon } from "../components/nav/MobileNavIcon";
import { SettingsMenu } from "./settings/SettingsMenu";
import { CenturionMonthSelect } from "../screens/CenturionScreen/CenturionMonthSelect";
import { ActivityFeedButton } from "../components/activityFeed/ActivityFeedButton";
import { GlobalNoticeComponent } from "./GlobalNoticeComponent";
import { useUshopRedirectMarkets } from "../hooks/useUshopRedirectMarkets";
import { LanguageContext } from "../hooks/LanguageContext";
import { Drawers } from "./Drawers";

//Wrapper element for all pages in Office. Contains page title, settings menu, and navigation drawer.
const OfficePage = ({
  children,
  pageTitle,
  showBackArrow,
  onBackArrowClick,
  isWebView = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { shopLink } = useUshopRedirectMarkets();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const { setCurrentLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("lang")) {
      setCurrentLanguage(params.get("lang"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])


  // used in logic to display or hide elements on mobile design
  const showBackArrowList = [
    "new_feel_great_members",
    "order_reminders",
    "potential_distributors",
    "partials",
    "unicity_news",
    "search_results",
    "statements"
  ];

  const handlePartnersClick = () => {
    if (pageTitle === "partners") {
      navigate("/partners");
    }
  };

  const translatedPageTitle = useTranslateKey(pageTitle);

  return (
    <Box sx={{ display: "flex" }}>
      {!isWebView && (viewportIsSmall || viewportIsMedium)
        && <MainNavDrawer
          shopLink={shopLink}
          viewportIsLarge={viewportIsLarge}
        />
      }
      <Container
        maxWidth={false}
        sx={{
          margin: viewportIsLarge && "0px 16px 0px 16px",
          padding: viewportIsLarge && "0px 0px",
        }}
      >
        <Helmet>{<title>{translatedPageTitle} | Unicity Office</title>}</Helmet>
        {!isWebView && (
          <Box
            className="flex-row"
            style={{
              padding: viewportIsSmall ? "19.5px 0px" : "11.25px 16px",
              gap: "12px"
            }}
          >
            <Box style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              {!viewportIsSmall && !showBackArrowList.includes(pageTitle) && (
                <MobileNavIcon shopLink={shopLink} />
              )}
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "12.5px" }}
              >
                {showBackArrowList.includes(pageTitle) &&
                  showBackArrow && <BackArrowButton pageTitle={pageTitle} onBackArrowClick={onBackArrowClick} />}
                <Typography
                  data-test-id="page-title"
                  onClick={handlePartnersClick}
                  sx={{
                    fontSize: !viewportIsSmall ? "18px" : "20px",
                    fontWeight: "600",
                    cursor: pageTitle === "partners" && "pointer",
                  }}
                >
                  <T>{pageTitle}</T>
                </Typography>
                {/* show the GlobalNoticeComponent at this level on large viewports */}
                {viewportIsLarge && pageTitle === "dashboard" && <GlobalNoticeComponent />}
              </Box>
              {pageTitle === "centurion" && viewportIsSmall && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CenturionMonthSelect isDashboard={false} />
                </Box>
              )}
            </Box>
            <Stack direction="row" spacing={1.5} flexShrink={0}>
              <ActivityFeedButton />
              <SettingsMenu />
            </Stack>
          </Box>
        )}
        <Box>
          {pageTitle === "centurion" && !viewportIsSmall && (
            <CenturionMonthSelect />
          )}
        </Box>
        <Box sx={{ marginBottom: viewportIsSmall && "30px" }}>{children}</Box>
        <Drawers />
      </Container>
    </Box>
  );
};

export { OfficePage };
