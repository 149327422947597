import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MembersDrawer } from './drawer/MembersDrawer';
import { DistributorsDrawer } from '../screens/PartnersScreen/DistributorProfileDrawer/DistributorsDrawer';
import { ArticleDrawer } from './drawer/news/ArticleDrawer';
import { ActivityFeedDrawer } from './activityFeed/ActivityFeedDrawer';

const drawerComponents = {
  member: MembersDrawer,
  partner: DistributorsDrawer,
  article: ArticleDrawer,
  activityFeed: ActivityFeedDrawer,
};

const Drawers = () => {
  const [drawer, setDrawer] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const drawerParam = searchParams.get("drawer");
    if (drawerParam && drawerComponents[drawerParam]) {
      setDrawer(drawerParam);
    } else {
      setDrawer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const DrawerComponent = drawerComponents[drawer];

  return (
    <>
      {
        DrawerComponent && <DrawerComponent />
      }
    </>
  )
}

export { Drawers }