import React, { useState, useEffect, useContext } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";
import { OfficePage } from "../../components/OfficePage";
import { OfficeBox } from "../../components/OfficeBox";
import { Select, MenuItem, InputLabel, FormControl, Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useApi } from "../../hooks/useApi";
import { selectStyles } from "./EarningsScreen.styles";
import { menuPropsStyles } from "./EarningsScreen.styles";
import { inputPropsStyles } from "./EarningsScreen.styles";
import { useColumns } from "./useColumns";
import {
  dataGridStyling,
  statementsDataGridStyling,
} from "../../common/data-grid-styling";
import { AllStatementsFooter } from "./AllStatementsFooter";
import { LanguageContext } from "../../hooks/LanguageContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const AllStatementsScreen = ({ isWebView, renderAsChild }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const {
    sendRequestAsCallback: fetchStatementsData,
    status: fetchStatementsStatus,
    response: fetchStatementsRes,
  } = useApi();
  const navigate = useNavigate();

  // loading state
  const [loading, setLoading] = useState(false);

  // statements state
  const [statements, setStatements] = useState([]);
  const [currency, setCurrency] = useState("USD");
  const columns = useColumns({ setLoading, currency });

  // get all the years from the statements and remove duplicates to populate the year select
  const yearOptions = statements.map((statement) =>
    new Date(statement.period).getFullYear()
  );
  const uniqueYearOptions = [...new Set(yearOptions)];
  uniqueYearOptions.reverse();
  uniqueYearOptions.unshift("All time");

  const [selectedYear, setSelectedYear] = useState("All time");

  const sortedStatements = [...statements].sort((a, b) => {
    const dateA = new Date(a.period);
    const dateB = new Date(b.period);
    return dateB - dateA;
  });

  // filter statements by selected year
  const filteredStatements = sortedStatements.filter((statement) => {
    if (selectedYear === "All time") return true;

    const statementYear = new Date(statement.period).getUTCFullYear();

    return statementYear === selectedYear;
  });

  //  calculate the total earnings for the filtered statements
  const totalEarnings = filteredStatements.reduce((acc, statement) => {
    return acc + statement.earnings;
  }, 0);

  const formattedTotalEarnings = new Intl.NumberFormat(currentLanguage, {
    style: "currency",
    currency: currency,
  }).format(totalEarnings);

  const translatedYear = useTranslateKey("year");

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    const abortController = new AbortController();

    fetchStatementsData({
      method: "get",
      endpoint: `customers/me/commissionstatements`,
      abortController,
    });

    return () => {
      abortController.abort();
    };
  }, [fetchStatementsData]);

  useEffect(() => {
    if (
      fetchStatementsStatus === "uninitialized" ||
      fetchStatementsStatus === "wait"
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }

    if (fetchStatementsRes.status === 200) {
      // filter out items with type "Weekly" and map the items to include an id
      const itemsWithId = fetchStatementsRes.data?.items
        .filter((item) => item.type !== "Weekly")
        .map((item, index) => ({
          ...item,
          id: index,
        }));
      if (itemsWithId.length > 0) {
        setStatements(itemsWithId);
        // set the currency based on the first statement
        itemsWithId[0].currency && setCurrency(itemsWithId[0].currency);
      }
    }
  }, [fetchStatementsRes, fetchStatementsStatus]);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const onBackArrowClick = () => {
    navigate("/earnings", { replace: true });
  };

  return (
    <>
      <OfficePage pageTitle="statements" showBackArrow={renderAsChild} onBackArrowClick={onBackArrowClick}>
        <OfficeBox>
          <Box p={3}>
            <FormControl>
              <InputLabel id="month-select-label">
                <T>year</T>
              </InputLabel>
              <Select
                labelId="statements-select-label"
                id="statements-select"
                data-test-id="statements-select"
                value={selectedYear || "All time"}
                label={translatedYear}
                sx={selectStyles}
                MenuProps={menuPropsStyles}
                inputProps={inputPropsStyles}
                onChange={handleChange}
                disabled={loading}
                renderValue={(i) => (
                  i === "All time" ? <T>all_time</T> : i
                )}
              >
                {uniqueYearOptions.map((year, idx) => {
                  return <MenuItem
                    value={year}
                    key={idx}
                    sx={{ justifyContent: "space-between" }}
                  >
                    {year}
                    {selectedYear === year && <img src="icons/check.png" alt="checkmark" />}
                  </MenuItem>
                })}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              height: `calc(100dvh - ${viewportIsSmall ? "275px" : isWebView ? "140px" : "205px"
                })`,
              minHeight: "315px",
            }}
          >
            <DataGridPro
              rows={filteredStatements}
              columns={columns}
              pagination
              loading={loading}
              sx={{
                ...dataGridStyling,
                ...statementsDataGridStyling,
              }}
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooterSelectedRowCount
              disableColumnReorder
              pageSizeOptions={[12, 24, 48]}
              slots={{
                footer: AllStatementsFooter,
              }}
              slotProps={{
                footer: {
                  perPageKey: "statements_per_page",
                  totals: formattedTotalEarnings,
                  pageSizeOptions: [12, 24, 48],
                },
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 12 } },
              }}
            />
          </Box>
        </OfficeBox>
      </OfficePage>
    </>
  );
};

export { AllStatementsScreen };
