import { useState, useContext } from "react";
import { useApi } from "./useApi";
import { useAuth } from "./useAuth";
import { PartnersContext } from "./PartnersContext"
import { getCurrentCommissionMonth } from "../common/date-functions";

const useSearchSuggestions = (customerType) => {
  const { sendRequest } = useApi();
  const { downlineUid } = useContext(PartnersContext);
  const { userInfo } = useAuth();
  const [searchSuggestionsResults, setSearchSuggestionsResults] = useState([]);
  const [searchSuggestionsResultsCount, setSearchSuggestionsResultsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getSearchSuggestions = (searchField) => {
    const endpoint = customerType === "partners" ?
      `${process.env.REACT_APP_OFFICE_API}/${downlineUid}/partnersmembers?period=${getCurrentCommissionMonth()}&searchTerm=${searchField}&type=partners&limit=8&sortField=relevance&sortDirection=desc` :
      `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/members?searchTerm=${searchField}&limit=8&sortField=relevance&sortDirection=desc`

    const fetchData = async () => {
      setLoading(true);
      setSearchSuggestionsResults([]);
      setSearchSuggestionsResultsCount(0);
      try {
        sendRequest({
          endpoint: endpoint,
          method: "get",
          addPrefix: false,
        }).then((response) => {
          setSearchSuggestionsResults(response.data?.items);
          setSearchSuggestionsResultsCount(response.data.hits);
          setLoading(false);
        }).catch((error) => {
          setError(error);
          setLoading(false);
        })
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }

  return { getSearchSuggestions, searchSuggestionsResults, searchSuggestionsResultsCount, loading, error };
};

export { useSearchSuggestions };