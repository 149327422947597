import React from "react"

import { useNavigate, useLocation } from "react-router-dom";
import { useMixPanel } from "../../../hooks/useMixPanel";

import { Typography, Stack } from "@mui/material"

import { OfficeAvatar } from "../../../components/OfficeAvatar";
import { CopyToClipboard } from "../../../components/CopyToClipboard";

import { formatPhoneNumber, formatInitial } from "../../../common/format-functions";
import { decodeString } from "../../../common/format-functions";

const MemberCell = ({ params, eventLocation }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const handleNameClick = () => {
    logEvent("open_profile", {
      user_type: "member",
      location: eventLocation
    });
    navigate(`${location.pathname}?drawer=member&id=${params.unicityId}&drawerContent=profile`, { state: location });
  }

  let formattedPhoneNumber = "";

  if (params?.homePhone) {
    formattedPhoneNumber = formatPhoneNumber(params.homePhone);
  } else if (params?.mobilePhone) {
    formattedPhoneNumber = formatPhoneNumber(params.mobilePhone);
  }

  return (
    <Stack spacing={1} direction="row">
      <Stack>
        <OfficeAvatar firstInitial={formatInitial(params.name)} addBadge={false} id="user-avatar" />
      </Stack>
      <Stack justifyContent="center">
        <Typography
          data-test-id={`name-cell-name-value`}
          variant="h4"
          onClick={handleNameClick}
          sx={{ "&:hover": { textDecoration: "underline" }, cursor: "pointer" }}
        >
          {decodeString(params.name)}
        </Typography>
        < Typography
          data-test-id={`name-cell-phone-value`}
          variant="h5"
          color="rgba(21, 56, 98, 1)"
          sx={{ fontWeight: "400", fontSize: "10px", opacity: ".6" }}
        >

          {
            formattedPhoneNumber !== "" &&
            <>
              {formattedPhoneNumber}
              <CopyToClipboard
                stringToCopy={formattedPhoneNumber}
                sendEvent={true}
                eventLocation={eventLocation}
                eventName="phone_copy"
                userType="member"
                userId={params.unicityId}
                sx={{
                  verticalAlign: "middle",
                  marginLeft: "2px",
                  cursor: "pointer",
                  opacity: ".6",
                  color: "#153862",
                  fontSize: "12px"
                }}
              />
            </>
          }
        </Typography>
      </Stack >
    </Stack >
  )

}

export { MemberCell }
