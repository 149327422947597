import React, { useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import { T } from "../../../hooks/useTranslate";

import { Stack, Box, Typography, Link } from "@mui/material";

import { CenturionMonthSelect } from "../../CenturionScreen/CenturionMonthSelect.jsx"
import { CenturionProgressCircle } from "../../../components/CenturionProgressCircle.jsx"
import { DashboardDivider } from "../DashboardDivider.jsx";
import { Arrow } from "../../../components/Arrow.jsx";

import { useMixPanel } from "../../../hooks/useMixPanel.js";
import { OfficeBox } from "../../../components/OfficeBox.jsx";
import { LanguageContext } from "../../../hooks/LanguageContext.js";
import { CenturionContext } from "../../../hooks/CenturionContext.js";

import { GeneralAlert } from "../../../components/GeneralAlert.jsx";
import { CenturionQualificationsHeader } from "../../CenturionScreen/CenturionQualificationsHeader.jsx";

const CenturionQualificationsContent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { logEvent } = useMixPanel();
  const { languageDir } = useContext(LanguageContext);
  const { isError } = useContext(CenturionContext);

  const handleClick = () => {
    logEvent("centurion_dashboard_seemore", { location: 'dashboard' });
    navigate("/centurion", { state: location });
  }

  return (
    <OfficeBox>
      {isError && <GeneralAlert style={{ marginTop: "24px" }} alertKey="missing_rank_info_alert" />}
      <CenturionQualificationsHeader addPadding={true} />
      <Stack pr={3} pl={3} pb={3} gap={3}>
        <CenturionMonthSelect isFullWidth={false} showNotched={true} isDashboard={true} />
        <Stack alignItems="center">
          <Box sx={{ minHeight: "150px", minWidth: "150px" }}>
            <CenturionProgressCircle isDashboard={true} />
          </Box>
        </Stack>
      </Stack>
      <DashboardDivider />
      <Link
        onClick={handleClick}
        underline="hover"
        sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}
      >
        <Stack flexDirection="row" p={3} alignItems="center" justifyContent="space-between">
          <Typography variant="h4"><T>see_more</T></Typography>
          <Arrow dir={languageDir} />
        </Stack>
      </Link>
    </OfficeBox>
  )
}

export { CenturionQualificationsContent }