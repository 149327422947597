import React, { useContext } from "react"

import { Box } from "@mui/material"
import { OfficeAvatar } from "../OfficeAvatar";
import { formatDateByLocale } from "../../common/date-functions";
import { LanguageContext } from "../../hooks/LanguageContext";

const ReferralTableCellLeft = ({ referral }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const currentLocale = currentLanguage.split("-")[0];

  return (
    <Box sx={{
      display: "flex",
      gap: "8px"
    }}>
      <Box sx={{
        display: "flex",
        alignItems: "center"
      }}>
        <OfficeAvatar firstInitial={referral.name[0]} id="user-avatar" />
      </Box>
      <Box>
        <Box sx={{ fontWeight: "600" }}>
          {referral.name}
        </Box>
        <Box sx={{ fontSize: "10px", opacity: ".6" }}>
          {`Date joined: ${formatDateByLocale(currentLocale, referral.joinDate)}`}
        </Box>
      </Box>
    </Box>
  )
}

export default ReferralTableCellLeft