import React, { useState, useContext } from 'react';
import { T } from "../../hooks/useTranslate";
import { Box, IconButton } from "@mui/material"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PartnersFilterButtonMenu } from './PartnersFilterButtonMenu';
import { PartnersContext } from "../../hooks/PartnersContext";
import { styles } from "../../themes/datagrid.styles";
import CloseIcon from "@mui/icons-material/Close";
import { getFilterButtonStyling } from "./getFilterButtonStyling";


const PartnersFilterButton = ({ logEvent }) => {
  const { filterValue, setFilterValue, loading, setFilterSelection } = useContext(PartnersContext);
  const isFilterApplied = filterValue !== "all-statuses";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event) => {
    if (loading) return;
    const target = event.currentTarget;
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseIconClick = (e) => {
    e.stopPropagation();
    logEvent("partners_filter_change", {
      location: 'partners',
      filter_by: "all-statuses"
    })
    setFilterValue("all-statuses");
    setFilterSelection("all-statuses");
    handleClose();
  }

  return (
    <>
      <Box
        id="partners-filter-button"
        data-test-id={`partners-filter-button`}
        aria-controls={open ? "sort-partners-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="open partners filter menu"
        onClick={handleButtonClick}
        style={getFilterButtonStyling(loading, open, isFilterApplied)}
        sx={{
          ...styles.button,
          opacity: loading ? ".5" : null,
          paddingRight: isFilterApplied ? ".5rem" : "0.375rem",
          "&:hover": {
            cursor: loading ? "not-allowed" : "pointer",
            backgroundColor: "#FAFAFA"
          }
        }}>
        <T>activity</T>:&nbsp;
        <T>{formatSelection(filterValue)}</T>
        {
          isFilterApplied && (
            <IconButton
              onClick={handleCloseIconClick}
              sx={{
                ...styles.filterButtonIconButton,
                backgroundColor: loading ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 158, 207, 0.1)"
              }}>
              <CloseIcon
                sx={{
                  ...styles.filterButtonCloseIcon,
                  opacity: loading ? ".5" : null,
                  color: loading ? "rgba(0, 0, 0, 0.26)" : "rgba(0, 158, 207, 1)"
                }} />
            </IconButton>
          )
        }
        {
          !isFilterApplied && (
            open ? (
              <KeyboardArrowUpIcon sx={{ transform: "scale(.8)" }} />
            ) : (
              <KeyboardArrowDownIcon sx={{ transform: "scale(.8)" }} />
            )
          )
        }
      </Box>
      <PartnersFilterButtonMenu handleClose={handleClose} anchorEl={anchorEl} open={open} logEvent={logEvent} />
    </>
  )
};

export { PartnersFilterButton }

function formatSelection(value) {
  switch (value) {
    case "all-statuses":
      return "all"
    case "active":
      return "active"
    case "Inactive":
      return "inactive"
    case "suspended":
      return "suspended"
    case "pending":
      return "pending"
    default:
      return "undefined"
  }
}