import { useState, useEffect } from "react";

import {
  Box,
  Divider,
  Menu,
  Button,
  FormControl,
  RadioGroup,
} from "@mui/material";

import { T } from "../../hooks/useTranslate";

import { FilterRadioButton } from "./FilterRadioButton";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { styles } from "../../themes/datagrid.styles";
import { useMixPanel } from "../../hooks/useMixPanel";

const SubscriptionMenu = ({
  open,
  anchorEl,
  handleClose,
  filterModel,
  setFilterModel
}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const [selected, setSelected] = useState('all-statuses');
  const { logEvent } = useMixPanel();

  useEffect(() => {
    setSelected(checkFilterStatus(filterModel));
  }, [filterModel])

  const handleMenuClose = () => {
    // when the menu closes, reset the current filter based on the filterModel
    setSelected(checkFilterStatus(filterModel));
    handleClose();
  }

  const changeFilterSelection = (event) => {
    setSelected(event.target.value);
  }

  const handleApplyFilterClick = () => {
    // remove current subscription filter from filterModel
    const newFilterModel = filterModel.filter(obj => !obj.hasOwnProperty('filterHasSubscription'));

    // add new subscription filter to filterModel based on selected
    if (selected === 'subscribed') {
      newFilterModel.push({ filterHasSubscription: 1 });
    } else if (selected === 'not-subscribed') {
      newFilterModel.push({ filterHasSubscription: 0 });
    }
    setFilterModel(newFilterModel);
    logEvent("members_filter_change", {
      location: 'members',
      filterModel: newFilterModel
    })
    handleClose();
  }

  // check the filterModel to see if any subscription filters are present
  const checkFilterStatus = (model) => {
    let hasSubscribed = false;
    let hasNotSubscribed = false;

    for (const obj of model) {
      if (obj.filterHasSubscription === 1) {
        hasSubscribed = true;
      } else if (obj.filterHasSubscription === 0) {
        hasNotSubscribed = true;
      }
    }

    if (hasSubscribed) {
      return 'subscribed';
    } else if (hasNotSubscribed) {
      return 'not-subscribed';
    } else {
      return 'all-statuses';
    }
  }

  return (
    <Menu
      id="subscription-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      MenuListProps={{
        "aria-labelledby": "subscription-button",
      }}
      PaperProps={{
        sx: {
          ...styles.filterMenuPaperProps,
          width: viewportIsSmall ? 280 : "100%",
        },
      }}
    >
      <form id="subscription-filter-form">
        <Box sx={{ padding: "16px 24px 0px 24px" }}>
          <Box sx={styles.filterMenuHeader}>
            <T isUpperCase={true}>filter</T>
          </Box>
          <Box mb={2}>
            <FormControl>
              <Box sx={styles.filterMenuHeaderContainer}>
                <T>subscription</T>
              </Box>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                id="radioGroupPurchasePeriodDateRange"
                data-test-id={`subscription-menu`}
                value={selected}
                onChange={changeFilterSelection}
              >
                <FilterRadioButton
                  id="all-statuses"
                  value="all-statuses"
                  text="all_statuses"
                />
                <FilterRadioButton
                  id="subscribed"
                  value="subscribed"
                  text="subscribed"
                />
                <FilterRadioButton
                  id="not-subscribed"
                  value="not-subscribed"
                  text="not_subscribed"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Divider />
        <Box sx={styles.filterMenuButtonContainer}>
          <Button
            id="apply-filter-button"
            aria-label="apply filter"
            sx={styles.filterApplyButton}
            onClick={handleApplyFilterClick}
          >
            <T>apply_filter</T>
          </Button>
          <Button
            onClick={handleMenuClose}
            id="cancel-button"
            aria-label="cancel filter"
            sx={styles.filterCancelButton}
          >
            <T>cancel</T>
          </Button>
        </Box>
      </form>
    </Menu>
  );
};

export { SubscriptionMenu };
