import React, { useContext } from "react";
import { T } from "../../../hooks/useTranslate";

import { Box, Typography, Button } from "@mui/material"

import { StyledGridOverlay } from "../../../components/StyledGridOverlay";

import { PartnersContext } from "../../../hooks/PartnersContext";

const PartnersClearFilterOverlay = () => {
  const { setFilterValue, setFilterSelection } = useContext(PartnersContext);

  const handleButtonClick = () => {
    setFilterValue("all-statuses");
    setFilterSelection("all-statuses");
  }
  return (
    <StyledGridOverlay data-test-id="partners-no-rows-overlay">
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "center", margin: "60px 0px" }}>
        <img src="icons/no-search-results.svg" alt="" style={{ width: "80px" }} />
        <Typography variant="h3"><T>no_results_to_display</T></Typography>
        <Typography variant="h5" sx={{ width: "221px", textAlign: "center" }}><T>filter_no_rows_overlay_text</T></Typography>
        <Button
          sx={{
            height: "31px",
            paddingRight: "8px",
            paddingLeft: "8px",
            fontSize: "12px",
            backgroundColor: "#009ECF",
            borderRadius: "4px",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#009ECF90"
            }
          }}
          onClick={handleButtonClick}
        >
          <T>clear_filter</T>
        </Button>
      </Box>
    </StyledGridOverlay>

  )

}

export { PartnersClearFilterOverlay }