import React, { useContext } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";

import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box, Typography, CircularProgress } from "@mui/material";

import { CenturionContext } from "../../hooks/CenturionContext";
import { ClientSidePagination } from "../../components/ClientSidePagination.jsx";
import { CenturionNoSalesOverlay } from "./CenturionNoSalesOverlay";

import { columns } from "./format-centurion-columns.js";
import "./CenturionDataGrid.css";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { formatNumber } from "common/helper-functions";
import { useLanguageContext } from "hooks/LanguageContext";


const CenturionDataGrid = ({ sales }) => {
  const { currentMembers, status, isLoading } = useContext(CenturionContext);
  const totalCenturionCount = useTranslateKey("total_centurion_count")
  const { currentLanguage } = useLanguageContext()

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Box id="CenturionDataGrid">
      {/* height comes from the is needed to fill the centurion sales container based on the adjacent child */}
      <Box id="CenturionDataGridWrapper" sx={{ height: viewportIsLarge ? "633.781px" : "769.328px" }}>
        <Box sx={{ padding: "24px 24px 0px 24px" }}>
          <Typography data-test-id="centurion-qual-header2" variant="h3">
            <T>centurion_sales</T>
          </Typography>
        </Box>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}
          >
            <CircularProgress data-test-id="progress-bar-shown" />
          </Box>
        )}
        {status === "complete" && !isLoading && currentMembers.length === 0 && (
          <CenturionNoSalesOverlay />
        )}
        {(!isLoading || status === "complete") && currentMembers.length !== 0 && (
          <DataGridPro
            rows={currentMembers}
            disableColumnMenu
            columns={columns}
            pagination
            sortingOrder={["desc", "asc"]}
            pageSizeOptions={[10, 25, 50]}
            disableRowSelectionOnClick
            hideFooterSelectedRowCount
            slots={{
              noRowsOverlay: CenturionNoSalesOverlay,
              noResultsOverlay: CenturionNoSalesOverlay,
              pagination: ClientSidePagination
            }}
            slotProps={{
              pagination: {
                perPageKey: "sales_per_page",
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'count', sort: 'desc' }],
              },
              pagination: { paginationModel: { pageSize: !viewportIsLarge ? 25 : 10 } },
            }}
          />
        )}
        {
          sales >= 100 && (
            <div style={{
              padding: "5px 0px 35px 14px",
              fontSize: '11px',
              fontWeight: 600,
              lineHeight: "16.5px",
            }}
            >
              {`${totalCenturionCount}: ${formatNumber(parseInt(sales, currentLanguage))} / 100`}
            </div>
          )
        }
      </Box>
    </Box>
  );
};

export { CenturionDataGrid };
