import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { T } from "../../../hooks/useTranslate";

import { Typography, Stack, Link } from "@mui/material";

import { RecentActivityList } from "../RecentActivity/RecentActivityList"
import { DashboardDivider } from "../DashboardDivider";
import { OfficeBox } from "../../../components/OfficeBox";
import { LanguageContext } from "../../../hooks/LanguageContext";
import { Arrow } from "../../../components/Arrow";
import { useMixPanel } from "hooks/useMixPanel";

const RecentActivityContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { languageDir } = useContext(LanguageContext);
  const { logEvent } = useMixPanel();

  const handleClick = () => {
    logEvent('recent_activity_see_all');
    navigate(`${location.pathname}?drawer=activityFeed`)
  }
  return (
    <OfficeBox>
      <Typography variant="h3" p={3} data-test-id="recent-activities-qual-header1">
        <T>recent_activity</T>
      </Typography>
      <RecentActivityList />
      <DashboardDivider />
      <Link
        onClick={handleClick}
        underline="hover"
        sx={{
          "&:hover": {
            cursor: "pointer"
          }
        }}
      >
        <Stack flexDirection="row" p={3} alignItems="center" justifyContent="space-between">
          <Typography variant="h4"><T>see_all</T></Typography>
          <Arrow dir={languageDir} />
        </Stack>
      </Link>
    </OfficeBox>
  )
}

export { RecentActivityContent }