import React, { useContext, useEffect } from "react";
import { T } from "../../hooks/useTranslate";

import { ActionHubContext } from "../../hooks/ActionHubContext"
import "./DataGridComponents/ActionHubDataGrid.css"

import { Box } from "@mui/material"
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { CollapsibleTable } from "./DataGridComponents/CollapsibleTable"

import { OfficePage } from "../../components/OfficePage"
import { OfficeBox } from "../../components/OfficeBox";
import { ActionHubAlert } from "../../components/ActionHubAlert";
import { ActionHubPagination } from "./DataGridComponents/ActionHubPagination";
import { MemberCell } from "./DataGridComponents/MemberCell";
import { ActionHubNoRowsOverlay } from "./DataGridComponents/ActionHubNoRowsOverlay";

import { FormatDate } from "../../components/FormatDate";
import { ContactCell } from "./DataGridComponents/ContactCell";
import { MemberContextProvider } from "../../hooks/MemberContext";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AvailabilityCell } from "./DataGridComponents/AvailabilityCell";
import { useNavigate } from "react-router-dom";

const PartialsScreen = () => {
  const { partials,
    partialsCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    loading,
    partialsSortModel,
    setPartialsSortModel,
    resetContext
  } = useContext(ActionHubContext);

  const apiRef = useGridApiRef();
  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();

  // When action hub section mounts, clear page state from previous action hub section
  useEffect(() => {
    resetContext();
    // eslint-disable-next-line
  }, []);

  const handleBackArrowClick = () => {
    return navigate("/actionhub", { replace: true })
  }

  return (
    <OfficePage pageTitle="partials" showBackArrow={true} onBackArrowClick={handleBackArrowClick}>
      <MemberContextProvider>
        <OfficeBox>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Box sx={{ padding: "24px" }}>
              <ActionHubAlert
                color="rgba(21, 56, 98, 1)"
                backgroundColor="rgba(0, 158, 207, 0.05)"
                border="1px solid rgba(0, 158, 207, 0.15)"
                boldText="partials_alert_bold"
                text="partials_alert_not_bold"
              />
            </Box>
            <Box id="ActionHubDataGridWrapper" sx={{
              flexGrow: 1,
              height: "70vh",
              minHeight: "315px",
              width: "100%"
            }}>
              {
                viewportIsMedium ?
                  <DataGridPro
                    disableColumnMenu
                    apiRef={apiRef}
                    rows={partials}
                    rowCount={partialsCount}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={setPage}
                    columns={columns}
                    disableRowSelectionOnClick
                    hideFooterSelectedRowCount
                    onPageSizeChange={setPageSize}
                    rowsPerPageOptions={[10, 50, 100]}
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={partialsSortModel}
                    onSortModelChange={(sortModel) => setPartialsSortModel(sortModel)}
                    sortingOrder={['desc', 'asc']}
                    pagination
                    loading={loading}
                    slots={{
                      pagination: ActionHubPagination,
                      noRowsOverlay: ActionHubNoRowsOverlay
                    }}
                    slotProps={{
                      pagination: {
                        page: page,
                        pageSize: pageSize,
                        rowCount: partialsCount,
                        handlePageSizeChange: setPageSize,
                        loading: loading,
                        handlePageChange: setPage,
                        apiRef: apiRef,
                      },
                    }}
                  /> :
                  <CollapsibleTable
                    rows={partials}
                    totalRowCount={partialsCount}
                    loading={loading}
                    tableType={"partials"}
                    screen="partials"
                  />
              }
            </Box>
          </Box>
        </OfficeBox>
      </MemberContextProvider>
    </OfficePage>
  )
}

export { PartialsScreen }

const headerTextStyle = { fontWeight: "600", fontSize: "12px" }

const columns = [
  {
    field: "name",
    headerName: "Name",
    flex: 0.75,
    minWidth: 200,
    renderCell: (params) => (
      <MemberCell params={params.row} eventLocation="action_hub_partials" />
    ),
    renderHeader: () => <Box sx={headerTextStyle}><T>name</T></Box>
  },
  {
    field: "availability",
    headerName: "availability",
    align: "right",
    width: 150,
    headerAlign: "right",
    sortable: false,
    renderCell: (params) => (
      <AvailabilityCell params={params.row} />
    ),
    renderHeader: () => <Box sx={headerTextStyle}><T>availability</T></Box>
  },
  {
    field: "joinDate",
    headerName: "date_joined",
    align: "right",
    headerAlign: "right",
    width: 180,
    renderCell: (params) => <FormatDate dateString={params.row.joinDate} fontSizeValue={"12px"} />,
    renderHeader: () => <Box sx={headerTextStyle}><T>date_joined</T></Box>
  },
  {
    field: "contact",
    headerName: "Contact",
    headerAlign: "right",
    align: "right",
    width: 130,
    sortable: false,
    renderCell: (params) => (
      <ContactCell params={params.row} screen="partials" />
    ),
    renderHeader: () => <Box sx={headerTextStyle}><T>contact</T></Box>
  },
];