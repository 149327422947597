import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { useNavigate, useLocation } from "react-router-dom"
import { useMixPanel } from "../../hooks/useMixPanel";

import { Box, Typography } from "@mui/material"
import { LanguageContext } from "../../hooks/LanguageContext";


const ActionHubItem = ({ id, text, value, background, border, url, eventName }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const { localizeNumber } = useContext(LanguageContext);

  const localizedNumber = localizeNumber(value);

  const handleClick = () => {
    logEvent(eventName, {
      pending_actions: value
    });
    navigate(`${url}`, { state: location });
  }

  return (
    <Box
      onClick={handleClick}
      sx={{
        border: border,
        flex: "1 1 0",
        padding: "20px 24px",
        background: background,
        cursor: "pointer",
        borderRadius: "8px",
        maxWidth: "850px"
      }}>
      <Box>
        <Typography variant="h3" data-test-id={`${id}-header`}><T>{text}</T></Typography>
      </Box>
      <Box sx={{ fontWeight: "600", fontSize: "44px", marginTop: "20px" }} data-test-id={`${id}-value`}>
        {localizedNumber}
      </Box>
    </Box>
  )
}

export { ActionHubItem }