import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import { OfficeAvatar } from "../../components/OfficeAvatar";
import { PartnersGenealogyChip } from "./PartnersGenealogyChip";

import { useAuth } from "../../hooks/useAuth";
import { DistributorContext } from "../../hooks/DistributorContext";

const PartnersGenealogyRow = ({
  user,
  name,
  number,
  isTop,
  isSelected = false,
  firstInitial,
  viewportIsSmall,
}) => {
  const {
    userInfo: { unicityId },
  } = useAuth();

  const { resetContext } = useContext(DistributorContext);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (unicityId.toString() === user.id.unicity) {
      resetContext();
      navigate(`/partners`, { state: location });
    } else {
      resetContext();
      navigate(`/partners/${user.id.unicity}`, { state: location });
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={handleClick}
      sx={{ marginTop: !isTop && "0.5rem", cursor: !isSelected && "pointer", background: isSelected ? "rgba(0, 158, 207, 0.05)" : "rgba(21, 56, 98, 0.05)", borderRadius: 50, padding: "0.5rem 0.75rem" }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <PartnersGenealogyChip isSelected={isSelected} number={number} />
        <OfficeAvatar firstInitial={firstInitial} id="user-avatar" />
        {viewportIsSmall ? (
          <Typography
            variant={isSelected ? "h4" : "h5"}
            color={isSelected && "#009ECF"}
            data-test-id={`genealogy-row-name-${number}`}
          >
            {name}
          </Typography>
        ) : (
          <Typography
            variant={isSelected ? "h3" : "h8"}
            color={isSelected && "#009ECF"}
            data-test-id={`genealogy-row-name-${number}`}
          >
            {name}
          </Typography>
        )}
      </Stack>
      {!isSelected &&
        <img
          data-test-id={`genealogy-row-unselected-icon`}
          src="icons/add.svg"
          alt="add icon"
        />
      }
    </Stack>
  );
};

export { PartnersGenealogyRow };
