import React, { useState } from "react";
import { T, useTranslateKey } from "../hooks/useTranslate";

import { Box, Typography, Alert, Link, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ReferralsAlert = () => {
  const [alertDisplay, setAlertDisplay] = useState("flex");

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const handleAlertClose = () => {
    setAlertDisplay("none");
  }

  const learnMoreHref = useTranslateKey("referral_links_alert_explained_url");

  const alertStyling = {
    display: alertDisplay,
    alignItems: "center",
    padding: "0px 14px",
    marginBottom: "24px",
    border: "1px solid rgba(0, 158, 207, 0.15)",
    borderRadius: "8px",
    backgroundColor: "rgba(0, 158, 207, 0.05)",
    color: "#153862",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "12px",
    '& .MuiAlert-message': {
      width: "100%",
      justifyContent: "space-between"
    }
  }

  if (viewportIsSmall) {
    return (
      <Alert
        data-test-id="referrals-alert"
        icon={<InfoOutlinedIcon sx={{ color: "#153862" }} />}
        severity="info"
        sx={alertStyling}
      >
        <Box>
          <T>referral_alert_text</T>&nbsp;
          <Link
            href={learnMoreHref}
            target="_blank"
            sx={{ fontWeight: "600" }}
            noWrap
          >
            <T>referral_alert_link_text</T>
          </Link>
        </Box>
        <Box>
          <IconButton onClick={handleAlertClose}>
            <img src="icons/alert-close.svg" alt="close icon" />
          </IconButton>
        </Box>
      </Alert>
    )
  }

  return (
    <Alert
      data-test-id="referrals-alert"
      icon={<InfoOutlinedIcon sx={{ color: "#153862" }} />}
      severity="info"
      sx={alertStyling}>
      <Box sx={{ display: "flex", padding: "8px 0px 8px 12px" }}>
        <Typography
          sx={{ fontSize: "12px", marginRight: "20px" }}>
          <T>referral_alert_text</T>&nbsp;
          <Link href={learnMoreHref}
            target="_blank"
            sx={{ fontWeight: 600 }}
            noWrap>
            <T>referral_alert_link_text</T>
          </Link>
        </Typography>
        <IconButton onClick={handleAlertClose} sx={{ height: "16px", width: "16px" }}>
          <img src="icons/alert-close.svg" alt="close icon" />
        </IconButton>
      </Box>
    </Alert>
  )


}

export { ReferralsAlert }