import React from 'react';
import { Stack, Typography, Divider, Box } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { dateRangeStyles, projectedEarningStyle } from './EarningsScreen.styles';
import { isPayDateUpcoming } from '../../common/date-functions';
import { useTranslateKey } from '../../hooks/useTranslate';
import { T } from "../../hooks/useTranslate";


const EarningsRow = ({ week,
  monthlyReport,
  column1,
  column2,
  column3,
  isProcessingFees,
  isTotalRow,
  currentMonthStatement,
  isLabelShown = false
}) => {
  const translateUpcoming = useTranslateKey("upcoming");
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.down("376"));

  // is month end date in the future AND no value for month end earnings
  const monthEndUpcoming = () => {
    if (monthlyReport.earning !== ".0000") {
      return false;
    } else {
      let date = new Date(monthlyReport.StartDate);
      let today = new Date();
      date.setMonth(date.getMonth() + 1);
      date.setDate(15);
      return today < date;
    }
  }

  // if monthly report, check if month end date is in the future
  // if weekly report, display upcoming if week is empty and pay date is in the future
  const displayUpcoming = monthlyReport ?
    monthEndUpcoming() :
    week && week.earning === ".0000" && isPayDateUpcoming(week.StartDate);

  const formattedColumn3 = displayUpcoming ?
    translateUpcoming : column3;

  const stackStyles = {
    backgroundColor: isTotalRow ? "#43C06F1A" : null,
    padding: "18px 24px"
  };

  const typographyVariant = isTotalRow ? "h3" : "h4";
  const typographyColor = isProcessingFees ? "#D03C3C" : null;
  const typographyOpacity = displayUpcoming ? ".5" : null;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={stackStyles}
        alignItems="center"
      >
        {isTotalRow ? (<>
          <Stack alignItems="center" direction={viewportIsSmall ? "column" : "row"}>
            {column1 !== null && (
              <Typography variant={typographyVariant} width={"auto"}>
                {column1}
              </Typography>
            )}
            {isLabelShown && (
              <Typography sx={projectedEarningStyle(viewportIsSmall)}>
                {'('}<T>{"projected_earnings"}</T>{')'}
              </Typography>
            )}
          </Stack>
        </>) : (<>
          <Stack sx={{
            justifyContent: "flex-start",
            alignItems: "center",
          }} direction="row">
            {column1 !== null && (
              <Box sx={{ padding: "3px 8px" }}>
                <Typography variant={typographyVariant} width={viewportIsSmall ? "auto" : "7.5rem"}>
                  {column1}
                </Typography>
              </Box>
            )}
            {column2 !== null && (
              <Stack sx={dateRangeStyles}>
                {column2}
              </Stack>
            )}
          </Stack>
        </>)}
        <Typography
          variant={typographyVariant}
          color={typographyColor}
          sx={{ opacity: typographyOpacity }}
        >
          {formattedColumn3}
        </Typography>
      </Stack >
      {!isTotalRow && !isProcessingFees && <Divider />
      }
    </>
  );
};

export { EarningsRow };
