import { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { Box, Menu, FormControl, RadioGroup, Divider, Typography, Button } from "@mui/material"
import { styles } from "./partners.styles"
import { PartnersContext } from "../../hooks/PartnersContext";
import { FilterRadioButton } from "../../components/filters/FilterRadioButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const PartnersFilterButtonMenu = ({ anchorEl, handleClose, open, logEvent }) => {
  const { filterSelection, setFilterSelection, filterValue, setFilterValue } = useContext(PartnersContext);

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const handleFilterSelectionChange = (e) => {
    setFilterSelection(e.target.value);
  }

  const handleApplyFilter = () => {
    logEvent("partners_filter_change", {
      location: 'partners',
      filter_by: filterSelection
    })
    setFilterValue(filterSelection);
    handleClose();
  }

  const handleMenuClose = () => {
    setFilterSelection(filterValue);
    handleClose();
  }

  return (
    <Menu
      id="filter-partners-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      MenuListProps={{
        "aria-labelledby": "filter-partners-menu-button",
        style: {
          paddingBottom: "0px"
        }
      }}
      slotProps={{
        paper: {
          style: {
            ...styles.paperPropsStyle,
            width: !viewportIsSmall && "100%"
          }
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={styles.menuContainer}>
        <Box sx={styles.subheaderStyle}><T isUpperCase={true}>filter</T></Box>
        <Box sx={{ fontSize: "12px", fontWeight: "500", marginBottom: "8px" }}><T>activity</T></Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            id="radioGroupfilterMenu"
            value={filterSelection}
            onChange={handleFilterSelectionChange}
          >
            <FilterRadioButton
              id="all-statuses"
              value="all-statuses"
              text="all_statuses"
              type="filterOption"
            />
            <FilterRadioButton
              id="active"
              value="active"
              text="active"
              type="filterOption"
            />
            <FilterRadioButton
              id="inactive"
              // this capitalized "Inactive" is needed for the API to function until fixed
              value="Inactive"
              text="inactive"
              type="filterOption"
            />
            <FilterRadioButton
              id="suspended"
              value="suspended"
              text="suspended"
              type="filterOption"
            />
          </RadioGroup>
        </FormControl>
        <Typography sx={styles.inactiveText}><T>inactive_explained</T></Typography>
      </Box>
      <Divider />
      <Box
        sx={styles.buttonContainer}
      >
        <Button
          type="submit"
          id="apply-filter-button"
          aria-label="apply filter"
          onClick={handleApplyFilter}
          sx={styles.applyButton}
        >
          <T>apply_filter</T>
        </Button>
        <Button
          id="cancel-button"
          aria-label="cancel filter"
          onClick={handleMenuClose}
          sx={styles.cancelButton}
        >
          <T>cancel</T>
        </Button>
      </Box>
    </Menu>
  )
}

export { PartnersFilterButtonMenu }