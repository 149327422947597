import React from "react"

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  Paper,
  Stack,
  CircularProgress
} from "@mui/material"
import { CollapsibleTableRow } from "../DataGridComponents/CollapsibleTableRow"

import { ActionHubPaginationMobile } from "./ActionHubPaginationMobile";
import { ActionHubNoRowsOverlay } from "./ActionHubNoRowsOverlay";

import "./CollapsibleTable.css"


const CollapsibleTable = ({ rows, totalRowCount, loading, tableType, screen }) => {

  if (totalRowCount === 0) return <ActionHubNoRowsOverlay isMobile={true} />

  return (
    <Box id="ActionHubMobileTable">
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="collapsible table">
          {
            !loading ? <TableBody>
              {rows.map((row, idx) => (
                <CollapsibleTableRow
                  key={idx}
                  row={row}
                  tableType={tableType}
                  screen={screen}
                />
              ))}
            </TableBody> :
              <Stack alignItems="center" m={6}><CircularProgress data-test-id="progress-bar-shown" /></Stack>
          }
          <TableFooter>
            <TableRow>
              <TableCell colSpan={10}>
                <ActionHubPaginationMobile rowCount={totalRowCount} />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
}

export { CollapsibleTable }