const subscriptionDate = {
  padding: "4px 8px",
  borderRadius: "38px",
  fontSize: "10px",
  fontWeight: "600"
}

const iconSizingStyle = {
  height: 14,
  width: 14
}

const gridTextStyle = {
  fontSize: 12
}

const gridChildLeftWrapper = {
  display: "flex",
  justifyContent: "left",
  alignItems: "center"
}

const officeAvatarWrapper = {
  display: "flex",
  alignItems: "center",
  justifyItems: "center",
}

export { subscriptionDate, iconSizingStyle, gridTextStyle, gridChildLeftWrapper, officeAvatarWrapper }