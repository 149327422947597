import React, { useState, useEffect } from "react";
import { T } from "../../hooks/useTranslate";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useAuth } from "../../hooks/useAuth";
import FeelGreatLink from "../../components/FeelGreatLink";

const FeelGreatSection = ({ referralCode }) => {
  const [link, setLink] = useState("Loading...");
  const [showCode, setShowCode] = useState(false);

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const { userInfo } = useAuth();

  const handleToggle = () => {
    setShowCode(!showCode);
  };

  const toggleText = showCode ? <T>show_link</T> : <T>show_code</T>

  useEffect(() => {
    if (referralCode) {
      setLink(`${process.env.REACT_APP_FEEL_GREAT_REFERRAL_URL}/${referralCode}`);
    } else {
      setLink(`${process.env.REACT_APP_FEEL_GREAT_REFERRAL_URL}/${userInfo.unicityId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode]);

  return (
    <FeelGreatLink 
      viewportIsSmall={viewportIsSmall} 
      handleToggle={handleToggle} 
      toggleText={toggleText} 
      link={link} 
      showCode={showCode} 
      referralCode={referralCode}
    />
  )
};

export { FeelGreatSection };
