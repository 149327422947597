import React from "react";
import { Stack } from "@mui/material";

import { EarningsRow } from "./EarningsRow";

import { formatEarningsDateRange, formatToMidNextMonth } from "../../common/date-functions";

const EarningsRows = ({
  earningsSummary,
  currentLanguage,
  selectedMonth,
  addFees,
  addPayments,
  formatCurrency,
  monthNameTranslated,
  totalTranslated,
  paidTranslated,
  processingFeesTranslated,
  weekTranslated,
  monthEndTranslated,
  showWeekly,
  currentMonthStatement,
  isLabelShown
}) => {
  const weeklyReports = earningsSummary.filter(item => item["Commission Type"] === "Weekly");
  const monthlyReport = earningsSummary.filter(item => item["Commission Type"] === "Monthly")[0];

  return (
    <Stack>
      {
        showWeekly &&
        weeklyReports.map((week, idx) => (
          <EarningsRow
            key={idx}
            week={week}
            column1={weekTranslated + " " + (idx + 1)}
            column2={formatEarningsDateRange(week.StartDate, currentLanguage)}
            column3={formatCurrency(week.earning)}
            bankDate={week.bankDate}
            earning={week.earning}
            currentLanguage={currentLanguage}
          />
        ))
      }
      <EarningsRow
        key={weeklyReports.length + 1}
        monthlyReport={monthlyReport}
        currentLanguage={currentLanguage}
        column1={monthEndTranslated}
        column2={formatToMidNextMonth(selectedMonth.value, currentLanguage)}
        column3={formatCurrency(monthlyReport.earning)}
      />
      <EarningsRow
        key={weeklyReports.length + 2}
        currentLanguage={currentLanguage}
        column1={processingFeesTranslated}
        column2={null}
        column3={formatCurrency(addFees(earningsSummary))}
        isProcessingFees={true}
      />
      <EarningsRow
        key={weeklyReports.length + 3}
        monthlyReport={monthlyReport}
        currentLanguage={currentLanguage}
        column1={`${monthNameTranslated} ${totalTranslated} ${paidTranslated}`}
        column3={formatCurrency(addPayments(earningsSummary))}
        isTotalRow={true}
        currentMonthStatement={currentMonthStatement}
        isLabelShown={isLabelShown}
      />
    </Stack>
  )
}

export { EarningsRows }