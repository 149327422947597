import React from "react";

import ArrowForward from "@mui/icons-material/ArrowForward";
import ArrowBack from "@mui/icons-material/ArrowBack";

const Arrow = ({ dir, testId }) => {
  return (
    <>
      {
        dir === "rtl" ?
          <ArrowBack
            sx={{
              height: "20px",
              width: "20px",
            }}
            data-test-id={testId}
          /> :
          <ArrowForward
            sx={{
              height: "20px",
              width: "20px",
            }}
            data-test-id={testId}
          />
      }
    </>
  )
}

export { Arrow }