import React, { useState } from "react";
import { Stack } from "@mui/material";
import { BackdropLoadingOverlay } from "../../../components/BackdropLoadingOverlay";

import { SettingsDistributorProfile } from "./SettingsDistributorProfile";
import { SettingsShareFeelGreat } from "./SettingsShareFeelGreat";

const SettingsMyProfileSection = () => {
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);

  const handleClose = () => {
    setShowLoadingOverlay(false);
  };
  return (
    <Stack direction="column" flex={1} gap={3}>
      <SettingsDistributorProfile
        showLoadingOverlay={showLoadingOverlay}
        setShowLoadingOverlay={setShowLoadingOverlay} />
      <SettingsShareFeelGreat
        showLoadingOverlay={showLoadingOverlay}
        setShowLoadingOverlay={setShowLoadingOverlay}
      />
      <BackdropLoadingOverlay
        showLoadingOverlay={showLoadingOverlay}
        handleClose={handleClose}
      />
    </Stack>
  )
}

export { SettingsMyProfileSection }