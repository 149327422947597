import { useState } from 'react';

const useStack = () => {
  const [items, setItems] = useState([]);

  // Push element to the top of the stack
  const push = (element) => {
    setItems(prevItems => [...prevItems, element]);
  };

  // Remove and return the top element from the stack
  const pop = () => {
    if (isEmpty()) {
      return "Underflow";
    }
    const poppedItem = items[items.length - 1];
    setItems(prevItems => prevItems.slice(0, -1));
    return poppedItem;
  };

  // Return the top element of the stack without removing it
  const peekOne = () => {
    if (isEmpty()) {
      return "No elements in the stack";
    }
    return items[items.length - 1];
  };

  // Return the 2nd element from the top without removing it
  const peekTwo = () => {
    if (isEmpty()) {
      return "No elements in the stack";
    }
    return items[items.length - 2];
  };

  // Check if the stack is empty
  const isEmpty = () => {
    return items.length === 0;
  };

  // Return the size of the stack
  const size = () => {
    return items.length;
  };

  // Clear the stack
  const clear = () => {
    setItems([]);
  };

  // Print the stack elements
  const print = () => {
    console.log("items:", items);
  };

  // Check if the stack contains a specific item
  const contains = (item) => {
    return items.includes(item);
  };

  return { push, pop, peekOne, peekTwo, isEmpty, size, clear, print, contains };
}

export { useStack }
