import React from "react"
import { useNavigate, useLocation } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material"
import ErrorIcon from '@mui/icons-material/Error';

const MemberErrorScreen = () => {
  const navigate = useNavigate();

  // get current baseURL for closing drawer
  const location = useLocation();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 40, gap: "15px", flexDirection: "column" }}>
        <ErrorIcon />
        An error has occurred.
        <Typography variant="h5" sx={{ textAlign: "center", width: "300px" }}><strong>Note</strong>: Full profile information for non U.S. Members may not be visible for all requests</Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => navigate(`${location.pathname}`, { state: location, replace: true })}
          sx={{
            height: "31px",
            color: "#FFFFFF",
            backgroundColor: "#009ECF",
            fontWeight: "600",
            fontSize: "12px",
            pl: "12px",
            pr: "12px",
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: "rgba(0, 158, 198, 0.48)"
            }
          }}
        >
          Close
        </Button>
      </Box>
    </Box>
  )
}

export { MemberErrorScreen }