import React from "react";

import { Stack, Skeleton } from "@mui/material";

const SkeletonComponent = ({ height }) => {
  return (
    <Skeleton variant="rectangular" width="100%" height={height} sx={{ borderRadius: "8px" }} />
  );
};

const LoadingSkeleton = ({ heights }) => {
  return (
    <Stack alignItems="center" gap={1}>
      {heights.map((height, index) => (
        <SkeletonComponent key={index} height={height} />
      ))}
    </Stack>
  );
};

export { LoadingSkeleton };
