// mobile modal container styles
export const mobileContainerStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "white",
  border: "1px solid rgba(145, 158, 171, 0.10)",
  boxShadow:
    "0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)",
  borderRadius: "0",
};

// desktop modal container styles
export const desktopContainerStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  border: '1px solid rgba(145, 158, 171, 0.10)',
  boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)',
  borderRadius: "8px",
  padding: "20px"
};

// modal button styles
export const buttonStyles = {
  height: "34px",
  fontFamily: "Inter",
  fontWeight: "600",
  fontSize: "12px",
  padding: "8px 12px 8px 12px",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
};
