import { useState, useContext } from "react";
import { Box } from "@mui/material";
import { PartnersMonthSelectMenu } from "./PartnersMonthSelectMenu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { PartnersContext } from "../../hooks/PartnersContext";
import { styles } from "../../themes/datagrid.styles"
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TM } from "../../hooks/useTranslate";
import { getFilterButtonStyling } from "./getFilterButtonStyling";


const PartnersMonthSelect = ({ logEvent }) => {
  const {
    selectedMonth,
    setSelectedMonth,
    contextMonthRange,
    contextUserJoinDate,
    loading
  } = useContext(PartnersContext);

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const month = contextMonthRange.find(item => item.value === selectedMonth);

  const handleButtonClick = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        id="partners-sort-button"
        aria-controls={open ? "sort-partners-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="open partners sort menu"
        data-test-id={`partners-sort-button`}
        onClick={handleButtonClick}
        style={getFilterButtonStyling(loading, open, false)}
        sx={{
          ...styles.button,
          opacity: loading ? ".5" : null,
          paddingRight: "0.375rem",
          "&:hover": {
            cursor: loading ? "not-allowed" : "pointer",
            backgroundColor: "#FAFAFA"
          }
        }}>
        {month && <TM>{month.label}</TM>}
        {open ? (
          <KeyboardArrowUpIcon sx={{ transform: "scale(.8)" }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ transform: "scale(.8)" }} />
        )}
      </Box>
      <PartnersMonthSelectMenu
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        contextUserJoinDate={contextUserJoinDate}
        contextMonthRange={contextMonthRange}
        viewportIsSmall={viewportIsSmall}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        logEvent={logEvent}
      />
    </>
  );
};

export { PartnersMonthSelect };
