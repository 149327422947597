import { T } from "../../hooks/useTranslate";
import { Box } from "@mui/material";

import { CenturionMemberCell } from "./CenturionMemberCell";
import { SubscriptionCell } from "./SubscriptionCell";
import { CountCell } from "./CountCell";
import { LastPurchaseCell } from "./LastPurchaseCell";

import { MobileLeftCell } from "./MobileLeftCell"
import MobileHeader from "./MobileHeader";

const headerTextStyle = {
  fontWeight: "600",
  fontSize: "10px",
  opacity: ".6"
}

const columns = [
  {
    field: "name",
    headerName: "MEMBER",
    headerClassName: "firstCol",
    renderCell: (params) => <CenturionMemberCell params={params.row} />,
    renderHeader: () => <Box
      sx={headerTextStyle}
      data-test-id={`member-column-header`}
    ><T isUpperCase={true}>name</T>
    </Box>,
    flex: .6,
    minWidth: 150
  },
  {
    field: "lastFeelGreatOrderDate",
    headerName: "LAST PURCHASE",
    headerAlign: "right",
    align: "right",
    minWidth: 130,
    flex: .4,
    renderCell: (params) => <LastPurchaseCell params={params.row} />,
    renderHeader: () => <Box
      sx={headerTextStyle}
      data-test-id={`last-purchase-column-header`}>
      <T isUpperCase={true}>last_purchase</T>
    </Box>,
  },
  {
    field: "count",
    headerName: "COUNT",
    headerAlign: "right",
    align: "right",
    flex: .3,
    minWidth: 120,
    renderCell: (params) => <CountCell params={params.row} />,
    renderHeader: () => <Box
      sx={headerTextStyle}
      data-test-id={`count-column-header`}
    ><T isUpperCase={true}>count</T>
    </Box>,
  },
  {
    field: "hasAutoOrder",
    headerName: "SUBSCRIPTION",
    headerClassName: "lastCol",
    headerAlign: "center",
    align: "center",
    minWidth: 120,
    flex: .4,
    renderCell: (params) => <SubscriptionCell params={params.row} />,
    renderHeader: () => <Box
      sx={headerTextStyle}
      data-test-id={`subscription-column-header`}
    >
      <T isUpperCase={true}>subscription</T>
    </Box>,
  }
];

const mobileColumns = [
  {
    field: "name",
    headerName: "MEMBER",
    headerClassName: "firstCol",
    renderCell: (params) => <MobileLeftCell params={params.row} />,
    renderHeader: () =>
    <MobileHeader
      sx={headerTextStyle}
      data-test-id={`member-column-header`}
    >
      <T isUpperCase={true}>name</T>
    </MobileHeader>,
    flex: 1,
  },
  {
    field: "hasAutoOrder",
    headerName: "SUBSCRIPTION",
    headerClassName: "lastCol",
    headerAlign: "right",
    align: "right",
    width: 150,
    renderCell: (params) => <SubscriptionCell params={params.row} />,
    renderHeader: () =>
      <MobileHeader
        sx={headerTextStyle}
        pr={1.5}
        data-test-id={`subscription-column-header`}
      >
        <T isUpperCase={true}>subscription</T>
      </MobileHeader>
  }
];

export { columns, mobileColumns }