import React, { useContext } from "react"

import { Box } from "@mui/material"
import { LanguageContext } from "../../hooks/LanguageContext"


const CountCell = ({ params }) => {
  const { localizeNumber } = useContext(LanguageContext);
  const localizedNumber = localizeNumber(params.count);

  const textColor = {
    fontSize: "12px",
    opacity: params.count !== 0 ? "" : "70%"
  }


  return (
    <Box sx={textColor}>
      {localizedNumber}
    </Box>
  )
}

export { CountCell }