import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Drawer } from "@mui/material";

import { ActivityFeedContext } from "../../hooks/ActivityFeedContext";
import { ActivityFeedContent } from "./ActivityFeedContent";

import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";

const ActivityFeedDrawer = () => {
  const { activities, setActivities } = useContext(ActivityFeedContext);
  const [activityFeedOpen, setActivityFeedOpen] = useState(false);

  const { sendRequest } = useApi();
  const { userInfo } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const drawer = searchParams.get("drawer");
    if (drawer === "activityFeed") {
      setActivityFeedOpen(true);
    } else {
      setActivityFeedOpen(false);
    }
  }, [location])

  const handleClose = () => {
    setActivityFeedOpen(!activityFeedOpen);
    navigate(location.pathname, { replace: true });

    // send request to mark all messages read, if good promise, then update context to fill gap between calls (if no sockets)
    const seenActivities = activities.map((activity) => {
      return { ...activity, isSeen: "true" }
    })
    setActivities(seenActivities);

    sendRequest({
      method: "POST",
      endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/activities/seen`,
      addPrefix: false,
      data: '{"all" : "true"}',
      addContentType: true
    })
  }

  return (
    <div>
      <Drawer
        anchor="right"
        open={activityFeedOpen}
        onClose={handleClose}
      >
        <Box
          sx={{
            width: "100vw",
            maxWidth: 400,
            padding: 0,
          }}
          role="presentation"
          data-test-id="activity-feed-drawer"
        >
          <ActivityFeedContent handleClose={handleClose} />
        </Box>
      </Drawer>
    </div>
  );
};

export { ActivityFeedDrawer };
