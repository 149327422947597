import React, { useState, useEffect } from "react";
import { T } from "../../../hooks/useTranslate";
import { useAuth } from "../../../hooks/useAuth";
import { useApi } from "../../../hooks/useApi";

import { Typography, Button, Stack } from "@mui/material";
import { OfficeBox } from "../../../components/OfficeBox";
import { BackdropLoadingOverlay } from "../../../components/BackdropLoadingOverlay";
import { SettingsSponsorEnrollerCard } from "./SettingsSponsorEnrollerCard";
import styles from "../settingsScreenStyles";

const SettingsSponsorEnrollerSection = () => {
  const { userInfo, refreshUserInfo } = useAuth();
  const { sendRequestAsCallback } = useApi(false);

  const [sponsorRes, setSponsorRes] = useState(null);
  const [sponsorProfilePictureUrl, setSponsorProfilePictureUrl] = useState("");
  // eslint-disable-next-line
  const [enrollerRes, setEnrollerRes] = useState(null);
  const [enrollerProfilePictureUrl, setEnrollerProfilePictureUrl] = useState("");
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [error, setError] = useState(null);

  const [sponsorEnrollerIsSame, setSponsorEnrollerIsSame] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setShowLoadingOverlay(true);
      let hasSponsor = false;
      try {
        const sponsorRes = await sendRequestAsCallback({
          method: "get",
          endpoint: `customers/${userInfo.sponsor.href.split("/").splice(-1)[0]}`
        })

        setSponsorRes(sponsorRes?.data);
        if (sponsorRes?.data) {
          hasSponsor = true;
        }

        const profilePhotoRes = await sendRequestAsCallback({
          method: "get",
          addPrefix: false,
          endpoint: `${sponsorRes?.data.profilePicture.href}`
        })

        if (profilePhotoRes.data?.sizes.length > 0) {
          setSponsorProfilePictureUrl(profilePhotoRes.data?.sizes[0].media);
        }

        if (userInfo.sponsor.id.unicity !== userInfo.enroller.id.unicity) {
          const enrollerRes = await sendRequestAsCallback({
            method: "get",
            endpoint: `customers/${userInfo.enroller.href.split("/").splice(-1)[0]}`
          })

          setEnrollerRes(enrollerRes?.data);

          const profilePhotoRes = await sendRequestAsCallback({
            method: "get",
            addPrefix: false,
            endpoint: `${enrollerRes?.data.profilePicture.href}`
          })

          if (profilePhotoRes.data?.sizes.length > 0) {
            setEnrollerProfilePictureUrl(profilePhotoRes.data?.sizes[0].media);
          }
        }
      } catch (error) {
        // only throw error if there is no sponsor
        if (!hasSponsor) {
          setError(error);
        }
      } finally {
        setShowLoadingOverlay(false);
      }
    }

    fetchData();
  }, [
    sendRequestAsCallback,
    userInfo
  ])

  useEffect(() => {
    if (userInfo.sponsor) {
      setSponsorEnrollerIsSame(userInfo.sponsor.id.unicity === userInfo.enroller.id.unicity);
    }
  }, [userInfo])

  const handleClose = () => {
    setShowLoadingOverlay(false);
  };

  const handleRefresh = () => {
    setError(null);
    refreshUserInfo(userInfo.token);
  }

  if (error) {
    return (
      <Stack data-test-id="error" alignItems="center" gap={5}>
        <Typography variant="h2"><T>generic_error</T></Typography>
        <Button
          sx={styles.errorButton}
          onClick={handleRefresh}><T>reload</T></Button>
      </Stack>
    );
  }

  return (
    <OfficeBox sx={styles.mainContent}>
      <Typography variant="h2" mb={5}><T>sponsor_and_enroller</T></Typography>
      {
        sponsorRes && (
          <SettingsSponsorEnrollerCard
            cardData={sponsorRes}
            imageUrl={sponsorProfilePictureUrl}
            type="sponsor"
            sponsorEnrollerIsSame={sponsorEnrollerIsSame}
          />
        )
      }
      {
        enrollerRes && (
          <SettingsSponsorEnrollerCard
            cardData={enrollerRes}
            imageUrl={enrollerProfilePictureUrl}
            type="enroller"
          />
        )
      }
      <BackdropLoadingOverlay
        showLoadingOverlay={showLoadingOverlay}
        handleClose={handleClose}
      />
    </OfficeBox>
  )
}

export { SettingsSponsorEnrollerSection }