import { useEffect, useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { T } from "../hooks/useTranslate";
import { useMixPanel } from '../hooks/useMixPanel';
import { useAuth } from '../hooks/useAuth';

const VersionChangeBanner = () => {
  const { showVersionChangeBanner } = useFlags();
  const currentVersion = process.env.REACT_APP_VERSION;
  const { logEvent } = useMixPanel();
  const { userInfo } = useAuth();

  const [timer, setTimer] = useState(5);
  const [open, setOpen] = useState(false);

  const logEventOnce = useCallback((event, data) => {
    if (!localStorage.getItem(event)) {
      logEvent(event, data);
      localStorage.setItem(event, "true");
    }
  }, [logEvent]);

  useEffect(() => {
    if (
      showVersionChangeBanner?.showBanner &&
      showVersionChangeBanner.newVersion !== currentVersion &&
      showVersionChangeBanner.newVersion > currentVersion &&
      userInfo.loggedIn
    ) {
      setOpen(true);

      logEventOnce("version_change_banner_triggered", {
        oldVersion: currentVersion,
        newVersion: showVersionChangeBanner.newVersion,
      });

      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(interval);

            localStorage.setItem("browser_refreshed", "true");

            window.location.reload();
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [showVersionChangeBanner, currentVersion, logEventOnce, userInfo.loggedIn]);

  useEffect(() => {
    if (localStorage.getItem("browser_refreshed")) {
      logEvent("browser_refreshed", {
        oldVersion: currentVersion,
        newVersion: showVersionChangeBanner?.newVersion,
      });
      localStorage.removeItem("browser_refreshed");
    }
  }, [logEvent, currentVersion, showVersionChangeBanner?.newVersion]);

  return (
    userInfo.loggedIn && (
      <Snackbar
        sx={{ backgroundColor: "rgba(241, 182, 68, 0.05)" }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
      >
        <Alert severity="info" sx={{ width: '100%' }}>
          <T timer={timer}>{"new_version_banner"}</T>
        </Alert>
      </Snackbar>)
  );
};

export default VersionChangeBanner;
