import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { useTranslateKey } from "../../hooks/useTranslate";

import { Box } from "@mui/material";

import { isDateInFuture, formatDateShortByLocale } from "../../common/date-functions";

import { LanguageContext } from "../../hooks/LanguageContext";
import { useTheme } from "@mui/material/styles";

// if text, render chip with text, else conditionally render subscription chip
const Chip = ({ id, text, containerStyle = {}, textStyle = {}, subscriptionRenewalDate }) => {
  const theme = useTheme()
  const { currentLanguage } = useContext(LanguageContext);

  const isSubscriptionDate = subscriptionRenewalDate && isDateInFuture(subscriptionRenewalDate);
  const nextTranslated = useTranslateKey("next");

  const formatNextSubscriptionDate = (date) => {
    return `${nextTranslated}: ${formatDateShortByLocale(currentLanguage, date)}`
  };

  const styling = {
    padding: "4px 8px",
    borderRadius: "38px",
    color: !isSubscriptionDate ? theme.subscription.default.color : theme.subscription.subscribed.color,
    backgroundColor: !isSubscriptionDate ? theme.subscription.default.backgroundColor : theme.subscription.subscribed.backgroundColor,
    fontSize: "10px",
    fontWeight: 600,
    textAlign: "center",
    ...containerStyle,
  };

  return (
    <Box id={id} sx={styling}>
      <span style={textStyle}>
        {text ? text :
          subscriptionRenewalDate && isDateInFuture(subscriptionRenewalDate) ? formatNextSubscriptionDate(subscriptionRenewalDate) :
            <T>no</T>}
      </span>
    </Box>
  );
};

export { Chip };
