export const referralLinkIncludesBlacklist = [
  "feelgreat",
  "ChristopherKim",
  "cure",
  "DanHughes",
  "diabetes",
  "Enrich",
  "FDA",
  "guarantee",
  "Immunizen",
  "Lifiber",
  "MakeLifeBetter",
  "prevent",
  "Probionic",
  "Resolv",
  "Rexall",
  "StewartHughes",
  "ufeelgreat",
  "Unicity",
  "Unimate",
  "save10",
  "saveten",
  "balance",
  "paraway",
  "aloevera",
  "enjuvenate",
  "melatonin",
  "visionessentials",
  "bonefortify",
  "jointmobility",
  "naturestea",
  "enzygen",
  "supergreen",
  "coq10",
  "omegalife",
  "bioc",
  "noni",
  "immunizen",
  "quadplex",
  "bioreishi",
  "fuck",
  "shit",
  "bitch",
  "HughesStewart",
  "HughesDan"
].map(word => word.toLowerCase());

export const displayNameIncludesBlacklist = [
  "feel great",
  "Unicity",
  "ufeelgreat",
  "feelgreat",
  "Bios Life",
  "Rexall",
  "Enrich",
  "Unimate",
  "Probionic",
  "Immunizen",
  "Resolv",
  "Lifiber",
  "cure",
  "prevent",
  "treat",
  "guarantee",
  "FDA",
  "diabetes",
  "fuck",
  "shit",
  "bitch",
  "Stewart Hughes",
  "Hughes Stewart",
  "Dan Hughes",
  "Hughes Dan",
  "Christopher Kim",
  "Kim Christopher",
  "Make Life Better"
].map(word => word.toLowerCase());

export const displayNameCombinedBlacklist = [
  ["stewart", "hughes"],
  ["dan", "hughes"],
  ["christopher", "kim"],
  ["makelife", "better"],
  ["make", "lifebetter"]
]