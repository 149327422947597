import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useMixPanel } from "hooks/useMixPanel";

//Element for use in creating authorized routes (see OfficeRouter.js)
function RequireAuth({ children }) {
  const { userInfo } = useAuth();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  if (userInfo.loggedIn && userInfo.type === 'Associate' && (userInfo.status === 'Active' || userInfo.status === 'Pending'))
    return children;
  
  if (userInfo.status === 'Suspended' || userInfo.status === 'Terminated') {
    logEvent(`${userInfo.status.toLowerCase()}-logged-out`, {
      status: userInfo.status,
      id: userInfo.unicityId
    })
    localStorage.setItem('showSuspendedTerminatedError', true)
  }
  
  return <Navigate to="/login" replace state={{ path: location.pathname }} />
}

export { RequireAuth };
