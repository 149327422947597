import { useState, useEffect } from "react";

import {
  Box,
  Divider,
  Menu,
  Button,
  FormControl,
  RadioGroup,
} from "@mui/material";

import { T } from "../../hooks/useTranslate";

import { FilterRadioButton } from "./FilterRadioButton";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { styles } from "../../themes/datagrid.styles";
import { useMixPanel } from "../../hooks/useMixPanel";

const ProductMenu = ({
  open,
  anchorEl,
  handleClose,
  filterModel,
  setFilterModel
}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const [selected, setSelected] = useState('all-products');
  const { logEvent } = useMixPanel();

  useEffect(() => {
    setSelected(checkFilterStatus(filterModel));
  }, [filterModel])

  const handleMenuClose = () => {
    // when the menu closes, reset the current filter based on the filterModel
    setSelected(checkFilterStatus(filterModel));
    handleClose();
  }

  const changeFilterSelection = (event) => {
    setSelected(event.target.value);
  }

  const handleApplyFilterClick = () => {
    // remove current subscription filter from filterModel
    const newFilterModel = filterModel.filter(obj => !obj.hasOwnProperty('filterHasFeelGreat'));

    // add new subscription filter to filterModel based on selected
    if (selected === 'feel-great') {
      newFilterModel.push({ filterHasFeelGreat: 1 });
    } else if (selected === 'other-products') {
      newFilterModel.push({ filterHasFeelGreat: 0 });
    }
    setFilterModel(newFilterModel);
    logEvent("members_filter_change", {
      location: 'members',
      filterModel: newFilterModel
    })
    handleClose();
  }

  // check the filterModel to see if any subscription filters are present
  const checkFilterStatus = (model) => {
    let hasFeelGreat = false;
    let notHasFeelGreat = false;

    for (const obj of model) {
      if (obj.filterHasFeelGreat === 1) {
        hasFeelGreat = true;
      } else if (obj.filterHasFeelGreat === 0) {
        notHasFeelGreat = true;
      }
    }

    if (hasFeelGreat) {
      return 'feel-great';
    } else if (notHasFeelGreat) {
      return 'other-products';
    } else {
      return 'products';
    }
  }

  return (
    <Menu
      id="products-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      MenuListProps={{
        "aria-labelledby": "products-button",
      }}
      PaperProps={{
        sx: {
          ...styles.filterMenuPaperProps,
          width: viewportIsSmall ? 280 : "100%",
        },
      }}
    >
      <form id="products-filter-form">
        <Box sx={{ padding: "16px 24px 0px 24px" }}>
          <Box sx={styles.filterMenuHeader}>
            <T isUpperCase={true}>filter</T>
          </Box>
          <Box mb={2}>
            <FormControl>
              <Box sx={styles.filterMenuHeaderContainer}>
                <T>products</T>
              </Box>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                id="radioGroupPurchasePeriodDateRange"
                data-test-id={`product-menu`}
                value={selected}
                onChange={changeFilterSelection}
              >
                <FilterRadioButton
                  id="all-products"
                  value="products"
                  text="all_products"
                />
                <FilterRadioButton
                  id="feel-great"
                  value="feel-great"
                  text="feel_great"
                />
                <FilterRadioButton
                  id="not-feel-great"
                  value="other-products"
                  text="other_products"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Divider />
        <Box sx={styles.filterMenuButtonContainer}>
          <Button
            id="apply-filter-button"
            aria-label="apply filter"
            sx={styles.filterApplyButton}
            onClick={handleApplyFilterClick}
          >
            <T>apply_filter</T>
          </Button>
          <Button
            onClick={handleMenuClose}
            id="cancel-button"
            aria-label="cancel filter"
            sx={styles.filterCancelButton}
          >
            <T>cancel</T>
          </Button>
        </Box>
      </form>
    </Menu>
  );
};

export { ProductMenu };
