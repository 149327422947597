import { Stack } from "@mui/material";
import { OfficeBox } from "./OfficeBox";

const AbsoluteCenteredBox = ({ children }) => {
  return (
    <Stack
      sx={{
        minHeight: "75vh",
        // eslint-disable-next-line
        minHeight: "75dvh",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden"
      }}
    >
      <OfficeBox>{children}</OfficeBox>
    </Stack>
  );
};

export { AbsoluteCenteredBox };
