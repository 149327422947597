import React, { useContext } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { LanguageContext } from "../../../hooks/LanguageContext";
import { useTranslateKey } from "../../../hooks/useTranslate";
import { formatDate } from "../../../common/newsDateUtils";
import { styles } from "./news.styles"
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';

const ArticleListItem = ({ article, logEvent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentLanguage } = useContext(LanguageContext);

  const todayTranslated = useTranslateKey("today");
  const yesterdayTranslated = useTranslateKey("yesterday");
  const dateString = article.updated ? article.updated : article.created;
  const displayDate = formatDate(dateString, currentLanguage, todayTranslated, yesterdayTranslated);

  const imgUrl = article.banner?.styles?.original;

  const handleClick = () => {
    const url = window.location.href;
    const isDashboard = url.includes("dashboard");
    logEvent("news_article_opened", {
      location: isDashboard ? "dashboard" : "news",
      article_title: article.name,
      article_date: article.displayDate
    })

    navigate(`${location.pathname}?drawer=article&articleId=${article.id}`, { state: location })
  }

  return (
    <Stack
      direction="row"
      alignItems={imgUrl ? "flex-start" : "center"}
      gap={1.5}
      pl={3} pr={3} pt={1.8} pb={1.8}
      onClick={handleClick}
      style={styles.newsItem}
    >
      {
        imgUrl ?
          <img src={imgUrl} alt="article" height="24px" width="24px" data-test-id="article-image" /> :
          <ImageNotSupportedIcon sx={styles.imageIcon} />
      }
      <Stack>
        <Typography
          data-test-id="article-title"
          sx={styles.nameText}
        >
          {article.name}
        </Typography>
        <Typography
          data-test-id="article-date"
          sx={styles.dateText}
        >
          {displayDate}
        </Typography>
      </Stack>
    </Stack>
  );
}

export { ArticleListItem };
