export function setAttributesForHtml(htmlContent, fontSize) {
  // Create a temporary div element to parse the HTML
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlContent;

  // Traverse the DOM to find all <p> tags
  const pTags = tempDiv.querySelectorAll('p');

  // Apply the font size to each <p> tag
  pTags.forEach((pTag) => {
    pTag.style.fontSize = `${fontSize / 16}rem`;
  });

  // Traverse the DOM to find all <a> tags
  const aTags = tempDiv.querySelectorAll('a');

  // Set the target attribute to "_blank" for each <a> tag to open in a new tab
  aTags.forEach((aTag) => {
    aTag.setAttribute('target', '_blank');
  });

  // Convert the modified DOM back to HTML
  const modifiedHTML = tempDiv.innerHTML;

  return modifiedHTML;
}
