import React from "react";
import { T } from "../hooks/useTranslate";

import { Box, Divider, Typography } from "@mui/material"

const TooManyHitsOverlay = () => {
  return (
    <Box>
      <Divider sx={{ border: 0, borderTop: "1px solid #EDEFF1" }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "center", margin: "60px 0px" }}>
        <img src="icons/no-search-results.svg" alt="" style={{ width: "80px" }} />
        <Typography variant="h3"><T>please_add_a_filter</T></Typography>
        <Typography variant="h5" sx={{ width: "221px", textAlign: "center" }}>
          <T>too_many_hits1</T>
        </Typography>
        <Typography variant="h5" sx={{ width: "221px", textAlign: "center" }}>
          <T>too_many_hits2</T>
        </Typography>
      </Box>
    </Box >
  )
}

export { TooManyHitsOverlay }