import { useContext } from "react";
import { T } from "../../hooks/useTranslate";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { LanguageContext } from "../../hooks/LanguageContext";
import { ActionableIndicator } from "../../components/ActionableIndicator";
import { useActionableItemsContext } from "../../hooks/ActionableItemsContext";

import { Stack, Typography } from "@mui/material";

const SettingsOption = ({
  option,
  selectedSetting,
  setSelectedSetting,
  testId
}) => {
  const navigate = useNavigate();
  const isSelected = option.key === selectedSetting;
  const { userInfo } = useAuth();
  const { languageDir } = useContext(LanguageContext);
  const { isActionable } = useActionableItemsContext();

  const handleClick = () => {
    if (!option.isDisabled) {
      if (!option.externalUrl) {
        setSelectedSetting(option.key);
        navigate(`/settings/${option.key.replaceAll("_", "-")}`);
      } else {
        // open external link in new tab, and replace {unicityId} with user's unicityId
        window.open(option.externalUrl.replace("{unicityId}", userInfo.unicityId), '_blank');
      }
    }
  };

  const getCursor = () => !option.isDisabled ? "pointer" : "not-allowed";
  const getBackgroundColor = () => isSelected ? "rgba(0, 158, 207, 0.05)" : "transparent";
  const getColor = () => isSelected ? "#009ECF" : option.isDisabled ? "#8A9BB0" : "inherit";

  return (
    <Stack
      data-test-id={testId}
      direction="row"
      alignItems="center"
      onClick={handleClick}
      sx={{
        padding: "12px 24px",
        cursor: getCursor(),
        backgroundColor: getBackgroundColor(),
        color: getColor()
      }}
    >
      <ActionableIndicator invisible={!isActionable(option.key)}>
        <Typography onClick={handleClick} variant="h4" sx={{display: 'inline'}}>
          <T>{option.key}</T>
        </Typography>      
        {option.isDisabled && (
          <img
            src="icons/lock-icon.svg"
            alt="lock icon"
            style={{ marginLeft: "4px" }}
          />
        )}
        {
          option.externalUrl && (
            <img
              src="icons/external.svg"
              alt="external icon"
              style={{
                marginLeft: "4px",
                transform: languageDir === "rtl" ? "rotateY(180deg)" : "none"
              }} />
          )
        }
      </ActionableIndicator>
    </Stack>
  );
};

export { SettingsOption };
