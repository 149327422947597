import { T } from "../../hooks/useTranslate";
import { OrderSummaryCell } from "./OrderSummaryCell";

const formatCommissionMonth = (initialValue) => {
  if (!initialValue) return;

  const arr = initialValue.split("-");
  const year = arr[0];
  const month = arr[1];

  return `${month}/${year}`;
};

export const columns = [
  {
    headerName: "Order Details",
    headerClassName: "ordersTableHeader",
    sortable: false,
    width: 175,
    field: "id.unicity",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => <OrderSummaryCell order={params.row} />,
    renderHeader: () => <span data-test-id="order-details-column-header"><T>order_details</T></span>
  },
  {
    headerName: "Commission Mo.",
    field: "terms.period",
    headerClassName: "ordersTableHeader",
    width: 110,
    sortable: false,
    headerAlign: "right",
    align: "right",
    renderCell: (params) => <span data-test-id={`commission-month-value-${params.row.id.unicity}`}>{formatCommissionMonth(params.row.terms.period)}</span>,
    renderHeader: () => <span data-test-id="commission-column-header"><T>commission_month</T></span>
  },
  {
    headerName: "PV",
    width: 60,
    field: "terms.pv",
    headerClassName: "ordersTableHeader",
    sortable: false,
    headerAlign: "right",
    align: "right",
    renderCell: (params) => <div style={{ display: "grid", gridTemplateColumns: "25px auto", justifyItems: "center" }}>
      <span data-test-id={`pv-value-${params.row.id.unicity}`}>{params.row.fulfillmentStatus === "Cancelled" ? '-' : params.row.terms.pv}</span>
    </div>,
    renderHeader: () => <span data-test-id="pv-column-header"><T>pv</T></span>
  },
];