import { Box } from "@mui/material";
import { T } from "../../hooks/useTranslate";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";

import { OfficeAvatar } from "./../OfficeAvatar";
import { OfficeBox } from "./../OfficeBox";

const SettingsButton = ({
  rankDetails,
  userInfo,
  menuIsOpen,
  viewportIsSmall,
  openMenu,
  menuAnchorRef
}) => {
  return (
    <OfficeBox
      aria-label="open settings menu"
      className="flex-row settings-menu"
      onClick={openMenu}
      innerRef={menuAnchorRef}
      sx={{
        padding: "10px"
      }}
      id="settingsContainer"
    >
      <OfficeAvatar
        id="settingsAvatar"
        imageUrl={userInfo?.profilePictureUrl}
        firstInitial={userInfo?.name ? userInfo?.name[0] : ""}
      />
      {viewportIsSmall && (
        <>
          <Box id="settingsUsername"
            ml={1}
            mr={1}
            sx={{
              fontWeight: 600,
              fontSize: "12px"
            }}
          >
            {userInfo.name}
          </Box>
          {
            rankDetails?.rankTranslateAbbrKey &&
            <Box
              data-test-id="settings-button-rank-chip"
              sx={{
                backgroundColor: rankDetails?.backgroundColor,
                color: rankDetails?.color,
                fontWeight: "600",
                fontSize: "10px",
                padding: "1px 6px 1px 6px",
                borderRadius: "38px"
              }}>
              <T>{rankDetails?.rankTranslateAbbrKey}</T>
            </Box>
          }
        </>
      )}
      <Box>
        {menuIsOpen ? (
          <ArrowDropUpOutlined sx={{ color: "rgba(21, 56, 98, 0.5)" }} />
        ) : (
          <ArrowDropDownOutlined sx={{ color: "rgba(21, 56, 98, 0.5)" }} />
        )}
      </Box>
    </OfficeBox>
  )
}

export { SettingsButton }