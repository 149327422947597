import React, { useState } from "react";
import { T } from "../hooks/useTranslate";
import { Stack, Typography, Popover, Link } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from "@mui/icons-material/Launch";

const HelpIcon = ({ text1, text2, text3, externalLink, menuWidth }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to open the popover
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the popover
  const handlePopoverClose = () => {
    // If there is a link in the menu, delay onMouseLeave close by 1.5 seconds
    externalLink
      ? setTimeout(() => {
        setAnchorEl(null);
      }, 2500)
      : setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      {/* Help Icon */}
      <Stack
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Stack>
          <img src="icons/help.svg" alt="help icon" data-test-id="help-icon" />
        </Stack>
      </Stack>

      {/* Popover */}
      <Popover
        id="mouse-over-popover"
        sx={{
          mt: 1,
          // pointerEvents prop needed to stop infinite bounce on popover without a timeout
          pointerEvents: !externalLink && "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          style: {
            boxShadow:
              "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(145, 158, 171, 0.1)",
            border: "1px solid rgba(145, 158, 171, 0.1)",
            borderRadius: "12px",
          },
        }}
      >
        <Stack padding="18.5px" direction="row" spacing="18.5px" width={menuWidth && menuWidth}>
          <Stack>
            <InfoOutlinedIcon />
          </Stack>
          <Stack direction="column" maxWidth={text3 ? "200px" : "280px"} spacing={2}>
            {text1 && (
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                <T>{text1}</T>
              </Typography>
            )}
            {text2 && (
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                <T>{text2}</T>
              </Typography>
            )}
            {text3 && externalLink && (
              <Link href={externalLink} target="_blank" rel="noreferrer" sx={{ textDecoration: "none" }}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="h4">
                    <T>{text3}</T>
                  </Typography>
                  <LaunchIcon sx={{ height: "20px", cursor: "pointer" }} />
                </Stack>
              </Link>
            )}
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
};

export { HelpIcon };
