import { T } from "../../hooks/useTranslate";

import { Box, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";

import { CopyToClipboard } from "../CopyToClipboard";

import {
  iconSizingStyle,
  gridTextStyle,
  gridChildLeftWrapper,
} from "./drawer-styles";


const ProfileEmailLine = ({ customerHrefData, drawerType, eventLocation }) => {
  return (
    <>
      <Box sx={gridChildLeftWrapper}>
        <MailIcon sx={iconSizingStyle} />
      </Box>
      {customerHrefData.email !== "" ? (
        <Box component="span" sx={{ fontSize: "12px" }}>          
          <CopyToClipboard
            text={customerHrefData?.email}
            stringToCopy={customerHrefData?.email}
            iconId={`${drawerType}-profile-copy-email`}
            eventName="email_copy"
            eventLocation={eventLocation}
            sendEvent={true}
            userType={drawerType}
            userId={customerHrefData.unicity}
          />
        </Box>
      ) : (
        <Typography sx={gridTextStyle} data-testid={`${drawerType}-profile-email`}>
          <T>not_available</T>
        </Typography>
      )}
    </>
  )
}

export { ProfileEmailLine }