import React from "react";

import { TextField, Box } from "@mui/material";

const DisplayNameTextField = ({
  label,
  name,
  formik,
  changeHandler,
  handleKeyPress,
  showLoadingOverlay
}) => {
  const showError = Boolean(formik.errors[name]) && !showLoadingOverlay && formik.dirty;

  const handleBlur = () => {
    const trimmedValue = formik.values[name].trim();

    formik.setFieldValue(name, trimmedValue);
  };

  return (
    <TextField
      data-test-id={`${name}-text-field`}
      inputProps={{
        "data-test-id": `${name}-input-tag`,
        maxLength: 20,
        onBlur: handleBlur,
        style: {
          fontSize: ".875rem",
        }
      }}
      InputLabelProps={{
        "data-test-id": `${name}-input-label`,
        style: {
          fontSize: ".875rem",
        }
      }}
      fullWidth
      disabled={formik.touched[name] && Boolean(formik.errors[name])}
      label={label}
      variant="outlined"
      sx={{ marginTop: "35px" }}
      name={name}
      value={formik.values[name]}
      onChange={changeHandler}
      onKeyDown={handleKeyPress}
      error={showError}
      helperText={
        showError
          ? <Box data-test-id={`${name}-error-text`}>{formik.errors[name]}</Box>
          : ""
      }
      FormHelperTextProps={{
        "data-test-id": `helperText`
      }}
    />
  );
};

export { DisplayNameTextField }