import React from "react";
import { Stack, Typography, Divider, Link } from "@mui/material";
import { OfficeAvatar } from "../../../components/OfficeAvatar";
import { CopyToClipboard } from "../../../components/CopyToClipboard";
import styles from "../settingsScreenStyles";
import { formatPhoneNumber } from "../../../common/format-functions";
import { useTranslateKey } from "../../../hooks/useTranslate";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const InfoRow = ({ iconSrc, altText, text, copyText, linkPrefix }) => (
  <Stack
    sx={styles.infoRow}
    direction="row"
    alignItems="center"
    gap={1}
  >
    <img
      src={iconSrc}
      alt={altText}
      width="20px"
      height="20px"
    />
    <Typography sx={styles.infoRowText}>
      {linkPrefix ? (
        <Link href={`${linkPrefix}:${text}`}>
          {text}
        </Link>
      ) : (
        text
      )}
    </Typography>
    {copyText && (
      <CopyToClipboard
        sx={styles.copyToClipboardIcon}
        stringToCopy={copyText}
      />
    )}
  </Stack>
);

const SettingsSponsorEnrollerCard = ({ cardData, type, imageUrl, sponsorEnrollerIsSame }) => {
  const firstAndLastName = `${cardData.humanName.firstName} ${cardData.humanName.lastName}`;
  const phoneText = formatPhoneNumber(cardData.homePhone) ||
    formatPhoneNumber(cardData.mobilePhone) ||
    formatPhoneNumber(cardData.workPhone);
  const translateTzUnavailable = useTranslateKey("timezone_unavailable");
  const translateEnroller = useTranslateKey("enroller");
  const translateType = useTranslateKey(type);

  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const mainContainerFlexDirection = viewportIsSmall ? "row" : "column";

  return (
    <Stack
      direction={mainContainerFlexDirection}
      p={3}
      sx={{ ...styles.cardContainer, marginTop: type === "enroller" ? "1.5rem" : null }}
      data-test-id="main-container"
    >
      <Stack
        flexBasis="100%"
        data-test-id="left-col"
        gap={3}
      >
        <Stack
          direction="row"
          data-test-id="left-col-row-1"
          gap={2}
          alignItems="center"
        >
          <OfficeAvatar
            id={type}
            imageUrl={imageUrl}
            width={56}
            height={56}
            firstInitial={cardData?.humanName?.firstName[0]}
          />
          <Stack>
            <Typography sx={styles.nameText}>
              {firstAndLastName}
            </Typography>
            <Stack direction="row" alignItems="center">
              <Typography sx={styles.uidText}>
                UID: {cardData.unicity}
              </Typography>
              <CopyToClipboard
                sx={styles.copyToClipboardIcon}
                stringToCopy={cardData.unicity}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="row" gap={.5}>
          <Typography
            data-test-id="left-col-row-2"
            sx={styles.chip}
            mb={viewportIsSmall ? 0 : 3}
          >
            {translateType}
          </Typography>
          {
            sponsorEnrollerIsSame && (
              <Typography
                data-test-id="left-col-row-2"
                sx={styles.chip}
                mb={viewportIsSmall ? 0 : 3}
              >
                {translateEnroller}
              </Typography>
            )
          }
        </Stack>
      </Stack>
      <Divider
        orientation={viewportIsSmall ? "vertical" : "horizontal"}
        flexItem
      />
      <Stack
        flexBasis="100%"
        pl={3}
        gap={2.5}
        justifyContent="center"
        data-test-id="right-col"
        mt={viewportIsSmall ? 0 : 3}
      >
        <InfoRow
          linkPrefix="tel"
          iconSrc="icons/call.svg"
          altText="call icon"
          text={phoneText}
          copyText={phoneText}
        />
        <InfoRow
          linkPrefix="mailTo"
          iconSrc="icons/mail-solid.svg"
          altText="mail icon"
          text={cardData.email}
          copyText={cardData.email}
        />
        <InfoRow
          iconSrc="icons/pending-subscription.svg"
          altText="pending-subscription icon"
          text={cardData.timezone || translateTzUnavailable}
        />
      </Stack>
    </Stack>
  );
};

export { SettingsSponsorEnrollerCard };
