import { useState } from "react";
import { T } from "../hooks/useTranslate";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function HelpIconMobile({ text1, text2 }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Stack onClick={handleClick}>
        <img src="icons/help.svg" alt="help icon" data-test-id="help-icon-mobile" />
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(145, 158, 171, 0.1)",
            border: "1px solid rgba(145, 158, 171, 0.1)",
            borderRadius: "12px"
          }
        }}
      >
        <Stack padding="18.5px" direction="row" spacing="18.5px">
          <Stack>
            <InfoOutlinedIcon />
          </Stack>
          <Stack direction="column" maxWidth="180px" spacing={2}>
            {text1 && (
              <Typography sx={{ fontSize: "12px", fontWeight: "600" }}>
                <T>{text1}</T>
              </Typography>
            )}
            {text2 && (
              <Typography sx={{ fontSize: "12px", fontWeight: "400" }}>
                <T>{text2}</T>
              </Typography>
            )}
          </Stack>
        </Stack>
      </Popover>
    </div>
  );
}

export { HelpIconMobile }