import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useMixPanel } from "../../../hooks/useMixPanel";

import { T } from "../../../hooks/useTranslate";
import { useAuth } from "../../../hooks/useAuth";
import { useApi } from "../../../hooks/useApi";

import { OfficeBox } from "../../../components/OfficeBox";
import { BackdropLoadingOverlay } from "../../../components/BackdropLoadingOverlay";

import { PlacementDescription } from "./PlacementDescription";
import { PlacementForm } from "./PlacementForm";
import { PlacementModal } from "./PlacementModal";

import styles from "../settingsScreenStyles"

const SettingsPlacementProgramSection = () => {

  const { userInfo } = useAuth();
  const { name, unicityId } = userInfo
  const { logEvent } = useMixPanel();

  const { sendRequest } = useApi(false);

  const [showLoadingOverlay, setShowLoadingOverlay] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  const handleSaveChanges = async (values) => {
    logEvent("placement_submit", {
      location: "settings",
      user_type: values.selection
    })
    const { distributorName, distributorUid, sponsorName, sponsorUid, month } = values
    let placementReqUrl
    setShowLoadingOverlay(true)
    try {
      const customerReq = await sendRequest({
        method: "get",
        endpoint: `customers?id.unicity=${distributorUid}`,
      })
      if (customerReq?.data?.items.length > 0) {
        placementReqUrl = customerReq?.data?.items[0]?.href
      }
      const data = JSON.stringify({
        sponsor: {
          id: {
            unicity: sponsorUid.toString()
          },
          humanName: {
            fullName: sponsorName
          },
          customer: {
            id: distributorUid.toString(),
            humanName: {
              fullName: distributorName
            }
          },
          creator: {
            id: unicityId.toString(),
            humanName: {
              fullName: name
            }
          },
          period: month
        }
      })
      await sendRequest({
        method: "post",
        endpoint: `${placementReqUrl}`,
        data,
        addPrefix: false,
        addContentType: true
      })
      setFormSuccess(true)
    } catch (e) {
      setFormSuccess(false)
    }
    setShowLoadingOverlay(false)
    handleModalOpen()
  };

  const handleOverlayClose = () => {
    setShowLoadingOverlay(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    if (formSuccess) setFormSuccess(false)
  };

  return (
    <>
      <Stack direction="column" flex={1} gap={3}>
        <OfficeBox data-test-id="settings-placement-section" sx={styles.mainContent}>
          <Typography variant="h2" mb={5} data-test-id="settings-placement-title">
            <T>placement_program</T>
          </Typography>
          <PlacementDescription />
          <PlacementForm
            enrollerName={name}
            enrollerID={unicityId}
            handleSaveChanges={handleSaveChanges}
            success={formSuccess}
          />
        </OfficeBox>
        <BackdropLoadingOverlay
          showLoadingOverlay={showLoadingOverlay}
          handleClose={handleOverlayClose}
        />
      </Stack>
      <PlacementModal
        open={modalOpen}
        success={formSuccess}
        handleModalOpen={handleModalOpen}
        handleModalClose={handleModalClose}
      />
    </>
  );
};

export { SettingsPlacementProgramSection };
