import React from "react";
import { T } from "../hooks/useTranslate";

import { useNavigate, useLocation } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material";

import { StyledGridOverlay } from "../components/StyledGridOverlay";

const CustomNoRowsOverlay = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <StyledGridOverlay data-test-id="no-rows-overlay">
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", alignItems: "center", margin: "60px 0px" }}>
        <img src="icons/no-search-results.svg" alt="" style={{ width: "80px" }} />
        <Typography variant="h3"><T>empty_list</T></Typography>
        <Typography variant="h5" sx={{ width: "221px", textAlign: "center" }}>
          <T>custom_no_rows_overlay_text</T>
        </Typography>
        <Button
          sx={{
            height: "31px",
            paddingRight: "8px",
            paddingLeft: "8px",
            fontSize: "12px",
            backgroundColor: "#009ECF",
            borderRadius: "4px",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#009ECF90"
            }
          }}
          onClick={() => navigate("/referrals", { state: location })}
        >
          <T>go_to_referral_links</T>
        </Button>
      </Box>
    </StyledGridOverlay>

  )

}

export { CustomNoRowsOverlay }