import React from "react";
import { T } from "../../../../hooks/useTranslate";

import { Box, Typography } from "@mui/material"

const CollapsibleLastOrderDate = ({ row }) => {

  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const getFirstOrderDate = (str) => {
    return dateFormatter.format(new Date(str + "T00:00:00.000"))
  }

  return (
    <Box sx={{ flex: 1, backgroundColor: "rgba(21, 56, 98, 0.05);", borderRadius: "8px", padding: "12px" }}>
      <Typography sx={{ color: "#153862", opacity: ".6", fontSize: "11px" }}><T>last_order_date</T></Typography>
      <Typography sx={{ fontWeight: "500", fontSize: "12px" }}>{getFirstOrderDate(row.lastOrderDate)}</Typography>
    </Box>
  )
}

export { CollapsibleLastOrderDate }