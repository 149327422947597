import React, { useState, useEffect } from "react";

import { useApi } from "./useApi";
import { useAuth } from "./useAuth";

const { DateTime } = require("luxon");

const ActionHubContext = React.createContext([{}, () => { }]);

const ActionHubContextProvider = (props) => {
  //error
  const [error, setError] = useState(false);

  // new feel great
  const [feelGreatCount, setFeelGreatCount] = useState(null);
  const [feelGreatMembers, setFeelGreatMembers] = useState([]);
  const [feelGreatSortModel, setFeelGreatSortModel] = useState([
    { field: "firstFeelGreatOrderDate", sort: "desc" },
  ]);

  //reminders
  const [reminderCount, setReminderCount] = useState(null);
  const [reminders, setReminders] = useState([]);
  const [remindersSortModel, setRemindersSortModel] = useState([
    { field: "lastOrderDate", sort: "desc" },
  ]);

  //partials
  const [partialsCount, setPartialsCount] = useState(null);
  const [partials, setPartials] = useState([]);
  const [partialsSortModel, setPartialsSortModel] = useState([
    { field: "joinDate", sort: "desc" },
  ]);

  // pagniation
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(true);

  // auth and API
  const { userInfo } = useAuth();
  const { sendRequest } = useApi();

  const resetContext = () => {
    setFeelGreatCount(null);
    setFeelGreatMembers([]);
    setFeelGreatSortModel([
      { field: "firstFeelGreatOrderDate", sort: "desc" },
    ]);

    setReminderCount(null);
    setReminders([]);
    setRemindersSortModel([
      { field: "lastOrderDate", sort: "desc" },
    ]);

    setPartialsCount(null);
    setPartials([]);
    setPartialsSortModel([
      { field: "joinDate", sort: "desc" },
    ]);

    setPage(0);
    setPageSize(10);

    setLoading(true);
  }

  useEffect(() => {
    if (!userInfo.loggedIn) {
      resetContext();
    }
  }, [userInfo])


  useEffect(() => {
    if (!userInfo.loggedIn) return;
    
    const abortController = new AbortController();
    setLoading(true);
    const offset = page * pageSize;

    const awsUrl = `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/members`;

    let today = DateTime.now();
    let twoWeeksAgo = today.minus({ days: 14 }).toISODate();
    let thirtyFiveDaysAgo = today.minus({ days: 35 }).toISODate();

    const promise1 = sendRequest({
      method: "get",
      endpoint: `${awsUrl}?&offset=${offset}&limit=${pageSize}&sortField=${feelGreatSortModel[0].field}&firstFeelGreatOrderStartDate=${twoWeeksAgo}&sortDirection=${feelGreatSortModel[0].sort}`,
      addPrefix: false,
      abortController,
    });

    const promise2 = sendRequest({
      method: "get",
      endpoint: `${awsUrl}?&offset=${offset}&limit=${pageSize}&sortField=${remindersSortModel[0].field}&sortDirection=${remindersSortModel[0].sort}&purchasePeriodEndDate=${thirtyFiveDaysAgo}`,
      addPrefix: false,
      abortController,
    });

    const promise3 = sendRequest({
      method: "get",
      endpoint: `${awsUrl}?offset=${offset}&limit=${pageSize}&sortField=${partialsSortModel[0].field
        }&sortDirection=${partialsSortModel[0].sort
        }&joinedStartDate=${twoWeeksAgo}&joinedEndDate=${today.toISODate()}&filterHasOrders=0`,
      addPrefix: false,
      abortController,
    });

    Promise.all([promise1, promise2, promise3])
      .then((values) => {
        const [res1, res2, res3] = values;
        setFeelGreatMembers(
          res1?.data?.items.map((item, idx) => {
            return { id: idx, ...item };
          })
        );
        setFeelGreatCount(res1.data.hits);

        setReminders(
          res2?.data?.items.map((item, idx) => {
            return { id: idx, ...item };
          })
        );
        setReminderCount(res2?.data?.hits);

        setPartials(
          res3?.data?.items.map((item, idx) => {
            return { id: idx, ...item };
          })
        );
        setPartialsCount(res3?.data?.hits);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);

        setPartialsCount(0);
        setFeelGreatCount(0);
        setReminderCount(0);

        setError(true);
      });

    return () => abortController.abort();
    // eslint-disable-next-line
  }, [
    page,
    pageSize,
    remindersSortModel,
    feelGreatSortModel,
    partialsSortModel,
    userInfo,
  ]);

  // reset page to 0 when sortModel is changed
  useEffect(() => {
    setPage(0);
  }, [remindersSortModel, feelGreatSortModel, partialsSortModel]);

  return (
    <ActionHubContext.Provider
      value={{
        feelGreatCount,
        feelGreatMembers,
        reminderCount,
        reminders,
        page,
        setPage,
        pageSize,
        setPageSize,
        loading,
        remindersSortModel,
        setRemindersSortModel,
        feelGreatSortModel,
        setFeelGreatSortModel,
        partials,
        setPartials,
        partialsCount,
        setPartialsCount,
        partialsSortModel,
        setPartialsSortModel,
        error,
        resetContext
      }}
    >
      {props.children}
    </ActionHubContext.Provider>
  );
};

export { ActionHubContext, ActionHubContextProvider };
