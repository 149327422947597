import React from "react";
import { T, useTranslateKey } from "../hooks/useTranslate";

import { Box, Link } from "@mui/material"


const ExplainedButton = ({
  testId,
  imgUrl,
  altText,
  buttonText,
  urlKey
}) => {
  const href = useTranslateKey(urlKey);
  return (
    <Link
      data-test-id={testId}
      href={href}
      underline="none"
      target="_blank"
      rel="noreferrer"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          backgroundColor: "rgba(255, 255, 255, 0.6)",
          border: "1px solid rgba(145, 158, 171, 0.2)",
          borderRadius: "4px",
          padding: "6px 12px",
          fontWeight: "600",
          fontSize: "12px",
          color: "#7287A0",
          cursor: "pointer"
        }}>
        <Box>
          <img src={imgUrl} alt={altText} />
        </Box>
        <Box>
          <T>{buttonText}</T>
        </Box>
      </Box>
    </Link>
  )
}

export { ExplainedButton }