import React, { useContext } from "react";
import { T } from "../../../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { RankQualAlertIcon } from "./RankQualAlertIcon";
import { LanguageContext } from "../../../hooks/LanguageContext";


const RankQualPvTvBox = ({
  type,
  values,
  inDrawer,
  isError
}) => {
  const { localizeNumber } = useContext(LanguageContext);
  const isQualified = values?.qualified;

  return (
    <Stack sx={{
      background: inDrawer ? "#FFFFFF" : "rgba(145, 158, 171, 0.05)",
      flex: 1,
      borderRadius: "8px"
    }}>
      <Stack id="1" direction="row" justifyContent={"space-between"}>
        <Stack pl={2} pt={2} pb={2} pr={2}>
          <Stack>
            <Typography
              variant="h4"
              sx={{
                opacity: ".6"
              }}
            >
              <T>{type}</T>
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography
              data-test-id={`${type}-value`}
              sx={{
                fontWeight: "700",
                fontSize: "16px",
                color: "#153862"
              }}>
              {isError ? 0 : localizeNumber(values?.value || 0)}&nbsp;
            </Typography>
            {
              type !== "total_ov" && (
                <Typography
                  data-test-id={`${type}-required-value`}
                  variant="h9"
                  sx={{
                    fontSize: "16px",
                    color: "#153862",
                    opacity: ".6"
                  }}
                  noWrap={true}
                >
                  / {isError ? 0 : localizeNumber(values?.requiredValue || 0)}
                </Typography>
              )
            }
          </Stack>
        </Stack>
        {!isError && type !== "total_ov" && <Stack p={1}>
          <RankQualAlertIcon
            type={type}
            values={values}
            isQualified={isQualified}
          />
        </Stack>}
      </Stack>
    </Stack>
  )
}

export { RankQualPvTvBox }