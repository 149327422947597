import React, { useState, useEffect } from "react";
import { T, useTranslateKey } from "../hooks/useTranslate";
import { useAuth } from "../hooks/useAuth";
import { Link } from "react-router-dom";
import { Alert } from "@mui/material";

const LoginErrorAlert = () => {
  const { userInfo, errorCode } = useAuth();
  const translatedUrl = useTranslateKey("member_help_center_url");
  const [isShowSuspendedTerminatedError, setIsShowSuspendedTerminatedError] = useState(false);

  useEffect(() => {
    const specialError = [ 'Suspended', 'Terminated' ]
    if (!specialError.includes(userInfo?.status)) return
    setIsShowSuspendedTerminatedError(localStorage.getItem('showSuspendedTerminatedError') === 'true')

    // Hide the error after 12 seconds
    setTimeout(() => {
      setIsShowSuspendedTerminatedError(false)
      localStorage.removeItem('showSuspendedTerminatedError')
    }, 12000)
  }, [ setIsShowSuspendedTerminatedError, userInfo ])

  if (errorCode === 429) {
    return (
      <Alert id="loginError" severity="warning">
        <T>too_many_requests</T>
      </Alert>
    )
  }

  return (
    <>
      {["error", "unauthorized"].includes(userInfo.state) && (
        <Alert id="loginError" severity="error">
          {userInfo.state === "error" && (
            <T>login_or_password_is_incorrect</T>
          )}
          {userInfo.state === "unauthorized" && (
            <span>
              <T>unauthorized_login</T>&nbsp;
              <Link id="memberHelpCenterLink" href={translatedUrl}>
                <T>member_help_center</T>
              </Link>
            </span>
          )}
        </Alert>
      )}
      {
        (isShowSuspendedTerminatedError && (userInfo.state === "complete" || userInfo.state === "uninitialized")) && (
          <Alert 
            id="suspendedTerminatedError" 
            severity="error"
            sx={{
              padding: "15px"
            }}
          >
            <span>
              <T>suspended_terminated_error</T>&nbsp;
              <Link id="dcLink" href="mailto:dc@unicity.com">
                <T>dc_link</T>
              </Link>
              .
            </span>
          </Alert>
        )
      }
    </>
  )
}

export { LoginErrorAlert }