import React from "react";
import { T } from "../../../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { styles } from "../partners.styles";

const MemberChip = () => {
  return (
    <Stack justifyContent="center">
      <Typography sx={styles.inactiveChip}>
        <T>member</T>
      </Typography>
    </Stack>
  )
}

export { MemberChip }