import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ArticleDrawerContent } from "./ArticleDrawerContent";

const ArticleDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const viewportIsSmall = !useMediaQuery(theme.breakpoints.up("sm"));

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const articleId = searchParams.get("articleId");

  useEffect(() => {
    const drawer = searchParams.get("drawer");
    if (drawer === "article") {
      setIsDrawerOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    navigate(`${location.pathname}`, { replace: true });
  }

  return (
    <div>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <Box
          sx={{
            width: "100vw",
            maxWidth: !viewportIsSmall ? 400 : null,
            padding: 0,
          }}
          role="presentation"
        >
          <ArticleDrawerContent articleId={articleId} setIsDrawerOpen={setIsDrawerOpen} />
        </Box>
      </Drawer>
    </div>
  );
};

export { ArticleDrawer };
