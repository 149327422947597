import { T, TM } from "../../hooks/useTranslate";
import { DateTime } from "luxon";
import { Menu, Box, MenuItem } from "@mui/material";
import { styles } from "./partners.styles";


const PartnersMonthSelectMenu = ({
  anchorEl,
  open,
  handleClose,
  contextUserJoinDate,
  contextMonthRange,
  selectedMonth,
  setSelectedMonth,
  viewportIsSmall,
  logEvent
}) => {
  return (
    <Menu
      id="sort-partners-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "sort-partners-menu-button",
        style: {
          paddingBottom: "0px",
        }
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        style: {
          ...styles.paperPropsStyle,
          width: viewportIsSmall && "100%"
        },
      }}
    >
      <Box pt={2} pb={2}>
        <Box
          data-test-id="partners-month-dropdown-subheader"
          sx={styles.subheaderStyle}
          pl={2}>
          <T>select_period</T>
        </Box>
        {contextMonthRange.map(item => {
          // if there is a join date for the context user, get boolean for each month item
          // else show all three months
          let isDisabled = false;
          if (contextUserJoinDate) {
            const monthTimestamp = DateTime.fromISO(item.value).ts;
            const joinDateTimestamp = DateTime.fromISO(contextUserJoinDate).ts;
            isDisabled = monthTimestamp < joinDateTimestamp;
          }

          return (
            <MenuItem
              value={item.value}
              key={item.value}
              data-test-id={`partners-month-dropdown-listitem`}
              onClick={() => {
                setSelectedMonth(item.value);
                handleClose();
                logEvent("partners_month_select", {
                  month: item.label,
                  location: "partners",
                });
              }}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                minWidth: "230px",
                fontSize: "14px"
              }}
              disabled={isDisabled}
            >
              <TM>{item.label}</TM>
              {selectedMonth === item.value && <img src="icons/check.png" alt="checkmark" />}
            </MenuItem>
          );
        })}
      </Box>
    </Menu>
  )
}

export { PartnersMonthSelectMenu }