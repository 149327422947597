const LANGUAGE_DETAILS = [
  {
    countryCode: "en-US",
    label: "english"
  },
  {
    countryCode: "es-ES",
    label: "español"
  },
  {
    countryCode: "de-DE",
    label: "deutsch"
  },
  {
    countryCode: "fr-FR",
    label: "français"
  },
  {
    countryCode: "ru-RU",
    label: "pусский"
  },
  {
    countryCode: "ar-EG",
    label: "العربية"
  },
  {
    countryCode: "ja-JP",
    label: "日本語"
  },
  {
    countryCode: "zh-HK",
    label: "粵語"
  },
  {
    countryCode: "zh-CN",
    label: "普通话"
  }
]

function hasCountryCode(array, targetCountryCode) {
  return array.some(item => item.countryCode === targetCountryCode);
}

export { LANGUAGE_DETAILS, hasCountryCode }