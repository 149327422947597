import { useContext } from 'react';

// https://mui.com/x/react-date-pickers/faq/#what-is-the-democontainer-and-demoitem
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { Box, IconButton, InputAdornment } from '@mui/material';
import { LanguageContext } from '../../hooks/LanguageContext';
import CalendarToday from '@mui/icons-material/CalendarToday';

import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/es";
import "dayjs/locale/es";
import "dayjs/locale/es";

const DateRangePickerComponent = ({ dateRangePickerValue, setDateRangePickerValue }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const currentLocale = currentLanguage.split("-")[0];

  return (
    <Box
      data-test-id="date-range-picker-container"
      pl={2}
      pr={2}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLocale}>
        <DemoContainer components={['SingleInputDateRangeField']}>
          <DemoItem component="DateRangePicker">
            <DateRangePicker
              slots={{
                field: SingleInputDateRangeField
              }}
              name="custom-date-range"
              disableFuture
              value={dateRangePickerValue}
              onChange={(newValue) => setDateRangePickerValue(newValue)}
              slotProps={{
                textField: {
                  inputProps: {
                    style: { fontSize: ".9rem" },
                  },
                  InputProps: {
                    style: { paddingRight: ".5rem" },
                    endAdornment: (
                      <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                        <IconButton>
                          <CalendarToday style={{
                            fontSize: 20,
                          }} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                }
              }}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </Box >
  );
}

export { DateRangePickerComponent }