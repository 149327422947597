import { useContext } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

import { LanguageContext } from "./LanguageContext";

const LanguageDirectionWrapper = (props) => {
  const { languageDir } = useContext(LanguageContext);

  // Create rtl cache
  const rtlCache = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const ltrCache = createCache({
    key: 'mui',
  });

  return (
    <CacheProvider value={languageDir === "ltr" ? ltrCache : rtlCache}>
      {props.children}
    </CacheProvider>
  )
}

export { LanguageDirectionWrapper }