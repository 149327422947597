import React from "react";

import { Stack } from "@mui/material";
import { OfficeBox } from "../../components/OfficeBox";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MembersFilterButtons } from "./MembersFilterButtons";
import { DatagridSearchInput } from "../../components/DatagridSearch/DatagridSearchInput";
import { UpdatedDate } from "../../components/UpdatedDate";

const MembersSearchFilterSection = ({
  setSearchField,
  columnVisibility,
  setColumnVisibility,
  filterModel,
  setFilterModel,
  handlePageChange,
  clearFilters,
  apiInProgress,
  gridUpdateTimestamp
}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <OfficeBox mb={2}>
      <Stack
        id="partners-data-grid-head-section-row"
        direction={viewportIsExtraLarge ? "row" : "column"}
        justifyContent="space-between"
        gap={viewportIsExtraLarge ? 2 : 1}
        mr={viewportIsSmall ? 3 : 2}
        ml={viewportIsSmall ? 3 : 2}
        mt={2}
      >
        <DatagridSearchInput
          type="members"
          setSearchField={setSearchField}
        />
        <MembersFilterButtons
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          handlePageChange={handlePageChange}
          apiInProgress={apiInProgress}
          clearFilters={clearFilters}
          columnVisibility={columnVisibility}
          setColumnVisibility={setColumnVisibility}
        />
      </Stack>
      <Stack
        ml={viewportIsSmall ? 3 : 2}
        mb={2}
      >
        {!apiInProgress && <UpdatedDate date={gridUpdateTimestamp} showUnavailable />}
      </Stack>
    </OfficeBox>
  )
}

export { MembersSearchFilterSection }