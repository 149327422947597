import { Modal, Box, Typography, Button, Rating, Divider, Stack } from '@mui/material';
import { StarRateRounded } from '@mui/icons-material';
import { T, useTranslateKey } from '../hooks/useTranslate';
import useFeedback from 'hooks/useFeedback';
import { useFeedbackContext } from 'hooks/FeedbackContext';

const ratingLabels = {
  1: 'Unacceptable',
  2: 'Weak',
  3: 'Acceptable',
  4: 'Good',
  5: 'Excellent',
};

const FeedbackModal = ({ open, onClose }) => {
  const {
    hover,
    setHover,
    rating,
    setRating,
    handleSkip,
    handleFeedbackSubmit,
    isFeedbackSubmitted
  } = useFeedback(onClose, ratingLabels)
  const { titleKeys } = useFeedbackContext()
  const ratingLabel = useTranslateKey(ratingLabels[hover !== -1 ? hover : rating]?.toLowerCase());
  const translatedTitleKey = useTranslateKey(titleKeys?.modalTitleKey || "");

  return (
    <Modal
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          onClick: () => {
            handleSkip()
            onClose()
          }
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '315px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          padding: "25px",
          borderRadius: "12px",
          outline: 'none',
        }}
      >
        {
          !isFeedbackSubmitted && (
            <Typography
              textAlign="center"
              gutterBottom
              sx={{
                fontSize: '19px',
                fontWeight: 700,
              }}
            >
              {translatedTitleKey}
            </Typography>
          )
        }
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={4}
        >
          {
            isFeedbackSubmitted
              ? (
                <Stack
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src="icons/ty-image.svg"
                    alt="submit-feedback"
                    width={159}
                    height={184}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "23px",
                      textAlign: "center"
                    }}
                  >
                    <T>thank_you</T>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "16px",
                      textAlign: "center"
                    }}
                    mt={3}
                  >
                    <T>submit_feedback</T>
                  </Typography>
                </Stack>
              )
              : (
                <Stack>
                  <Rating
                    name="hover-feedback"
                    value={rating}
                    precision={1}
                    onChange={(_, newValue) => {
                      setRating(!newValue ? 0 : newValue);
                    }}
                    onChangeActive={(_, newHover) => {
                      setHover(newHover);
                    }}
                    icon={<StarRateRounded fontSize="inherit" sx={{ borderRadius: '5px' }} />}
                    emptyIcon={<StarRateRounded fontSize="inherit" style={{ color: '#cfcfcf' }} />}
                    sx={{
                      '& .MuiRating-iconFilled': {
                        color: '#ff5566',
                      },
                      '& .MuiRating-iconHover': {
                        color: '#ff5566',
                      },
                      fontSize: '4rem',
                    }}
                    size="large"
                  />
                  <Typography variant="body2" color="text.secondary" align="center" mt={1}>
                    {ratingLabel === "{{undefined}}" ? '' : ratingLabel}
                  </Typography>
                </Stack>
              )
          }
        </Stack>
        <Divider sx={{ mt: !isFeedbackSubmitted ? 7 : 3 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          {
            !isFeedbackSubmitted
              ? (
                <Stack flexDirection="row" justifyContent="flex-end">
                  <Button
                    id="skip-button"
                    data-test-id="skip-survey-button"
                    aria-label="skip survey"
                    sx={{
                      color: "#153862",
                      backgroundColor: "transparent",
                      height: "34px",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      fontSize: "12px",
                      padding: "8px 12px 8px 12px",
                      "&:hover": {
                        backgroundColor: "#FFFFFF",
                      },
                      marginTop: "5px"
                    }}
                    onClick={handleSkip}
                  >
                    <T>skip</T>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={rating === 0}
                    onClick={() => handleFeedbackSubmit()}
                    sx={{ padding: "12px 16px", ml: 1, fontSize: "12px" }}
                  >
                    <T>submit_feedback_card</T>
                  </Button>
                </Stack>
              )
              : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onClose()}
                  sx={{ padding: "12px 16px", ml: 1, fontSize: "12px" }}
                >
                  <T>close</T>
                </Button>
              )
          }
        </Box>
      </Box>
    </Modal>
  );
};

export default FeedbackModal;
