import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Drawer } from "@mui/material";
import { MemberContext } from "../../hooks/MemberContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MemberProfileDrawerContent } from "./MemberProfileDrawerContent";
import { OrderSummaryDrawerContent } from "../../components/drawer/OrderSummaryDrawerContent";
import { OrderDetailDrawerContent } from "../../components/drawer/OrderDetailDrawerContent";
import { ReferralSummaryDrawerContent } from "../../components/drawer/ReferralSummaryDrawerContent";

const drawerContentComponents = {
  profile: MemberProfileDrawerContent,
  orders: OrderSummaryDrawerContent,
  orderDetails: OrderDetailDrawerContent,
  referrals: ReferralSummaryDrawerContent,
};

const MembersDrawer = () => {
  const [drawerContent, setDrawerContent] = useState(null);
  const [orderHref, setOrderHref] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { isDrawerOpen, setIsDrawerOpen, setMemberId, resetContext } = useContext(MemberContext);

  const theme = useTheme();
  const viewportIsSmall = !useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = () => {
    setIsDrawerOpen(false);
    resetContext();
    navigate(`${location.pathname}`, { replace: true });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const drawer = searchParams.get("drawer");
    if (drawer === "member") {
      setIsDrawerOpen(true);
      setDrawerContent(searchParams.get("drawerContent"));
      setMemberId(searchParams.get("id"));
      setOrderHref(searchParams.get("orderHref"));
    } else {
      setIsDrawerOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const DrawerContentComponent = drawerContentComponents[drawerContent];

  return (
    <div>
      <Drawer anchor="right" open={isDrawerOpen} onClose={handleClose}>
        <Box
          sx={{
            width: "100vw",
            maxWidth: !viewportIsSmall ? 400 : null,
            padding: 0,
          }}
          role="presentation"
        >
          {DrawerContentComponent && <DrawerContentComponent orderHref={orderHref} />}
        </Box>
      </Drawer>
    </div>
  );
};

export { MembersDrawer };
