import React from "react";
import { T } from "../../hooks/useTranslate";

import { Button, CircularProgress, Stack } from "@mui/material";

const LoadMoreOrdersButton = ({ customerOrdersOffset, setCustomerOrdersOffset, customerOrdersOffsetLoading }) => {
  const handleClick = () => {
    setCustomerOrdersOffset(customerOrdersOffset + 10);
  };

  return (
    <Stack
      mt={1}
      alignItems="center"
    >
      {
        customerOrdersOffsetLoading ? (
          <CircularProgress mt={2} />
        ) : (
          <Button sx={{ padding: "0px 16px" }} onClick={handleClick}>
            <T>see_more</T>
          </Button>
        )
      }

    </Stack>

  )
}

export { LoadMoreOrdersButton }