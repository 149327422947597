// https://stackoverflow.com/questions/68942362/how-to-use-debounce-with-react
import { useState, useEffect, useRef, useCallback } from "react";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const handlerRef = useRef(null);

  useEffect(() => {
    handlerRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handlerRef.current);
    };
  }, [value, delay]);

  const cancel = useCallback(() => {
    clearTimeout(handlerRef.current);
  }, []);

  return [debouncedValue, cancel];
}

export { useDebounce };
