import { useState, useEffect } from "react";
import { useApi } from "./useApi";

const useConfig = (path) => {
  // Custom hook to fetch configurations
  const { sendRequest } = useApi(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        sendRequest({
          endpoint: `${process.env.REACT_APP_OFFICE_CONFIG_URL}/${path}`,
          addPrefix: false,
          addToken: false,
        }).then((data) => {
          setData(data || {});
        });
      } catch (err) {
        setError(err);
        console.log("An error has occurred retrieving configurations.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  return { data, loading, error };
};

export { useConfig };