import React from "react";
import { useTranslateKey } from "../../../hooks/useTranslate";

import { Box, Stack } from "@mui/material";
import { SettingsTextField } from "../SettingsTextField"
import { PersonalInfoStateSelect } from "./PersonalInfoStateSelect";
import { formatLocation } from "../../../common/format-functions";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const PersonalInfoTextFieldContainer = ({
  personalInfo,
  stateOptions,
  formik
}) => {
  const theme = useTheme();
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));

  const stackDirection = viewportIsMedium ? "row" : "column";
  const stackGap = viewportIsMedium ? 1 : 3.4;
  const stackGapContainer = viewportIsMedium ? 4.4 : 3.4;

  const fullNameLabel = useTranslateKey("full_name");
  const countryLabel = useTranslateKey("country");
  const optionalLabel = useTranslateKey("optional");
  const address1Label = `${useTranslateKey("address")} 1`
  const address2Label = `${useTranslateKey("address")} 2 (${optionalLabel})`
  const cityLabel = useTranslateKey("city");
  const stateLabel = useTranslateKey("state");
  const zipCodeLabel = useTranslateKey("zip_code");
  // const taxLabel = useTranslateKey("tax_id_ssn");

  return (
    <Stack mb={3} mt={4.4} gap={stackGapContainer} data-test-id="text-field-container">
      <Stack direction={stackDirection} gap={stackGap} data-test-id="text-field-row-1">
        <SettingsTextField
          name="full-name"
          label={fullNameLabel}
          content={personalInfo.humanName.fullName}
          isLocked={true}
          formik={formik}
        />
        <SettingsTextField
          name="country"
          label={countryLabel}
          content={formatLocation({ country: personalInfo.mainAddress.country })}
          countryCode={formik.values.country}
          isLocked={true}
          isCountry={true}
          formik={formik}
        />
      </Stack>
      <Stack direction={stackDirection} gap={stackGap} data-test-id="text-field-row-2">
        <SettingsTextField
          name="address1"
          label={address1Label}
          isLocked={false}
          formik={formik}
        />
        <SettingsTextField
          name="address2"
          label={address2Label}
          content={formik.values.address2}
          isLocked={false}
          formik={formik}
        />
      </Stack>
      <Stack direction={stackDirection} gap={stackGap} data-test-id="text-field-row-3">
        <Box sx={{ flexBasis: "100%" }}>
          <SettingsTextField
            name="city"
            label={cityLabel}
            content={formik.values.city}
            isLocked={false}
            formik={formik}
          />
        </Box>
        <Stack direction="row" gap={1} sx={{ flexBasis: "100%" }}>
          <PersonalInfoStateSelect
            name="state"
            value={formik.values.state}
            stateLabel={stateLabel}
            stateOptions={stateOptions}
            formik={formik}
          />
          <SettingsTextField
            name="zip"
            label={zipCodeLabel}
            content={formik.values.zip}
            isLocked={false}
            formik={formik}
          />
        </Stack>
      </Stack>
      {/* need taxId from API */}
      {/* <Stack direction="row" gap={1} width="49.5%">
        <SettingsTextField
          name="tax-id"
          label={taxLabel}
          content={personalInfo.humanName.fullName}
          isLocked={true}
        />
      </Stack> */}
    </Stack>
  )
}

export { PersonalInfoTextFieldContainer }