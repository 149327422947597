import React from "react";
import { T } from "../../hooks/useTranslate";

import { Stack, Typography, Link } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router";

const EarningsScreenBottomLink = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/earnings/statements")
  }
  return (
    <Link
      onClick={handleClick}
      underline="hover"
      sx={{
        "&:hover": {
          cursor: "pointer"
        }
      }}
    >
      <Stack flexDirection="row" p={3} alignItems="center" justifyContent="space-between">
        <Typography variant="h4"><T>view_all_statements</T></Typography>
        <ArrowForwardIcon sx={{ height: "20px", width: "20px" }} />
      </Stack>
    </Link>
  )
}

export { EarningsScreenBottomLink }