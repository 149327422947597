import React, { useState, useEffect } from "react";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { T, useTranslateKey } from "../../../hooks/useTranslate";
import { Box, Typography, Divider } from "@mui/material";
import { PersonalInfoTextFieldContainer } from "./PersonalInfoTextFieldContainer";
import { PersonalInfoButtonContainer } from "./PersonalInfoButtonContainer";
import { OfficeBox } from "../../../components/OfficeBox";
import { useApi } from "../../../hooks/useApi";
import styles from "../settingsScreenStyles";
import { deepEqual } from "../../../common/format-functions";

const AccountInfoSection = ({ showLoadingOverlay, setShowLoadingOverlay, customerInfo }) => {
  const [personalInfo, setPersonalInfo] = useState(null);
  const [valuesFromApi, setValuesFromApi] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [isModified, setIsModified] = useState(false);
  const [error, setError] = useState(null);

  const { sendRequestAsCallback } = useApi();

  const translatedValidateError = useTranslateKey("error_validate_update_address");
  const translatedAddressLine1error = useTranslateKey("address1_not_blank");

  const validationSchema = Yup.object({
    address1: Yup.string().required(translatedAddressLine1error),
  });

  // formik is used for form state management
  const formik = useFormik({
    initialValues: valuesFromApi,
    validationSchema,
    onSubmit: () => { }
  });

  const fetchStateOptions = async (country) => {
    const regionsResponse = await sendRequestAsCallback({
      endpoint: `countries/${country}/regions.json`
    });
    setStateOptions(regionsResponse.data?.items);
  }

  useEffect(() => {
    if (customerInfo) {
      setPersonalInfo(customerInfo);
      const { address1, address2, city, state, zip, country } = customerInfo.mainAddress
      formik.setValues({ address1, address2, city, state, zip, country });
      setValuesFromApi({ address1, address2, city, state, zip, country });
      fetchStateOptions(country);
    }
    // eslint-disable-next-line
  }, [customerInfo])

  useEffect(() => {
    const isUpdateBodyModified = !deepEqual(valuesFromApi, formik.values);
    const hasErrors = Object.keys(formik.errors).length !== 0;

    setIsModified(isUpdateBodyModified && !hasErrors);
    // eslint-disable-next-line
  }, [formik.values, formik.errors]);

  const handleFormReset = () => {
    formik.setValues(valuesFromApi);
  };

  const handleSaveChanges = async () => {
    const validateUsAddress = async () => {
      try {
        const validateUsAddressRes = await sendRequestAsCallback({
          method: "get",
          endpoint: `addresses?country=${formik.values.country}&address1=${formik.values.address1}&address2${formik.values.address2}=&state=${formik.values.state}&zip=${formik.values.zip}&city=${formik.values.city}`,
        });

        if (validateUsAddressRes.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    };

    const updatePersonalInfo = async () => {
      try {
        // post new info
        const updatePersonalInfoResponse = await sendRequestAsCallback({
          method: "post",
          endpoint: "customers/me",
          addContentType: true,
          data: JSON.stringify({
            mainAddress: formik.values
          })
        });

        if (updatePersonalInfoResponse.status === 200) {
          formik.setValues(updatePersonalInfoResponse.data.mainAddress);
          setValuesFromApi(updatePersonalInfoResponse.data.mainAddress);
        }
      } catch (error) {
        setError(error);
      }
    };

    const validateAndUpdate = async () => {
      setShowLoadingOverlay(true);
      let isValid = false;

      try {
        if (formik.values.country === "US") {
          isValid = await validateUsAddress();
          if (!isValid) {
            return false;
          } else {
            await updatePersonalInfo();
            return true;
          }
        } else {
          await updatePersonalInfo();
          return true;
        }
      } finally {
        setShowLoadingOverlay(false);
        return isValid || formik.values.country !== "US";
      }
    }

    setShowLoadingOverlay(true);
    const result = await validateAndUpdate();
    setShowLoadingOverlay(false);

    if (!result) {
      alert(translatedValidateError);
    } else {
      setIsModified(false);
    }
  };

  if (error) {
    return (
      <Box data-test-id="error-box">
        Error loading personal information: {error.message}
      </Box>
    );
  }

  return (
    <OfficeBox data-test-id="account-info-section" sx={styles.mainContent}>
      <Typography variant="h2" mb={5} data-test-id="account-info-title">
        <T>account_info</T>
      </Typography>
      <Typography variant="h3" sx={{ lineHeight: '1.375rem' }} data-test-id="name-address-title">
        <T>name_and_address</T>
      </Typography>
      <Typography sx={styles.subText} data-test-id="info-description">
        <T>well_use_this_information</T>
      </Typography>
      {personalInfo && (
        <>
          <PersonalInfoTextFieldContainer
            personalInfo={personalInfo}
            stateOptions={stateOptions}
            formik={formik}
          />
          <Divider />
          <PersonalInfoButtonContainer
            isModified={isModified}
            handleFormReset={handleFormReset}
            handleSaveChanges={handleSaveChanges}
          />
        </>
      )}
    </OfficeBox>
  )
}

export { AccountInfoSection }