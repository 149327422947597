import { useState } from "react";
import { T } from "../../hooks/useTranslate";

import { Typography, Grid, Stack, Button, Alert } from "@mui/material";
import { styles } from "../DashboardScreen/News/news.styles";
import { OfficePage } from "../../components/OfficePage";
import { useNews } from "../../hooks/useNews";
import { AllNewsLoadingSkeleton } from "./AllNewsLoadingSkeleton";
import { NewsErrorComponent } from "../DashboardScreen/News/NewsErrorComponent";
import { AllNewsArticleCard } from "./AllNewsArticleCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const AllNewsScreen = () => {
  const { news, loading, error } = useNews();
  const [articlesShown, setArticlesShown] = useState(6);
  const theme = useTheme();
  const viewportIsSmall = !useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const showReadMoreButton = news.length > articlesShown;

  const handleReadMoreClick = () => {
    setArticlesShown(articlesShown + 6);
  }

  const handleBackArrowClick = () => {
    return navigate("/dashboard", { replace: true })
  }

  const renderNews = () => {

    if (error) {
      return <NewsErrorComponent />;
    }
    if (loading) {
      return (
        <Stack m={viewportIsSmall && 3}>
          <AllNewsLoadingSkeleton />
        </Stack>
      )
    }

    const articles = news.slice(0, `${articlesShown}`);

    if (news.length > 0) {
      return (
        <Grid
          data-test-id="article-cards-grid-container"
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
        >
          {articles.map((article, index) => (
            <Grid
              data-test-id="article-cards-grid-item"
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              key={index}
              sx={styles.skeletonGridItem}
            >
              <AllNewsArticleCard article={article} />
            </Grid>
          ))}
        </Grid>
      )
    }

    return <Alert severity="info"><T>no_news_to_display</T></Alert>;
  };


  return (
    <OfficePage pageTitle="unicity_news" showBackArrow={true} onBackArrowClick={handleBackArrowClick}>
      <Typography variant="h2" mb={2} ml={viewportIsSmall && 3}>
        <T>latest_news</T>
      </Typography>
      {renderNews()}
      {
        showReadMoreButton && (
          <Stack alignItems="center" mt={3}>
            <Button sx={{
              ...styles.readMoreButton,
              padding: "4px 12px"
            }}>
              <Typography
                data-test-id={`see_more_articles`}
                variant="h4"
                onClick={handleReadMoreClick}
              >
                <T>see_more</T>
              </Typography>
            </Button>
          </Stack>
        )
      }
    </OfficePage>
  )
}

export { AllNewsScreen }