import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { T } from "../../hooks/useTranslate";

import { PartnersContext } from "../../hooks/PartnersContext";

import { FormControlLabel, Radio, Box } from "@mui/material";

const FilterRadioButton = ({ text, value, onChange, type }) => {
  const { filterSelection, sortValue, orderValue } = useContext(PartnersContext);
  const location = useLocation();
  const ariaLabelText = `filter: ${text}`

  const useOpacity = () => {
    if (type === "filterOption") {
      return filterSelection !== value;
    } else if (type === "sortOption") {
      return sortValue !== value;
    } else if (type === "orderOption") {
      return orderValue !== value;
    } return false;
  }

  return (
    <FormControlLabel
      aria-label={ariaLabelText}
      value={value}
      data-test-id={`radio-button-listitem-${text.replaceAll("_", "-")}`}
      control={<Radio
        disabled={!location.pathname.includes("search-results") && value === "relevance"}
        sx={{
          color: 'rgba(21, 56, 98, 0.6)'
        }} />}
      label={
        <Box
          sx={{
            fontFamily: "Inter",
            fontSize: 14,
            opacity: useOpacity() && ".8"
          }}
        >
          <T>{text}</T>
        </Box>
      }
      onChange={onChange}
    />
  );
};

export { FilterRadioButton };
