import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { UpdatedDate } from "../../components/UpdatedDate";
import { CenturionContext } from "../../hooks/CenturionContext";

const CenturionQualificationsHeader = ({ addPadding = false }) => {
  const { dateCalculated } = useContext(CenturionContext);

  return (
    <Stack
      direction="column"
      gap={.5}
      p={addPadding ? 3 : 0}
      justifyContent="center"
    >
      <Typography variant="h3" data-test-id="centurion-qual-header1">
        <T>centurion_qualifications</T>
      </Typography>
      <UpdatedDate date={dateCalculated} />
    </Stack>
  )
}

export { CenturionQualificationsHeader }