import React, { useContext } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";

import { useNavigate, useLocation } from "react-router-dom";

import { DateTime } from "luxon";

import { Box, Link, Stack } from "@mui/material"
import { OfficeAvatar } from "../../components/OfficeAvatar";

import { LanguageContext } from "../../hooks/LanguageContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const MobileLeftCell = ({ params }) => {
  const { currentLanguage, localizeNumber, languageDir } = useContext(LanguageContext);

  const localizedNumber = localizeNumber(params.count);

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const viewPortIsSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const viewportIsMedium = useMediaQuery(theme.breakpoints.up("md"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const handleArrowClick = () => {
    navigate(`${location.pathname}?drawer=member&id=${params.unicityId}&drawerContent=profile`, { state: { location: location, } });
  }

  const translateDateString = useTranslateKey("date");

  const formatDateTime = () => {
    if (params.lastFeelGreatOrderDate) {
      const formattedDate = DateTime.fromISO(params.lastFeelGreatOrderDate).setLocale(currentLanguage.split("-")[0]).toLocaleString(DateTime.DATE_MED);
      return `${translateDateString}: ${formattedDate}`
    } return null
  }

  return (
    <Box 
      pl={((viewPortIsSmall || (!viewPortIsSmall && !viewportIsLarge && !viewportIsMedium)) && languageDir === 'rtl') && 2} 
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "8px" }}
    >
      <Box>
        <Stack direction="row" spacing={1} alignItems="center"
        >
          <Box>
            <OfficeAvatar firstInitial={formatInitial(params.name)} addBadge={true} badgeOn={params.count !== 0} id="user-avatar" />
          </Box>
          <Stack direction="column">
            <Link
              onClick={handleArrowClick}
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                opacity: params.count !== 0 ? "" : "70%",
                cursor: "pointer",
                textDecoration: "none"
              }}>{formatName(params.name)}
            </Link>
            {viewPortIsSmall ? (<>
              <Box sx={{ fontSize: "11px", opacity: ".6" }}>
                {formatDateTime()}
              </Box>
              <Box sx={{ fontSize: "11px", opacity: ".6" }}>
                <T>count</T>: {localizedNumber}
              </Box></>) : (
              <Stack direction="row">
                <Box sx={{ fontSize: "11px", opacity: ".6" }}>
                  {`${formatDateTime()} •`}&nbsp;
                </Box>
                <Box sx={{ fontSize: "11px", opacity: ".6" }}>
                  <T>count</T>: {localizedNumber}
                </Box>
              </Stack>)
            }
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export { MobileLeftCell }

function formatInitial(name) {
  if (name.includes(", ")) {
    return name.split(", ")[1][0]
  } return name[0]
}

function formatName(name) {
  if (name.includes(", ")) {
    const splitName = name.split(", ");
    return `${splitName[1]} ${splitName[0]}`
  } return name
}