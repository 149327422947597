import React, { useContext } from "react";
import { TM } from "../../../../hooks/useTranslate";

import { MenuItem, FormControl, Select } from "@mui/material"

import { DistributorContext } from "../../../../hooks/DistributorContext";

const SuccessTrackerSelectDropdown = ({ disabled }) => {
  const { selectedMonth, contextMonthRange, setSelectedMonth } = useContext(DistributorContext);
  return (
    <FormControl
      data-test-id="success-tracker-month-dropdown"
      sx={{
        minWidth: "150px",
        backgroundColor: "#FFFFFF"
      }}
    >
      <Select
        labelId="month-dropdown-label"
        value={selectedMonth}
        autoWidth
        onChange={(e) => setSelectedMonth(e.target.value)}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
              border: "1px solid rgba(145, 158, 171, 0.1)",
              borderRadius: "8px",
              marginTop: "5px"
            }
          }
        }}
        inputProps={{
          sx: {
            fontSize: "12px",
            fontWeight: "600",
            color: "rgba(21, 56, 98, 1)",
            padding: "3.5px 8px 3.5px 8px"
          }
        }}
      >
        {contextMonthRange.map((item) => {
          return (
            <MenuItem
              value={item.value}
              key={item.value}
              sx={{ fontSize: "12px" }}
              data-test-id={`${item.value}`}
            >
              <TM>{item.label}</TM>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  )
}

export { SuccessTrackerSelectDropdown }