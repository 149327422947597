import { createTheme } from "@mui/material/styles";

//Theme for mui components. Wraps the application in index.js. All CSS updates for mui components go here.
const createDefaultTheme = () =>
  createTheme({
    palette: {
      primary: {
        main: "rgb(4, 158, 207)",
        stackBackground: "rgba(255, 255, 255, 1)",
      },
      text: {
        primary: "rgba(21, 56, 98, 1)",
      },
      severity: {
        error: "rgba(255, 242, 242, 1)",
        warning: 'rgba(145, 115, 209, 1)',
        info: 'rgba(0, 158, 207, 1)',
        success: 'rgba(213, 240, 223, 1)',
        default: "rgba(255, 255, 255, 1)"
      },
    },
    typography: {
      fontFamily: "Inter",
      button: {
        textTransform: "none",
      },
      h2: {
        fontWeight: "600",
        fontSize: "16px"
      },
      h3: {
        fontWeight: "600",
        fontSize: "14px"
      },
      h4: {
        fontWeight: "600",
        fontSize: "12px"
      },
      h5: {
        fontWeight: "400",
        fontSize: "12px"
      },
      h6: {
        fontWeight: "600",
        fontSize: "10px",
        opacity: ".6"
      },
      h7: {
        fontWeight: "400",
        fontSize: "12px",
        opacity: ".6"
      },
      h8: {
        fontWeight: "400",
        fontSize: "14px"
      },
      h9: {
        fontWeight: "500",
        fontSize: "11px"
      },
      h10: {
        fontWeight: "600",
        fontSize: "10px",
        opacity: ".5"
      },
      h11: {
        fontWeight: "400",
        fontSize: "10px",
        color: "#919EAB"
      },

    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "7px",
            fontWeight: "600",
            height: "45px",
            padding: "0px 1px 0px 1px",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            fontFamily: '"Inter", sans-serif',
          },
          cell: {
            borderBottom: "1px solid rgba(237, 239, 241, 1)",
            ":focus-within": {
              outline: "none",
            },
          },
          columnHeaders: {
            borderBottomColor: "rgba(237, 239, 241, 1)",
          },
          columnHeader: {
            border: "none",
            backgroundColor: "#f9fafb",
            letterSpacing: "-0.01em",
            ":focus-within": {
              outline: "none",
            },
          },
          "columnHeader--sorted": {
            textDecoration: "underline",
          },
          columnHeaderTitle: {
            fontWeight: 600,
          },
          columnSeparator: {
            display: "none",
          },
          footerContainer: {
            display: "flex",
            flexDirection: "column-reverse",
            alignItems: "stretch",
            justifyContent: "flex-start",
            borderTop: "1px solid rgba(237, 239, 241, 1)"
          },
          row: {
            ":hover": {
              backgroundColor: "rgba(0, 55, 100, 0.04)",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "rgb(21, 56, 98)",
            "&:hover": {
              color: "rgb(21, 56, 98)",
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: "rgba(21, 56, 98, 1)",
            textDecorationColor: "rgb(21, 56, 98, 1)",
            ":hover": {
              color: "rgba(0, 158, 207, 1)",
            },
            fontFamily: '"Inter", sans-serif',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#153862",
            opacity: "0.5",
            "&.Mui-focused": {
              opacity: "1",
            },
          },
          // style text in notch above select component
          shrink: {
            fontFamily: "Inter",
            fontSize: "14px",
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderRadius: "8px",
              border: "1px solid rgba(145, 158, 171, 0.2)",
            },
            "&:hover:not(:focus):not(:focus-within) fieldset": {
              border: "1px solid rgba(145, 158, 171, 0.2) !important",
            },
          },
        },
      },
      // Style dates on calendar
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontFamily: "Inter",
            "&:focus.Mui-selected": {
              backgroundColor: "rgb(4, 158, 207)",
            },
          },
        },
      },
      // style calendar container
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            padding: "24px 24px 24px 10px",
            color: "rgb(21, 56, 98)",
          },
        },
      },
      // style calendar outer container
      MuiPaper: {
        styleOverrides: {
          root: {
            "&.MuiPickersPopper-paper": {
              boxShadow: "0px 6px 12px rgba(145, 158, 171, 0.2)",
            },
          },
        },
      },
      // style calendar header
      MuiPickersCalendarHeader: {
        styleOverrides: {
          labelContainer: {
            fontFamily: "Inter",
            color: "rgba(21, 56, 98, 1)",
          },
        },
      },
      // style M T W etc. in calendar
      MuiTypography: {
        styleOverrides: {
          root: {
            "&.MuiDayPicker-weekDayLabel": {
              fontFamily: "Inter",
            },
          },
        },
      },
      // style number value inside of pagination select tag
      MuiSelect: {
        styleOverrides: {
          select: {
            "&.MuiInputBase-inputSizeSmall": {
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "11px",
              paddingTop: "5.5px",
              paddingBottom: "5.5px",
            },
          },
        },
      },
      // style height for pagination select tag
      MuiInputBase: {
        styleOverrides: {
          root: {
            "&.MuiInputBase-sizeSmall": {
              height: "24px",
              borderRadius: "4px",
            },
          },
        },
      },
      // style chevron on pagination select tag
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            "&.MuiSelect-icon": {
              fontSize: "1.2rem",
              color: "#153862",
            },
          },
        },
      },
      // style disabled but checked option in SortByDropdownMobile >> Manage columns
      MuiButtonBase: {
        styleOverrides: {
          root: {
            // override disabled & hovered styling
            "&.MuiIconButton-root:hover": {
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26)"
              }
            }
          },
        },
      },
      // center alert message
      MuiAlert: {
        styleOverrides: {
          message: {
            display: "flex",
            alignItems: "center",
            padding: "0px 0px"
          }
        }
      },
    },
    subscription: {
      subscribed: {
        color: "#009ECF",
        backgroundColor: "#E6F5FA"
      },
      default: {
        color: "#84909C",
        backgroundColor: "#DDE1E5"
      },
      oneTimePurchase: {
        color: "#43C06F",
        backgroundColor: "#ECF9F1"
      },
    }
  });


export { createDefaultTheme };
