const styles = {
  header: {
    marginBottom: 2,
  },
  subheader: {
    fontSize: "11px",
    fontWeight: "400",
    color: "rgba(21, 56, 98, 0.8)",
  },
  button: {
    color: "#fff",
    backgroundColor: "#009ECF",
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.20)",
    padding: "12px 26px",
    "&:hover": {
      backgroundColor: "#009ECF",
      opacity: ".7"
    }
  },
  disabledButton: {
    color: "#fff",
    backgroundColor: "#919EAB",
    cursor: "not-allowed",
    opacity: ".3",
    "&:hover": {
      backgroundColor: "#919EAB",
      cursor: "not-allowed"
    },
  },
  resetButton: {
    color: "#153862",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "1px solid rgba(145, 158, 171, 0.20)",
    padding: "12px 26px",
    "&:hover": {
      opacity: ".7"
    }
  },
  textField: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      fontSize: ".875em"
    },
  },
  lockedTextField: {
    backgroundColor: "rgba(145, 158, 171, 0.06)",
    borderRadius: "8px",
    cursor: "not-allowed",
    '& .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: "1px solid rgba(145, 158, 171, 0.20)"
    },
  },
  labelStyle: {
    fontSize: ".875em"
  },
  subText: {
    fontWeight: 400,
    fontSize: "11px",
    opacity: ".8"
  },
  defaultFlagIcon: {
    height: 18,
    width: 18,
    marginRight: 4
  },
  reactFlagIcon: {
    height: 24,
    width: 24,
    marginRight: 6,
    borderRadius: "10px"
  },
  selectTag: {
    fontSize: ".875rem",
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      fontSize: ".875em"
    },
    height: "3.321rem",
    border: "none",
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(145, 158, 171, 0.20)",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid rgba(145, 158, 171, 0.20)",
      },
    },
    '.MuiSvgIcon-root ': {
      fill: "rgba(21, 56, 98, 1)",
      opacity: ".5"
    }
  },
  menuPaperProps: {
    PaperProps: {
      sx: {
        boxShadow:
          "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
        border: "1px solid rgba(145, 158, 171, 0.1)",
        borderRadius: "8px",
        marginTop: "5px",
      },
    },
    MenuListProps: {
      sx: {
        ".MuiButtonBase-root": {
          fontWeight: "500",
          fontSize: "14px",
          paddingLeft: "24px",
          paddingRight: "24px",
        },
        ".Mui-selected": {
          color: "rgba(0, 158, 207, 1)",
          backgroundColor: "rgba(0, 158, 207, 0.05)",
          fontWeight: "600",
        },
      },
    },
  },
  cardContainer: {
    border: "1px solid rgba(145, 158, 171, 0.10)"
  },
  infoRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 0
  },
  infoRowText: {
    fontSize: ".75rem",
    paddingLeft: "1rem"
  },
  copyToClipboardIcon: {
    height: ".8rem",
    width: ".8rem",
    paddingLeft: ".25rem",
    fill: "#153862",
    opacity: ".6",
    cursor: "pointer",
  },
  nameText: {
    fontSize: "1.125rem",
    fontWeight: "600"
  },
  uidText: {
    fontSize: ".75rem",
    fontWeight: "400",
    opacity: ".6"
  },
  chip: {
    color: "#9172D1",
    backgroundColor: "#9173D11A",
    borderRadius: "2rem",
    padding: ".5rem 1rem",
    fontSize: ".75rem",
    fontWeight: "600",
    width: 'fit-content',
    display: 'inline-block'
  },
  errorButton: {
    padding: "1rem",
    border: "1px solid rgba(145, 158, 171, 0.2)",
    borderRadius: "4px",
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "600",
    color: "#153862",
    whiteSpace: "nowrap",
    flexShrink: 0,
    maxWidth: "25rem",
    minWidth: "24rem"
  },
  mainContent: {
    flex: 1,
    padding: "24px",
  },
  contactInfoButton: {
    border: "1px solid rgba(145, 158, 171, 0.20)",
    borderRadius: "8px",
    padding: "12px"
  },
  modalBox: {
    position: "absolute",
    width: 400,
    backgroundColor: "white",
    boxShadow: 5,
    padding: "2rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
  },

  modalButton: {
    padding: "12px 16px",
    borderRadius: "8px",
    fontSize: ".75rem",
  },
  inputProps: {
    sx: {
      fontSize: ".875rem"
    }
  },
  formHelperTextProps: {
    fontSize: ".75rem"
  },
  alert: {
    fontSize: ".75rem",
    fontWeight: "400",
  },
  updateAlert: {
    fontSize: ".625rem",
    color: "#43C06F",
    fontWeight: "400",
  },
  maxChangesText: {
    fontSize: ".625rem",
    color: "rgba(21, 56, 98, 0.8)",
    fontWeight: "400"
  },
  characterCountText: {
    color: "rgba(21, 56, 98, 0.80)",
    fontWeight: "400",
    fontSize: ".625rem",
  }
};

export default styles;
