import React from "react";
import { T } from "../../hooks/useTranslate";

import { Box } from "@mui/material"

import { formatTextSizeWeight } from "./format-functions";

const CenturionLevelBox = ({ sales, number, salesNeeded }) => {
  const isComplete = sales >= salesNeeded;

  // complete box
  if (isComplete) return (
    <Box
      data-test-id={`level-box-${number}`}
      sx={{
        flex: "1 1 0px",
        backgroundColor: "#FFECE5",
        color: "rgba(21, 56, 98, 1)",
        borderRadius: "8px",
        padding: "16px 20px"
      }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "23px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box data-test-id={`level-value-${number}`}>
            <Box sx={formatTextSizeWeight("11px", 600)}>
              <T>level</T> {number}
            </Box>
            <Box sx={formatTextSizeWeight("14px", 700)}>
              100 %
            </Box>
          </Box>
          <Box>
            <img src="icons/centurion-check.svg" alt="" />
          </Box>
        </Box>
        <Box sx={{ color: "#E07854", fontWeight: "600", fontSize: "11px" }} data-test-id={`level-text-${number}`}>
          <T>completed</T> {salesNeeded} <T>sales</T>
        </Box>
      </Box>
    </Box>
  )

  // incomplete box
  return (
    <Box
      data-test-id={`level-box-${number}`}
      sx={{
        flex: "1 1 0px",
        backgroundColor: "#F3F5F7",
        color: "rgba(21, 56, 98, 1)",
        borderRadius: "8px",
        padding: "16px 20px"
      }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "23px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box data-test-id={`level-value-${number}`}>
            <Box sx={formatTextSizeWeight("11px", 600)}>
              <T>level</T> {number}
            </Box>
            <Box sx={formatTextSizeWeight("14px", 700)}>
              {`${Math.trunc(sales / salesNeeded * 100)} %`}
            </Box>
          </Box>
          <Box>
            {getSvg(number)}
          </Box>
        </Box>
        <Box sx={{ color: "#153862", fontWeight: "600", fontSize: "11px", opacity: ".5" }} data-test-id={`level-text-${number}`}>
          <T>reach</T> {salesNeeded} <T>sales</T>
        </Box>
      </Box>
    </Box>
  )
}

export { CenturionLevelBox }

const getSvg = (num) => {
  switch (num) {
    case 1:
      return <img src="icons/centurion-one.svg" alt="centurion level one" />
    case 2:
      return <img src="icons/centurion-two.svg" alt="centurion level two" />
    case 3:
      return <img src="icons/centurion-three.svg" alt="centurion level three" />
    default:
      return "Icon not found"
  }
}