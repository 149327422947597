import React, { useContext } from "react";
import { T } from "../../../hooks/useTranslate";
import { Stack, Typography } from "@mui/material";

import { LanguageContext } from "../../../hooks/LanguageContext";


const StatsContentBox = ({ text, value }) => {
  const { localizeNumber } = useContext(LanguageContext);

  return (
    <Stack
      data-test-id={`${text.replace("_", "-")}-container`}
      p={2}
      backgroundColor="rgba(0, 158, 207, 0.05)"
      borderRadius="8px"
      width="100%"
    >
      <Typography
        data-test-id={`${text.replace("_", "-")}-subheader`}
        variant="h4"
        sx={{ opacity: ".6" }}
      >
        <T>{text}</T>
      </Typography>
      <Typography
        data-test-id={`${text.replace("_", "-")}-value`}
        sx={{
          fontWeight: "700",
          fontSize: "18px",
          color: "#153862"
        }}>
        {localizeNumber(value)}
      </Typography>
    </Stack>
  );
};

export { StatsContentBox };