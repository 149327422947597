import React from "react";
import { T } from "../hooks/useTranslate";

import { Box, Typography, Alert } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const ActionHubAlert = ({
  color,
  backgroundColor,
  border,
  text,
  boldText,
  maxWidth,
}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const alertStyling = {
    maxWidth: maxWidth,
    alignItems: "center",
    padding: viewportIsSmall ? "0px 14px" : "12px",
    border: border,
    borderRadius: "8px",
    backgroundColor: backgroundColor,
  };

  const textStyling = {
    color: color,
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "12px",
  };

  if (viewportIsSmall) {
    return (
      <Alert
        icon={<InfoOutlinedIcon sx={{ color: "#153862" }} />}
        severity="info"
        sx={alertStyling}
      >
        <Box sx={{ display: "flex", padding: "8px 12px 8px 12px" }}>
          <Typography sx={textStyling} data-test-id="actionhub-alert">
            <strong><T>{boldText}</T></strong>{" "}<T>{text}</T>
          </Typography>
        </Box>
      </Alert>
    );
  }

  return (
    <Alert
      data-test-id="actionhub-alert"
      icon={<InfoOutlinedIcon sx={{ color: "#153862" }} />}
      severity="info"
      sx={alertStyling}
    >
      <Box sx={{ display: "flex", padding: "8px 12px 8px 12px" }}>
        <Typography sx={{ fontSize: "12px", marginRight: "20px" }}>
          <strong>
            <T>{boldText}</T>
          </strong>{" "}
          <T>{text}</T>
        </Typography>
      </Box>
    </Alert>
  );
};

export { ActionHubAlert };
