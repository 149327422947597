import { useFormik } from 'formik';
import { useApi } from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslateKey } from '../../../hooks/useTranslate';
import { useReferralCodeValidationSchema } from '../../../components/LoginComponents/ValidationSchemas';

export const useFormikInstances = (setIsModalLoading,
  setIsModalOpen,
  handleCloseModal,
  setIsUpdated,
  setReferralCode,
  setReferralLink
) => {
  const { userInfo } = useAuth();
  const { sendRequest } = useApi();

  const formikNewReferralCode = useFormik({
    initialValues: {
      referralCode: "",
    },
    validationSchema: useReferralCodeValidationSchema(useTranslateKey),
    validateOnMount: false,
    onSubmit: async ({ referralCode }) => {
      setIsModalLoading(true);
      try {
        const response = await sendRequest({
          method: "POST",
          endpoint: `${userInfo.href}/referralcodes`,
          addPrefix: false,
          addContentType: true,
          data: JSON.stringify({ referralCode }),
        });

        if (response.status === 200) {
          setIsUpdated(true);
          handleCloseModal();

          setReferralCode(response.data.items[0].referralCode);
          setReferralLink(`${process.env.REACT_APP_FEEL_GREAT_REFERRAL_URL}/${response.data.items[0].referralCode}`);
        }

        setIsModalOpen(false);
      } catch (err) {
        // build error key >> split error message on "." or "Error" to get the first part of the error message, trim spaces, to lower case, replace spaces with underscores
        const customSplit = (inputString) => inputString.includes('.') ? inputString.split('.') : (inputString.includes('Error') ? inputString.split('Error') : [inputString]);
        const errorKey = customSplit(err.response?.data?.error?.error_message).map((item) => item.trim())[0].toLowerCase().replaceAll(" ", "_");

        formikNewReferralCode.setErrors({
          referralCode: `${errorKey}_error`
            || "error"
        });

      } finally {
        setIsModalLoading(false);
      }
    },
  });

  return { formikNewReferralCode };
};