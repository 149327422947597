import React from "react";

import { Stack } from "@mui/material";
import { PartnersGenealogySection } from "./PartnersGenealogySection";
import { PartnersStatsDesktopContainer } from "./PartnersStatsDesktopContainer";

const PartnersLeftColumn = () => {
  return (
    <Stack gap={2}>
      <PartnersGenealogySection />
      <PartnersStatsDesktopContainer />
    </Stack>
  );
};

export { PartnersLeftColumn };
