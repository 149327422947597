import { T } from "../../../hooks/useTranslate";

import { Typography, Stack } from "@mui/material"
import { HelpIcon } from "../../../components/HelpIcon"

const ReferralHeaderCell = () => {
  return (
    <Stack direction="row" spacing="2px" data-test-id={`referral-column-header`}>
      <Stack justifyContent="center">
        <Typography variant="h4"><T>referred_by</T></Typography>
      </Stack>
      <Stack>
        <HelpIcon
          text1={"referred_by_line1"}
          text2={"referred_by_line2"}
        />
      </Stack>
    </Stack>
  )
}

export { ReferralHeaderCell }
