import React, { useContext } from "react";
import { T } from "../hooks/useTranslate";
import { DateTime } from "luxon";

import { Typography } from "@mui/material";
import { formatDateByLocale } from "../common/date-functions";
import { LanguageContext } from "../hooks/LanguageContext";

const UpdatedDate = ({ date, showUnavailable }) => {
  const { currentLanguage } = useContext(LanguageContext);
  const dateFormatted = formatDateByLocale(currentLanguage, date, DateTime.DATETIME_FULL)

  if (showUnavailable && !date) return (
    <Typography variant="h11">
      <T>updated</T>: <T>not_available</T>
    </Typography>
  )

  if (date) return (
    <Typography variant="h11">
      <T>updated</T>: {dateFormatted}
    </Typography>
  )
}

export { UpdatedDate }