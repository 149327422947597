const styles = {
  actionDashboardItemStack: {
    flexDirection: "row",
    alignItems: "center",
    padding: "20px 24px",
    gap: "20px",
    borderRadius: "12px"
  },
  oldOfficeText: {
    display: "inline-block",
  },
  arrow: {
    verticalAlign: "middle"
  },
  goNowText: {
    display: "inline-block",
    color: "#153862",
    margin: "0 4px 0 4px"
  },
  button: {
    color: "#000",
    width: "fit-content",
    height: "100%",
    backgroundColor: "#919EAB33",
    borderRadius: "4px",
    padding: "8px",
    "&:hover": {
      backgroundColor: "#919EAB33",
      opacity: ".7"
    }
  },
  disabledButton: {
    color: "#fff",
    backgroundColor: "#919EAB",
    opacity: ".3",
    "&:hover": {
      backgroundColor: "#919EAB",
      cursor: "not-allowed"
    },
  },
  modalBox: {
    position: "absolute",
    maxWidth: 400,
    backgroundColor: "white",
    boxShadow: 5,
    padding: "1.5rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
  },
  modalBodyText: {
    fontSize: "0.688rem"
  },
  modalButton: {
    padding: "12px 16px",
    borderRadius: "8px",
    fontSize: ".75rem",
  },
};

export default styles;
