import React, { useState, useEffect } from "react";
import { T } from "../../../hooks/useTranslate";

import { Stack, Typography, CircularProgress } from "@mui/material";
import { StatsContentBox } from "./StatsContentBox";

import { useApi } from "../../../hooks/useApi";
import { useAuth } from "../../../hooks/useAuth";
import { OfficeBox } from "../../../components/OfficeBox";


const StatsContent = () => {
  const [activeSubscribers, setActiveSubscribers] = useState(0);
  const [statsLoading, setStatsLoading] = useState(true);

  const { sendRequest } = useApi();
  const { userInfo } = useAuth();

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchStats() {
      try {
        const statsResponse = await sendRequest({
          method: "get",
          endpoint: `${process.env.REACT_APP_OFFICE_API}/${userInfo.unicityId}/stats`,
          addPrefix: false,
          abortController,
        });

        setActiveSubscribers(statsResponse?.data?.activeSubscribers);
        setStatsLoading(false);
      } catch (error) {
        console.error("Error fetching stats:", error);
        console.error("Error fetching stats:", error);
        setActiveSubscribers("\u00A0");
        setStatsLoading(false);
      }
    }

    fetchStats();

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <OfficeBox>
      <Stack p={3} gap="13.5px" data-test-id={`stats-module`}>
        <Typography variant="h3" data-test-id="stats-header">
          <T>stats</T>
        </Typography>
        {statsLoading ? (
          <Stack sx={{ height: 150 }} justifyContent="center" alignItems="center" data-test-id="stats-loading-icon">
            <CircularProgress data-test-id="progress-bar-shown" />
          </Stack>
        ) : (
          <Stack gap="13.5px">
            <Stack direction="row" justifyContent="space-between" gap={2}>
              <StatsContentBox text="active_subscribers" value={activeSubscribers} />
            </Stack>
          </Stack>
        )}
      </Stack>
    </OfficeBox>
  );
};

export { StatsContent };
