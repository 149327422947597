import React, { useState } from "react";
import { T } from "../../hooks/useTranslate";
import { TextField, IconButton, InputAdornment } from "@mui/material";

const PasswordInput = ({ formik, InputLabelProps, inputProps, FormHelperTextProps, hidePlaceholderText }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      id="password"
      data-test-id="password-text-field"
      label={<T>password</T>}
      InputProps={{
        ...inputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={event => event.preventDefault()}
              sx={{ marginRight: "-4px" }}
            >
              {showPassword ? <img
                src="icons/eye-visible.svg"
                alt="visible password"
              /> : <img
                src="icons/eye-invisible.svg"
                alt="visible password"
              />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ ...InputLabelProps, shrink: hidePlaceholderText && true }}
      FormHelperTextProps={FormHelperTextProps}
      type={showPassword ? 'text' : 'password'}
      variant="outlined"
      value={formik.values.password}
      onChange={formik.handleChange}
      error={
        formik.touched.password && Boolean(formik.errors.password)
      }
      helperText={formik.touched.password && Boolean(formik.errors.password) && formik.errors.password}
      fullWidth
    />
  )
}

export { PasswordInput }