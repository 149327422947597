import React from "react";
import { T } from "../../hooks/useTranslate";

import { Box, Typography } from "@mui/material";

const CenturionNoSalesOverlay = () => {
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      marginTop: "80px",
      marginBottom: "60px"
    }}>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(108, 130, 157, 0.06);",
        height: "80px",
        width: "80px",
        borderRadius: "50%"
      }}>
        <img src="icons/no-sales.svg" alt="no sales icon" height={40} width={40} />
      </Box>
      <Box>
        <Typography variant="h3" color="#153862"><T>no_sales</T></Typography>
      </Box>
      <Box sx={{ width: "220px", textAlign: "center" }}>
        <Typography variant="h5" color="#153862" sx={{ opacity: ".8" }}>
          <T>no_sales_overlay1</T>
        </Typography>
      </Box>
    </Box>
  )
}

export { CenturionNoSalesOverlay }