import React, { useContext, useState } from 'react'
import { Box, Typography, Link, Tooltip, ClickAwayListener } from '@mui/material'
import { T } from '../hooks/useTranslate'
import { ReferralsCopyBox } from './ReferralsCopyBox'
import { ReferralsAlert } from './ReferralsAlert'
import { ReferralsLink } from './ReferralsLink'
import { Arrow } from './Arrow'
import { Stack } from '@mui/material'
import { LanguageContext } from '../hooks/LanguageContext'
import { DashboardDivider } from '../screens/DashboardScreen/DashboardDivider'
import { useMixPanel } from '../hooks/useMixPanel'
import { useNavigate, useLocation } from "react-router-dom";


const tooltipSlotProps = {
  arrow: {
    sx: {
      color: "#566E81"
    }
  },
  tooltip: {
    sx: {
      backgroundColor: "#566E81",
      textAlign: "center",
      maxWidth: 200,
      padding: "15px",
      gap: 10,
      borderRadius: "4px"
    }
  }
}

const tooltipPopperProps = {
  disablePortal: true,
}


/**
 * Reusable component for FeelGreatSection Link
 * @param {Object} props 
 * @returns FeelGreatLink component
 */
const FeelGreatLink = ({ 
  viewportIsSmall, 
  handleToggle, 
  toggleText,
  link,
  showCode,
  referralCode,
  isDashboard
}) => {
  const { languageDir } = useContext(LanguageContext);
  const location = useLocation();
  const { logEvent } = useMixPanel();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    logEvent("redirect_to_referral_link_page", { location: 'dashboard' });
    navigate("/referrals", { state: location });
  }

  const RenderReferferralsLink = () => (
    !isDashboard &&
      <ReferralsLink
        text={toggleText}
        handleToggle={handleToggle}
      />
  )

  const RenderFGText = () => (
    <Stack direction="row" spacing={1}>
      <T>feel_great</T> {isDashboard && (
        <React.Fragment>
          <T>dashboard_referral_link</T>
          <span style={{ marginLeft: '8px' }}>
            {
              viewportIsSmall
                ? <Tooltip
                    arrow
                    placement="top"
                    PopperProps={tooltipPopperProps}
                    slotProps={tooltipSlotProps}
                    title={<T>fg_referral_link</T>}
                  >
                    <img src="icons/help.svg" alt="Referral Link" />
                  </Tooltip>
                : <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Tooltip
                      arrow
                      placement="top"
                      PopperProps={tooltipPopperProps}
                      slotProps={tooltipSlotProps}
                      title={<T>fg_referral_link</T>}
                      onClose={() => setOpen(false)}
                      open={open}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                    >
                      <img src="icons/help.svg" alt="Referral Link" onClick={() => setOpen(true)} />
                    </Tooltip>
                  </ClickAwayListener>
            }
          </span>
        </React.Fragment>
      )}
    </Stack>
  )

  return (
    <>
      <Box sx={{ marginBottom: "32px", padding: "0px 32px" }}>
        {!isDashboard && <ReferralsAlert />}
        <Typography variant="h3" sx={{ marginBottom: "8px" }}>
          <RenderFGText />
        </Typography>
        {
          !isDashboard &&
          <Typography variant="h5" sx={{ marginBottom: "24px" }}>
            <T>feel_great_subtext</T>
          </Typography>
        }
        {viewportIsSmall ? (
          <Box sx={{ display: "flex", alignItems: "center", gap: "24px", marginTop: isDashboard ? "25px" : "0" }}>
            <ReferralsCopyBox text={showCode ? referralCode : link} />
            <RenderReferferralsLink />
          </Box>
        ) : (
          <Box>
            <Box sx={{ marginBottom: "16px", marginTop: isDashboard ? "25px" : "0" }}>
              <ReferralsCopyBox text={showCode ? referralCode : link} />
            </Box>
            <RenderReferferralsLink />
          </Box>
        )}
      </Box>
      {
        isDashboard &&
        <React.Fragment>
          <DashboardDivider />
          <Link
            data-test-id="see-more-referral-link"
            onClick={handleClick}
            underline="hover"
            sx={{
              "&:hover": {
                cursor: "pointer"
              }
            }}
          >
            <Stack
              flexDirection="row"
              p={3}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h4" data-test-id="see-more-news-text"><T>see_more</T></Typography>
              <Arrow dir={languageDir} testId="see-more-news-icon" />
            </Stack>
          </Link>
        </React.Fragment>
      }
    </>
  )
}

export default FeelGreatLink
