import React from "react";
import { T } from "../../../hooks/useTranslate";
import { Modal, Typography, Stack, Box } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';

const COMPLIANCE_EMAIL = 'Distributor.compliance@unicity.com'

const PlacementModal = ({ open, success, handleModalClose }) => {

  const handleModalCancel = () => {
    handleModalClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleModalCancel}
      aria-labelledby="modal-placement-result"
    >
      <Stack sx={styles.modal}>
        <Stack mb={2} alignItems={"flex-end"}>
          <CloseIcon onClick={handleModalClose} sx={{ cursor: "pointer", height: "16px", width: "16px" }} />
        </Stack>
        <Stack alignItems="center" mb={3}>
          {success && <img alt="Placement success" src="icons/placement-success.svg" />}
          {!success && <img alt="Placement success" src="icons/placement-failure.svg" />}
        </Stack>
        <Stack alignItems={"center"} mb={1}>
          <Typography variant="h3" sx={{ lineHeight: '1.375rem' }}>
            {success && <T>thank_you</T>}
            {!success && <T>uh_oh</T>}
          </Typography>
        </Stack>
        <Stack alignItems={"center"} mb={1}>
          <Box sx={{ width: "240px" }}>
            {success && <Typography sx={styles.text}><T>placement_success</T></Typography>}
            {!success &&
              <>
                <Typography sx={styles.text}><T>placement_failure</T></Typography>
                <Typography sx={[styles.text, { textDecoration: "underline" }]}>{COMPLIANCE_EMAIL}</Typography>
              </>
            }
          </Box>
        </Stack>
      </Stack>
    </Modal>
  )
}

const styles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: "380px",
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '1px solid rgba(145, 158, 171, 0.10)',
    boxShadow: '0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)',
    borderRadius: "8px",
    padding: "1.25rem",
    paddingBottom: "2.5rem"
  },
  text: {
    color: "#153862",
    textAlign: "center",
    fontSize: "0.75rem",
    fontWeight: "400",
    lineHeight: "1.25rem"
  }
}

export { PlacementModal }
