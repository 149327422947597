import React, { useContext } from "react";
import { T } from "../../../../hooks/useTranslate";

import { Stack, Typography } from "@mui/material";
import { RankQualSelectDropdown } from "./RankQualSelectDropdown";
import { RankQualPvTvBoxes } from "../../../DashboardScreen/RankQualifications/RankQualPvTvBoxes";
import { RankQualProgBarContainer } from "../../../DashboardScreen/RankQualifications/RankQualProgBarContainer";
import { DistributorContext } from "../../../../hooks/DistributorContext";
import { UpdatedDate } from "../../../../components/UpdatedDate";

const RankQualSection = () => {
  const {
    customer1,
    customer2,
    customer3,
    selectedRankDetails,
    thirdLegCount,
    totalOvDetails,
    dateCalculated } = useContext(DistributorContext);

  return (
    <Stack bgcolor="#919EAB0D" borderRadius={1}>
      <Stack margin="16px 16px 0px 16px">
        <UpdatedDate date={dateCalculated} />
      </Stack>
      <Stack
        margin="8px 16px 16px 16px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3"><T>rank_qualifications</T></Typography>
        <RankQualSelectDropdown />
      </Stack>
      <Stack>
        <RankQualPvTvBoxes
          selectedRankDetails={selectedRankDetails}
          inDrawer={true}
          totalOv={totalOvDetails}
        />
      </Stack>
      <Stack>
        <RankQualProgBarContainer
          selectedRankDetails={selectedRankDetails}
          name1={customer1}
          name2={customer2}
          name3={customer3}
          inDrawer={true}
          thirdLegCount={thirdLegCount}
        />
      </Stack>
    </Stack>
  )
}

export { RankQualSection }