import React, { useContext } from "react";
import { T } from "../../../../hooks/useTranslate";

import { FormControl, MenuItem, Select } from "@mui/material";

import { RANK_DETAILS } from "../../../../common/rankDetails";

import { DistributorContext } from "../../../../hooks/DistributorContext";

const RankQualSelectDropdown = () => {
  const { rank, setRank, rankOptions } = useContext(DistributorContext);

  const handleRankChange = (e) => {
    setRank(e.target.value);
  };

  return (
    <FormControl
      data-test-id="rank-dropdown"
      sx={{
        minWidth: "122px",
        backgroundColor: "#FFFFFF"
      }}
    >
      {rankOptions.length > 0 && (
        <Select
          labelId="rank-dropdown-label"
          id="rank-select"
          value={rank}
          autoWidth
          onChange={handleRankChange}
          MenuProps={menuPaperProps}
          inputProps={inputProps}
        >
          {rankOptions.map((option, idx) => {
            const rankDetails = RANK_DETAILS.filter(item => item.rankCode === option.rank)[0]
            if (rankDetails) {
              return (
                <MenuItem
                  value={rankDetails.rankCode}
                  sx={menuItemProps}
                  key={idx}
                  data-test-id={`${rankDetails.rankTranslateAbbrKey}`}
                >
                  <T>{rankDetails.rankTranslateFullKey}</T>
                </MenuItem>
              );
            } else return null;
          })}
        </Select>
      )}
    </FormControl>
  );
};

export { RankQualSelectDropdown };

const menuPaperProps = {
  PaperProps: {
    sx: {
      boxShadow:
        "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
      border: "1px solid rgba(145, 158, 171, 0.1)",
      borderRadius: "8px",
      marginTop: "5px",
    },
  },
};

const inputProps = {
  sx: {
    color: "rgba(21, 56, 98, 1)",
    fontSize: "12px",
    fontWeight: "600",
    padding: "3.5px 8px 3.5px 8px",
  },
};

const menuItemProps = { fontSize: "12px" };
