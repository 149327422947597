import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import styles from "../settingsScreenStyles";

const PersonalInfoStateSelect = ({
  stateLabel,
  stateOptions,
  formik
}) => {
  const handleChange = (e) => {
    const selectedState = e.target.value;
    formik.setFieldValue("state", selectedState);
  }

  return (
    <FormControl fullWidth data-test-id="select-state-formcontrol">
      {stateOptions && (
        <>
          <InputLabel
            id="select-state-label"
            data-test-id="select-state-label"
            sx={{ fontSize: ".875rem" }}>
            {stateLabel}
          </InputLabel>
          <Select
            labelId="select-state-label"
            id="select-state"
            data-test-id="select-state"
            value={formik.values.state || ""}
            label={stateLabel}
            sx={styles.selectTag}
            MenuProps={styles.menuPaperProps}
            onChange={handleChange}
          >
            {stateOptions.map((state, idx) => (
              <MenuItem value={state.value} key={idx}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export { PersonalInfoStateSelect };
