import React, { useContext } from "react";

import { Stack } from "@mui/material";
import { OfficeBox } from "../../components/OfficeBox";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PartnersSearchSortButtons } from "./PartnersSearchSortButtons";
import { DatagridSearchInput } from "../../components/DatagridSearch/DatagridSearchInput";
import { PartnersContext } from "../../hooks/PartnersContext";
import { UpdatedDate } from "../../components/UpdatedDate";

const PartnersSearchSortSection = () => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const viewportIsExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));

  const { loading, gridUpdateTimestamp } = useContext(PartnersContext);
  
  return (
    <OfficeBox mb={2}>
        <Stack
          id="partners-data-grid-head-section-row"
          direction={viewportIsLarge ? "row" : "column"}
          justifyContent="space-between"
          gap={viewportIsExtraLarge ? 2 : 1}
          mr={viewportIsSmall ? 3 : 2}
          ml={viewportIsSmall ? 3 : 2}
          mt={2}
        >
          <DatagridSearchInput type="partners" />
          <PartnersSearchSortButtons />
        </Stack>
        <Stack mb={2} ml={viewportIsSmall ? 3 : 2}>
          {!loading && <UpdatedDate date={gridUpdateTimestamp} showUnavailable />}
        </Stack>
    </OfficeBox>
  )
}

export { PartnersSearchSortSection }