// Markets that do not have custom link section
const marketsWithNoCustomReferralLinks = [
  "AU",
  "BR",
  "KH",
  "HK",
  "ID",
  "JP",
  "KR", 
  "LA", 
  "MY", 
  "MM", 
  "NZ", 
  "PH", 
  "SG", 
  "TW", 
  "TH",
  "VN",
  "DZ",
  'BS', 
  'LB'
]

export default marketsWithNoCustomReferralLinks
