import { useContext } from "react";
import { T, useTranslateKey } from "../../hooks/useTranslate";

import { Box, Menu, MenuItem, Checkbox, ListItemText } from "@mui/material"
import { styles } from "./partners.styles";
import { columns } from "./PartnersDataGridComponents/format-partners-columns";

import { PartnersContext } from "../../hooks/PartnersContext";
import { useMixPanel } from "../../hooks/useMixPanel";

const PartnersManageColumnsMenu = ({ anchorEl, handleClose, open }) => {
  const { logEvent } = useMixPanel();
  const { columnVisibilityModel, setColumnVisibilityModel, loading } = useContext(PartnersContext);

  const translateKey = useTranslateKey;

  const handleMenuClose = () => {
    handleClose();
  }

  const handleManageColumnClick = (e) => {
    // when manage columns is modified, update the column visibility model and store in local storage
    const newObj = { ...columnVisibilityModel };
    const currentField = e.currentTarget.getAttribute("value");

    newObj[currentField] = !columnVisibilityModel[currentField];
    setColumnVisibilityModel(newObj);
    sessionStorage.setItem("partnersColumnVisibilityModel", JSON.stringify(newObj));

    logEvent("partners_manage_columns_edit", {
      location: "partners",
      columnVisibilityModel: newObj
    })
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      slotProps={{
        paper: {
          "data-test-id": "partners-manage-columns-menu",
          style: {
            ...styles.paperPropsStyle,
            marginTop: "8px",
          }
        }
      }}

      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{ width: "240px" }}>
        <Box sx={{ ...styles.subheaderStyle, paddingTop: "12px", paddingLeft: "24px", marginBottom: "0px" }}>
          <T isUpperCase={true}>manage_columns</T>
        </Box>
        {columns.filter(c => !c.hideFromGrid).map((column) => {
          // Hide the name and genealogyButton columns from the manage columns menu
          if (column.field === "name" || column.field === "genealogyButton") return null;
          return <MenuItem
            key={column.headerName}
            value={column.field}
            data-test-id={`manage-columns-${column.headerName}-listItem`}
            aria-label={column.headerName}
            onClick={handleManageColumnClick}
            disabled={loading}
          >
            <Checkbox checked={columnVisibilityModel[column.field] === true} data-test-id={`manage-columns-${column.headerName}-checkbox`} />
            <ListItemText primary={translateKey(column.headerName)} primaryTypographyProps={styles.checkboxTextStyle} data-test-id={`manage-columns-${column.headerName}-listItemText`} />
          </MenuItem>
        })}
      </Box>
    </Menu>
  )
}

export { PartnersManageColumnsMenu }

