import React from "react";
import { T, useTranslateKey } from "../../../hooks/useTranslate";
import { Divider, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Stack, Modal, Box } from '@mui/material';
import styles from "../settingsScreenStyles"
import { SettingsTextField } from "../SettingsTextField";
import { ModalButtons } from "../ModalButtons";
import { BackdropLoadingOverlay } from "../../../components/BackdropLoadingOverlay";

const SettingsShareFeelGreatModal = ({
  formikNewReferralCode,
  isModalOpen,
  handleCloseModal,
  isModalLoading,
  viewportIsSmall
}) => {
  const theme = useTheme();
  const translateAddCustomPhrase = useTranslateKey("add_custom_phrase");

  // modal takes up entire screen on mobile
  const modalStyles = {
    ...styles.modalBox,
    [theme.breakpoints.down('sm')]: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      backgroundColor: "white",
      border: "1px solid rgba(145, 158, 171, 0.10)",
      boxShadow:
        "0px 1px 3px 0px rgba(145, 158, 171, 0.10), 0px 6px 15px 0px rgba(0, 0, 0, 0.08)",
      borderRadius: "0",
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="share-feel-great-modal-title"
      aria-describedby="share-feel-great-modal-description"
      data-test-id="share-feel-great-modal"
    >
      <>
        <Stack
          direction="column"
          justifyContent="space-between"
          sx={{
            ...modalStyles,
            minHeight: viewportIsSmall ? "100vh" : "",
            // eslint-disable-next-line
            minHeight: viewportIsSmall ? "100dvh" : ""
          }}>
          <Box p={viewportIsSmall && 3}>
            {
              viewportIsSmall && (
                <Box
                  onClick={handleCloseModal}
                  sx={{ cursor: "pointer" }}
                  mb={3}
                >
                  <img src="icons/close-icon.svg" alt="close icon" />
                </Box>
              )
            }
            <Typography
              variant="h2"
              mb={viewportIsSmall ? 5 : 4.4}
              data-test-id="share-feel-great-modal-header"
            >
              <T>referral_link_modal_header</T>
            </Typography>
            <SettingsTextField
              name="referralCode"
              label={translateAddCustomPhrase}
              content={formikNewReferralCode.values.referralCode}
              formik={formikNewReferralCode}
              maxLength={21}
              translateErrors={true}
            />
            <Stack alignItems="flex-end" mt={1}>
              <Typography sx={styles.characterCountText} data-test-id="character-count">
                {formikNewReferralCode.values.referralCode.length}/20
              </Typography>
            </Stack>
          </Box>
          <Box p={viewportIsSmall && 3}>
            <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />
            <ModalButtons
              formik={formikNewReferralCode}
              type="referral-code"
              handleCloseModal={handleCloseModal}
              viewportIsSmall={viewportIsSmall}
            />
          </Box>
        </Stack>
        <BackdropLoadingOverlay showLoadingOverlay={isModalLoading} />
      </>
    </Modal>
  )
}

export { SettingsShareFeelGreatModal }