import React from "react";
import { Divider } from "@mui/material"


const DashboardDivider = () => {
  return (
    <Divider sx={{ borderColor: "rgba(237, 239, 241, 1)" }} />
  )
}

export { DashboardDivider }