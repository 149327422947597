import React from "react";
import { Stack } from "@mui/material";
import styles from "../settingsScreenStyles";
import { SaveChangesButton } from "../MyProfile/SaveChangesButton";
import { ResetFormButton } from "../MyProfile/ResetFormButton";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const PersonalInfoButtonContainer = ({
  isModified,
  handleFormReset,
  handleSaveChanges,
}) => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack
      direction={viewportIsSmall ? "row" : "column-reverse"}
      justifyContent="flex-end"
      alignItems={viewportIsSmall ? "center" : "normal"}
      gap={2}
      mt={3}
      data-test-id="save-button-section"
    >
      {isModified && (
        <ResetFormButton handleFormReset={handleFormReset} styles={styles} />
      )}
      <SaveChangesButton
        styles={styles}
        handleSaveChanges={handleSaveChanges}
        enableSaveChanges={isModified}
      />
    </Stack>
  );
};

export { PersonalInfoButtonContainer };
