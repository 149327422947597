import { Box } from "@mui/material";

import { OfficeAvatar } from "../OfficeAvatar";

const AvatarChip = ({ id, imageUrl, name, clickHandler }) => {
  return (
    <Box
      id={id}
      onClick={clickHandler}
      sx={{
        height: "24px",
        background: "rgb(236,239,242)",
        borderRadius: "34px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box sx={{ padding: "2px 0px 2px 2px" }}>
        <OfficeAvatar
          id={`${id}-avatar`}
          imageUrl={imageUrl}
          firstInitial={name[0]}
          height={20}
          width={20}
        />
      </Box>
      <span style={{ fontSize: "10px", padding: "5px", fontWeight: "600" }}>
        {" "}
        {name}
      </span>
    </Box>
  );
};

export { AvatarChip };
