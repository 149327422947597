import { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { DataGridPro } from "@mui/x-data-grid-pro";
import { columns } from "./format-order-columns";
import { MemberContext } from "../../hooks/MemberContext";


const OrderTable = ({ orders }) => {
  const { memberId } = useContext(MemberContext);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const drawer = searchParams.get("drawer");

  const handleOrderClick = (row) => {
    const orderHref = row.row.href.split("/").pop();
    navigate(`${location.pathname}?drawer=${drawer}&id=${memberId}&drawerContent=orderDetails&orderHref=${orderHref}`, { state: location });
  }

  const filterCancelledOrder = (o) => {
    return orders.filter(o => o.fulfillmentStatus !== "Cancelled");
  }

  return (
    <DataGridPro
      id="OrdersDataGrid"
      columns={columns}
      rows={filterCancelledOrder(orders)}
      getRowId={(row) => row.uniqueId}
      disableColumnMenu
      autoHeight
      hideFooter
      onRowClick={handleOrderClick}
      sx={{
        fontSize: "12px",
        border: "none",
        backgroundColor: "#fff",
      }}
    />
  );
};

export { OrderTable };
