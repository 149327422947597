const styles = {
  cardPrintWrapper: {
    userSelect: "none",
    '@media print': {
      width: "100vw",
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }
  },
  cardHolder: {
    padding: 0,
    gap: 0,
    margin: "0 auto",
    alignItems: "center",
    display: "flex",
    position:"relative",
    justifyContent: "center",
    width: "fit-content",
    '@media print': {
      width: "327px",
    }
  },
  contexTopHolder: {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    width: "100%",
    top: 'calc((22 / 424) * 424px)',
    left: 0,
    '@media print': {
      width: "327px",
    }
  },
  contextHolder: {
    padding: "5px",
    width: "50%",
    textAlign: "right",
    '&:first-of-type': {
      textAlign: "left"
    },
    '& span': {
      display: "block",
      margin: 0,
      '@media print': {
        fontSize: "12px",
      },  
      '&:first-of-type': {
        marginBottom: "0.2rem",
        '@media print': {
          fontSize: "10px",
        }    
      }
    }
  }  
};

export default styles;
