import { T } from "../../hooks/useTranslate";

import { Box, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import { CopyToClipboard } from "../CopyToClipboard";

import {
  iconSizingStyle,
  gridTextStyle,
  gridChildLeftWrapper,
} from "./drawer-styles";
import {
  formatPhoneNumber
} from "../../common/format-functions";


const ProfilePhoneLine = ({ customerHrefData, drawerType, eventLocation }) => {
  let formattedPhoneNumber = "";
  if (customerHrefData?.homePhone) {
    formattedPhoneNumber = formatPhoneNumber(customerHrefData.homePhone);
  } else if (customerHrefData?.mobilePhone) {
    formattedPhoneNumber = formatPhoneNumber(customerHrefData.mobilePhone);
  }

  return (
    <>
      <Box sx={gridChildLeftWrapper}>
        <PhoneIcon sx={iconSizingStyle} />
      </Box>
      {formattedPhoneNumber !== "" ? (
        <Box component="span" sx={{ fontSize: "12px" }}>
          <CopyToClipboard
            text={formattedPhoneNumber}
            stringToCopy={formattedPhoneNumber}
            iconId={`${drawerType}-profile-copy-phone`}
            data-testid={`${drawerType}-profile-copy-phone`}
            eventLocation={eventLocation}
            eventName="phone_copy"
            sendEvent={true}
            userType={drawerType}
            userId={customerHrefData.unicity}
          />
        </Box>
      ) : (
        <Typography sx={gridTextStyle} data-testid={`${drawerType}-profile-phone`}>
          <T>not_available</T>
        </Typography>
      )}
    </>
  )
}

export { ProfilePhoneLine }