import { OfficePage } from "../../components/OfficePage";

import { T } from "../../hooks/useTranslate";

const ErrorScreen = (params) => {
  console.log(params);
  return (
    <OfficePage pageTitle={"error"}>
      <T>there_was_an_error</T>
    </OfficePage>
  );
};

export { ErrorScreen };
