import { styles } from "../../themes/datagrid.styles"

const getFilterButtonStyling = (loading, open, isFilterApplied) => {
  if (loading) {
    return styles.disabledStyle
  } else if (open) {
    return styles.openButtonStyle
  } else if (isFilterApplied) {
    return styles.filteredButtonStyle
  } else {
    return styles.unfilteredButtonStyle
  }
}

export { getFilterButtonStyling }  