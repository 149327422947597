import React from "react";
import { T, TM } from "../../../hooks/useTranslate";

import {
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
} from "@mui/material";

import { ranks } from "../../../common/ranks.js";

const RankQualDropdowns = ({
  rankOptions,
  monthOptions,
  rank,
  month,
  setRank,
  setMonth,
  loading,
  isError
}) => {
  const handleRankChange = (event) => {
    setRank(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  return (
    <Stack direction="row">
      <FormControl
        sx={{ ml: 2.5, mr: 0.5, flex: "1 1 100%" }}
        disabled={loading}
      >
        <InputLabel id="rank-dropdown">
          <T>rank</T>
        </InputLabel>
        <Select
          labelId="rank-dropdown-label"
          id="rank-select"
          data-test-id={`rank-qual-rank-select`}
          value={rank}
          disabled={isError || loading}
          label="Rank"
          renderValue={(i) => {
            const selected = ranks.filter((rank) => rank.value.includes(i));
            return <T>{selected[0].label}</T>;
          }}
          onChange={handleRankChange}
          MenuProps={menuPaperProps}
          inputProps={inputProps}
        >
          {rankOptions.map((option, idx) => {
            const rankShort = option.rank;
            let filtered = ranks.filter((rank) => {
              return rank.value.includes(rankShort);
            });
            if (filtered.length > 0) {
              return (
                <MenuItem
                  value={rankShort}
                  key={idx}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    minWidth: "230px",
                  }}
                  data-test-id={`${filtered[0].label}`}
                >
                  <T>{filtered[0].label}</T>
                  {rank === option.rank && (
                    <img src="icons/check.png" alt="checkmark" />
                  )}
                </MenuItem>
              );
            } else return null;
          })}
        </Select>
      </FormControl>
      <FormControl
        sx={{ mr: 2.5, ml: 0.5, flex: "1 1 100%" }}
        disabled={loading}
      >
        <InputLabel id="month-dropdown">
          <T>month</T>
        </InputLabel>
        <Select
          labelId="month-dropdown-label"
          id="month-select"
          data-test-id={`rank-qual-month-select`}
          value={month}
          label="Month"
          renderValue={(i) => {
            const selected = monthOptions.filter(
              (month) => month.value === i
            )[0];
            return <TM>{selected.label}</TM>;
          }}
          onChange={handleMonthChange}
          MenuProps={menuPaperProps}
          inputProps={inputProps}
        >
          <Box sx={subheaderStyle}>
            <T isUpperCase={true}>select_period</T>
          </Box>
          {monthOptions.map((item, idx) => {
            return (
              <MenuItem
                data-test-id={`rank-qual-month-dropdown-item`}
                value={item.value}
                key={idx}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  minWidth: "230px",
                }}
              >
                <TM>{item.label}</TM>
                {month === item.value && (
                  <img src="icons/check.png" alt="checkmark" />
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};

export { RankQualDropdowns };

const menuPaperProps = {
  PaperProps: {
    sx: {
      boxShadow:
        "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
      border: "1px solid rgba(145, 158, 171, 0.1)",
      borderRadius: "8px",
      marginTop: "5px",
    },
  },
  MenuListProps: {
    sx: {
      ".MuiButtonBase-root": {
        fontWeight: "500",
        fontSize: "14px",
        paddingLeft: "24px",
        paddingRight: "24px",
      },
      ".Mui-selected": {
        color: "rgba(0, 158, 207, 1)",
        backgroundColor: "rgba(0, 158, 207, 0.05)",
        fontWeight: "600",
      },
    },
  },
};

const inputProps = {
  sx: {
    color: "rgba(21, 56, 98, 1)",
    fontSize: "12px",
    fontWeight: "500",
    padding: "8px 16px 8px 16px",
  },
};

const subheaderStyle = {
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: 10,
  color: "#8A9BB0",
  marginTop: "8px",
  marginBottom: "8px",
  marginLeft: "24px",
};
