import React, { useContext } from "react";
import { T } from "../hooks/useTranslate";

import { Stack, Box, MenuItem, FormControl, Select, IconButton, Typography } from "@mui/material";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { menuPropsStyles } from "../screens/EarningsScreen/EarningsScreen.styles";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  useGridApiContext,
  gridPageSelector,
  gridPageSizeSelector,
  gridPaginationRowRangeSelector,
  gridPageCountSelector
} from '@mui/x-data-grid-pro';
import { LanguageContext } from "../hooks/LanguageContext";

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50];

const ClientSidePagination = ({ pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS, perPageKey }) => {
  const apiRef = useGridApiContext();
  const { localizeNumber, languageDir } = useContext(LanguageContext);

  const theme = useTheme();
  const viewportIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const totalRows = apiRef.current.getRowsCount(); // total rows
  const pageCount = gridPageCountSelector(apiRef); // current total pages
  const page = gridPageSelector(apiRef); // current page
  const gridPageSizeSelector1 = gridPageSizeSelector(apiRef); // current pageSize
  const gridPaginationRowRangeSelector1 = gridPaginationRowRangeSelector(apiRef); // {firstRowIndex: 0, lastRowIndex: 9}

  const firstRowIndex = gridPaginationRowRangeSelector1 ? gridPaginationRowRangeSelector1.firstRowIndex + 1 : 0;
  const secondRowIndex = gridPaginationRowRangeSelector1 ? gridPaginationRowRangeSelector1.lastRowIndex + 1 : 0;

  const nextPage = () => {
    apiRef.current.setPage(page + 1);
  }

  const backPage = () => {
    apiRef.current.setPage(page - 1);
  }

  const firstPage = () => {
    apiRef.current.setPage(0);
  }

  const lastPage = () => {
    apiRef.current.setPage(pageCount);
  }

  const handleSelectDropdown = (e) => {
    apiRef.current.setPageSize(e.target.value);
  }

  const iconStyling = {
    border: "1px solid rgba(145, 158, 171, 0.2);",
    borderRadius: "4px",
    padding: 0,
    marginRight: "8px",
    transform: languageDir === "rtl" ? "rotate(180deg)" : "none",
  };

  return (
    <Box
      id="pagination-container"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15.395px 15px 15.395px 15px",
      }}
    >
      <Stack
        direction="row"
        data-test-id={`row-count`}
        gap={.4}>
        <Typography variant="h5">
          {localizeNumber(firstRowIndex)}
        </Typography>
        <Typography variant="h5">
          -
        </Typography>
        <Typography variant="h5">
          {localizeNumber(secondRowIndex)}
        </Typography>
        <Typography variant="h5">
          <T>of</T>
        </Typography>
        <Typography variant="h5">
          {localizeNumber(totalRows)}
        </Typography>
      </Stack>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{ marginRight: "24px", display: "flex", alignItems: "center" }}
        >
          {viewportIsLarge && (
            <Box sx={{ marginRight: "8px", fontSize: "12px" }}><T>{perPageKey}</T>:</Box>
          )}
          <FormControl size="small">
            <Select
              labelId="page-size-select-label"
              id="page-size-select"
              aria-label="change page size"
              value={gridPageSizeSelector1}
              onChange={handleSelectDropdown}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={menuPropsStyles}
            >
              {pageSizeOptions.map((option, idx) => (
                <MenuItem value={option} key={idx} aria-label={`select ${option}`}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            aria-label="go to first page"
            id="first-page-button"
            sx={iconStyling}
            name="first-page-icon"
            onClick={firstPage}
            disabled={page === 0}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            aria-label="go to page before"
            id="before-page-button"
            sx={iconStyling}
            name="nav-before-icon"
            onClick={backPage}
            disabled={page === 0}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            aria-label="go to next page"
            id="next-page-button"
            sx={iconStyling}
            name="nav-next-icon"
            onClick={nextPage}
            disabled={page === pageCount - 1}
          >
            <NavigateNextIcon />
          </IconButton>
          <IconButton
            aria-label="go to last page"
            id="last-page-button"
            sx={iconStyling}
            name="last-page-icon"
            onClick={lastPage}
            disabled={page === pageCount - 1}
          >
            <LastPageIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export { ClientSidePagination };
