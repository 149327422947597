import React from "react";
import { T } from "../../../hooks/useTranslate";

import { Button } from "@mui/material";

const SaveChangesButton = ({
  styles,
  handleSaveChanges,
  enableSaveChanges,
  buttonText
}) => {
  return (
    <Button
      data-test-id="save-changes-button"
      onClick={handleSaveChanges}
      disableRipple={!enableSaveChanges}
      disabled={!enableSaveChanges}
      sx={{
        ...styles.button,
        ...(!enableSaveChanges && styles.disabledButton),
        "&.Mui-disabled": {
          color: "#fff"
        }
      }}
    >
      <T>{buttonText?buttonText:"save_changes"}</T>
    </Button>
  )
}

export { SaveChangesButton }