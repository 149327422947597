import React from 'react'
import { Stack, Typography } from "@mui/material";
import { T } from "../hooks/useTranslate";

const chipBaseStyle = {
  display: "flex",
  alignItems: "center",
  gap: '2px',
  borderRadius: '38px'
}

const textBaseStyle = {
  textAlign: "center",
  fontSize: "9px",
  fontStyle: "normal",
  fontWeight: "600",
  lineHeight: "normal",
  fontFamily: "Inter",
}

const Chip = ({
  customChipStyles = {},
  customTextStyles = {},
  translationKey,
  children
}) => {
  
  return (
    <Stack
      sx={{ ...chipBaseStyle, ...customChipStyles }}
      justifyContent="center"
    >
      <Typography sx={{ ...customTextStyles, ...textBaseStyle }}>
        <T>{translationKey}</T>{children && children}
      </Typography>
    </Stack>
  )
}

export default Chip