import React from "react"

import { Link } from "@mui/material"

const ReferralsLink = ({ text, handleToggle }) => {
  return (
    <Link
      noWrap
      sx={{ fontSize: "12px", cursor: "pointer" }}
      onClick={handleToggle}>
      {text}
    </Link>
  )
}

export { ReferralsLink }