import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { Box, Typography, Stack } from "@mui/material";

import { OfficeBox } from "../../components/OfficeBox";
import { OfficePage } from "../../components/OfficePage";
import { CenturionProgressCircle } from "../../components/CenturionProgressCircle";
import { CenturionLevelBoxWrapper } from "./CenturionLevelBoxWrapper";
import { ExplainedButton } from "../../components/ExplainedButton";
import { CenturionDataGrid } from "./CenturionDataGrid";
import { CenturionDataGridMobile } from "./CenturionDataGridMobile";
import { CenturionQualificationsHeader } from "./CenturionQualificationsHeader";

import { MemberContextProvider } from "../../hooks/MemberContext";
import "./CenturionScreen.css";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CenturionContext } from "hooks/CenturionContext";

const CenturionScreen = () => {
  const theme = useTheme();
  const viewportIsSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const viewportIsTabletAndBelow = useMediaQuery(theme.breakpoints.down("913"));
  const { sales } = useContext(CenturionContext);

  return (
    <OfficePage pageTitle={"centurion"}>
      <MemberContextProvider>
        <Box sx={{ width: "100%" }}>
          <Stack spacing={'18px'} direction={viewportIsTabletAndBelow ? "column" : "row"}>
            <OfficeBox sx={{ flex: "1 1 100%" }}>
              <Box sx={{ padding: "24px" }}>
                <CenturionQualificationsHeader />
                <Typography variant="h6" data-test-id="centurion-qual-subheader1" mt={3}>
                  <T isUpperCase={true}>award_progress</T>
                </Typography>
                <Box sx={{
                  margin: "0 auto",
                  height: !viewportIsSmall ? "240px" : "180px",
                  minWidth: !viewportIsSmall ? "240px" : "180px",
                  marginTop: !viewportIsSmall ? "87px" : "40px",
                  marginBottom: sales < 100
                    ? "0px"
                    : !viewportIsSmall 
                      ? "80px"
                      : !viewportIsSmall 
                        ? 0
                        : "80px"
                }}>
                  <Stack flexDirection="row" justifyContent="center">
                    <CenturionProgressCircle />
                  </Stack>
                </Box>
              </Box>
              <Box sx={{ padding: "24px" }}>
                <Typography variant="h6" sx={{ marginBottom: "24px" }} data-test-id="centurion-qual-subheader2">
                  <T isUpperCase={true}>bonuses</T>
                </Typography>
                <CenturionLevelBoxWrapper />
              </Box>
            </OfficeBox>
            {
              // desktop DataGrid
              !viewportIsTabletAndBelow ?
                <OfficeBox sx={{ flex: "1 1 100%" }}>
                  <CenturionDataGrid sales={sales} />
                </OfficeBox> : <OfficeBox sx={{ marginTop: "24px" }}>
                  <CenturionDataGridMobile sales={sales} />
                </OfficeBox>
            }
          </Stack>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", margin: "40px" }}>
          <ExplainedButton
            type="centurion"
            testId="centurion-explained"
            imgUrl="icons/centurion-info.svg"
            altText="centurion explained info"
            buttonText="centurion_explained"
            urlKey="centurion_explained_url" />
        </Box>
      </MemberContextProvider>
    </OfficePage>
  )
}

export { CenturionScreen }