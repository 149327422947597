import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { T } from "../../../hooks/useTranslate";
import { useMixPanel } from "../../../hooks/useMixPanel";

import { Stack, Typography } from "@mui/material";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { LanguageContext } from "../../../hooks/LanguageContext";
import { MemberContext } from "../../../hooks/MemberContext";

const RankQualBox = ({ color1, color2, legNumber, name, leg, inDrawer, thirdLegCount }) => {
  const { localizeNumber } = useContext(LanguageContext);
  const { memberId, isDistributorLevelLimit } = useContext(MemberContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { logEvent } = useMixPanel();

  const isClickAllowed = !(name === undefined || !leg?.value || isDistributorLevelLimit)

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: color2
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: color1,
    },
  }));

  // linear progress bar doesn't handle big numbers over 100%
  const calculateProgress = () => {
    if (!leg || leg.value === undefined) {
      return 0
    }
    return leg.value === 0 && leg.requiredValue === 0 ? 0 :
      leg.value / leg.requiredValue * 100 > 100 ? 100 :
        leg.value / leg.requiredValue * 100;
  };

  const formatName = () => {
    if (JSON.stringify(name) === "{}") {
      return "-";
    } else if (name === undefined) {
      return "-";
    } else if (legNumber === "3") {
      return <>{localizeNumber(thirdLegCount.members)} <T>members</T> / {localizeNumber(thirdLegCount.partners)} <T>partners</T></>;
    } else {
      return name.humanName.fullName;
    }
  };

  const handleBoxClick = () => {
    // if leg box is empty, do nothing
    if (!isClickAllowed) return;
    // for leg box 1 and 2, navigate to partner profile, for leg box 3, navigate to partners page
    if (["1", "2"].includes(legNumber)) {
      logEvent("open_profile", {
        location: inDrawer ? "rank_tracker_sidebar" : "rank_tracker_dashboard",
        user_type: "partner",
        leg_number: legNumber
      })
      navigate(`${location.pathname}?drawer=partner&id=${name.id.unicity}&drawerContent=profile`)
    } else {
      logEvent("partners_view", {
        location: inDrawer ? "rank_tracker_sidebar" : "rank_tracker_dashboard",
        user_type: "partner",
      })
      memberId ? navigate(`/partners/${memberId}`) : navigate(`/partners`)
    }
  }

  return (
    <Stack
      sx={{
        padding: "16px",
        background: !inDrawer && "rgba(145, 158, 171, 0.05)",
        borderRadius: "8px",
        cursor: (!isClickAllowed) ? "not-allowed" : "pointer",
        "&:hover": {
          background: "rgba(145, 158, 171, 0.1)"
        }
      }}
      gap="10px"
      onClick={handleBoxClick}>
      <BorderLinearProgress variant="determinate" value={calculateProgress()} data-test-id={`leg-${legNumber}-progress-bar`} />
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap="4px">
          <Stack
            data-test-id={`leg-${legNumber}-chip`}
            justifyContent="center"
            sx={{
              backgroundColor: color2,
              color: color1,
              fontSize: "9px",
              fontWeight: "600",
              padding: "2px 7px",
              borderRadius: "38px"
            }}>
            <T>leg</T> {legNumber}
          </Stack>
          <Typography
            data-test-id={`leg-${legNumber}-name`}
            variant="h9"
          >
            {formatName()}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Typography
            data-test-id={`leg-${legNumber}-value`}
            variant="h9"
          >
            {leg?.value ? localizeNumber(leg.value) : 0}&nbsp;
          </Typography>
          <Typography
            data-test-id={`leg-${legNumber}-required-value`}
            variant="h9"
            sx={{
              opacity: ".6"
            }}
          >
            {`/ ${leg?.requiredValue ? localizeNumber(leg.requiredValue) : 0}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { RankQualBox };
