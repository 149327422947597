import { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { FormControl, Stack, Typography } from "@mui/material";
import { IosStyleSwitch } from "../../components/IosStyleSwitch";
import { PartnersContext } from "../../hooks/PartnersContext";
import { useMixPanel } from "../../hooks/useMixPanel";

const PartnersIncludeMembersToggle = ({ viewportIsSmall }) => {
  const { includeMembers, setIncludeMembers } = useContext(PartnersContext);
  const { logEvent } = useMixPanel();

  const handleChange = () => {
    setIncludeMembers(!includeMembers);
    logEvent("partners_toggle_include_members", {
      location: 'partners',
      include_members: !includeMembers
    })
  }

  return (
    <Stack
      direction={viewportIsSmall ? "row" : "row-reverse"}
      alignItems="center"
      gap={1}
    >
      <Typography variant="h4"><T>partners_toggle_text</T></Typography>
      <FormControl>
        <IosStyleSwitch
          checked={includeMembers}
          onChange={handleChange}
        />
      </FormControl>
    </Stack>
  )
}

export { PartnersIncludeMembersToggle }