export const selectStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(145, 158, 171, 0.20)",
    borderRadius: "8px"
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#DDDDDD',
  }
};

export const menuPropsStyles = {
  PaperProps: {
    sx: {
      boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(145, 158, 171, 0.1)",
      border: "1px solid rgba(145, 158, 171, 0.1)",
      borderRadius: "8px",
      marginTop: "5px"
    }
  },
  MenuListProps: {
    sx: {
      ".MuiButtonBase-root": {
        fontWeight: "500",
        fontSize: "12px",
        paddingLeft: "24px",
        paddingRight: "24px"
      },
      ".Mui-selected": {
        color: "rgba(0, 158, 207, 1)",
        backgroundColor: "rgba(0, 158, 207, 0.05)",
        fontWeight: "600"
      }
    }
  }
};

export const inputPropsStyles = {
  sx: {
    color: "rgba(21, 56, 98, 1)",
    fontSize: "12px",
    fontWeight: "500",
    padding: "9px 32px 9px 12px",
    minWidth: "6.3rem"
  }
};

export const dateRangeStyles = {
  borderRadius: "38px",
  backgroundColor: "#43C06F1A",
  color: "#43C06F",
  fontSize: "10px",
  fontWeight: "600",
  padding: "3px 8px"
}

export const projectedEarningStyle = (isViewPortSmall = false) => ({
  color: "#009ECF",
  fontFamily: "Inter",
  fontSize: "13px",
  fontWeight: "600",
  lineHeight: "16px",
  padding: isViewPortSmall ? "4px 0px" : "3px 16px",
})

export const statementButtonStyles = {
  height: "44px",
  padding: "0px 8px 0px 12px",
  border: "1px solid rgba(145, 158, 171, 0.20)",
  borderRadius: "4px",
  fontFamily: "Inter",
  fontSize: "12px",
  fontWeight: "600",
  color: "#153862",
  whiteSpace: "nowrap",
  flexShrink: 0
}