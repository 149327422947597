const RANK_DETAILS = [
  {
    rankId: 0,
    rankFull: "Member",
    rankShort: "Member",
    rankCode: "",
    rankTranslateFullKey: "member",
    rankTranslateAbbrKey: "member",
    backgroundColor: 'rgba(237, 239, 241, 1)',
    color: "rgba(145, 158, 171, 1)"
  },
  {
    rankId: 1,
    rankFull: "Distributor",
    rankShort: "Distributor",
    rankCode: "Dst",
    rankTranslateFullKey: "distributor_full",
    rankTranslateAbbrKey: "distributor",
    backgroundColor: 'rgba(237, 239, 241, 1)',
    color: "rgba(145, 158, 171, 1)"
  },
  {
    rankId: 3,
    rankFull: "Manager",
    rankShort: "Manager",
    rankCode: "Mgr",
    rankTranslateFullKey: "manager_full",
    rankTranslateAbbrKey: "manager",
    backgroundColor: 'rgba(255, 173, 145, 0.15)',
    color: "rgba(255, 155, 121, 1)"
  },
  {
    rankId: 4,
    rankFull: "Senior Manager",
    rankShort: "Sr. Manager",
    rankCode: "SrM",
    rankTranslateFullKey: "senior_manager_full",
    rankTranslateAbbrKey: "senior_manager",
    backgroundColor: 'rgba(255, 173, 145, 0.15)',
    color: "rgba(255, 155, 121, 1)"
  },
  {
    rankId: 5,
    rankFull: "Executive Manager",
    rankShort: "Exec. Manager",
    rankCode: "ExM",
    rankTranslateFullKey: "executive_manager_full",
    rankTranslateAbbrKey: "executive_manager",
    backgroundColor: 'rgba(255, 173, 145, 0.15)',
    color: "rgba(255, 155, 121, 1)"
  },
  {
    rankId: 6,
    rankFull: "Director",
    rankShort: "Director",
    rankCode: "Dir",
    rankTranslateFullKey: "director_full",
    rankTranslateAbbrKey: "director",
    backgroundColor: 'rgba(67, 192, 111, 0.15)',
    color: "rgba(67, 192, 111, 1)",
    leg1Req: 5000,
    leg2Req: 3000,
    leg3Req: 1000
  },
  {
    rankId: 7,
    rankFull: "Senior Director",
    rankShort: "Sr. Director",
    rankCode: "SrD",
    rankTranslateFullKey: "senior_director_full",
    rankTranslateAbbrKey: "senior_director",
    backgroundColor: 'rgba(67, 192, 111, 0.15)',
    color: "rgba(67, 192, 111, 1)"
  },
  {
    rankId: 8,
    rankFull: "Executive Director",
    rankShort: "Exec. Director",
    rankCode: "ExD",
    rankTranslateFullKey: "executive_director_full",
    rankTranslateAbbrKey: "executive_director",
    backgroundColor: 'rgba(67, 192, 111, 0.15)',
    color: "rgba(67, 192, 111, 1)",
    leg1Req: 20000,
    leg2Req: 10000,
    leg3Req: 5000
  },
  {
    rankId: 9,
    rankFull: "Presidential Director",
    rankShort: "Pres. Director",
    rankCode: "PrD",
    rankTranslateFullKey: "presidential_director_full",
    rankTranslateAbbrKey: "presidential_director",
    backgroundColor: "rgba(0, 158, 207, 0.15)",
    color: "rgba(0, 158, 207, 1)"
  },
  {
    rankId: 10,
    rankFull: "Presidential Sapphire",
    rankShort: "Pres. Sapphire",
    rankCode: "PrS",
    rankTranslateFullKey: "presidential_sapphire_full",
    rankTranslateAbbrKey: "presidential_sapphire",
    backgroundColor: "rgba(0, 158, 207, 0.15)",
    color: "rgba(0, 158, 207, 1)"
  },
  {
    rankId: 11,
    rankFull: "Presidential Ruby",
    rankShort: "Pres. Ruby",
    rankCode: "PrR",
    rankTranslateFullKey: "presidential_ruby_full",
    rankTranslateAbbrKey: "presidential_ruby",
    backgroundColor: "rgba(0, 158, 207, 0.15)",
    color: "rgba(0, 158, 207, 1)"
  },
  {
    rankId: 12,
    rankFull: "Presidential Diamond",
    rankShort: "Diamond",
    rankCode: "DIA",
    rankTranslateFullKey: "presidential_diamond_full",
    rankTranslateAbbrKey: "presidential_diamond",
    backgroundColor: "rgba(186, 113, 174, 0.15)",
    color: "rgba(186, 113, 174, 1)"
  }
]

export { RANK_DETAILS }