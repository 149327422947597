import styled from "styled-components";

const LogoDiv = styled.div`
    color: rgb(4, 158, 207);
    font-size: 1.25em;
    font-weight: 450;
  `;

const UnicityOfficeLogo = ({ children, ...props }) => {
  return (
    <div
      {...props}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "10px"
      }}
    >
      <img alt="Unicity Logo" src="icons/logo-unicity.svg" />
      <LogoDiv>OFFICE</LogoDiv>
    </div>
  );
};

export { UnicityOfficeLogo };
