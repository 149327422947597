import React, { useContext } from "react";
import { T } from "../../hooks/useTranslate";

import { Stack, Typography, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ActivityFeedItem } from "./ActivityFeedItem";
import { ActivityFeedContext } from "../../hooks/ActivityFeedContext";
// import { ActivityFeedSettingsButton } from "./ActivityFeedSettingsButton"

const ActivityFeedContent = ({ handleClose }) => {
  const { activities, loading, newCount } = useContext(ActivityFeedContext);

  const activitiesMarkedUnseen = activities.filter(activity => activity.isSeen === "false");

  return (
    <Stack>
      <Stack padding={3} direction="row" justifyContent={"space-between"}>
        <CloseIcon
          data-test-id="activity-feed-close-icon"
          sx={{ cursor: "pointer" }}
          onClick={handleClose}
        />
        {/* Settings button is built and ready for functionality */}
        {/* <ActivityFeedSettingsButton /> */}
      </Stack>
      <Stack pl={3} pr={3} mt={3} spacing={1} direction="row">
        <Typography variant="h2" data-test-id="activity-feed-header">
          <T>recent_activity</T>
        </Typography>
        {!loading && newCount > 0 && activitiesMarkedUnseen.length !== 0 && (
          <Stack justifyContent="center" data-test-id="activity-feed-chip">
            <Typography variant="span" sx={newChipStyle}>
              {newCount} <T>new</T>
            </Typography>
          </Stack>
        )}
      </Stack>

      {!loading && activities.length > 0 && (
        <Stack mt={6} spacing={2}>
          {activities.length > 0 &&
            activities.map((item, idx) => {
              return (
                <ActivityFeedItem
                  props={item}
                  key={idx}
                  isLastItem={idx + 1 === activities.length}
                  handleClose={handleClose}
                />
              );
            })}
        </Stack>
      )}

      {!loading && activities.length === 0 && (
        <Typography sx={{ padding: '2em' }}><T>no_activities_to_display</T></Typography>
      )}

      {loading && (
        <Stack mt={10} alignItems="center">
          <CircularProgress data-test-id="progress-bar-shown" />
        </Stack>
      )}
    </Stack>
  );
};

export { ActivityFeedContent };

const newChipStyle = {
  backgroundColor: "rgba(0, 158, 207, 0.15)",
  color: "rgba(0, 158, 207, 1)",
  fontSize: "10px",
  fontWeight: "600",
  padding: "2px 8px",
  borderRadius: "38px",
};
