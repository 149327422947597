import React, { useContext } from "react";
import { T } from "../../../hooks/useTranslate";

import { Stack } from "@mui/material"

import { RANK_DETAILS } from "../../../common/rankDetails"

import { formatDecimalPlaces } from "../../../common/date-functions"
import { LanguageContext } from "../../../hooks/LanguageContext";

const OvToNextRankCell = ({ params }) => {
  const { localizeNumber } = useContext(LanguageContext);
  // default to manager in the case of Non-Manager
  const nextRank = params.nextRank === "Non-Manager" ? "Manager" : params.nextRank;
  const customerTypeIsPartner = params.hasOwnProperty('is100PvActive');

  const rankDetails = RANK_DETAILS.filter(rank => {
    return rank.rankFull === nextRank
  })

  // do not render this component for members or if rank details are not found
  if (!customerTypeIsPartner || rankDetails.length === 0) return null;

  return (
    <Stack
      data-field="ov-to-next-rank-cell-container"
      data-test-id="ov-to-next-rank-cell-container"
      direction="row"
      sx={{
        borderRadius: "38px",
        backgroundColor: `${rankDetails[0].backgroundColor}`
      }}>
      <Stack
        data-field="ov-to-next-rank-value"
        data-test-id="ov-to-next-rank-value"
        sx={{
          fontWeight: "600",
          fontSize: "10px",
          padding: "4px 8px 4px 8px",
          borderRight: "2px solid white",
          borderRadius: "38px",
          backgroundColor: "#EDEFF1",
          boxSizing: "border-box"
        }}>
        {localizeNumber(formatDecimalPlaces(params.totalOvToNextRank))}
      </Stack>
      <Stack
        data-field="ov-to-next-rank-text"
        data-test-id="ov-to-next-rank-text"
        sx={{
          color: `${rankDetails[0].color}`,
          borderRadius: "38px",
          fontWeight: "600",
          fontSize: "10px",
          padding: "4px 8px 4px 4px"
        }}>
        <T>{rankDetails[0].rankTranslateAbbrKey}</T>
      </Stack>
    </Stack>
  )
}

export { OvToNextRankCell }
