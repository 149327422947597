import React from "react";

import { FilterButton } from "../../components/filters/FilterButton"
import { PurchasePeriodMenu } from '../../components/filters/PurchasePeriodMenu';
import { SubscriptionMenu } from '../../components/filters/SubscriptionMenu';
import { ReferralsMenu } from '../../components/filters/ReferralsMenu';
import { ProductMenu } from '../../components/filters/ProductMenu';
import { ManageColumns } from '../../components/filters/ManageColumns';

const DataGridFilterButtons = ({
  filterModel,
  setFilterModel,
  handlePageChange,
  apiInProgress,
  columnVisibility,
  setColumnVisibility
}) => {
  return (
    <>
      <FilterButton
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        defaultButtonText={"purchase_period"}
        filterModelKey={"purchasePeriodStartDate"}
        apiInProgress={apiInProgress}
        MenuComponent={PurchasePeriodMenu}
        handlePageChange={handlePageChange}
      />
      <FilterButton
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        defaultButtonText={"products"}
        filterModelKey={"filterHasFeelGreat"}
        apiInProgress={apiInProgress}
        MenuComponent={ProductMenu}
        handlePageChange={handlePageChange}
      />
      <FilterButton
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        defaultButtonText={"referrals"}
        filterModelKey={"filterHasReferral"}
        apiInProgress={apiInProgress}
        MenuComponent={ReferralsMenu}
        handlePageChange={handlePageChange}
      />
      <FilterButton
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        defaultButtonText={"subscriptions"}
        filterModelKey={"filterHasSubscription"}
        apiInProgress={apiInProgress}
        MenuComponent={SubscriptionMenu}
        handlePageChange={handlePageChange}
      />
      <ManageColumns
        loading={apiInProgress}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
      />
    </>
  )
}

export { DataGridFilterButtons }