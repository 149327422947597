import React, { useState, useContext } from 'react';
import { T } from "../../hooks/useTranslate";

import { Box } from "@mui/material"
import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { styles } from "../../themes/datagrid.styles";

import { PartnersManageColumnsMenu } from './PartnersManageColumnsMenu';
import { PartnersContext } from "../../hooks/PartnersContext";
import { getFilterButtonStyling } from './getFilterButtonStyling';


const PartnersManageColumnsButton = () => {
  const { loading } = useContext(PartnersContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleButtonClick = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        id="partners-manage-columns-button"
        aria-controls={open ? "partners-manage-columns-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="open partners manage columns menu"
        data-test-id={`partners-manage-columns-button`}
        onClick={handleButtonClick}
        style={getFilterButtonStyling(loading, open, false)}
        sx={{
          ...styles.button,
          paddingLeft: ".75rem",
          paddingRight: ".75rem",
          opacity: loading ? ".5" : null,
          "&:hover": {
            cursor: loading ? "not-allowed" : "pointer",
            backgroundColor: "#FAFAFA"
          }
        }}>
        <ViewColumnOutlinedIcon sx={{ marginRight: "5px" }} />
        <T>manage_columns</T>
      </Box >
      <PartnersManageColumnsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
      />
    </>
  );
};

export { PartnersManageColumnsButton };
