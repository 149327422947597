import { T } from "../../hooks/useTranslate";

import { Box, Typography } from "@mui/material";

import { CopyToClipboard } from "../CopyToClipboard";

import {
  gridTextStyle,
  gridChildLeftWrapper,
} from "./drawer-styles";

const ProfileReferralLinkLine = ({ customerHrefData, referralCode, drawerType, eventLocation }) => {
  const link = `${process.env.REACT_APP_FEEL_GREAT_REFERRAL_URL}/${referralCode}`
  return (
    <>
      <Box sx={gridChildLeftWrapper}>
        <img src="icons/link-icon.svg" alt="link icon" style={{ height: 18, width: 18 }} />
      </Box>
      {typeof referralCode === "string" || referralCode === "" ? (
        <Box component="span" sx={{ fontSize: "12px" }}>
          <CopyToClipboard
            text={link}
            stringToCopy={link}
            iconId={`${drawerType}-profile-copy-referral-link`}
            eventName="referral_link_copy"
            sendEvent={true}
            userType={drawerType}
            eventLocation={eventLocation}
            userId={customerHrefData.unicity}
          />
        </Box>
      ) : (
        <Typography sx={gridTextStyle} data-testid={`${drawerType}-profile-referral-link`}>
          <T>not_available</T>
        </Typography>
      )}
    </>
  )
}

export { ProfileReferralLinkLine }